<template>
    <div>
        <ClubCard v-if="$store.getters.loggedinUser.role === 'NEAS'"/>

        <EditClubCardAsNEL v-if="$store.getters.loggedinUser.role === 'NEL'"/>
        <EditClubCardAsYPLP v-if="$store.getters.loggedinUser.role === 'YLPL'"/>
    </div>
</template>

<script>
    import ClubCard from "./NEAS/ClubCard";
    import EditClubCardAsNEL from "./NEL/EditClubCardAsNEL";
    import EditClubCardAsYPLP from "./YPLP/EditClubCardAsYPLP";
    export default {
        name: "Dashboard",
        components: {EditClubCardAsYPLP, ClubCard, EditClubCardAsNEL},
    }
</script>

<style scoped>

</style>
