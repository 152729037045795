import Vue from "vue";
import VueRouter from "vue-router";
import HomePage from "./components/HomePage";
import Confirmation from "./components/Confirmation";
import InsertClubVATForCheck from "./components/NEAS/InsertClubVATForCheck";
import ClubCard from "./components/NEAS/ClubCard";
import EditClubCardAsNEL from "./components/NEL/EditClubCardAsNEL";
import Dashboard from "./components/Dashboard";
import store from './store.js';
import ClubContactInfo from "./components/NEL/ClubContactInfo";
import ApplicationForm from "./components/NEL/ApplicationForm";
import Members from "./components/NEL/Members";
import RoleSelection from "./components/RoleSelection";
import HistoryOfClub from "./components/NEL/HistoryOfClub";
import OfficialsCard from "./components/officials/OfficialsCard.vue";
import Clubs from "./components/officials/Clubs";
import TAXISNETComponent from "./components/TAXISNETComponent";
import HistoryOfMemberfile from "./components/reusable/HistoryOfMemberfile.vue"
import SearchForMember from "@/components/reusable/SearchForMember.vue";
import ClubVATCheck from "./components/NEL/ClubVATCheck";
import SubmittedApplications from "./components/officials/SubmittedApplications";
import SelectClub from "@/components/NEL/SelectClub.vue";
import Branches from "@/components/NEL/Branches.vue";
import ApplicationInfo from "@/components/NEL/ApplicationInfo.vue";
import Places from "@/components/NEL/Places.vue";
import SelectBranch from "@/components/YPLP/SelectBranch.vue";
import EditClubCardAsYPLP from "@/components/YPLP/EditClubCardAsYPLP.vue";
import BranchMembers from "@/components/YPLP/BranchMembers";
import SearchApplications from "@/components/officials/SearchApplications.vue";
import PlaceLicencing from "@/components/officials/PlaceLicencing.vue";
import DevLogin from "@/components/Development/DevLogin.vue";
import DevLoginPa from "@/components/Development/DevLoginPa.vue";
import MemberDashboard from "@/components/Member/MemberDashboard.vue";
import MemberHistory from "@/components/Member/MemberHistory.vue";
import MemberCurrent from "@/components/Member/MemberCurrent.vue";
import ClubIdentification from "@/components/NEAS/ClubIdentification.vue";
import ClubConsent from "@/components/NEAS/ClubConsent.vue";
import ChangeNEL from "@/components/NEAS/ChangeNEL.vue";
import ClubInfo from "@/components/NEAS/ClubInfo.vue";
import HistoryOfActionsForNEAS from "@/components/NEAS/HistoryOfActionsForNEAS.vue";
import dissolutionClub from "@/components/NEAS/dissolutionClub.vue";
import HistoryOfClubBySeason from "@/components/NEAS/HistoryOfClubBySeason.vue";
import CouncilMembers from "@/components/NEL/CouncilMembers.vue";
import NotificationsPage from "@/components/reusable/NotificationsPage.vue";
import BranchPlaces from "@/components/YPLP/BranchPlaces.vue";
import searchClubs from "@/components/reusable/SearchClubs.vue";
import HistoryOfClubsForOfficials from "@/components/officials/HistoryOfClubsForOfficials.vue";
import HistoryOfAT from "@/components/officials/HistoryOfAT.vue";
import VueCookies from 'vue-cookies';
import requests from "@/requests";

Vue.use(VueRouter);

const routes = [
    {path: '', component: HomePage},
    {path: '/confirmation', component: Confirmation},
    {path: '/check-vat-club', component: InsertClubVATForCheck},
    {path: '/club-card', component: ClubCard},
    //{path: '/edit-club-card', component: EditClubCardAsNEL},
    {path: '/dashboard', component: Dashboard},
    {path: '/club-contact-info', component: ClubContactInfo},
    {path: '/application-form', component: ApplicationForm},
    {path: '/members', component: Members},
    {path: '/role-selection', component: RoleSelection},
    {path: '/history-of-club', component: HistoryOfClub},
    {path: '/officials-card', component: OfficialsCard},
    {path: '/clubs', component: searchClubs},
    {path: '/fan-club-registry', component: TAXISNETComponent},
    {path: '/history-of-uploaded-memberfiles', component: HistoryOfMemberfile},
    {path: '/search-for-members', component: SearchForMember},
    {path: '/nel-dashboard', component: EditClubCardAsNEL},
    {path: '/club-vat-check', component: ClubVATCheck},
    {path: '/submitted', component: SubmittedApplications},
    {path: '/select-club', component: SelectClub},
    {path: '/view-branches', component: Branches},
    {path: '/branch-places', component: BranchPlaces},
    {path: '/application-info', component: ApplicationInfo},
    {path: '/view-places', component: Places},
    {path: '/select-branch', component: SelectBranch},
    {path: '/ypl-dashboard', component: EditClubCardAsYPLP},
    {path: '/branch-members', component: BranchMembers},
    {path: '/search-for-applications', component: SearchApplications},
    {path: '/place-licencing', component: PlaceLicencing},
    {path: '/dev-login', component: DevLogin},
    {path: '/dev-login-pa', component: DevLoginPa},
    {path: '/member-dashboard', component: MemberDashboard},
    {path: '/member', component: MemberHistory},
    {path: '/member-current', component: MemberCurrent},
    {path: '/club-identification', component: ClubIdentification},
    {path: '/club-consent', component: ClubConsent},
    {path: '/change-club-representative', component: ChangeNEL},
    {path: '/club-info', component: ClubInfo},
    {path: '/history-of-actions', component: HistoryOfActionsForNEAS},
    {path: '/dissolution-club', component: dissolutionClub},
    {path: '/council-members', component: CouncilMembers},
    {path: '/history-of-club-by-season', component: HistoryOfClubBySeason},
    {path: '/history-of-clubs-officials', component: HistoryOfClubsForOfficials},
    {path: '/notifications-page', component: NotificationsPage},
    {path: '/history-of-at', component: HistoryOfAT},
];

const router = new VueRouter({
    routes: routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    document.title = 'Μητρώο Μελών Λεσχών Φιλάθλων';

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('jwt_token');

    if (token) {
        document.cookie = `jwt_token=${token}; path=/`;
    }

    const isAuthenticated = document.cookie.includes('jwt_token=');
    console.log("isAuthenticated?");
    console.log(isAuthenticated);
    requests.postRequest('properties/dev-mode', {}, getDevModeCallback)


    function getDevModeCallback(response) {
        const devMode = response.data;
        const requiresAuth = !isAuthenticated && to.path != '/' && (!devMode || (devMode && to.path != '/dev-login' && to.path != '/dev-login-pa'));

        if (requiresAuth) {
            history.pushState(null, null, window.location.href);
            window.location.href = 'https://www.gov.gr';
        } else {
            next();
        }
    }
})
//

export default router
