<template>
  <div>

  </div>

</template>

<script>
import utils from "../utilities";
import requests from "../requests";

export default {
  name: "TAXISNETComponent",
  data: () => ({
    utils
  }),
  methods: {

    getCallback(response) {

    }
  },
  created() {
    const jwtToken = this.$cookies.get("jwt_token");

    // utils.setLocalStorage('jwt_token', jwtToken);

    requests.getSecureRequest("club/", '', this.getCallback)
  }

}
</script>

<style scoped>

</style>
