<template>
<div class="mb-4">
  <v-card>
    <v-card-title style="background-color: #003375; word-break: normal" class="white--text">
      <v-row align="center" justify="center" class="ma-2 text-h4">
        Λέσχη
      </v-row>
    </v-card-title>
    <v-divider/>
    <v-card-text>
      <ClubAndHistoryInfoForNeas :key="$store.getters.selectedSeasonKey" :editable=true />
    </v-card-text>
  </v-card>
</div>
</template>

<script>
import requests from "@/requests";
import router from "@/router";
import ClubAndHistoryInfoForNeas from "@/components/NEAS/ClubAndHIstoryInfoForNeas.vue";
export default {
  name: "ClubInfo",
  components: {ClubAndHistoryInfoForNeas},
  data: () => ({
  }),
  methods:{

    setMenuItems(){
      let menuItems = [
        {
          to: '/role-selection',
          name: 'Αρχική σελίδα'
        },
        {
          to: '/club-info',
          name: 'Στοιχεία Λέσχης',
          disabled: !this.$store.getters.initializationNEAS.exists
        },
        {
          to: '/history-of-club-by-season',
          name: ' Ιστορικό λέσχης',
          disabled: !this.$store.getters.initializationNEAS.exists
        },
        {
          name: 'Ενέργειες',
          children: [
            {
              to: '/club-consent',
              name: ' Χορήγηση/Ανανέωση συναίνεσης',
              //disabled: (this.$store.getters.selectedClub.clubstatus_id==2)
            },
            {
              to: '/change-club-representative',
              name: ' Αλλαγή υπευθύνου λέσχης φιλάθλων',
              disabled: (!this.$store.getters.initializationNEAS.exists ) /*|| this.$store.getters.selectedClub.clubstatus_id==2*/
            },
            {
              to: '/history-of-actions',
              name: ' Ιστορικό ενεργειών',
              disabled: !this.$store.getters.initializationNEAS.exists
            },

            {
              to: '/dissolution-club',
              name: ' Διάλυση λέσχης',
              disabled: !this.$store.getters.initializationNEAS.exists
            },
          ]
        },
        {
          to: '/search-for-members',
          name: 'Αναζήτηση μελών λέσχης',
          disabled: !this.$store.getters.initializationNEAS.exists
        },
        {
          to: '/notifications-page',
          name: 'Ειδοποιήσεις',
          disabled: false
        },

      ]
      this.$store.commit('setMenuItems', menuItems);
    },
  },
  created(){
    this.$store.commit('setNotificationIcon', true)

    if(!this.$store.getters.initializationNEAS.exists) {
      router.push('/club-consent')
    }
    this.setMenuItems();
  }
}
</script>


<style scoped>

</style>
