<template>
  <v-snackbar v-model="isShow">
    <span v-html="$store.getters.infoSnackBarObj.text">

    </span>
    <v-btn
        color="blue"
        text
        @click="closeSnackBar">
      Κλείσιμο
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: "InfoSnackBar",
  data: () => ({
    isShow: true
  }),
  methods: {

    closeSnackBar() {

      this.$store.commit('setInfoSnackBar', false);
    }
  },
  watch: {
    'isShow': function(newValue, oldValue) {
      if (!newValue) {
        this.$store.commit('setInfoSnackBar', false);
      }
    }
  }
}

</script>

<style scoped>

</style>