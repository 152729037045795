<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="mb-3">
    <v-row class="mb-1">
      <v-col class="mx-auto text-center font-weight-light headline">
        <div>
          {{ 'Αναζήτηση μελών' }}
        </div>
      </v-col>
    </v-row>
    <v-expansion-panels class="ma-5 pr-10" v-model="panel" multiple>
      <v-expansion-panel expand>
        <v-expansion-panel-header>
          <v-row>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="govgr-!-font-size-24">
                  Στοιχεία αναζήτησης
                </v-list-item-title>
                <v-list-item-subtitle>
                  Τα πεδία αναζήτησης είναι ανεξάρτητα και προαιρετικά
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-row>
          <v-spacer/>
          <template v-slot:actions>
            <v-icon color="primary">
              mdi-arrow-down-bold-circle-outline
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-divider/>
        <v-expansion-panel-content class="mt-6"
                                   v-if="($store.getters.loggedinUser.role == 'ΓΔΑ' ||
                                     $store.getters.loggedinUser.role == 'ΑΤ')">
          <v-form v-model="isFormValid" class="mb-2">
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <v-autocomplete label="Λέσχες"
                                    :items="clubs"
                                    return-object
                                    :item-text="item =>item.name"
                                    v-model="selectedClub"
                                    @change="enableBranches()"
                                    no-data-text="Δεν υπάρχουν καταχωρήσεις"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete label="Παραρτήματα"
                                    :disabled="disabledBranches"
                                    :items="branches"
                                    return-object
                                    :item-text="item =>item.name"
                                    v-model="selectedBranch"
                                    @change="clearRegionFilters()"
                                    no-data-text="Δεν υπάρχουν καταχωρήσεις"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="membersCardNumber" label="Αριθμός κάρτας μέλους">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical/>
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field v-model="membersAfm"
                                  label="ΑΦΜ μέλους"
                                  :rules="vatRule" counter="9" type="number"
                                  hide-spin-buttons>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="memberAmka"
                                  label="ΑΜΚΑ μέλους"
                                  :rules="nameRules">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-select label="Κατάσταση μέλους"
                              v-model="memberStatus"
                              :items="memberStatuses"
                              return-object
                              :item-text="item =>item.status"
                              no-data-text="Δεν υπάρχουν καταχωρήσεις">
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical/>
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field v-model="membersFirstName"
                                  label="Όνομα μέλους"
                                  :rules="nameRules">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="membersLastName"
                                  label="Επώνυμο μέλους"
                                  :rules="nameRules">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="membersIdentificationDocument"
                                  label="ΑΔΤ" :rules="nameRules">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="membersBirthYear"
                                  type="number"
                                  :mask="integerMask"
                                  step="1"
                                  :min="new Date(Date.now()).getFullYear()-150"
                                  :max="new Date(Date.now()).getFullYear()"
                                  :rules="yearRules">
                      <template v-slot:label>
                        <div style="margin-left: 2px;">Έτος Γέννησης</div>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical/>
              <v-col>
                <v-row>
                  <v-col>
                    <v-autocomplete label="Περιφέρεια"
                                    :items="perifereies"
                                    return-object
                                    :item-text="item =>item.name"
                                    v-model="perifereia"
                                    no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                    @change="autoSelectPerifereia()"
                                    :disabled="perifereies.length == 0"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete label="Περιφερειακή ενότητα"
                                    :items="nomoi"
                                    return-object
                                    :item-text="item =>item.name"
                                    v-model="nomos"
                                    no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                    @change="autoSelectNomos()"
                                    :disabled="nomoi.length == 0"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete label="Δήμος"
                                    :items="dhmoi"
                                    return-object
                                    :item-text="item =>item.name"
                                    v-model="dhmos"
                                    no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                    :disabled="dhmoi.length == 0"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-btn @click="exportTable()"
                   class="primary ma-2"
            >
              Έκδοση λίστας μελών
            </v-btn>
            <v-spacer/>
            <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-btn icon v-on="on" @click="clearSearch()" class="ma-2">
                  <v-icon color="primary">
                    mdi-broom
                  </v-icon>
                </v-btn>
              </template>
              <span>
                    Καθαρισμός
                  </span>
            </v-tooltip>
            <v-btn @click="searchForAllMembers()" class="ma-2">
              Εμφάνιση όλων
            </v-btn>
            <v-btn :disabled="searchButtonValid"
                   @click="searchForMembers()"
                   class="ma-2 primary">
              Αναζήτηση
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
        <v-expansion-panel-content class="mt-6"
                                   v-if="($store.getters.loggedinUser.role == 'NEL') ||
                                     ($store.getters.loggedinUser.role == 'NEAS') ||
                                     ($store.getters.loggedinUser.role == 'YLPL')">
          <v-form v-model="isFormValid" class="mb-2">
            <v-row>
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field v-model="membersAfm"
                                  label="ΑΦΜ μέλους"
                                  :rules="vatRule" counter="9" type="number" hide-spin-buttons>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="membersCardNumber"
                                  label="Αριθμός κάρτας μέλους">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical/>
              <v-col>
                <v-row>
                  <v-col>
                    <v-text-field v-model="membersFirstName"
                                  label="Όνομα μέλους" :rules="nameRules">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="membersLastName"
                                  label="Επώνυμο μέλους"
                                  :rules="nameRules">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical/>
              <v-col>
                <v-row>
                  <v-col>
                    <v-select label="Κατάσταση μέλους"
                              v-model="memberStatus"
                              :items="memberStatuses"
                              return-object
                              :item-text="item =>item.status"
                              no-data-text="Δεν υπάρχουν καταχωρήσεις">
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="memberAmka"
                                  label="ΑΜΚΑ μέλους"
                                  :rules="nameRules">
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-divider vertical/>
              <v-col v-if="($store.getters.loggedinUser.role == 'NEL') ||
                             ($store.getters.loggedinUser.role == 'NEAS')">
                <v-row>
                  <v-col cols="6">
                    <v-text-field v-model="membersIdentificationDocument"
                                  label="ΑΔΤ" :rules="nameRules">
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field v-model="membersBirthYear"
                                  type="number"
                                  :mask="integerMask"
                                  step="1"
                                  :min="1900"
                                  :rules="yearRules">
                      <template v-slot:label>
                        <div style="margin-left: 2px;">Έτος Γέννησης</div>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-autocomplete
                        label="Παραρτήματα"
                        :items="branches"
                        return-object
                        no-data-text="Δεν υπάρχουν καταχωρήσεις"
                        :item-text="item =>item.name"
                        v-model="selectedBranch"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-else>
                <v-row>
                  <v-col>
                    <v-text-field v-model="membersIdentificationDocument"
                                  label="ΑΔΤ" :rules="nameRules">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field v-model="membersBirthYear"
                                  type="number"
                                  :mask="integerMask"
                                  step="1"
                                  :min="1900"
                                  :rules="yearRules">
                      <template v-slot:label>
                        <div style="margin-left: 2px;">Έτος Γέννησης</div>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-btn @click="exportTable()"
                   class="primary ma-2"
            >
              Έκδοση λίστας μελών
            </v-btn>
            <v-spacer/>
            <v-tooltip top>
              <template v-slot:activator="{on}">
                <v-btn icon v-on="on" @click="clearSearch()" class="ma-2">
                  <v-icon color="primary">
                    mdi-broom
                  </v-icon>
                </v-btn>
              </template>
              <span>
                    Καθαρισμός
                  </span>
            </v-tooltip>
            <v-btn @click="searchForAllMembers()" class="ma-2">
              Εμφάνιση όλων
            </v-btn>
            <v-btn :disabled="searchButtonValid" @click="searchForMembers()"
                   class="ma-2 primary">
              Αναζήτηση
            </v-btn>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


    <v-card>
      <v-row>
        <v-col>
          <v-data-table :page="pageNumber"
                        :footer-props="{page:1,
                                          pageCount:1,
                                          showCurrentPage:true,
                                          itemsPerPageOptions:[5,10,20,30],
                                          showFirstLastPage:true,
                                          itemsPerPageText:'Μέλη ανά σελίδα:',
                                          itemsPerPageAllText:'Όλα'}"
                        :headers="headers"
                        :items="members"
                        :items-per-page="itemsPerPage"
                        @update:options="getBuildingPerPage"
                        :server-items-length="tableLength"
                        no-data-text="Δέν υπάρχουν αποτελέσματα, παρακαλώ πατήστε αναζήτηση."
                        @pagination="handleTableUpdate">
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:item="{item}">
              <tr @click="additionalInfo(item)">
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.vat }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.first_name }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.last_name }}
                </td>
                <td class="text-center d-sm-table-cell d-flex"
                    v-if="item.userbranch_branchrole_histories.length > 0">
                  <v-chip v-if="item.userbranchbranchrolestatus_id  == 1"
                          class="white--text" color="green">
                    {{ item.userbranchbranchrolestatus.status }}
                  </v-chip>
                  <v-chip v-else-if="item.userbranchbranchrolestatus_id  == 2
                                    || item.userbranchbranchrolestatus_id  == 5
                                    || item.userbranchbranchrolestatus_id  == 6
                                    || item.userbranchbranchrolestatus_id  == 3"
                          color="red" class="white--text">
                    {{ item.userbranchbranchrolestatus.status }}
                  </v-chip>
                  <v-chip v-else>
                    {{ item.userbranchbranchrolestatus.status }}
                  </v-chip>
                </td>
                <td class="text-center d-sm-table-cell d-flex" v-else>
                  {{ '-' }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.branch.club.name }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.branch.name }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.date_of_birth }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.identification_document }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!--</v-container>-->

    <v-dialog max-width="1200px" max-height="720px" v-model="additionalMemberInfoDialog" persistent>
      <v-card>
        <v-card-title class="primary white--text">
          Περισσότερες πληροφορίες μέλους
          <!--          {{additionalMemberInfo.vat}} {{additionalMemberInfo.first_name}} {{additionalMemberInfo.last_name}}-->
          <v-spacer/>
          <v-btn icon color="white" @click="closeAdditionalMemberInfoDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card class="mt-3" v-if="($store.getters.loggedinUser.role == 'ΓΔΑ'
          || $store.getters.loggedinUser.role == 'ΑΤ')">
            <v-card-title class="primary white--text">
              Στοιχεία που αντιστοιχούν στον ΑΦΜ του μέλους
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ additionalMemberInfo.first_name }}</v-list-item-title>
                      <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ additionalMemberInfo.last_name }}</v-list-item-title>
                      <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ additionalMemberInfo.father_name }}</v-list-item-title>
                      <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ additionalMemberInfo.mother_name ? additionalMemberInfo.mother_name : '-' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Μητρώνυμο</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          additionalMemberInfo.address && additionalMemberInfo.city ? '' : '-'
                        }}
                        {{ additionalMemberInfo.address ? additionalMemberInfo.address : '' }}
                        {{ additionalMemberInfo.city ? ', ' + additionalMemberInfo.city : '' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{
                          additionalMemberInfo.postal_code ? additionalMemberInfo.postal_code : '-'
                        }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Ταχυδρομικός κώδικας</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ additionalMemberInfo.vat }}</v-list-item-title>
                      <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>{{ additionalMemberInfo.identification_document }}</v-list-item-title>
                      <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ additionalMemberInfo.date_of_birth ? additionalMemberInfo.date_of_birth : '-' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Ημερομηνία γέννησης</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ additionalMemberInfo.amka ? additionalMemberInfo.amka : '-' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>ΑΜΚΑ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-divider/>
              <v-row>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ additionalMemberInfo.phone_number ? additionalMemberInfo.phone_number : '-' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Τηλέφωνο επικοινωνίας</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ additionalMemberInfo.email ? additionalMemberInfo.email : '-' }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Email</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <h1 class="mt-4 govgr-!-font-size-19" style="font-weight: bold">Το μέλος ανήκει:</h1>
          <v-expansion-panels v-model="panel1">
            <v-expansion-panel v-for="(user_branch, i) in additionalMemberInfo.user_branches" :key="i" class="mt-2">
              <v-expansion-panel-header>
                <v-row>
                  <v-col cols="3">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ user_branch.branch.club.name }}</v-list-item-title>
                        <v-list-item-subtitle>Λέσχη</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="3">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ (user_branch.branch.is_default == true) ? ('Έδρα') : (user_branch.branch.name) }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Παράρτημα</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="3" style="margin-top: -3px">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          <v-chip small v-if="user_branch.userbranchbranchrolestatus.id ==
                                  $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.ACTIVE"
                                  class="white--text" color="green">
                            {{ user_branch.userbranchbranchrolestatus.status }}
                          </v-chip>
                          <v-chip small v-else-if="user_branch.userbranchbranchrolestatus.id  ==
                                       $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.DELETED
                                      ||
                                      user_branch.userbranchbranchrolestatus.id  ==
                                      $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.INVALID
                                      ||
                                      user_branch.userbranchbranchrolestatus.id  ==
                                      $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.SUSPENDED
                                      ||
                                      user_branch.userbranchbranchrolestatus.id  ==
                                      $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.INACTIVE"
                                  color="red" class="white--text">{{ user_branch.userbranchbranchrolestatus.status }}
                          </v-chip>
                          <v-chip small v-else>{{ user_branch.userbranchbranchrolestatus.status }}</v-chip>
                        </v-list-item-title>
                        <v-list-item-subtitle>Κατάσταση μέλους</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="3">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ user_branch.member_number }}</v-list-item-title>
                        <v-list-item-subtitle>Αριθμός κάρτας μέλους</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card outlined>
                  <v-card-title class="primary white--text">
                    Στοιχεία μέλους όπως είναι καταχωρισμένα απο τη λέσχη
                    <v-spacer/>
                    <v-tooltip bottom v-if="($store.getters.loggedinUser.role == 'ΓΔΑ' ||
                    $store.getters.loggedinUser.role == 'ΑΤ')">
                      <template v-slot:activator="{ on }">
                        <v-btn icon color="white">
                          <v-icon @click="compareUserInfo(user_branch)"
                                  v-on="on">mdi-arrow-collapse
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Σύγκριση στοιχείων μέλους</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ user_branch.first_name ? user_branch.first_name : '-' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ user_branch.last_name ? user_branch.last_name : '-' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ user_branch.father_name ? user_branch.father_name : '-' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ user_branch.mother_name ? user_branch.mother_name : '-' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Μητρώνυμο</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ user_branch.address ? user_branch.address : '-' }}
                              {{ user_branch.city ? ', ' + user_branch.city : '' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ user_branch.postal_code ? user_branch.postal_code : '-' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Ταχυδρομικός κώδικας</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ additionalMemberInfo.vat }}</v-list-item-title>
                            <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ user_branch.identification_document ? user_branch.identification_document : '-' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ user_branch.date_of_birth ? user_branch.date_of_birth : '-' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Ημερομηνία γέννησης</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ user_branch.amka ? user_branch.amka : '-' }}</v-list-item-title>
                            <v-list-item-subtitle>ΑΜΚΑ</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                    <v-divider/>
                    <v-row>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ user_branch.phone_number ? user_branch.phone_number : '-' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Τηλέφωνο επικοινωνίας</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="6">
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ user_branch.email ? user_branch.email : '-' }}</v-list-item-title>
                            <v-list-item-subtitle>Email</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card outlined class="mt-3">
                  <v-card-title class="primary white--text mb-3" style="width: 100%">
                    Ιστορικό εγγραφής μέλους
                  </v-card-title>
                  <v-card-text>
                    <v-data-table dense
                                  :items="user_branch.userbranch_branchrole_histories"
                                  :headers="historyHeaders"
                                  :items-per-page="5"
                                  :footer-props="{
                                    itemsPerPageOptions:[5,10,15],
                                    showFirstLastPage: true,
                                    itemsPerPageText:'Ενέργειες ανά σελίδα:',
                                    itemsPerPageAllText:'Όλα'}"
                                  no-data-text="Δέν υπάρχουν αποτελέσματα.">
                      <template v-slot:[`footer.page-text`]="items">
                        {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
                      </template>
                      <template v-slot:item="{item}">
                        <tr>
                          <td class="text-center d-sm-table-cell d-flex">
                            {{ utilities.timestampToDatetime(item.change_time) }}
                          </td>
                          <td class="text-center d-sm-table-cell d-flex">
                            {{ item.userbranchbranchrolestatus.status }}
                          </td>
                          <td class="text-center d-sm-table-cell d-flex" v-if="item.penalty_ends_time != null">
                            {{ item.penalty_ends_time.split('T')[0] }}
                          </td>
                          <td class="text-center d-sm-table-cell d-flex" v-else>
                            {{ '-' }}
                          </td>
                          <td class="text-center d-sm-table-cell d-flex"
                              v-if="item.suspension_reason != null || item.delete_reason != null"
                              @click="openSuspensionReasonDialog(item)">
                            <v-tooltip left>
                              <template v-slot:activator="{on}">
                                <v-btn icon v-on="on">
                                  <v-icon color="primary">
                                    mdi-information-variant
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>
                                Λεπτομέρειες αναστολής
                              </span>
                            </v-tooltip>
                          </td>
                          <td class="text-center d-sm-table-cell d-flex" v-else>
                            {{ '-' }}
                          </td>
                          <td class="text-center d-sm-table-cell d-flex">
                            <v-tooltip left>
                              <template v-slot:activator="{on}">
                                <v-icon color="primary" v-on="on">
                                  mdi-account
                                </v-icon>
                              </template>
                              <span>
                                {{ item.user.vat }} {{ item.user.first_name }} {{ item.user.last_name }}
                              </span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent width="1024px" v-model="compareDialog">
      <v-card>
        <v-card-title class="primary white--text" style="word-break: normal">
          Σύγκριση στοιχείων μέλους
          <v-spacer/>
          <v-btn icon color="white" @click="compareDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-card class="mt-3" v-if="($store.getters.loggedinUser.role == 'ΓΔΑ' ||
            $store.getters.loggedinUser.role == 'ΑΤ')">
                <v-card-title class="primary white--text">
                  Επίσημα στοιχεία μέλους
                </v-card-title>
                <v-card-text class="mt-4">
                  <v-row class="mb-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ additionalMemberInfo.first_name }}</v-list-item-title>
                        <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ additionalMemberInfo.last_name }}</v-list-item-title>
                        <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ additionalMemberInfo.father_name }}</v-list-item-title>
                        <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ additionalMemberInfo.mother_name ? additionalMemberInfo.mother_name : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Μητρώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            additionalMemberInfo.address && additionalMemberInfo.city ? '' : '-'
                          }}
                          {{ additionalMemberInfo.address ? additionalMemberInfo.address : '' }}
                          {{ additionalMemberInfo.city ? ', ' + additionalMemberInfo.city : '' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{
                            additionalMemberInfo.postal_code ? additionalMemberInfo.postal_code : '-'
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Ταχυδρομικός κώδικας</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ additionalMemberInfo.vat }}</v-list-item-title>
                        <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ additionalMemberInfo.identification_document }}</v-list-item-title>
                        <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ additionalMemberInfo.date_of_birth ? additionalMemberInfo.date_of_birth : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Ημερομηνία γέννησης</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ additionalMemberInfo.amka ? additionalMemberInfo.amka : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>ΑΜΚΑ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ additionalMemberInfo.phone_number ? additionalMemberInfo.phone_number : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Τηλέφωνο επικοινωνίας</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ additionalMemberInfo.email ? additionalMemberInfo.email : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Email</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="mt-3" v-if="($store.getters.loggedinUser.role == 'ΓΔΑ' ||
            $store.getters.loggedinUser.role == 'ΑΤ')">
                <v-card-title class="primary white--text">
                  Στοιχεία μέλους λέσχης
                </v-card-title>
                <v-card-text class="mt-4">
                  <v-row class="mb-1">
                    <v-list-item :style="compareInfo.first_name == additionalMemberInfo.first_name ?
                                'background-color: ' : 'background-color: lightcoral'">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ compareInfo.first_name ? compareInfo.first_name : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item :style="compareInfo.last_name == additionalMemberInfo.last_name ?
                                'background-color: ' : 'background-color: lightcoral'">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ compareInfo.last_name ? compareInfo.last_name : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item :style="compareInfo.father_name == additionalMemberInfo.father_name ?
                                'background-color: ' : 'background-color: lightcoral'">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ compareInfo.father_name ? compareInfo.father_name : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item :style="compareInfo.mother_name == additionalMemberInfo.mother_name || additionalMemberInfo.mother_name == null ?
                  'background-color: ' : 'background-color: lightcoral'">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ compareInfo.mother_name ? compareInfo.mother_name : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Μητρώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ compareInfo.address ? compareInfo.address : '-' }}
                          {{ compareInfo.city ? ', ' + compareInfo.city : '' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ compareInfo.postal_code ? compareInfo.postal_code : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Ταχυδρομικός κώδικας</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ additionalMemberInfo.vat }}</v-list-item-title>
                        <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item
                        :style="compareInfo.identification_document == additionalMemberInfo.identification_document ?
                      'background-color: ' : 'background-color: lightcoral'">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ compareInfo.identification_document ? compareInfo.identification_document : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item :style="compareInfo.date_of_birth == additionalMemberInfo.date_of_birth ?
                  'background-color: ' : 'background-color: lightcoral'">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ compareInfo.date_of_birth ? compareInfo.date_of_birth : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Ημερομηνία γέννησης</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item :style="compareInfo.amka == additionalMemberInfo.amka || additionalMemberInfo.amka == null ?
                  'background-color: ' : 'background-color: lightcoral'">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ compareInfo.amka ? compareInfo.amka : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>ΑΜΚΑ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ compareInfo.phone_number ? compareInfo.phone_number : '-' }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Τηλέφωνο επικοινωνίας</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                  <v-divider/>
                  <v-row class="mb-1 mt-1">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>{{ compareInfo.email ? compareInfo.email : '-' }}</v-list-item-title>
                        <v-list-item-subtitle>Email</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent width="400px" v-model="suspensionReasonDialog">
      <v-card>
        <v-card-title style="word-break: normal" class="primary white--text">
          Αιτιολογία αναστολής μέλους
        </v-card-title>
        <v-card-text class="mt-4 govgr-!-font-size-19">
          {{ suspensionReason }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="suspensionReasonDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import requests from "@/requests";
import utilities from "../../utilities";
import store from "@/store";

export default {
  name: "SearchForMember",
  computed: {
    utilities() {
      return utilities
    },
    removeDotAndCommaComp() {
      return this.membersBirthYear
    },
    searchButtonValid() {
      if (this.membersAfm.trim() != '' && this.membersAfm.trim().length < 3) {
        return true
      }
      if (this.memberAmka.trim() != '' && this.memberAmka.trim().length < 3) {
        return true
      }
      if (this.membersFirstName.trim() != '' && this.membersFirstName.trim().length < 3) {
        return true
      }
      if (this.membersLastName.trim() != '' && this.membersLastName.trim().length < 3) {
        return true
      }
      if (this.membersIdentificationDocument.trim() != '' && this.membersIdentificationDocument.trim().length < 3) {
        return true
      }
      return false
    },
  },
  beforeCreate() {

  },
  data() {
    return {
      //formExists: '',
      clubsAfm: '',
      clubsName: '',
      membersAfm: '',
      membersFirstName: '',
      membersLastName: '',
      membersBirthYear: '',
      membersCardNumber: '',
      memberAmka: '',
      membersIdentificationDocument: '',
      perifereia: '',
      nomos: '',
      dhmos: '',
      memberStatus: {
        id: ''
      },
      branches: [],
      selectedBranch: {
        id: ''
      },
      disabledBranches: true,
      clubs: [],
      selectedClub: {
        id: ''
      },
      // indeterminate: true,
      headers: [
        {
          text: 'ΑΦΜ',
          align: 'center',
          sortable: false,
          value: 'vat',
        },
        {
          text: 'Όνομα',
          align: 'center',
          sortable: false,
          value: 'first_name',
        },
        {
          text: 'Επώνυμο',
          align: 'center',
          sortable: false,
          value: 'last_name',
        },
        {
          text: 'Κατάσταση',
          align: 'center',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Λέσχη',
          align: 'center',
          sortable: false,
          value: 'club',
        },
        {
          text: 'Παράρτημα στο οποίο ανήκει',
          align: 'center',
          sortable: false,
          value: 'branch',
        },
        {
          text: 'Ημερομηνία γέννησης',
          align: 'center',
          sortable: false,
          value: 'date_of_birth'
        },
        {
          text: 'Ταυτοποιητικό έγγραφο',
          align: 'center',
          sortable: false,
          value: 'identification_document',
        },
      ],
      members: [],
      itemsPerPage: 5,
      pageNumber: 1,
      tableLength: 0,
      allItems: 0,
      searchObj: {},
      // rotate3: 1,
      yearRules: [
        v => v == '' || v <= (new Date(Date.now()).getFullYear()) && v >= (new Date(Date.now()).getFullYear() - 150)
            ||
            'Το έτος γέννησης μπορεί να πάρει τιμές απο ' + (new Date(Date.now()).getFullYear() - 150) + ' εώς ' +
            (new Date(Date.now()).getFullYear()),
        v => /^\d*$/.test(v) || 'Το έτος γέννησης πρέπει να είναι ακέραιος αριθμός απο ' +
            (new Date(Date.now()).getFullYear() - 150) + ' εώς ' + (new Date(Date.now()).getFullYear()),
      ],
      nameRules: [
        v => !!v && (v.trim() == '' || v.trim().length >= 3) ||
            'Το πεδίο πρέπει να έχει τουλάχιστον τρεις χαρακτήρες.',
      ],
      vatRule: [
        v => !!v || 'Το πεδίο είναι απαραίτητο',
        v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
      ],
      additionalMemberInfoDialog: false,
      additionalMemberInfo: {},
      historyHeaders: [
        {
          text: 'Χρονοσήμανση',
          align: 'center',
          sortable: false,
          value: 'change_time',
        },
        {
          text: 'Κατάσταση',
          align: 'center',
          sortable: false,
          value: 'userbranchbranchrolestatus.status',
        },
        {
          text: 'Ημ/νία λήξης αναστολής',
          align: 'center',
          sortable: false,
          value: 'penalty_ends_time',
        },
        {
          text: 'Λεπτομέρειες',
          align: 'center',
          sortable: false,
          value: 'sunspesion_reason',
        },
        {
          text: 'Υπεύθυνος',
          align: 'center',
          sortable: false,
          value: 'user',
        },
      ],
      integerMask: /^[0-9]+$/,
      isFormValid: false,
      panel: [0],
      panel1: [0],
      compareDialog: false,
      compareInfo: {},
      perifereies: [],
      nomoi: [],
      dhmoi: [],
      memberStatuses: [],
      suspensionReasonDialog: false,
      suspensionReason: '',
    }
  },
  created() {
    if (this.$store.getters.loggedinUser.role == 'NEL' || this.$store.getters.loggedinUser.role == 'NEAS') {
      this.getBranches()
    } else if (this.$store.getters.loggedinUser.role == 'ΓΔΑ' || this.$store.getters.loggedinUser.role == 'ΑΤ') {
      this.getClubs()
    }
    this.getMemberStatuses()
  },
  methods: {
    // rotateCheckbox(){
    //   if(this.rotate3 == 3) this.rotate3 == 0
    //   this.rotate3 ++
    //   if(this.rotate3 == 1){
    //     this.indeterminate = true
    //     this.formExists = null
    //   }else if(this.rotate3 == 2){
    //     this.indeterminate = false
    //     this.formExists = true
    //   }else if(this.rotate3 == 3){
    //     this.indeterminate = false
    //     this.formExists = false
    //   }
    // },
    clearSearch() {
      //this.formExists = null
      this.clubsAfm = ''
      this.clubsName = ''
      this.membersAfm = ''
      this.membersFirstName = ''
      this.membersLastName = ''
      this.membersBirthYear = ''
      this.membersCardNumber = ''
      this.membersIdentificationDocument = ''
      this.memberAmka = ''
      this.dhmos = ''
      this.nomos = ''
      this.perifereia = ''
      this.memberStatus = ''
      this.selectedBranch = ''
      this.nomoi = []
      this.dhmoi = []
      this.disabledBranches = true
      this.getClubs()
      // this.indeterminate = true
    },
    clearRegionFilters() {
      this.nomos = ''
      this.perifereia = ''
      this.dhmos = ''
    },
    // indeterminateMethod(){
    //   return this.indeterminate
    // },
    searchForMembers() {
      if (this.pageNumber != 1) {
        this.pageNumber = 1
      } else {
        this.getMembers()
      }
    },
    searchForAllMembers() {
      this.clearSearch();
      if (this.pageNumber != 1) {
        this.pageNumber = 1
      } else {
        this.getMembers()
      }
    },
    mailTo(email) {
      document.location = "mailto:" + email
    },
    handleTableUpdate(options) {
      this.pageNumber = options.page;
      this.itemsPerPage = options.itemsPerPage;
    },
    getMembers() {
      this.$store.commit('setLoadingModal', true)

      this.createSearchObj()
      this.searchObj.limit = this.itemsPerPage
      this.searchObj.offset = 0

      if (this.$store.getters.loggedinUser.role == 'NEL' ||
          this.$store.getters.loggedinUser.role == 'NEAS' ||
          this.$store.getters.loggedinUser.role == 'YLPL') {
        requests.postSecureRequest('user/clubMembers', this.searchObj, this.getMembersCallback)
      } else {
        requests.postSecureRequest('user/allMembers', this.searchObj, this.getMembersCallback)
      }

    },
    getMembersCallback(response) {
      this.members = []
      this.tableLength = 0
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < response.users.length; i++) {
        response.users[i].user_branches[0].vat = response.users[i].vat
        this.members.push(response.users[i].user_branches[0])
      }

      this.tableLength = response.all

      this.$store.commit('setLoadingModal', false)
    },

    getBuildingPerPage(item) {
      this.members = []
      this.$store.commit('setLoadingModal', true)

      this.pageNumber = item.page
      this.itemsPerPage = item.itemsPerPage
      //this.printDataTableOptions()

      this.createSearchObj()
      this.searchObj.limit = this.itemsPerPage
      this.searchObj.offset = this.pageNumber - 1

      if (this.$store.getters.loggedinUser.role == 'NEL' ||
          this.$store.getters.loggedinUser.role == 'NEAS' ||
          this.$store.getters.loggedinUser.role == 'YLPL') {
        requests.postSecureRequest('user/clubMembers', this.searchObj, this.getMembersCallback)
      } else {
        requests.postSecureRequest('user/allMembers', this.searchObj, this.getMembersCallback)
      }
    },

    exportTable() {
      this.createSearchObj()
      if (this.searchObj.club_id == undefined || this.searchObj.club_id == null || this.searchObj.club_id == '') {
        this.$store.commit('setInfoDialog', true)
        this.$store.commit('setInfoDialogObj',
            {text: 'Πρέπει να επιλέξετε λέσχη φιλάθλων για την έκδοση λίστας μελών.'})
        return
      }
      requests.downloadXlsxTemplate('user/allMembersExport', this.searchObj)
    },

    tableRowClass(item) {
      if (item.is_valid == false) {
        return 'lightRed text-high-emphasis';
      } else {
        return '';
      }
    },

    createSearchObj() {
      if (this.selectedBranch == '' || this.selectedBranch == undefined || this.selectedBranch == null) {
        this.selectedBranch = {id: ''}
      }
      this.searchObj.loggedinUser = this.$store.getters.loggedinUser
      this.searchObj.membersAfm = this.membersAfm
      this.searchObj.membersFirstName = this.membersFirstName
      this.searchObj.membersLastName = this.membersLastName
      this.searchObj.membersBirthYear = this.membersBirthYear
      this.searchObj.membersCardNumber = this.membersCardNumber
      this.searchObj.membersAmka = this.memberAmka
      this.searchObj.membersIdentificationDocument = this.membersIdentificationDocument
      this.searchObj.memberStatusId = this.memberStatus.id
      this.searchObj.branch_id = this.selectedBranch.id

      this.searchObj.region_id = this.perifereia.id
      this.searchObj.regional_unit_id = this.nomos.id
      this.searchObj.municipality_id = this.dhmos.id

      if (this.selectedClub == '' || this.selectedClub == undefined || this.selectedClub == null) {
        //this.selectedBranch = ''
        this.selectedClub = {id: ''}
      }

      this.searchObj.clubsAfm = this.clubsAfm
      this.searchObj.clubsName = this.clubsName
      this.searchObj.club_id = this.selectedClub.id


      if (this.$store.getters.loggedinUser.role == 'NEL' || this.$store.getters.loggedinUser.role == 'NEAS') {
        this.searchObj.club = this.$store.getters.selectedClub
        this.searchObj.club_id = this.$store.getters.selectedClub.id
      } else if (this.$store.getters.loggedinUser.role == 'YLPL') {
        this.searchObj.branch_id = this.$store.getters.selectedBranchYPL.id
        this.searchObj.club_id = this.$store.getters.selectedBranchYPL.club.id
      }
    },

    additionalInfo(item) {
      let requestObj = {}
      if (['ΑΤ', 'ΓΔΑ'].includes(this.$store.getters.loggedinUser.role)) {
        this.$store.commit('setLoadingModal', true)
        requestObj = {
          user: item,
          club_id: ''
        }
      } else {
        requestObj = {
          user: item,
          club_id: this.$store.getters.selectedClub.id
        }
      }
      requests.postSecureRequest('user/allUserBranchInfo',
          requestObj,
          this.allUserBranchInfoCallback)
    },
    allUserBranchInfoCallback(response) {

      this.additionalMemberInfo = response
      this.$store.commit('setLoadingModal', false)
      this.additionalMemberInfoDialog = true
    },
    closeAdditionalMemberInfoDialog() {
      this.additionalMemberInfoDialog = false
    },

    getBranches() {
      if (this.$store.getters.selectedClub == null || this.$store.getters.selectedClub == 'null') {
        return
      }
      this.branches = []
      this.$store.commit('setLoadingModal', true)
      let url = 'get-branches-of-club';
      if (['ΑΤ', 'ΓΔΑ'].includes(this.$store.getters.loggedinUser.role)) {
        url = 'get-branches-of-club-including-deleted'
      }
      requests.postSecureRequest('branch/' + url,
          {club: this.$store.getters.selectedClub},
          this.getBranchesWithPlacesCallback)
    },

    getBranchesWithPlacesCallback(response) {
      this.branches = response
      this.$store.commit('setLoadingModal', false)
    },

    getClubs() {
      this.$store.commit('setLoadingModal', true)
      this.selectedClub = {}
      requests.getSecureRequest('club/not-draft', '', this.getClubsCallback)
    },

    getClubsCallback(response) {
      this.clubs = []
      this.clubs = response
      this.$store.commit('setLoadingModal', false)
      requests.getSecureRequest('region/', '', this.getRegionCallback)
    },
    getRegionCallback(response) {
      this.perifereies = []
      this.perifereies = response
      this.$store.commit('setLoadingModal', false)
    },

    autoSelectPerifereia() {
      this.nomoi = []
      this.dhmoi = []
      for (let nomos of this.perifereia.regional_units) {
        this.nomoi.push(nomos)
      }
    },

    autoSelectNomos() {
      this.dhmoi = []
      for (let dhmos of this.nomos.municipalities) {
        this.dhmoi.push(dhmos)
      }
    },

    enableBranches() {
      this.disabledBranches = false
      this.$store.commit('setSelectedClub', this.selectedClub)
      this.getBranches()
    },

    compareUserInfo(user_branch) {
      this.compareInfo = user_branch
      this.compareDialog = true
    },

    getMemberStatuses() {
      requests.getSecureRequest('user/get-member-statuses', '', this.getMemberStatusesCallback)
    },

    getMemberStatusesCallback(response) {
      this.memberStatuses = []
      this.memberStatuses = response
    },

    openSuspensionReasonDialog(item) {
      if (item.suspension_reason != null) {
        this.suspensionReason = item.suspension_reason;
      } else {
        this.suspensionReason = item.delete_reason;
      }

      this.suspensionReasonDialog = true
    },

    infoSnackBarSearch() {
      let message = 'Τα πεδία αναζήτησης δεν είναι υποχρεωτικά'
      this.$store.commit('setInfoSnackBarObj', {text: message});
      this.$store.commit('setInfoSnackBar', true);
    },

    removeDotAndComma() {
      // console.log('lalalla');
      if (this.membersBirthYear) {
        let temp1 = this.membersBirthYear.replaceAll('.', '')
        let temp2 = temp1.replaceAll(',', '')
        this.membersBirthYear = temp2
      }
    },
    // updateFormExists(){
    //   if(this.formExists == 3){
    //     this.formExists = 1
    //   }else{
    //     this.formExists++
    //   }
    // },

  },
}
</script>

<style scoped>
#fre:hover {

  background: v-bind('$vuetify.theme.themes.light.indianRed') !important;
}
</style>
