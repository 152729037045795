<template>
  <div class="mb-3">
    <v-row class="mb-1">
      <v-col class="mx-auto text-center font-weight-light headline">
        {{ 'Ιστορικό χώρων' }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mb-4">
      <v-expansion-panels v-model="panel" multiple style="width: 93%">
        <v-expansion-panel expand>
          <v-expansion-panel-header>
            <v-row>
              <span class="govgr-!-font-size-24 ml-2">
                Στοιχεία αναζήτησης
              </span>
            </v-row>
            <v-spacer/>
            <template v-slot:actions>
              <v-icon color="primary">
                mdi-arrow-down-bold-circle-outline
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-divider/>
          <v-expansion-panel-content class="mt-6">
            <v-form v-model="isFormValid">
              <v-row>
                <v-col>
                  <v-select
                      :items="clubs"
                      v-model="searchObj.club"
                      dense
                      no-data-text="Δεν υπάρχουν καταχωρήσεις"
                      outlined
                      item-text="name"
                      return-object label="Λέσχη"
                      @change="enableBranches()"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                      :items="branches"
                      v-model="searchObj.branch"
                      item-value="id"
                      dense
                      no-data-text="Δεν υπάρχουν καταχωρήσεις"
                      outlined
                      :disabled="disabledBranches"
                      item-text="name" return-object label="Παράρτημα"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                      :items="radioOptions"
                      v-model="searchObj.radioOption"
                      item-value="id"
                      dense
                      no-data-text="Δεν υπάρχουν καταχωρήσεις"
                      outlined
                      item-text="status"
                      return-object
                      label="Κατάσταση χώρων"/>
                </v-col>
              </v-row>
            </v-form>
            <v-row style="margin-top: -2%">
              <v-spacer/>
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <v-btn icon v-on="on" @click="clearSearch()" class="ma-2">
                    <v-icon color="primary">
                      mdi-broom
                    </v-icon>
                  </v-btn>
                </template>
                <span>
               Καθαρισμός
            </span>
              </v-tooltip>
              <v-btn @click="getPlaces()" class="ma-2 primary" :disabled="!isFormValid">
                Εμφάνιση χώρων
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row justify="center" align="center">
      <v-card style="width: 100%" class="mt-3" flat>
        <v-card-text>
          <v-data-table
              :page.sync="pageNumber"
              :items="places"
              :headers="placeHeaders"
              :items-per-page="itemsPerPage"
              :server-items-length="tableLength"
              :footer-props="{
                                      page:1,
                                      pageCount:1,
                                      showCurrentPage:true,
                                      itemsPerPageOptions:[5,10,15],
                                      showFirstLastPage: true,
                                      itemsPerPageText:'Χώροι ανά σελίδα:',
                                      itemsPerPageAllText:'Όλα'}"
              no-data-text="Δέν υπάρχουν αποτελέσματα."
              class="elevation-3 mx-5 px-2"
              @update:options="getBuildingPerPage"
          >
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-card color="primary"
                        class="mb-5"
                        height="80"
                        width="100">
                  <v-card-title style="word-break: normal" class="white--text">
                    {{ 'Χώροι' }}
                  </v-card-title>
                </v-card>
              </v-toolbar>
            </template>
            <template v-slot:item="{item}">
              <tr>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.place_type.type }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.address }} {{ ', ' + item.city }} {{ ', ' + item.postal_code }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.branch.club.name }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.branch.name }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  <v-chip v-if="item.place_status.id == 3" class="white--text" color="green">
                    {{ item.place_status.status }}
                  </v-chip>
                  <v-chip v-else-if="item.place_status.id == 1 || item.place_status.id == 2">
                    {{ item.place_status.status }}
                  </v-chip>
                  <v-chip v-else color="red" class="white--text">{{ item.place_status.status }}</v-chip>
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  <v-tooltip left>
                    <template v-slot:activator="{on}">
                      <v-btn fab
                             small
                             v-on="on"
                             :disabled="item.place_histories.length == 0"
                             @click="openHistoryDialog(item)"
                      >
                        <v-icon
                            color="primary">
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Προβολή ιστορικού
                    </span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>

    <v-dialog persistent v-model="historyDialog" v-if="countForShow != 0" width="1000px">
      <v-card>
        <v-card-title class="primary white--text" style="word-break: normal">
          Ιστορικό χώρου της λέσχης: {{ selectedPlace.branch.club.name }}
        </v-card-title>
        <v-card-text class="mt-4">
          <v-data-table :items="selectedHistory"
                        :headers="historyHeaders"
                        :items-per-page="5"
                        :footer-props="{
                                      page:1,
                                      pageCount:1,
                                      showCurrentPage:true,
                                      itemsPerPageOptions:[5,10,15],
                                      showFirstLastPage: true,
                                      itemsPerPageText:'Ενέργειες ανά σελίδα:',
                                      itemsPerPageAllText:'Όλα'}"
                        no-data-text="Δέν υπάρχουν αποτελέσματα."
                        class="elevation-3 mx-5 px-2"
          >
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:item="{item}">
              <tr>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.change_time }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.place_history_type.type }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  <v-chip v-if="item.place_status.id == 3" class="white--text" color="green">
                    {{ item.place_status.status }}
                  </v-chip>
                  <v-chip v-else-if="item.place_status.id == 1 || item.place_status.id == 2">
                    {{ item.place_status.status }}
                  </v-chip>
                  <v-chip v-else color="red" class="white--text">{{ item.place_status.status }}</v-chip>
                </td>
                <td class="text-center d-sm-table-cell d-flex click-cursor" v-if="item.place_licencing != null">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon color="primary" small
                             target="_blank"
                             @click="downloadFile(item)">
                        <v-icon color="primary"
                                v-on="on"
                        >
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ 'Κατέβασμα αρχείου' }}</span>
                  </v-tooltip>
                </td>
                <td class="text-center d-sm-table-cell d-flex click-cursor" v-else>
                  {{ '-' }}
                </td>
                <td class="text-center d-sm-table-cell d-flex" v-if="item.suspension_until_date != null ">
                  {{ utilities.formatTimestamp(item.suspension_until_date) }}
                </td>
                <td class="text-center d-sm-table-cell d-flex click-cursor" v-else>
                  {{ '-' }}
                </td>
                <td class="text-center d-sm-table-cell d-flex click-cursor"
                    v-if="item.suspension_reason != null || item.license_recall_reason != null"
                    @click="openInfoDialog(item)">
                  <v-tooltip left>
                    <template v-slot:activator="{on}">
                      <v-btn icon v-on="on">
                        <v-icon color="primary">
                          mdi-information-variant
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      Λεπτομέρειες αναστολής
                    </span>
                  </v-tooltip>
                </td>
                <td class="text-center d-sm-table-cell d-flex click-cursor" v-else>
                  {{ '-' }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon color="primary" small @click="openResponsibleUserDialog(item)">
                        <v-icon color="primary"
                                v-on="on"
                        >
                          mdi-account
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ 'Πληροφορίες χρήστη' }}</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="historyDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="responsibleUserDialog" max-width="900px" persistent>
      <v-card tile>
        <v-card-title class="primary">
          <span class="headline white--text">{{ 'Στοιχεία υπευθύνου ενέργειας' }}</span>
        </v-card-title>
        <v-card-text>
          <v-row class="my-1">
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ responsibleUser.vat }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ responsibleUser.first_name }}
                    {{ responsibleUser.last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Ονοματεπώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="2">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ responsibleUser.father_name }}</v-list-item-title>
                  <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ responsibleUser.identification_document }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn @click="responsibleUserDialog = false; responsibleUser = {};" color="primary" text>
            {{ 'Κλείσιμο' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent width="700px" v-model="infoDialog">
      <v-card>
        <v-card-title style="word-break: normal" class="primary white--text">
          Λεπτομέρειες κατάστασης
        </v-card-title>
        <v-card-text class="mt-4 govgr-!-font-size-19">
          {{ selectedInfo }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="infoDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import requests from "@/requests";
import utils from "@/utilities";
import utilities from "@/utilities";

export default {
  name: "HistoryOfAT",
  computed: {
    utilities() {
      return utilities
    }
  },
  components: {},
  data: () => ({
    panel: [0],
    pageNumber: 1,
    itemsPerPage: 5,
    tableLength: 0,
    isFormValid: true,
    clubs: [],
    branches: [],
    places: [],
    selectedHistory: [],
    selectedPlace: {},
    file: null,
    searchObj: {
      club: '',
      branch: '',
      radioOption: '',
      check_loc_code: true,
    },
    radioOptions: [],
    licencingDialog: false,
    checkDialog: false,
    statusForCheckDialog: '',
    courtDecisionNumber: '',
    openDatePicker: false,
    dateOfEnd: '',
    placeHeaders: [
      {
        text: 'Τύπος',
        align: 'center',
        sortable: false,
        value: 'type',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Λέσχη',
        align: 'center',
        sortable: false,
        value: 'club',
      },
      {
        text: 'Παράρτημα',
        align: 'center',
        sortable: false,
        value: 'branch',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Ιστορικό χώρου',
        align: 'center',
        sortable: false,
        value: 'icon',
      }
    ],
    historyHeaders: [
      {
        text: 'Ημερομηνία',
        align: 'center',
        sortable: false,
        value: 'date',
      },
      {
        text: 'Ενέργεια',
        align: 'center',
        sortable: false,
        value: 'action',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Αρχείο',
        align: 'center',
        sortable: false,
        value: 'file',
      },
      {
        text: 'Ημερομηνία λήξης αναστολής',
        align: 'center',
        sortable: false,
        value: 'file',
      },
      {
        text: 'Λεπτομέρειες',
        align: 'center',
        sortable: false,
        value: 'info',
      },
      {
        text: 'Χρήστης',
        align: 'center',
        sortable: false,
        value: 'user'
      }
    ],
    disabledBranches: true,
    historyDialog: false,
    countForShow: 0,
    responsibleUser: {},
    responsibleUserDialog: false,
    infoDialog: false,
    selectedInfo: {},
  }),
  methods: {
    getClubs() {
      this.$store.commit('setLoadingModal', true)
      this.selectedClub = {}
      requests.getSecureRequest('club/', '', this.getClubsCallback)
    },

    getClubsCallback(response) {
      this.clubs = []
      this.clubs = response
      this.$store.commit('setLoadingModal', false)
    },

    enableBranches() {
      this.getBranches()
      this.disabledBranches = false
    },

    getBranches() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('branch/get-branches-of-club', {club: this.searchObj.club}, this.getBranchesWithPlacesCallback)
    },

    getBranchesWithPlacesCallback(response) {
      this.branches = response
      this.$store.commit('setLoadingModal', false)
    },
    clearSearch() {
      this.searchObj = {
        club: '',
        branch: '',
        radioOption: '',
        check_loc_code: true,
      }
      this.branches = []
      this.disabledBranches = true
    },

    getPlaceStatuses() {
      this.$store.commit('setLoadingModal', true)
      requests.getSecureRequest('place/get-place-statuses', '', this.getPlaceStatusesCallback)
    },

    getPlaceStatusesCallback(response) {
      this.radioOptions = []
      for (let i = 0; i < response.length; i++) {
        if (response[i].id != this.$store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.DRAFT) {
          this.radioOptions.push(response[i])
        }
      }
      this.$store.commit('setLoadingModal', false)
    },

    getBuildingPerPage(item) {
      this.$store.commit('setLoadingModal', true)
      this.pageNumber = item.page
      this.itemsPerPage = item.itemsPerPage
      let requestObj = {
        club_id: this.searchObj.club.id,
        branch_id: this.searchObj.branch.id,
        place_status_id: this.searchObj.radioOption.id,
        loc_code_check: this.searchObj.check_loc_code,
        limit: this.itemsPerPage,
        offset: this.pageNumber - 1
      }
      requests.postSecureRequest('place/get-places-with-everything', requestObj, this.getPlacesCallback)
    },

    getPlaces() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_id: this.searchObj.club.id,
        branch_id: this.searchObj.branch.id,
        place_status_id: this.searchObj.radioOption.id,
        loc_code_check: this.searchObj.check_loc_code,
        limit: this.itemsPerPage,
        offset: 0
      }
      requests.postSecureRequest('place/get-places-with-everything', requestObj, this.getPlacesCallback)
    },

    getPlacesCallback(response) {
      this.places = []
      this.places = response.places
      this.tableLength = response.all;
      this.$store.commit('setLoadingModal', false)
    },

    openHistoryDialog(item) {
      this.selectedHistory = []
      let test_item = {}
      test_item = JSON.parse(JSON.stringify(item))
      Object.assign(this.selectedPlace, test_item)
      Object.assign(this.selectedHistory, test_item.place_histories)
      this.countForShow++
      JSON.parse(JSON.stringify(this.selectedHistory))
      for (let i = 0; i < test_item.place_histories.length; i++) {
        this.selectedHistory[i].change_time = utils.timestampToDatetime(test_item.place_histories[i].change_time)
      }
      this.historyDialog = true
    },

    downloadFile(item) {
      requests.downloadFileRequest('place/download-licencing/' + item.place_licencing.id)
    },

    openResponsibleUserDialog(item) {
      this.responsibleUser = item.user
      this.responsibleUserDialog = true
    },

    openInfoDialog(item) {
      if (item.suspension_reason != null) {
        this.selectedInfo = item.suspension_reason
      } else {
        this.selectedInfo = item.license_recall_reason
      }
      this.infoDialog = true
    },

  },
  created() {
    this.getPlaceStatuses()
    this.getClubs()
  }
}
</script>


<style scoped>

</style>