<template>
  <div class="mt-2">
    <v-tabs fixed-tabs v-model="tab" @click="getClubInfo()">
      <v-tab href="#tab-1"
             :style="tab=='tab-1' ? 'background-color: aliceblue;' : 'background-color: '"
      >
        Στοιχεια
      </v-tab>
      <v-tab href="#tab-2" @click="getCouncilMembers()"
             :style="tab=='tab-2' ? 'background-color: aliceblue;' : 'background-color: '"
      >
        Μελη Δ.Σ.
      </v-tab>
      <v-tab href="#tab-3" @click="getBranches()"
             :style="tab=='tab-3' ? 'background-color: aliceblue;' : 'background-color: '"
      >
        Παραρτηματα
      </v-tab>
      <v-tab href="#tab-4" @click="getBranchesWithPlaces()"
             v-if="!checkBox"
             :style="tab=='tab-4' ? 'background-color: aliceblue;' : 'background-color: '"
      >
        Χωροι
      </v-tab>
      <v-tab href="#tab-5" @click="getBranchesWithPlaces()"
             :style="tab=='tab-5' ? 'background-color: aliceblue;' : 'background-color: '"
      >
        Μελη λεσχης
      </v-tab>
      <v-tab href="#tab-6" @click="getDocuments()"
             :style="tab=='tab-6' ? 'background-color: aliceblue;' : 'background-color: '"
      >
        Δικαιολογητικα
      </v-tab>
      <v-tab href="#tab-7"
             @click="isDocumentValid"
             v-if="checkBox"
             :style="tab=='tab-7' ? 'background-color: aliceblue;' : 'background-color: '"
      >
        Εγκριση/απορριψη
      </v-tab>
      <v-tab href="#tab-7"
             v-else
             :style="tab=='tab-7' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getLicenceOfClub()"
      >
        Εγγραφα
      </v-tab>

      <v-tabs-items v-model="tab" class="mx-10 my-10">
        <v-tab-item value="tab-1">
          <v-card class="ma-3">
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <p class="mb-4 govgr-!-font-size-24" style="font-weight: bold">Λέσχης</p>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ club.name }}</v-list-item-title>
                      <v-list-item-subtitle>Επωνυμία</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ club.vat }}</v-list-item-title>
                      <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ club.branches[0].address }} {{ ', ' + club.branches[0].city }}
                        {{ ', ' + club.branches[0].postal_code }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Διεύθυνση έδρας</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ club.organization.name }}</v-list-item-title>
                      <v-list-item-subtitle>ΑΣ/ΤΑΑ/ΑΑΕ στο οποίο υπάγεται</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-divider vertical/>
                <v-col cols="6">
                  <p class="mb-4 govgr-!-font-size-24" style="font-weight: bold">Υπευθύνου</p>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ user.user_branches[0].first_name }}</v-list-item-title>
                      <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ user.user_branches[0].last_name }}</v-list-item-title>
                      <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ user.vat }}</v-list-item-title>
                      <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ user.user_branches[0].identification_document }}</v-list-item-title>
                      <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ user.user_branches[0].father_name }}</v-list-item-title>
                      <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-divider/>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card class="ma-3">
            <v-data-table
                :items-per-page="5"
                :headers="headersMembersDS"
                :footer-props="{page:1,
                              pageCount:1,
                              showCurrentPage:true,
                              itemsPerPageOptions:[5,10,15,30],
                              showFirstLastPage:true,
                              itemsPerPageText:'Μέλη Δ.Σ. ανά σελίδα:',
                              itemsPerPageAllText:'Όλα'}"
                no-data-text="Δέν υπάρχουν αποτελέσματα."
                :items="councilMembers">
              <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
              </template>
              <template v-slot:item="{item}">
                <tr>
                  <td align="center" justify="center">{{ item.user.vat }}</td>
                  <td align="center" justify="center">{{ item.user.user_branches[0].first_name }}</td>
                  <td align="center" justify="center">{{ item.user.user_branches[0].last_name }}</td>
                  <td align="center" justify="center">{{ item.user.user_branches[0].father_name }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-card class="ma-3">
            <v-data-table :footer-props="{page:1,
                                        pageCount:1,
                                        showCurrentPage: true,
                                        itemsPerPageOptions:[5,10,15,30],
                                        showFirstLastPage:true,
                                        itemsPerPageText:'Παραρτήματα ανά σελίδα',
                                        itemsPerPageAllText:'Όλα'}"
                          no-data-text="Δεν υπάρχουν αποτελέσματα."
                          :headers="headersBranches"
                          :items="branches"
                          :items-per-page="5"
            >
              <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>
                    <v-row class="mt-3">
                      <v-col>
                        <v-text-field class="ml-5 mb-1"
                                      append-icon="mdi-magnify"
                                      hide-details
                                      label="Αναζήτηση..."
                                      single-line
                                      v-model="search"
                                      :rules="searchRules"
                                      dense
                                      @keydown.enter="searchWithParameter"
                                      @click:append="searchWithParameter">
                          >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:item="{item}">
                <tr>
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.name }}
                  </td>
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.address }}
                  </td>
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.city }}
                  </td>
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.postal_code }}
                  </td>
                  <td class="text-center d-sm-table-cell d-flex click-cursor"
                      @click="ekprosopoiDialog = true; selectedItem = item;">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon color="primary">
                            mdi-account
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>
                      Πληροφορίες εκπροσώπου
                    </span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-4">
          <v-card class="ma-3">
            <v-data-table :headers="placesHeaders"
                          :items="places"
                          :items-per-page="10"
                          :footer-props="{page:1,
                                        pageCount:1,
                                        showCurrentPage:true,
                                        itemsPerPageOptions:[5,10,15,30],
                                        showFirstLastPage:true,
                                        itemsPerPageText:'Χώροι ανά σελίδα:',
                                        itemsPerPageAllText:'Όλα'}"
                          no-data-text="Δέν υπάρχουν αποτελέσματα."
            >
              <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>
                    <v-row class="mt-5">
                      <v-col>
                        <v-select label="Παραρτήματα"
                                  :items="branchesWithPlaces"
                                  return-object
                                  :item-text="item =>item.name + ' (Χώροι:' + item.places_count + ')'"
                                  dense
                                  no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                  v-model="selectedBranch"
                                  @change="selectBranchToViewPlaces(selectedBranch)">
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:item.user="{ item }">
                <td class="text-center d-sm-table-cell d-flex click-cursor" @click="openPlaceUserDialog(item)">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" v-on="on">mdi-account</v-icon>
                    </template>
                    <span>{{
                        item.user.user_branches[0].first_name
                      }} {{ item.user.user_branches[0].last_name }} {{ item.user.vat }}</span>
                  </v-tooltip>
                </td>
              </template>
            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-5">
          <v-card class="ma-3">
            <v-data-table
                :headers="memberHeaders"
                :items="members"
                :items-per-page="itemsPerPage"
                :page.sync="pageNumber"
                :footer-props="{page:1,
                              pageCount:1,
                              showCurrentPage:true,
                              itemsPerPageOptions:[5,10,15,30],
                              showFirstLastPage:true,
                              itemsPerPageText:'Μέλη ανά σελίδα:',
                              itemsPerPageAllText:'Όλα'}"
                :server-items-length="tableLength"
                @update:options="getBuildingPerPage"
                no-data-text="Δέν υπάρχουν αποτελέσματα."
            >
              <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>
                    <v-row class="mt-5">
                      <v-col>
                        <v-select label="Παραρτήματα"
                                  :items="branchesWithPlacesForShowMembers"
                                  return-object
                                  :item-text="item =>item.name"
                                  dense
                                  no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                  v-model="selectedBranchToShowMembers"
                                  @change="getMembers()">
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-toolbar-title>
                </v-toolbar>
              </template>
              <template v-slot:item.first_name="{ item }">
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.user_branches[0].first_name }}
                </td>
              </template>
              <template v-slot:item.last_name="{ item }">
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.user_branches[0].last_name }}
                </td>
              </template>
              <template v-slot:item.father_name="{ item }">
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.user_branches[0].father_name }}
                </td>
              </template>
              <template v-slot:item.date_of_birth="{ item }">
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.user_branches[0].date_of_birth }}
                </td>
              </template>
              <template v-slot:item.amka="{ item }">
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.user_branches[0].amka }}
                </td>
              </template>
              <template v-slot:item.address="{ item }">
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.user_branches[0].address }}
                </td>
              </template>
              <template v-slot:item.city="{ item }">
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.user_branches[0].city }}
                </td>
              </template>
              <template v-slot:item.postal_code="{ item }">
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.user_branches[0].postal_code }}
                </td>
              </template>
              <template v-slot:item.identification_document="{ item }">
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.user_branches[0].identification_document }}
                </td>
              </template>

            </v-data-table>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-6">
          <v-row justify="center" align="center" class="mt-2">
            <v-card class="ma-3" width="60%">
              <v-card class="mb-1"
                      :style="`background-color: ` + (index%2 == 0 ? `${$vuetify.theme.themes.light.documentBlue}` : '')"
                      v-for="(doc, index) in documents" :key="index"
              >
                <v-row class="pa-4" justify="center" align="center">
                  <v-col cols="1">
                    <v-checkbox v-if="checkBox" @change="updateCheckingStatus(doc)"
                                :input-value="doc.checked"></v-checkbox>
                  </v-col>
                  <v-col cols="6"><b>{{ doc.document_id + '. ' + doc.document.description }}</b></v-col>
                  <v-col cols="4">
                    {{ '(' }}{{ doc.filename }}{{ ')' }}
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-btn class="mt-4r" color="primary" icon @click="download(doc)">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-7" v-if="checkBox">
          <v-row align="center" justify="center">
            <v-card class="ma-3 mb-4 mt-4" width="600px">
              <v-card-text>
                <v-row>
                  <v-col align="center">
                    <p class="mb-4 govgr-!-font-size-24" style="font-weight: bold">Λέσχης</p>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ club.name }}</v-list-item-title>
                        <v-list-item-subtitle>Επωνυμία</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ club.vat }}</v-list-item-title>
                        <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ club.branches[0].address }} {{ ', ' + club.branches[0].city }}
                          {{ ', ' + club.branches[0].postal_code }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Διεύθυνση έδρας</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ club.organization.name }}</v-list-item-title>
                        <v-list-item-subtitle>ΑΣ/ΤΑΑ/ΑΑΕ στο οποίο υπάγεται</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
                <v-divider/>
              </v-card-text>
              <v-card-actions>
                <v-row align="center" justify="center">
                  <v-divider/>
                  <v-col cols="6" align="center" justify="center">
                    <v-btn :disabled="!disableApproveButton" min-width="30%" color="green" x-large
                           @click="openModalToApprove">
                      <span style="color: white">ΕΓΚΡΙΣΗ</span></v-btn>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon color="primary" @click="getDraftApprovalDocument">
                          <v-icon v-on="on">
                            mdi-download
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Έγγραφο έγκρισης</span>
                    </v-tooltip>
                  </v-col>
                  <v-col cols="6" align="center" justify="center">
                    <v-btn min-width="30%" color="red" x-large @click="openModalToReject"><span
                        style="color: white">ΑΠΟΡΡΙΨΗ</span></v-btn>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon color="primary" @click="getDraftRejectionDocument">
                          <v-icon v-on="on">
                            mdi-download
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Έγγραφο Απόρριψης</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-7" v-else>
          <v-row justify="center" align="center" class="mt-2">
            <v-card class="ma-3" width="60%">
              <v-card class="mb-3">
                <v-row class="pa-4" align="center" v-if="licenceForClub.application_approval_file != null">
                  <v-col cols="11" class="govgr-!-font-size-19">
                    <b>Βεβαίωση νόμιμης λειτουργίας λέσχης παραγόμενη από την Γενική Γραμματεία Αθλητισμού</b>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-btn class="mt-4r" color="primary" icon @click="getApprovalDocument()">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="pa-4" v-else>
                  <v-col class="govgr-!-font-size-19">
                    <b> Δεν υπάρχει βεβαίωση νόμιμης λειτουργίας λέσχης από την Γενική Γραμματεία Αθλητισμού.</b>
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="mb-1">
                <v-card-title style="word-break: normal">
                  <v-select
                      label="Επιλέξτε παράρτημα για την εμφάνιση των σχετικών αρχείων για τους χώρους του παραρτήματος"
                      :items="branchesWithPlaces"
                      return-object
                      :item-text="item =>item.name"
                      dense
                      no-data-text="Δεν υπάρχουν καταχωρήσεις"
                      v-model="selectedBranchForGettingFiles"
                      @change="getDocumentsForShowApplication(selectedBranchForGettingFiles)">
                  </v-select>
                </v-card-title>
                <v-card-text v-if="documentsForOfficials.length > 0">
                  <v-card class="mb-1"
                          :style="`background-color: ` + (index%2 == 0 ? `${$vuetify.theme.themes.light.documentBlue}` : '')"
                          v-for="(doc, index) in documentsForOfficials" :key="index"
                  >
                    <v-row class="pa-4">
                      <v-col cols="7"
                             v-if="doc.place_histories[0].place_licencing.licencing_type_id
                          == $store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.APPROVED ">
                        <b>{{ 'Έγγραφο έγκρισης για χώρο του παραρτήματος στην διεύθυνση: ' }}
                          {{ doc.address + ', ' + doc.city + ', ' + doc.postal_code }}</b>
                      </v-col>
                      <v-col cols="7"
                             v-if="doc.place_histories[0].place_licencing.licencing_type_id
                          == $store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.REJECTED">
                        <b>{{ 'Έγγραφο απόρριψης για χώρο του παραρτήματος στην διεύθυνση: ' }}
                          {{ doc.address + ', ' + doc.city + ', ' + doc.postal_code }}</b>
                      </v-col>
                      <v-col cols="4">
                        {{ '(' }}{{ doc.place_histories[0].place_licencing.filename }}{{ ')' }}
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="1">
                        <v-btn class="mt-4r" color="primary" icon
                               @click="getLicencingDocument(doc.place_histories[0].place_licencing)">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
                <v-card-text
                    v-if="documentsForOfficials.length == 0 && Object.keys(selectedBranchForGettingFiles).length != 0">
                  <v-row justify="center" align="center" class="mt-3 mb-3 govgr-!-font-size-19">
                    <b>{{ 'Δεν βρέθηκαν σχετικά αρχεία χώρων για το συγκεκριμένο παράρτημα.' }}</b>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <v-dialog v-model="ekprosopoiDialog" max-width="900px" persistent>
      <v-card tile>
        <v-card-title class="primary">
          <span class="headline white--text">{{ 'Στοιχεία υπευθύνων λειτουργίας' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-for="user_branch in selectedItem.user_branches" :key="user_branch.id">
            <v-row class="my-1">
              <v-col cols="3">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ user_branch.user.vat }}</v-list-item-title>
                    <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ user_branch.first_name }} {{ user_branch.last_name }}</v-list-item-title>
                    <v-list-item-subtitle>Ονοματεπώνυμο</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ user_branch.father_name }}</v-list-item-title>
                    <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="3">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ user_branch.identification_document }}</v-list-item-title>
                    <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn @click="ekprosopoiDialog = false; selectedItem = {};" color="primary" text>
            {{ 'Κλείσιμο' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="placeUserDialog" persistent max-width="900px">
      <v-card>
        <v-card-title style="background-color: #003375" class="white--text">
          Υπεύθυνος Χώρου
        </v-card-title>
        <v-card-text>
          <v-row class="my-1">
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ placeWithChangedUser.user.vat }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ placeWithChangedUser.user.user_branches[0].first_name }}
                    {{ placeWithChangedUser.user.user_branches[0].last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Ονοματεπώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="2">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ placeWithChangedUser.user.user_branches[0].father_name }}</v-list-item-title>
                  <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ placeWithChangedUser.user.user_branches[0].identification_document }}
                  </v-list-item-title>
                  <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer/>
        <v-card-actions>
          <v-btn text @click="closePlaceUserDialog">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationDialog @reject="reject" @approval="approve" :reject="isReject"
                        :applicationId=applicationId></ConfirmationDialog>


    <v-dialog persistent
              v-model="rejectionDialog"
              :retain-focus="false"
              width="700px">
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">Απόρριψη</span>
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-textarea
                  outlined
                  class="mb-1"
                  label="Αιτία απόρριψης"
                  v-model="rejectionReason"
                  dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-col cols="3">
            <v-file-input dense
                          v-model="file"
                          clearable
                          :accept="['.pdf', '.png', '.jpg', '.jpeg']"
                          chips
                          label="Αρχείο"
                          prepend-icon="mdi-file"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 text
                 @click="rejectionDialog = false"
          >
            Άκυρο
          </v-btn>
          <v-btn color="primary"
                 @click="openConfirmationModalToreject"
          >
            Απόρριψη
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "@/requests";
import prop from "@/properties";
import ConfirmationDialog from "@/components/reusable/ConfirmationDialog.vue";
import {eventBus} from "@/main";

export default {
  name: "ShowApplication",
  components: {ConfirmationDialog},
  props: {
    applicationObject: Object,
    applicationId: Number,
    user: Object,
    checkBox: Boolean
  },
  data: () => ({
    tab: null,
    club: {
      branches: [
        {
          address: '',
          city: '',
          postal_code: '',
        },
      ],
      organization: {
        name: ''
      }
    },
    headersMembersDS: [
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'relRelatedAfm',
        width: '25%',
      },
      {
        text: 'Όνομα',
        align: 'center',
        sortable: false,
        value: 'relRelatedOnoma',
        width: '25%',
      },
      {
        text: 'Επώνυμο',
        align: 'center',
        sortable: false,
        value: 'relRelatedEpwnumo',
        width: '25%',
      },
      {
        text: 'Πατρώνυμο',
        align: 'center',
        sortable: false,
        value: 'relRelatedPatrwnumo',
        width: '25%',
      },
    ],
    councilMembers: [
      {
        user: {
          user_branches: [
            {
              first_name: '',
              last_name: '',
              father_name: ''
            }
          ]
        }
      }
    ],

    headersBranches: [
      {
        text: 'Επωνυμία',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Εκπρόσωποι',
        align: 'center',
        sortable: false,
        value: 'representative',
      },
    ],
    branches: [],
    selectedItem: {},
    ekprosopoiDialog: false,
    search: '',
    searchRules: [
      v => (v.trim().length >= 3 || v.trim().length == 0) || 'Απαιτούνται τουλάχιστον 3 χαρακτήρες.'
    ],

    placesHeaders: [
      {
        text: 'Τύπος',
        align: 'center',
        sortable: false,
        value: 'place_type.type',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Υπεύθυνος',
        align: 'center',
        sortable: false,
        value: 'user'
      }
    ],
    branchesWithPlaces: [],
    branchesWithPlacesForShowMembers: [],
    places: [
      {
        user: {
          vat: '',
          user_branches: [
            {
              first_name: '',
              last_name: '',
            }
          ]
        }
      }
    ],
    selectedBranch: {},
    placeUserDialog: false,
    placeWithChangedUser: {
      user: {
        vat: '',
        first_name: '',
        last_name: '',
        father_name: '',
        identification_document: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
          }
        ]
      }
    },

    memberHeaders: [
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'vat',
      },
      {
        text: 'Όνομα',
        align: 'center',
        sortable: false,
        value: 'first_name',
      },
      {
        text: 'Επώνυμο',
        align: 'center',
        sortable: false,
        value: 'last_name',
      },
      {
        text: 'Πατρώνυμο',
        align: 'center',
        sortable: false,
        value: 'father_name',
      },
      {
        text: 'Ημερομηνία γέννησης',
        align: 'center',
        sortable: false,
        value: 'date_of_birth',
      },
      {
        text: 'ΑΜΚΑ',
        align: 'center',
        sortable: false,
        value: 'amka',
      },
      /*      {
              text: 'Κατάσταση',
              align: 'center',
              sortable: false,
              value: 'status',
            },*/
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Αριθμός ταυτότητας',
        align: 'center',
        sortable: false,
        value: 'identification_document',
      },

    ],
    members: [],
    selectedBranchToShowMembers: {},
    itemsPerPage: 5,
    pageNumber: 1,
    tableLength: 0,
    documents: {},
    checkingList: [],
    rejectionDialog: false,
    rejectionReason: '',
    isReject: false,
    disableApproveButton: false,
    file: null,
    effective_end_date: (new Date((new Date()).getFullYear() + 1, 5, 31)).toISOString().substr(0, 10),
    documentsForOfficials: [],
    licenceForClub: {},
    selectedBranchForGettingFiles: {},
  }),
  methods: {
    getClubInfo() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_vat: this.$store.getters.selectedClub.vat
      }
      requests.postSecureRequest('club/get-club-by-vat', requestObj, this.getClubInfoCallback)
    },
    getClubInfoCallback(response) {
      this.club = response
      this.$store.commit('setLoadingModal', false)
    },

    getCouncilMembers() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        application_id: this.applicationId
      }
      requests.postSecureRequest('history/get-council', requestObj, this.getCouncilMembersCallBack)
    },
    getCouncilMembersCallBack(response) {
      this.councilMembers = []
      this.councilMembers = response
      this.$store.commit('setLoadingModal', false)
    },

    getBranches() {
      this.$store.commit('setLoadingModal', true);
      let requestObj = {
        application_id: this.applicationId,
      }
      requestObj.parameter = '' + this.search + ''

      requests.postSecureRequest('history/get-branches', requestObj, this.getBranchesCallback)
    },
    getBranchesCallback(response) {
      this.branches = []
      for (let item of response) {
        // let user_branches = [{
        //   user: item.user
        // }]
        // item.user_branches = user_branches
        // this.branches.push(item)

        let branch = item.branch
        branch.name = item.name
        branch.address = item.address
        branch.city = item.city
        branch.postal_code = item.postal_code
        if (item.user.user_branches.length > 0) {
          item.user.user_branches[0].user = item.user
        }
        branch.user_branches = item.user.user_branches
        this.branches.push(branch)
      }
      this.$store.commit('setLoadingModal', false);
    },

    searchWithParameter() {
      if (this.search.trim().length < 3 && this.search.trim().length > 0) {
        prop.getErrorMessage({data: {message: 'Απαιτούνται τουλάχιστον 3 χαρακτήρες.'}})
        return
      }
      console.log('Searching. . .')
      this.getBranches();
    },

    getBranchesWithPlaces() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        application_id: this.applicationId,
      }
      requests.postSecureRequest('history/get-places', requestObj, this.getBranchesWithPlacesCallback)
    },
    getBranchesWithPlacesCallback(response) {
      this.branchesWithPlaces = []
      this.places = [
        {
          user: {
            vat: '',
            user_branches: [
              {
                first_name: '',
                last_name: '',
              }
            ]
          }
        }
      ]
      this.branchesWithPlaces = response
      if (this.branchesWithPlaces.length > 1) {
        this.branchesWithPlaces.sort(function (x, y) {
          return x.is_default == true ? -1 : y.is_default == false ? 1 : 0;
        })
      }

      this.branchesWithPlacesForShowMembers = []
      if (this.$store.getters.loggedinUser.role == 'ΓΓΑ') {
        for (let item of this.branchesWithPlaces) {
          if (item.branch.is_default == true) {
            this.branchesWithPlacesForShowMembers.push(item)
          }
        }
      } else {
        this.branchesWithPlacesForShowMembers = response
      }


      for (let branch of this.branchesWithPlaces) {
        branch.places_count = branch.places.length
      }

      if (this.branchesWithPlaces.length > 0) {
        if (Object.keys(this.selectedBranch).length == 0) {
          this.selectedBranch = this.branchesWithPlaces[0]
          this.places = this.selectedBranch.places
          console.log(this.places);
        } else {
          for (let branch of this.branchesWithPlaces) {
            if (branch.id == this.selectedBranch.id) {
              this.selectedBranch = branch
              this.places = this.selectedBranch.places
            }
          }
        }
      }
      this.placeWithChangedUser = this.places[0]
      /*      if(this.branchesWithPlaces.length > 0) {
              this.selectedBranchToShowMembers = this.branchesWithPlaces[0]
              this.getMembers();
            }*/
      this.$store.commit('setLoadingModal', false)
    },

    selectBranchToViewPlaces(item) {
      this.selectedBranch = item
      console.log(JSON.parse(JSON.stringify(this.selectedBranch)));
      this.places = this.selectedBranch.places
    },

    openPlaceUserDialog(item) {
      this.placeUserDialog = !this.placeUserDialog
      this.placeWithChangedUser = item
    },

    closePlaceUserDialog() {
      this.placeUserDialog = !this.placeUserDialog

      this.replacedPlaceUser = {
        vat: '',
        first_name: '',
        last_name: '',
        father_name: '',
        identification_document: '',
      }
    },

    getMembers() {
      this.$store.commit('setLoadingModal', true);
      let requestObj = {
        application_id: this.applicationId,
        branch_id: this.selectedBranchToShowMembers.branch_id,
        limit: this.itemsPerPage,
        offset: this.pageNumber - 1
      }
      requests.postSecureRequest('history/get-branch-members', requestObj, this.getMembersCallback)
    },
    getMembersCallback(response) {
      this.members = []
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.members.length; i++) {
        this.members.push(res.members[i].user)
      }
      console.log(this.members);
      this.tableLength = res.all
      this.$store.commit('setLoadingModal', false);
    },

    getBuildingPerPage(item) {
      if (Object.keys(this.selectedBranchToShowMembers).length === 0) {
        return
      }
      this.$store.commit('setLoadingModal', true);
      this.pageNumber = item.page
      this.itemsPerPage = item.itemsPerPage
      this.printDataTableOptions()

      let requestObj = {
        application_id: this.applicationId,
        branch_id: this.selectedBranchToShowMembers.branch_id,
        limit: this.itemsPerPage,
        offset: this.pageNumber - 1
      }
      console.log(requestObj);
      requests.postSecureRequest('history/get-branch-members', requestObj, this.getMembersCallback)
    },

    printDataTableOptions() {
      console.log('Page: ' + this.pageNumber + '\nItems per page: ' + this.itemsPerPage)
    },

    getDocuments() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        application: {
          id: this.applicationId
        }
      }
      requests.postSecureRequest('application/get-application-documents', requestObj, this.getDocumentsCallback)
    },

    getDocumentsCallback(response) {
      this.documents = response
      this.$store.commit('setLoadingModal', false)
    },

    updateCheckingStatus(applicationDocument) {

      applicationDocument.checked = !applicationDocument.checked

      console.log(JSON.parse(JSON.stringify(applicationDocument)));

      requests.putSecureRequest('application/update-document-status', applicationDocument, this.updateCheckingStatusCallback)

    },
    updateCheckingStatusCallback(response) {
      /*this.checkingList[response.document_id - 1] = response.checked
      console.log(this.checkingList);
      this.disableApproveButton = !(this.checkingList.filter(i => i === true).length === this.checkingList.length) || this.effective_end_date == null;*/
    },

    download(item) {
      requests.downloadFileRequest('application/download/' + this.applicationId + '/' + item.document_id)
    },
    openModalToApprove() {
      this.isReject = false
      this.$store.commit('setConfirmationDialog', true);
    },

    openModalToReject() {
      this.rejectionReason = "";

      for (let i = 0; i < this.$store.getters.selectedApplication.documents.length; i++) {

        let doc = JSON.parse(JSON.stringify(this.$store.getters.selectedApplication.documents[i]));
        console.log(doc);
        if (doc.application_document.checked == false) {
          // this.rejectionReason += 'Αιτία απόρριψης: ' + doc.id.toString() + '. ' + doc.description + ' \n\n'
          this.rejectionReason += doc.id.toString() + '. ' + doc.description + ' \n\n'

          console.log(this.rejectionReason);
        }
      }

      console.log(this.rejectionReason);

      this.rejectionDialog = true;
    },
    openConfirmationModalToreject() {
      this.isReject = true
      this.$store.commit('setConfirmationDialog', true);
    },

    isDocumentValid() {
      let obj = {
        application_id: this.applicationId
      }
      requests.postSecureRequest('application/checkDocuments', obj, this.isDocumentValidCallback)
    },

    isDocumentValidCallback(response) {
      this.disableApproveButton = response
      this.getSubmittedApplications()
    },

    downloadDocument(item) {
      let obj = {}
      obj.application_id = item.id
      requests.downloadFileRequestWithObj('application/get-aitisi', obj)
    },

    approve() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        application_id: this.applicationId,
        effective_end_date: this.effective_end_date,
      }
      requests.postSecureRequest('application/approval', requestObj, this.approveCallback)
    },
    approveCallback(response) {
      this.$store.commit('setEditGGAApplicationDialog', false)
      this.$store.commit('setInfoDialog', true)
      this.$store.commit('setInfoDialogObj', {text: 'Η μεταφόρτωση του αρχείου σας και η έγκριση ήταν επιτυχής.'})
      this.getSubmittedApplications()
    },

    reject() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {rejection_reason: this.rejectionReason, application_id: this.applicationId}
      requests.postSecureRequest('application/rejection', requestObj, this.rejectCallback)
    },
    rejectCallback(response) {
      if (this.file != null) {
        this.submitPraktiko(response.history_id)
      }
      this.closeRejectionDialog()
      this.$store.commit('setEditGGAApplicationDialog', false)
      this.$store.commit('setInfoDialog', true)
      this.$store.commit('setInfoDialogObj', {text: 'Η απόρριψη της αίτησης ήταν επιτυχής.'})
      this.getSubmittedApplications()
    },
    closeRejectionDialog() {
      this.rejectionDialog = false;
    },
    submitPraktiko(history_id) {
      /*
        Initialize the form data
      */
      let formData = new FormData();

      formData.append('file', this.file, encodeURI(this.file.name));
      formData.append('applicationId', this.applicationId);
      if (history_id == null) {
        return
      } else {
        formData.append('history_id', history_id);
      }

      requests.uploadFilePostAsync('application/upload-praktiko', formData, this.uploadPraktikoPostCallBack)
    },
    uploadPraktikoPostCallBack(response) {
      console.log(response)
      this.file = null;
      this.$store.commit('setLoadingModal', false)
    },

    getSubmittedApplications() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('application/findSubmitted', {}, this.getSubmittedApplicationsCallback)
    },

    getSubmittedApplicationsCallback(response) {
      console.log(JSON.parse(JSON.stringify(response)));

      this.numberOfSubmittedApplications = response.length
      this.$store.commit('setSubmitedApplications', response)
      this.$store.commit('setLoadingModal', false)
      for (let i = 0; i < response.length; i++) {
        if (response[i].id == this.$store.getters.selectedApplication.id) {
          this.$store.commit('setSelectedApplication', response[i])
        }
      }
    },

    getDraftApprovalDocument() {
      requests.downloadDocTemplate('application/get-draft-vevaiosi', {application_id: this.applicationId})
    },
    getDraftRejectionDocument() {
      requests.downloadDocTemplate('application/get-draft-aporripsi', {application_id: this.applicationId})
    },

    getLicenceOfClub() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('history/get-gga-files', {application_id: this.applicationId}, this.getLicenceOfClubCallback)
    },

    getLicenceOfClubCallback(response) {
      this.licenceForClub = response
      this.getBranchesWithPlaces()
    },

    getDocumentsForShowApplication(selectedBranchForGettingFiles) {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        effective_end_date: this.applicationObject.effective_end_date,
        effective_start_date: this.applicationObject.effective_start_date,
        branch_id: selectedBranchForGettingFiles.branch_id
      }
      requests.postSecureRequest('history/get-at-files', requestObj, this.getDocumentsForShowApplicationCallback)
    },

    getDocumentsForShowApplicationCallback(response) {
      this.documentsForOfficials = []
      this.documentsForOfficials = response
      this.$store.commit('setLoadingModal', false)
    },

    getLicencingDocument(item) {
      requests.downloadFileRequest('place/download-licencing/' + item.id)
    },

    getApprovalDocument() {
      requests.downloadFileRequestWithObj('application/get-approval-file', {application_id: this.licenceForClub.application_id})
    },


  },

  created() {
    this.getClubInfo()

    /* eventBus.$on('approveApplication', (() => {
       this.approve()
     }));
     eventBus.$on('rejectApplication', (() => {
       this.reject()
     }));*/
  },
  /*beforeDestroy() {
    eventBus.$off('approveApplication')
    eventBus.$off('rejectApplication')
  },*/
}
</script>


<style scoped>

</style>