import { render, staticRenderFns } from "./Clubs.vue?vue&type=template&id=67f02886&scoped=true&"
import script from "./Clubs.vue?vue&type=script&lang=js&"
export * from "./Clubs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67f02886",
  null
  
)

export default component.exports