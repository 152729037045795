<template>
  <div>
    <v-card>
      <v-card-title style="background-color: #E0F7FA" align="center" justify="center">ΛΕΣΧΗ</v-card-title>
      <v-card-text>
        <v-row  class="ma-2">
          <v-col cols="1">
            <v-row>
              Επωνυμία:
            </v-row>
            <v-row>
              ΑΦΜ:
            </v-row>
          </v-col>
          <v-col cols="5">
            <v-row>
              {{ $store.getters.selectedApplication.club.name }}
            </v-row>
            <v-row>
              {{ $store.getters.selectedApplication.club.vat }}
            </v-row>
          </v-col>
          <v-divider vertical/>
          <v-col cols="6">
            <v-row class="govgr-!-font-weight-bold mx-2">
              Υπεύθυνος λειτουργίας:
            </v-row>
            <v-row class="mx-4">
              <v-col cols="3">
                <v-row>
                  ΑΦΜ:
                </v-row>
                <v-row>
                  Όνομα:
                </v-row>
                <v-row>
                  Επώνυμο:
                </v-row>
                <v-row>
                  Πατρώνυμο:
                </v-row>
                <v-row>
                  ΑΔΤ:
                </v-row>
              </v-col>
              <v-col cols="9">
                <v-row>
                  {{ $store.getters.selectedApplication.club.user.vat }}
                </v-row>
                <v-row>
                  {{ $store.getters.selectedApplication.club.user.first_name }}
                </v-row>
                <v-row>
                  {{ $store.getters.selectedApplication.club.user.last_name }}
                </v-row>
                <v-row>
                  {{ $store.getters.selectedApplication.club.user.father_name }}
                </v-row>
                <v-row>
                  {{ $store.getters.selectedApplication.club.user.identification_document }}
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title style="background-color: #E0F7FA" align="center" justify="center">
        Μέλη λέσχης
      </v-card-title>
      <v-card-text>
        <v-expansion-panels class="mt-2">
          <v-expansion-panel v-for="(member, i) in $store.getters.selectedApplication.user_application" :key="i">
            <v-expansion-panel-header>
              {{ member.vat }} {{ member.first_name }} {{ member.last_name }}
            </v-expansion-panel-header>
            <v-divider/>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col>
                  ΑΔΤ: {{ member.identification_document }}
                </v-col>
                <v-col>
                  Διεύθυνση: {{ member.address }} {{ member.city }} {{ member.postal_code }}
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  Πατρώνυμο: {{ member.father_name }}
                </v-col>
                <v-col>
                  Μητρώνυμο : {{ member.mother_name}}
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-title style="background-color: #E0F7FA" align="center" justify="center">
        Παραρτήματα
      </v-card-title>
      <v-card-text>
        <v-expansion-panels class="mt-2">
          <v-expansion-panel v-for="(branch, i) in $store.getters.selectedApplication.application_branches" :key="i">
          <v-expansion-panel-header>
            <v-row>
              <v-col cols="2">
                Επωνυμία:
              </v-col>
              <v-col cols="4">
                {{ branch.name }}
              </v-col>
              <v-divider vertical class="ml-2"/>
              <v-col cols="2" class="ml-2">
                Διεύθυνση:
              </v-col>
              <v-col cols="3">
                {{ (branch.address != null && branch.address != '')? (branch.address + ',') : ('') }}
                {{ branch.city }}
                {{ branch.postal_code }}
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-divider/>
          <v-expansion-panel-content>
              <v-col>
                <v-row class="govgr-!-font-weight-bold mx-2">
                  Εκπρόσωπος παραρτήματος:
                </v-row>
                <v-row class="mx-4 mt-2" dense>
                  <v-col>
                    <v-row dense>
                      <v-col cols="3">
                        Όνομα:
                      </v-col>
                      <v-col>
                        {{ branch.user.first_name }}
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="3">
                        Επώνυμο:
                      </v-col>
                      <v-col>
                        {{ branch.user.last_name }}
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="3">
                        Πατρώνυμο:
                      </v-col>
                      <v-col>
                        {{ branch.user.father_name }}
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="ml-6">
                    <v-row dense>
                      <v-col cols="2">
                        ΑΦΜ:
                      </v-col>
                      <v-col>
                        {{ branch.user.vat }}
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="2">
                        ΑΔΤ:
                      </v-col>
                      <v-col>
                        {{ branch.user.identification_document }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>
        </v-expansion-panels>

      </v-card-text>
      <v-card-title style="background-color: #E0F7FA" align="center" justify="center">
        ΔΙΚΑΙΟΛΟΓΗΤΙΚΑ
      </v-card-title>
      <v-card-text class="mt-2">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card min-width="600">

              <v-card-text :style="`background-color: ` + (index%2 == 0 ? `${$vuetify.theme.themes.light.documentBlue}` : '')"
                           v-for="(doc, index) in documents" :key="index">

                <v-row class="pa-4">
                  <v-checkbox @change="updateCheckingStatus(doc.application_document)"
                              :input-value="doc.application_document.checked"></v-checkbox>
                  <v-col cols="8"><b>{{ doc.id + '. ' + doc.description }}</b></v-col>
                  <v-spacer></v-spacer>
                  <v-btn class="mt-4r" color="primary" icon @click="download(doc)">
                    <v-icon>mdi-download</v-icon>
                  </v-btn>

                </v-row>
              </v-card-text>

              <v-card-text>
                <v-container class="outlined-container">
                  <v-row>
                    <v-col cols="2">
                      Περίοδος Ισχύος:
                    </v-col>
                    <v-col cols="2">
                      Από {{ (new Date()).getDate() }} {{ utilities.monthNumberToShorthand((new Date()).getMonth()) }}
                      {{ (new Date()).getFullYear() }}
                    </v-col>
                    <v-spacer/>
                    <v-col cols="1">
                      Έως
                    </v-col>
                    <v-col cols="2">
                      <v-menu ref="menu"
                              :close-on-content-click="true"
                              transition="scale-transition"
                              offset-y
                              min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="effective_end_date"
                                        label="Ημερομηνία"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        dense
                                        @click="datePickerChanged = true"
                                        v-on="on"/>
                        </template>
                        <!--                                    Date.now() - (new Date()).getTimezoneOffset() * 60000)-->
                        <v-date-picker
                            :min="(new Date((new Date()).getFullYear() + 1, 5, 31)).toISOString().substr(0, 10)"
                            @change.self="checkDisableApproveButton(); handleDateSelect(); logEffectiveEndDate()"
                            v-model="effective_end_date"
                            no-title/>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title style="background-color: #E0F7FA" align="center" justify="center">ΕΛΕΓΧΟΣ</v-card-title>
      <v-card-subtitle style="background-color: #E0F7FA" class="red--text" v-if="effective_end_date == null">Πρέπει να
        επιλέξετε ημερομηνία λήξης περιόδου ισχύος αίτησης
      </v-card-subtitle>
      <v-card-text class="mt-2">
        <v-row align="center" justify="center">
          <v-col cols="6" align="center" justify="center">
            <v-btn :disabled="disableApproveButton" min-width="30%" color="green" x-large @click="openModalToApprove">
              <span style="color: white">ΕΓΚΡΙΣΗ</span></v-btn>
          </v-col>
          <v-col cols="6" align="center" justify="center">
            <v-btn min-width="30%" color="red" x-large @click="openModalToReject"><span
                style="color: white">ΑΠΟΡΡΙΨΗ</span></v-btn>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>

    <ConfirmationDialog v-if="isReject" event-name="rejectApplication"/>
    <ConfirmationDialog v-else event-name="approveApplication"/>


    <v-dialog persistent
              @keydown.esc="closeRejectionDialog"
              v-model="rejectionDialog"
              :retain-focus="false"
              min-width="70%">
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">Απόρριψη</span>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12">
              <v-textarea
                  outlined
                  class="mb-1"
                  label="Αιτία απόρριψης"
                  v-model="rejectionReason"
                  dense
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-col cols="2">
            <v-file-input dense
                          v-model="file"
                          clearable
                          chips
                          placeholder="Ανεβάστε το έγγραφό σας"
                          label="Αρχείο"
                          prepend-icon="mdi-file"
            >
              <template v-slot:selection="{ text }">
                <v-chip small label color="primary">
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn color="primary"
                 text
                 @click="closeRejectionDialog"
          >
            Άκυρο
          </v-btn>
          <v-btn color="primary"
                 @click="openConfirmationModalToreject"
          >
            Απόρριψη
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
    import requests from "../../requests";
    import ConfirmationDialog from "../reusable/ConfirmationDialog";
    import {eventBus} from "@/main";
    import utilities from "../../utilities";

    export default {
        name: "EditApplicationon",
        components: {ConfirmationDialog},
        data: () => ({
          checkingList: [],
          rejectionDialog: false,
          rejectionReason: '',
          isReject: false,
          selectedApplicationDocument: {},
          disableApproveButton: false,
          file: null,
          url: 'application/upload-praktiko',
          documents: [],
          effective_end_date: (new Date((new Date()).getFullYear() + 1, 5, 31)).toISOString().substr(0, 10),
          datePickerChanged: false,
        }),
        methods:{

            download(item) {

                requests.downloadFileRequest('application/download/' + this.$store.getters.selectedApplication.id + '/' + item.id)
            },

            openModalToApprove(){
              this.isReject = false
              this.$store.commit('setConfirmationDialog', true);
            },
            approve(){
              let requestObj = {
                application_id: this.$store.getters.selectedApplication.id,
                effective_end_date: this.effective_end_date,
              }
              requests.postSecureRequest('application/approval', requestObj, this.approveCallback)
            },
            approveCallback(response){
              this.$store.commit('setEditGGAApplicationDialog', false)
              this.getSubmittedApplications()
            },

            openModalToReject(){

                this.rejectionReason = "";

                for (let i = 0; i < this.$store.getters.selectedApplication.documents.length; i++) {

                    let doc = JSON.parse(JSON.stringify(this.$store.getters.selectedApplication.documents[i]));
                    console.log(doc);
                    if (doc.application_document.checked == false) {
                        // this.rejectionReason += 'Αιτία απόρριψης: ' + doc.id.toString() + '. ' + doc.description + ' \n\n'
                        this.rejectionReason += doc.id.toString() + '. ' + doc.description + ' \n\n'

                        console.log(this.rejectionReason);
                    }
                }

                console.log(this.rejectionReason);

                this.rejectionDialog = true;
            },

          openConfirmationModalToreject(){
              this.isReject = true
            this.$store.commit('setConfirmationDialog', true);
          },

            updateCheckingStatus(applicationDocument){

                applicationDocument.checked = !applicationDocument.checked

                console.log(JSON.parse(JSON.stringify(applicationDocument)));

                requests.putSecureRequest('application/update-document-status', applicationDocument, this.updateCheckingStatusCallback)

                // let value = this.disableApproveButton
            },
            updateCheckingStatusCallback(response){
                // console.log(JSON.parse(JSON.stringify(response)));

              this.checkingList[response.document_id - 1] = response.checked

              // console.log(JSON.parse(JSON.stringify(this.checkingList)));

              this.disableApproveButton = !(this.checkingList.filter(i => i === true).length === this.checkingList.length) || this.effective_end_date == null;
            },

            closeRejectionDialog(){
                this.rejectionDialog = false;
            },
            reject(){
              let requestObj = {rejection_reason: this.rejectionReason, application_id: this.$store.getters.selectedApplication.id}
              requests.postSecureRequest('application/rejection', requestObj, this.rejectCallback)
            },
            rejectCallback(response) {
              if(this.file != null){
                this.submitPraktiko()
              }
              this.closeRejectionDialog()
              this.$store.commit('setEditGGAApplicationDialog', false)
              this.getSubmittedApplications()
            },
          getSubmittedApplications(){
            this.$store.commit('setLoadingModal', true)
            requests.postSecureRequest('application/findSubmitted', {}, this.getSubmittedApplicationsCallback)
          },

          getSubmittedApplicationsCallback(response){
            console.log(response)
            this.numberOfSubmittedApplications = response.length

            this.$store.commit('setSubmitedApplications', response)
            this.$store.commit('setLoadingModal', false)
          },

          submitPraktiko() {

            /*
              Initialize the form data
            */
            let formData = new FormData();

            formData.append('file', this.file,  encodeURI(this.file.name));
            formData.append('applicationId', this.$store.getters.selectedApplication.id);

            requests.uploadFilePostAsync(this.url, formData, this.uploadPraktikoPostCallBack)
          },
          uploadPraktikoPostCallBack(response) {
            console.log(response)
            this.file=null;
          },

          checkDisableApproveButton(){
            this.disableApproveButton = !(this.checkingList.filter(i => i === true).length === this.checkingList.length) || this.effective_end_date == null;
          },

          handleDateSelect() {
            // Close the date picker
            console.log('handleDateSelect')
            this.datePickerChanged = false
          },

          logEffectiveEndDate(){
            console.log(this.effective_end_date);
          },
        },
        created() {

          console.log("created");
          eventBus.$on('approveApplication', (() => {
              this.approve()
            }));
            eventBus.$on('rejectApplication', (() => {
              this.reject()
            }));

            let clubIsRegistered = this.$store.getters.selectedApplication.club.is_registered

            this.checkingList = [];

            let documents = []
            if(clubIsRegistered == true){
              for(let i = 0; i < this.$store.getters.selectedApplication.documents.length; i++){
                if(this.$store.getters.selectedApplication.documents[i].is_registration_document == 0){
                  documents.push(this.$store.getters.selectedApplication.documents[i])
                }
              }
            }else if(clubIsRegistered == false){
              documents = this.$store.getters.selectedApplication.documents;
            }
            this.documents = documents

            for( let i = 0; i<documents.length; i++) {

              console.log( 'iteration ' +i);
              this.checkingList[i] = documents[i].application_document.checked
            }
          this.disableApproveButton = !(this.checkingList.filter(i => i === true).length === this.checkingList.length) || this.effective_end_date == null;

            console.log(this.$store.getters.selectedApplication.documents);
            console.log(this.checkingList);

            this.effective_end_date = (new Date((new Date()).getFullYear() + 1, 5, 31)).toISOString().substr(0, 10)
        },
        computed:{
          utilities() {
            return utilities
          }
        },

      watch:{
          '$store.getters.selectedApplication' : function (val){
            let i = 0;
            for(i<val.documents; i++;) {
              this.checkingList.push(!!val.documents[i].application_document.checked)
            }
          }
      }
    }
</script>

<style scoped>
  .outlined-container {
    border: 1px solid #000; /* Border color and thickness */
    border-radius: 4px; /* Border radius */
    padding: 16px; /* Optional padding */
  }
</style>
