<template>
  <div>
    <v-card>
      <v-card-title>
        Σύνδεση χρήστη με Α.Φ.Μ.
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field outlined label="Α.Φ.Μ." v-model="loginVat" :rules="vatRule" counter="9" type="number" hide-spin-buttons/>
          </v-col>
          <v-col align="center" justify="center">
            <v-btn class="primary" @click="loginWithVat" :disabled="loginVat == ''">Σύνδεση</v-btn>
          </v-col>
        </v-row>
        <v-row class="red--text">
          Development
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import requests from "@/requests";
import utils from "../../utilities"

export default {
  name: "DevLogin",
  data: () => ({
    loginVat: '',
    vatRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο',
      v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
    ],
  }),
  methods: {
    loginWithVat(){
      if(this.loginVat != ''){
        this.$store.commit('setLoadingModal',true)
        requests.postRequest('authentication/devLogin', {vat: this.loginVat}, this.devLoginCallback)
      }
    },
    devLoginCallback(response){

      // utils.setLocalStorage('jwt_token', response.data.token);
      document.cookie = `jwt_token=${response.data.token}`;
      this.$store.commit('setLoadingModal',false)
      this.$router.push({path: '/role-selection'})
    },
  },

  created() {

    utils.setLocalStorage('path', '/dev-login');
    this.$store.commit('setNotificationIcon', false)
    this.$store.commit('setMenuItems', [])
  }
}
</script>

<style scoped>

</style>