<template>
  <div>

    <v-row align="center" justify="center">

      <p :style="pageWidth<425 ? 'font-size: 19px' : 'font-size: 23px'">
        Επιλέξτε ρόλο:
      </p>
    </v-row>

    <v-row class="mt-10" align="center" justify="center">
      <v-list align="center" justify="center">
        <v-list-item>
          <v-btn outlined
                 class="elevation-8"
                 :style="pageWidth<425 ? 'min-width:300px; font-size:10px;' : 'min-width:400px; font-size:14px;'"
                 min-height="60px"
                 @click="login(1)">
            Εκπρόσωπος Α.Σ/ΤΑΑ/ΑΕΕ
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn outlined
                 :disabled="!loggedInUserIsNEL"
                 class="elevation-8 mt-4"
                 :style="pageWidth<425 ? 'min-width:300px; font-size:10px;' : 'min-width:400px; font-size:14px;'"
                 min-height="60px"
                 @click="login(2)">
            Εκπρόσωπος Λέσχης
          </v-btn>
        </v-list-item>
        <v-list-item>
          <v-btn outlined
                 :disabled="!loggedInUserIsYPL"
                 class="elevation-8 mt-4"
                 :style="pageWidth<425 ? 'min-width:300px; font-size:10px;' : 'min-width:400px; font-size:14px;'"
                 min-height="60px"
                 @click="login(3)">
            Εκπρόσωπος Παραρτήματος
          </v-btn>
        </v-list-item>
        <v-list-item>
          <!--:disabled="!loggedInUserIsMember"-->
          <!--Oloi mpenoun san melh kai apla meta blepoun adies tis selides-->
          <v-btn outlined
                 class="elevation-8 mt-4"
                 :style="pageWidth<425 ? 'min-width:300px; font-size:10px;' : 'min-width:400px; font-size:14px;'"
                 :disabled="isLegalPerson"
                 min-height="60px"
                 @click="login(4)">
            Μητρώο Μελών
          </v-btn>
        </v-list-item>
      </v-list>
    </v-row>

  </div>
</template>

<script>
import utils from "../utilities";
import requests from "../requests";
import services from "@/services";

export default {
  name: "RoleSelection",
  data: () => ({
    loggedInUserIsNEL: false,
    loggedInUserIsYPL: false,
    loggedInUserIsMember: false,
    pageWidth: window.innerWidth,
    isLegalPerson: false,
  }),
  methods: {

    login(role) {
      let user = this.$store.getters.loggedinUser;

      if (role === 1) {
        user.role = 'NEAS'
        this.$store.commit('setLoggedinUser', user)
        this.$router.push({path: '/club-identification'})
      } else if (role === 2) {
        user.role = 'NEL'
        this.$store.commit('setLoggedinUser', user)
        //this.$router.push({path: '/club-vat-check'})
        this.$router.push({path: '/select-club'})
      } else if (role === 3) {
        user.role = 'YLPL'
        this.$store.commit('setLoggedinUser', user)
        this.$router.push({path: '/select-branch'})
      } else if (role === 4) {
        user.role = 'MEMBER'
        this.$store.commit('setLoggedinUser', user)
        this.$router.push({path: '/member'})
      }

    },

    getLoginInformation() {
      requests.getSecureRequest('user/loginInformation', '', this.loginInformationCallabck)
    },

    loginInformationCallabck(response) {

      this.$store.commit('setLoggedinUser', response)
      this.$store.commit('setMyClubs', response.myClubs)


      if (this.$store.getters.loggedinUser.user.user_type_id == this.$store.getters.loggedinUser.CONSTANTS.USER.TYPE.LEGAL_PERSON) {
        this.isLegalPerson = true
      }
      // if(response.myClubs.length>0) {
      //     this.$store.commit('setMyClubsDialog', true)
      // }

      this.checkLoggedInUserIsNEL()
      this.checkLoggedInUserIsYPL()
      this.checkLoggedInUserIsMember()

      requests.getSecureRequest('properties/', 5, this.getDevModeCallback)
    },

    getDevModeCallback(response) {
      this.$store.commit('setDevMode', response)
    },

    checkLoggedInUserIsNEL() {
      for (let myClub of this.$store.getters.loggedinUser.myClubs) {
        if (myClub.isNEL === true) {
          this.loggedInUserIsNEL = true
          return
        }
      }

      this.loggedInUserIsNEL = false
    },

    checkLoggedInUserIsYPL() {
      this.loggedInUserIsYPL = Object.keys(this.$store.getters.loggedinUser.YPLonBranches).length > 0
    },

    checkLoggedInUserIsMember() {
      for (let myClub of this.$store.getters.loggedinUser.myClubs) {
        if (myClub.isMember === true) {
          this.loggedInUserIsMember = true
          return
        }
      }

      this.loggedInUserIsMember = false
    },
    handleResize() {
      this.pageWidth = window.innerWidth;
    },
    getAccessUrl() {
      requests.postRequest('properties/access-url', {}, this.getAccessUrlCallback)
    },

    getAccessUrlCallback(response) {
      this.$store.commit("setAccessUrl", response.data)
    },
  },

  created() {
    services.clearGetNotificationsInterval()
    this.$store.commit('setNotificationIcon', false)
    this.$store.commit('setMenuItems', [])

    this.$store.commit('setHomePath', '/role-selection');
    utils.setLocalStorage('path', '/role-selection');
    // const jwtToken = this.$cookies.get("jwt_token");
    // console.log(jwtToken);
    // utils.setLocalStorage('jwt_token', jwtToken);

    this.getLoginInformation();


    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.getAccessUrl();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>

</style>
