<template>
  <div>
    <v-row class="mb-1">
      <v-col class="mx-auto text-center font-weight-light headline">
        <div>
          {{ 'Ιστορικό μεταφορτώσεων αρχείων μελών' }}
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pt-1">
        <v-data-table
            :page.sync="pageNumber"
            :footer-props="{page:1,
                            pageCount:1,
                            showCurrentPage:true,
                            itemsPerPageOptions:[5,10,15,30],
                            showFirstLastPage:true,
                            itemsPerPageText:'Μεταφορτώσεις ανά σελίδα:',
                            itemsPerPageAllText:'Όλα'}"
            no-data-text="Δέν υπάρχουν αποτελέσματα."
            :headers="headers"
            :items="memberfiles"
            :items-per-page="itemsPerPage"
            :server-items-length="tableLength"
            @update:options="getBuildingPerPage"
            class="elevation-3 mx-5 px-2">
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>
                <v-row>
                  <v-col cols="12">
                    <v-select
                        label="Παράρτημα"
                        :items="branches"
                        return-object
                        item-text="name"
                        v-model="selectedBranch"
                        no-data-text="Δεν υπάρχουν καταχωρήσεις"
                        dense
                        @change="getMemberfiles"
                        :disabled="loggedInUserIsYPL"
                        v-if="!loggedInUserIsYPL">
                    </v-select>
                  </v-col>
                </v-row>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-card>

              </v-card>
            </v-toolbar>
          </template>
          <template v-slot:item="{item}">
            <tr>
              <td class="text-center d-sm-table-cell d-flex">
                {{ utilities.timestampToDatetime(item.upload_time) }}
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branch.user.last_name }}
                {{ item.user_branch.user.first_name }}
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branch.branch.name }}
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.added_members }}
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon @click="download(item, true)"
                            color="primary"
                            v-on="on">
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Λήψη αρχείου</span>
                </v-tooltip>
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.not_added_members }}
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon @click="download(item, false)"
                            color="primary"
                            v-on="on">
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Λήψη αρχείου</span>
                </v-tooltip>
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.members_from_users_file }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import requests from "@/requests";
import utilities from "../../utilities";

export default {
  name: "HistoryOfMemberfile.vue",
  computed: {
    utilities() {
      return utilities
    }
  },
  data() {
    return {
      headers: [
        {
          text: 'Ημερομηνία',
          align: 'center',
          sortable: false,
          value: 'upload_time',
        },
        {
          text: 'Υπεύθυνος',
          align: 'center',
          sortable: false,
          value: 'uploadedFrom',
        },
        {
          text: 'Λέσχη/Παράρτημα',
          align: 'center',
          sortable: false,
          value: 'branchName',
        },
        {
          text: 'Επιτυχής εισαγωγή μελών',
          align: 'center',
          sortable: false,
          value: 'added_members',
        },
        {
          text: 'Ανεπιτυχής εισαγωγή μελών',
          align: 'center',
          sortable: false,
          value: 'not_added_members',
        },
        {
          text: 'Σύνολο',
          align: 'center',
          sortable: false,
          value: 'members_from_users_file',
        },
      ],
      branches: [],
      memberfiles: [],
      selectedBranch: {},
      pageNumber: 1,
      itemsPerPage: 5,
      tableLength: 0,
      loggedInUserIsYPL: false,
    }
  },
  created() {
    this.checkLoggedInUserIsYPL()

    if (!this.loggedInUserIsYPL) {
      this.getBranches();
    }
  },
  methods: {
    checkLoggedInUserIsYPL() {
      if (this.$store.getters.loggedinUser.role === 'YLPL' && Object.keys(this.$store.getters.selectedBranchYPL).length > 0) {
        this.selectedBranch = this.$store.getters.selectedBranchYPL
        this.loggedInUserIsYPL = true
        return
      }
      this.loggedInUserIsYPL = false
    },
    // loadBranchesOnDropdown(){
    //   this.branches = []
    // for(let i = 0; i < this.$store.getters.selectedClub.myBranchesOnClub.length; i++){
    //   this.branches.push(this.$store.getters.selectedClub.myBranchesOnClub[i])
    // }
    //
    //   this.getBranches();
    //
    // },


    getBranches() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('branch/get-branches-of-club', {club: this.$store.getters.selectedClub}, this.getBranchesWithPlacesCallback)
    },

    getBranchesWithPlacesCallback(response) {
      this.branches = []
      this.branches = response
      this.$store.commit('setLoadingModal', false)
    },

    getMemberfiles() {
      this.$store.commit('setLoadingModal', true)
      this.pageNumber = 1
      this.itemsPerPage = 5
      requests.postSecureRequest('memberfile/getfiles/',
          {
            selectedBranchId: this.selectedBranch.id,
            loggedInUserId: this.$store.getters.loggedinUser.user.id,
            pageNumber: this.pageNumber - 1,
            itemsPerPage: this.itemsPerPage
          },
          this.getMemberfilesCallback)
    },
    getMemberfilesCallback(response) {
      if (response.hasOwnProperty('message')) {
        this.$store.commit('setInfoDialogObj', {text: response.message})
        this.$store.commit('setInfoDialog', true)
        this.$store.commit('setLoadingModal', false)
        return
      }
      this.memberfiles = []
      let res = JSON.parse(JSON.stringify(response))

      for (let i = 0; i < res.memberfiles.length; i++) {
        this.memberfiles.push(res.memberfiles[i])
      }
      this.tableLength = res.memberfilesCount
      this.$store.commit('setLoadingModal', false)
    },
    download(item, added) {
      //added boolean if true download addedMemberfile else download notAddedMemberfile


      let minioFile
      if (added == true) {
        minioFile = 'membersAdded'
        if (item.added_members == 0) {
          this.$store.commit('setSnackBarObj', {text: 'Δεν υπάρχουν εγγραφές.'})
          this.$store.commit('setSnackBar', true)
          return
        }
      } else {
        minioFile = 'membersNotAdded'
        if (item.not_added_members == 0) {
          this.$store.commit('setSnackBarObj', {text: 'Δεν υπάρχουν εγγραφές.'})
          this.$store.commit('setSnackBar', true)
          return
        }
      }


      requests.downloadRequest('memberfile/minio_download/', item.user_branch_id, item.id, minioFile)
    },

    printDataTableOptions() {
      console.log('Page: ' + this.pageNumber + '\nItems per page: ' + this.itemsPerPage)
    },
    getBuildingPerPage(item) {
      if (Object.keys(this.selectedBranch).length === 0) {
        return
      }
      this.pageNumber = item.page
      this.itemsPerPage = item.itemsPerPage
      this.printDataTableOptions()

      requests.postSecureRequest('memberfile/getfiles/',
          {
            selectedBranchId: this.selectedBranch.id,
            loggedInUserId: this.$store.getters.loggedinUser.user.id,
            pageNumber: this.pageNumber - 1,
            itemsPerPage: this.itemsPerPage
          },
          this.getMemberfilesCallback)
    }
  }
}
</script>

<style scoped>

</style>