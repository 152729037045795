<template>
    <div>

        <div class="govgr-width-container">
            <div class="govgr-main-wrapper">
                <div class="govgr-grid-column-two-thirds">
                    <h3 class="govgr-heading-xl">Στοιχεία χρήστη</h3>
                    <p class="govgr-body">

                        <v-list-item two-line >
                            <v-list-item-content >
                                <v-list-item-title>{{ '-'}}</v-list-item-title>
                                <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item two-line >
                            <v-list-item-content >
                                <v-list-item-title>{{ '-'}}</v-list-item-title>
                                <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item two-line >
                            <v-list-item-content >
                                <v-list-item-title>{{ '-'}}</v-list-item-title>
                                <v-list-item-subtitle>Όνομα πατρός</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item two-line >
                            <v-list-item-content >
                                <v-list-item-title>{{ '-'}}</v-list-item-title>
                                <v-list-item-subtitle>Όνομα μητρός</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item two-line >
                            <v-list-item-content >
                                <v-list-item-title>{{ '-'}}</v-list-item-title>
                                <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>

                        <br/>

                        <button @click="confirm" class="govgr-btn govgr-btn-primary govgr-btn-cta">
                            Επιβεβαίωση
                            <svg viewBox="0 0 24 24" class="govgr-arrow--right" focusable="false" aria-hidden="true"> <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"/> </svg>
                        </button>

                    </p>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Confirmation",
        methods: {
            confirm(){

                if (this.$store.getters.loggedinUser.role == 'NEAS') {
                    // this.$router.push({path: '/dashboard'})
                    this.$router.push({path: '/role-selection'})
                }else if(this.$store.getters.loggedinUser.role == 'NEL'){
                    // this.$router.push({path: '/dashboard'})

                    this.$router.push({path: '/role-selection'})
                }
            }
        }
    }
</script>

<style scoped>

</style>
