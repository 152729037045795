<template>
  <div>
    <v-card>
      <v-card-title class="primary white--text govgr-!-font-size-30" style="word-break: normal">
        Αλλαγή υπευθύνου λειτουργίας λέσχης φιλάθλων
      </v-card-title>
      <v-card-text class="mt-4 ml-2 mr-2 govgr-!-font-size-19">
        <v-row class="govgr-!-font-size-24 govgr-!-font-weight-bold">
          Υπεύθυνος λειτουργίας
        </v-row>
        <v-row class="mt-4">
          <v-col xl="5" lg="6" md="8" sm="10" xs="12">
            <v-row>
              <v-col class="govgr-!-font-weight-bold">
                ΑΦΜ:
              </v-col>
              <v-col>
                {{ (nel.vat == '') ? '-' : nel.vat }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="govgr-!-font-weight-bold">
                ΑΔΤ:
              </v-col>
              <v-col>
                {{ (nel.identification_document == '') ? '-' : nel.identification_document }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="govgr-!-font-weight-bold">
                Όνομα:
              </v-col>
              <v-col>
                {{ (nel.first_name == '') ? '-' : nel.first_name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="govgr-!-font-weight-bold">
                Επώνυμο:
              </v-col>
              <v-col>
                {{ (nel.last_name == '') ? '-' : nel.last_name }}
              </v-col>
            </v-row>
            <v-row>
              <v-col class="govgr-!-font-weight-bold">
                Πατρώνυμο:
              </v-col>
              <v-col>
                {{ (nel.father_name == '') ? '-' : nel.father_name }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-4">
        <v-btn text @click="redirectToClubConsent" class="govgr-!-font-size-19">
          Πίσω
        </v-btn>
        <v-spacer/>
        <v-btn class="primary govgr-!-font-size-19" @click="openRepresentativeDialog">
          Αλλαγή
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="representativeDialog" max-width="500">
      <v-card>
        <v-card-title>Ορισμός νέου υπευθύνου λειτουργίας λέσχης</v-card-title>
        <v-card-text>
          <v-form>
            <v-row class="mt-2" dense>
              <v-col cols="6">
                <v-text-field outlined label="ΑΦΜ" v-model="rep.afm" :rules="vatRule"
                              counter="9"
                              type="number" hide-spin-buttons></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="ΑΔΤ" v-model="rep.adt"></v-text-field>
              </v-col>
              <v-col cols="6">
                <!--@keyup="uppercase()"-->
                <v-text-field outlined label="Όνομα" v-model="rep.name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="Επώνυμο" v-model="rep.lastname"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="Πατρώνυμο" v-model="rep.fathername"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeRepresentativesDialog" text>Κλείσιμο</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="repFormIsValid()" @click="openDialogToConsent()">ΕΙΣΑΓΩΓΗ</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogToConsent" width="650px" persistent>
      <v-card>
        <v-card-title class="primary white--text">
          Επιβεβαίωση αλλαγής στοιχείων νέου υπεύθυνου λέσχης
        </v-card-title>
        <v-card-text class="govgr-!-font-size-19 mt-5 mb-5">
          <!--          <v-textarea outlined readonly class="mt-8" v-model="consentText"/>-->
          Είστε σίγουρος/η για την ενέργεια σας;
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogToConsent = false">
            Κλείσιμο
          </v-btn>
          <v-spacer/>
          <v-btn class="primary" @click="closeDialogAndSaveRepresentative()">
            Ναι, αποθήκευση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import router from "@/router";
import requests from "@/requests";

export default {
  name: "ChangeNEL",
  data: () => ({
    representativeDialog: false,
    dialogToConsent: false,
    consentText: '',
    nel: {
      vat: '',
      identification_document: '',
      first_name: '',
      last_name: '',
      father_name: '',
    },
    rep: {},
    vatRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο',
      v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
    ],
  }),
  methods: {
    getNEL() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_vat: this.$store.getters.initializationNEAS.club_vat
      }
      requests.postSecureRequest('user/get-nel-by-club-vat', requestObj, this.getNelByClubVatCallback)
    },
    getNelByClubVatCallback(response) {
      this.nel.vat = response.vat
      this.nel.identification_document = response.identification_document
      this.nel.first_name = response.first_name
      this.nel.last_name = response.last_name
      this.nel.father_name = response.father_name

      this.$store.commit('setLoadingModal', false)
    },

    redirectToClubConsent() {
      router.push('/club-consent')
    },

    openRepresentativeDialog() {
      this.representativeDialog = true;
    },
    closeRepresentativesDialog() {
      this.rep = {}
      this.representativeDialog = false;
    },

    saveRepresentative() {
      this.$store.commit('setLoadingModal', true)

      let requestObj = {
        current_nel: this.nel,
        new_nel: {
          vat: this.rep.afm,
          identification_document: this.rep.adt,
          first_name: this.rep.name,
          last_name: this.rep.lastname,
          father_name: this.rep.fathername,
        },
        club_vat: this.$store.getters.initializationNEAS.club_vat
      }
      requests.postSecureRequest('user/change-club-representative', requestObj, this.saveRepresentativeCallback)
    },
    saveRepresentativeCallback(response) {


      this.nel.vat = response.vat
      this.nel.identification_document = response.identification_document
      this.nel.first_name = response.first_name
      this.nel.last_name = response.last_name
      this.nel.father_name = response.father_name
      this.representativeDialog = false;
      this.$store.commit('setInfoDialogObj', {text: 'Η προσθήκη νέου/ας υπεύθυνου/ης ήταν επιτυχής.'})
      this.$store.commit('setInfoDialog', true)
      this.$store.commit('setLoadingModal', false);
    },

    repFormIsValid() {
      return (!this.rep.name || this.rep.name.length === 0) ||
          (!this.rep.lastname || this.rep.lastname.length === 0) ||
          (!this.rep.fathername || this.rep.fathername.length === 0) ||
          (!this.rep.adt || this.rep.adt.length === 0) ||
          (!this.rep.afm || this.rep.afm.length === 0);
    },

    openDialogToConsent() {
      this.consentText = 'Ως νόμιμος εκπρόσωπος του Αθλητικού Σωματείου συναινώ, ' +
          'βάσει της παρ. 3 του άρθρου 41Β του ν. 2725/1999, ' +
          'στην αναγνώριση και την εκ νέου συνέχιση της λειτουργίας της Λέσχης φιλάθλων με την  επωνυμία "' + this.$store.getters.initializationNEAS.club_name + '". ' +
          'Υπεύθυνος λειτουργίας ορίζεται ο/η ' + this.rep.lastname + ' ' + this.rep.name + ' του ' + this.rep.fathername + ' με ΑΦΜ ' + this.rep.afm + '.'

      this.dialogToConsent = true;
    },

    closeDialogAndSaveRepresentative() {
      this.saveRepresentative();
      this.dialogToConsent = false;
    },

    // uppercase() {
    //   this.rep.name = this.rep.name.toUpperCase();
    // }
  },

  created() {
    this.getNEL()
  }
}
</script>

<style scoped>

</style>