<template>
  <div>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="8">
            Πληροφορίες αίτησης με αναγνωριστικό: {{ $store.getters.selectedApplicationForInfo.id }}
          </v-col>
          <v-col clos="2">
            <v-btn class="primary white--text"
                   @click="openApplicationClubMembersInfoDialog"
            > <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)-->
              Μέλη λέσχης
            </v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn class="primary text--white" @click="openApplicationClubBranchesInfoDialog"
            >
              Παραρτήματα
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row class="ml-1">
          <span class="govgr-!-font-weight-bold">
            Ο/Η αιτών/ούσα:&nbsp;
          </span>
          {{ $store.getters.selectedApplicationForInfo.user.first_name }}
          {{ $store.getters.selectedApplicationForInfo.user.last_name }}
          {{ $store.getters.selectedApplicationForInfo.user.vat }}
        </v-row>
        <v-row>
          <v-col>
            <v-row class="pb-2">
              <v-col cols="8">
                Αριθμός πρωτοκόλλου:
              </v-col>
              <v-col>
                {{ $store.getters.selectedApplicationForInfo.protocol_number }}
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical/>
          <v-col>
            <v-row class="pb-2">
              <v-col cols="8">
                Κατάσταση αίτησης:
              </v-col>
              <v-col>
                {{ $store.getters.selectedApplicationForInfo.applicationstatus.name }}
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical/>
          <v-col>
            <v-row class="pb-2">
              <v-col cols="8">
                Υπεύθυνος λειτουργίας:
              </v-col>
              <v-col>
                {{ $store.getters.selectedApplicationForInfo.user.first_name }}
                {{ $store.getters.selectedApplicationForInfo.user.last_name }}
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical/>
          <v-col>
            <v-row class="pb-2">
              <v-col cols="8">
                Ημερομηνία υποβολής:
              </v-col>
              <v-col>
                {{ $store.getters.selectedApplicationForInfo.submission_date }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row class="mt-2">
          <v-col>
            <v-row class="pb-2">
              <v-col cols="8">
                Ημερομηνία έγκρισης:
              </v-col>
              <v-col>
                {{ $store.getters.selectedApplicationForInfo.approval_date }}
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical/>
          <v-col>
            <v-row class="pb-2">
              <v-col cols="8">
                Έναρξη περιόδου ισχύος:
              </v-col>
              <v-col>
                {{ $store.getters.selectedApplicationForInfo.effective_start_date }}
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical/>
          <v-col>
            <v-row class="pb-2">
              <v-col cols="8">
                Λήξη περιόδου ισχύος:
              </v-col>
              <v-col>
                {{ $store.getters.selectedApplicationForInfo.effective_end_date }}
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical/>
          <v-col>
            <v-row class="pb-2">
              <v-col cols="8">
                Ημερομηνία απόρριψης:
              </v-col>
              <v-col>
                {{ $store.getters.selectedApplicationForInfo.rejection_date }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="$store.getters.selectedApplicationForInfo.rejection_date != null">
          <v-divider class="ml-3 mr-3"/>
        </v-row>
        <v-row v-else class="ma-3">

        </v-row>

        <v-row class="mt-3 mb-2" v-if="$store.getters.selectedApplicationForInfo.rejection_date != null">
          <v-col cols="1">
            Αιτία απόρριψης:
          </v-col>
          <v-col cols="1" v-if="$store.getters.selectedApplicationForInfo.rejection_file_name != null">
            <v-tooltip left>
              <template v-slot:activator="{ download }">
                <v-btn class="mb-2" color="primary" dark fab x-small v-on="download" @click="downloadRejectionFile()">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>{{ '' }}</span>
            </v-tooltip>
          </v-col>
          <v-col>
            {{ $store.getters.selectedApplicationForInfo.rejection_reason }}
          </v-col>
        </v-row>
        <v-divider/>

        <v-row class="pa-4" v-for="(applicationDocument, index) in applicationDocuments" :key="index"
               :style="`background-color: ` + (index%2 == 0 ? $vuetify.theme.themes.light.whiteBlue : '')">
          <v-col cols="10">
            {{ applicationDocument.document.description }}
          </v-col>
          <v-divider vertical></v-divider>
          <v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" icon @click="downloadDocument(applicationDocument)" v-on="on">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span class="ml-2">
                {{ applicationDocument.filename }} {{ utilities.timestampToDatetime(applicationDocument.timestamp) }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="applicationClubBranchesInfoDialog" max-width="70%">
      <v-card>
        <v-card-title class="primary white--text">
          <v-row>
            <v-col cols="11">
              <v-row>
                Καταχωρημένα παραρτήματα λέσχης για την περίοδο ισχύος της αίτησης με ημερομηνία υποβολής
              </v-row>
              <v-row>
                {{ $store.getters.selectedApplicationForInfo.submission_date }}
              </v-row>
            </v-col>
            <v-col cols="1">
              <v-btn @click="closeApplicationClubBranchesInfoDialog" rounded class="primary" text>
                <v-icon color="white" x-large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="branchHeaders" :items="applicationBranches" :items-per-page="10">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="applicationClubMembersInfoDialog" max-width="70%">
      <v-card>
        <v-card-title class="primary white--text">
          <v-row>
            <v-col cols="11">
              Καταχωρισμένα μέλη λέσχης για την περίοδο ισχύος της αίτησης με ημερομηνία υποβολής
              {{ $store.getters.selectedApplicationForInfo.submission_date }}
            </v-col>
            <v-col cols="1">
              <v-btn @click="closeApplicationClubMembersInfoDialog" rounded class="primary" text>
                <v-icon color="white" x-large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="clubmembersHeaders" :items="applicationClubMembers" :items-per-page="10">
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "@/requests";
import utilities from "../../utilities";

export default {
  name: "ApplicationInfo",
  computed: {
    utilities() {
      return utilities
    }
  },
  data: () => ({
    applicationDocuments: [],
    applicationClubMembers: [],
    applicationBranches: [],
    applicationClubMembersInfoDialog: false,
    applicationClubBranchesInfoDialog: false,
    branchHeaders: [
      {
        text: 'Ονομασία',
        align: 'center',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address'
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city'
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code'
      },
    ],
    clubmembersHeaders: [
      {
        text: 'Α.Φ.Μ.',
        align: 'center',
        sortable: false,
        value: 'vat'
      },
      {
        text: 'Αριθμός ταυτότητας',
        align: 'center',
        sortable: false,
        value: 'identification_document'
      },
      {
        text: 'Όνομα',
        align: 'center',
        sortable: true,
        value: 'first_name'
      },
      {
        text: 'Επώνυμο',
        align: 'center',
        sortable: true,
        value: 'last_name'
      },
      {
        text: 'Πατρώνυμο',
        align: 'center',
        sortable: true,
        value: 'father_name'
      },
    ]
  }),
  created() {
    this.getApplicationDocuments()
  },
  methods: {
    getApplicationDocuments() {
      let requestObj = {application: this.$store.getters.selectedApplicationForInfo}
      requests.postSecureRequest('application/get-application-documents', requestObj, this.getApplicationDocumentsCallback)
    },
    getApplicationDocumentsCallback(result) {
      this.applicationDocuments = result


      this.applicationClubMembers = []
      this.applicationBranches = []

      let requestObj = {
        application_id: this.$store.getters.selectedApplicationForInfo.id
      }
      requests.postSecureRequest('application/get-application-users-branches', requestObj, this.getApplicationClubMembersAndBranchesCallback)

    },
    getApplicationClubMembersAndBranchesCallback(result) {
      this.applicationClubMembers = result.users


      this.applicationBranches = result.application_branches

    },

    downloadDocument(applicationDocument) {
      requests.downloadFileRequest('application/download/' + this.$store.getters.selectedApplicationForInfo.id + '/' + applicationDocument.document_id)
    },

    downloadRejectionFile() {
      requests.downloadRejectionFileRequest('application/download-rejection-file', this.$store.getters.selectedApplicationForInfo.rejection_file_name)
    },

    openApplicationClubMembersInfoDialog() {
      this.applicationClubMembersInfoDialog = true
    },
    closeApplicationClubMembersInfoDialog() {
      this.applicationClubMembersInfoDialog = false
    },

    openApplicationClubBranchesInfoDialog() {
      this.applicationClubBranchesInfoDialog = true
    },
    closeApplicationClubBranchesInfoDialog() {
      this.applicationClubBranchesInfoDialog = false
    },

  },
}
</script>

<style scoped>

</style>