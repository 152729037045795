<template>
  <div>
    <v-card>
      <v-card-title>
        Σύνδεση δημοσίου υπαλλήλου με Α.Φ.Μ.
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field outlined type="number" hide-spin-buttons label="Α.Φ.Μ." :rules="vatRule" counter="9" v-model="loginVat"/>
            <v-select :items="paRoles" v-model="selectedPaRole" no-data-text="Δεν υπάρχουν καταχωρήσεις" item-text="name" return-object label="Ρόλος"/>
            <v-autocomplete v-if="selectedPaRole.id === 4"
                            :items="policestations"
                            no-data-text="Δεν υπάρχουν καταχωρήσεις"
                            v-model="selectedPolicestation"
                            :item-text="displayText"
                            return-object
                            label="Επιλογή Α.Τ. (κωδικός φορέα)">
              <template v-slot:item="{ item, tile }">
                  <p>{{ displayText(item) }}</p>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col align="center" justify="center">
            <v-btn class="primary" @click="loginWithVat" :disabled="(loginVat == '' || Object.keys(selectedPaRole).length == 0)">Σύνδεση</v-btn>
          </v-col>
        </v-row>
        <v-row class="red--text">
          Development
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import requests from "@/requests";
import utils from "../../utilities"
import router from "@/router";

export default {
  name: "DevLoginPa",
  data: () => ({
    loginVat: '',
    paRoles: [],
    policestations: [],
    selectedPaRole: {},
    selectedPolicestation: {},
    vatRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο',
      v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
    ],
  }),
  methods: {
    getRoles(){
      requests.postRequest('user/getRoles', {}, this.getRolesCallback)
    },
    getRolesCallback(response){

      this.paRoles = response.data
      this.getPoliceStations()
    },

    getPoliceStations(){
      requests.postRequest('policestations/get-all-policestations', {}, this.getPoliceStationsCallback)
    },

    getPoliceStationsCallback(response){
      this.policestations = response.data
    },

    loginWithVat(){
      if(this.loginVat == '100000000') {
        this.$store.commit('setErrorDialog', true)
        this.$store.commit('setErrorDialogObj', {text: 'Ο κωδικός δημόσιας διοίκησης που παρείχατε ανήκει σε μη εξουσιοδοτημένο χρήστη.'})
        return
      }
      if(this.loginVat != ''){

        let obj = {vat: this.loginVat, role: this.selectedPaRole}

        if(this.selectedPaRole.id === 4){
          if(Object.keys(this.selectedPolicestation).length >0){
            obj.ATid = this.selectedPolicestation.id
          }else{
            this.$store.commit('setErrorDialog', true)
            this.$store.commit('setErrorDialogObj', {text: 'Επιλέξτε ΑΤ για να συνδεθείτε με τον επιλεγμένο ρόλο.'})
            return;
          }
        }

        requests.postRequest('authentication/devLoginPa', obj, this.devLoginCallback)
      }
    },
    devLoginCallback(response){

      // utils.setLocalStorage('jwt_token', response.data.token);
      document.cookie = `jwt_token=${response.data.token}`;
      this.$router.push({path: '/officials-card'})
    },
    displayText(item) {
      return item.name !== null ? item.name : item.org_number;
    },
  },
  created() {

    utils.setLocalStorage('path', '/dev-login-pa');
    this.$store.commit('setNotificationIcon', false)
    this.$store.commit('setMenuItems', [])
    this.getRoles()
  }
}
</script>

<style scoped>

</style>
