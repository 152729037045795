<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tab href="#tab-1"
             :style="tab=='tab-1' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getCouncilMembersChanges()">
        Μελη Δ.Σ.
      </v-tab>
      <v-tab href="#tab-2"
             :style="tab=='tab-2' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getBranchesChanges()">
        Παραρτηματα
      </v-tab>
      <v-tab href="#tab-3"
             :style="tab=='tab-3' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getPlacesChanges()">
        Χωροι
      </v-tab>
      <v-tab href="#tab-4"
             :style="tab=='tab-4' ? 'background-color: aliceblue;' : 'background-color: '"
      ><!--@click="getMembersChanges()"-->
        Μελη λεσχης
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1" class="mt-4">
          <v-data-table
              :items-per-page="5"
              :headers="headersMembersDSActions"
              :footer-props="{page:1,
                            pageCount:1,
                            showCurrentPage:true,
                            itemsPerPageOptions:[5,10,15,30],
                            showFirstLastPage:true,
                            itemsPerPageText:'Ενέργειες ανά σελίδα:',
                            itemsPerPageAllText:'Όλα'}"
              :items="councilMembersChanges"
              no-data-text="Δέν υπάρχουν αποτελέσματα."
          ><!--:items="councilMembers"-->
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:item="{item}">
              <tr>
                <td align="center" justify="center">{{ item.user.user_branches[0].first_name }}
                  {{ item.user.user_branches[0].last_name }}
                </td>
                <td align="center" justify="center">{{ "ΑΛΛΑΓΗ ΜΕΛΩΝ Δ.Σ." }}</td>
                <td v-if="item.in_council == true" align="center" justify="center">{{ "ΠΡΟΣΘΗΚΗ ΜΕΛΟΥΣ" }}</td>
                <td v-else align="center" justify="center">{{ "ΔΙΑΓΡΑΦΗ ΜΕΛΟΥΣ" }}</td>
                <td align="center" justify="center">{{ utilities.timestampToDatetime(item.entry_time) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-data-table
              :items-per-page="5"
              :headers="headersBranchesActions"
              :footer-props="{page:1,
                            pageCount:1,
                            showCurrentPage:true,
                            itemsPerPageOptions:[5,10,15,30],
                            showFirstLastPage:true,
                            itemsPerPageText:'Ενέργειες ανά σελίδα:',
                            itemsPerPageAllText:'Όλα'}"
              :items="branchesChanges"
              no-data-text="Δέν υπάρχουν αποτελέσματα."
          >
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:item="{item}">
              <tr>
                <td align="center" justify="center">{{ item.name }}</td>
                <td align="center" justify="center">{{ item.address }} {{ ', ' + item.city }} {{
                    ', ' + item.postal_code
                  }}
                </td>
                <td align="center" justify="center">{{ item.branch_history_type.type }}</td>
                <td align="center" justify="center">{{ item.branchstatus.name }}</td>
                <td align="center" justify="center">
                  {{
                    (item.responsible_userrole_id != 1) ? item.userrole.name : (item.user.user_branches.length > 0) ? item.user.user_branches[0].first_name + ', ' + item.user.user_branches[0].last_name : item.user.first_name + ', ' + item.user.last_name
                  }}
                </td>
                <td align="center" justify="center">{{ utilities.timestampToDatetime(item.change_time) }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-data-table
              :items-per-page="5"
              :headers="headersPlacesActions"
              :footer-props="{page:1,
                            pageCount:1,
                            showCurrentPage:true,
                            itemsPerPageOptions:[5,10,15,30],
                            showFirstLastPage:true,
                            itemsPerPageText:'Ενέργειες ανά σελίδα:',
                            itemsPerPageAllText:'Όλα'}"
              :items="placesChanges"
              no-data-text="Δέν υπάρχουν αποτελέσματα."
          ><!--:items="councilMembers"-->
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:item="{item}">
              <tr>
                <td align="center" justify="center">{{ item.place_history.place.place_type.type }}</td>
                <td align="center" justify="center">{{ item.place_history.place.address }}
                  {{ ', ' + item.place_history.place.city }} {{ ', ' + item.place_history.place.postal_code }}
                </td>
                <td align="center" justify="center">{{ item.place_history.place_history_type.type }}</td>
                <td align="center" justify="center">{{ item.place_history.place_status.status }}</td>
                <!--              <td align="center" justify="center">{{ item.place_history.user.first_name }} {{ item.place_history.user.last_name }}</td>-->
                <td align="center" justify="center">{{
                    (item.place_history.responsible_userrole_id != 1) ? item.place_history.userrole.name : (item.responsible_user.user_branches.length == 0) ? '-' : item.responsible_user.user_branches[0].first_name + ' ' + item.responsible_user.user_branches[0].last_name
                  }}
                </td>
                <td align="center" justify="center" v-if="item.place_history.place_file == null">{{ '-' }}</td>
                <td align="center" justify="center" v-else>
                  <v-btn icon color="primary" @click="downloadFile(item)">
                    <v-icon>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </td>
                <td align="center" justify="center">{{
                    utilities.timestampToDatetime(item.place_history.change_time)
                  }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-4">
          <v-data-table
              :page.sync="pageNumber"
              :footer-props="{page:1,
                            pageCount:1,
                            showCurrentPage:true,
                            itemsPerPageOptions:[5,10,15,30],
                            showFirstLastPage:true,
                            itemsPerPageText:'Ενέργειες ανά σελίδα:',
                            itemsPerPageAllText:'Όλα'}"
              no-data-text="Δέν υπάρχουν αποτελέσματα."
              :headers="headersMembersActions"
              :items="membersChanges"
              :items-per-page="itemsPerPage"
              :server-items-length="tableLength"
              :options.sync="optionsMembers"
              @update:options="getBuildingPerPage"
          >
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:item="{item}">
              <tr>
                <td align="center" justify="center">{{
                    item.user_branch.first_name + ' ' + item.user_branch.last_name
                  }}
                </td>
                <td align="center" justify="center">{{ item.user_branch.branch.name }}</td>
                <td align="center" justify="center">{{ "ΑΛΛΑΓΗ ΚΑΤΑΣΤΑΣΗΣ ΧΡΗΣΤΗ" }}</td>
                <td align="center" justify="center">{{ item.userbranchbranchrolestatus.status }}</td>
                <td align="center" justify="center">
                  {{
                    (item.userrole.id != 1)
                        ?
                        item.userrole.name
                        :
                        (item.user.user_branches.length > 0)
                            ?
                            (item.user.user_branches[0].first_name == null && item.user.user_branches[0].last_name == null)
                                ? '-'
                                :
                                ((item.user.user_branches[0].first_name == null)
                                    ? ' ' + item.user.user_branches[0].last_name
                                    :
                                    item.user.user_branches[0].first_name) + ' ' +
                                ((item.user.user_branches[0].last_name == null)
                                    ? ''
                                    :
                                    item.user.user_branches[0].last_name)
                            :
                            item.user.first_name + ' ' + item.user.last_name
                  }}
                </td>
                <td align="center" justify="center">{{ item.change_time }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import requests from "@/requests";
import utilities from "@/utilities";

export default {
  name: "ActionsAfterApproval",
  computed: {
    utilities() {
      return utilities
    }
  },
  data: () => ({
    tab: '',
    councilMembersChanges: [],
    headersMembersDSActions: [
      {
        text: 'Χρήστης',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Ενέργεια',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Λεπτομέρειες',
        align: 'center',
        sortable: false,
        value: 'action_text',
      },
      {
        text: 'Ημερομηνία',
        align: 'center',
        sortable: true,
        value: 'entry_time',
      },
    ],
    branchesChanges: [],
    headersBranchesActions: [
      {
        text: 'Επωνυμία παραρτήματος',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Ενέργεια',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Λεπτομέρειες',
        align: 'center',
        sortable: false,
        value: 'action_text',
      },
      {
        text: 'Χρήστης',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Ημερομηνία',
        align: 'center',
        sortable: true,
        value: 'change_time',
      },
    ],
    placesChanges: [],
    headersPlacesActions: [
      {
        text: 'Τύπος',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Ενέργεια',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Λεπτομέρειες',
        align: 'center',
        sortable: false,
        value: 'action_text',
      },
      {
        text: 'Χρήστης',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Αρχείο',
        align: 'center',
        sortable: false,
        value: 'file',
      },
      {
        text: 'Ημερομηνία',
        align: 'center',
        sortable: true,
        value: 'place_history.change_time',
      },
    ],
    membersChanges: [],
    headersMembersActions: [
      {
        text: 'Μέλος',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Παράρτημα που ανήκει',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Ενέργεια',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Λεπτομέρειες',
        align: 'center',
        sortable: false,
        value: 'action_text',
      },
      {
        text: 'Χρήστης',
        align: 'center',
        sortable: false,
        value: 'name'
      },
      {
        text: 'Ημερομηνία',
        align: 'center',
        sortable: true,
        value: 'action_time',
      },
    ],
    optionsMembers: {},
    itemsPerPage: 5,
    pageNumber: 1,
    tableLength: 0,
  }),
  methods: {
    getCouncilMembersChanges() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = this.$store.getters.selectedSeason
      requests.postSecureRequest('history/get-council-changes', requestObj, this.getCouncilMembersChangesCallback)
    },
    getCouncilMembersChangesCallback(response) {
      this.councilMembersChanges = [];
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.length; i++) {
        //res[i].entry_time = utilities.timestampToDatetime(res[i].entry_time);
        this.councilMembersChanges.push(res[i])
      }
      this.$store.commit('setLoadingModal', false)
    },

    getBranchesChanges() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = this.$store.getters.selectedSeason
      requests.postSecureRequest('history/get-branches-changes', requestObj, this.getBranchesChangesCallback)
    },
    getBranchesChangesCallback(response) {
      this.branchesChanges = [];
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.length; i++) {
        //res[i].change_time = utilities.timestampToDatetime(res[i].change_time);
        this.branchesChanges.push(res[i])
      }
      this.$store.commit('setLoadingModal', false)
    },

    getPlacesChanges() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = this.$store.getters.selectedSeason

      requests.postSecureRequest('history/get-places-changes', requestObj, this.getPlacesChangesCallback)
    },
    getPlacesChangesCallback(response) {
      this.placesChanges = [];
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.length; i++) {
        //res[i].place_history.change_time = utilities.timestampToDatetime(res[i].place_history.change_time);
        this.placesChanges.push(res[i])
      }
      this.$store.commit('setLoadingModal', false)
    },

    getMembersChanges() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = this.$store.getters.selectedSeason
      requestObj.limit = 5
      requestObj.offset = 0
      requests.postSecureRequest('history/get-members-changes', requestObj, this.getMembersChangesCallback)
    },
    getMembersChangesCallback(response) {
      this.membersChanges = [];
      let res = JSON.parse(JSON.stringify(response.changes))
      for (let i = 0; i < res.length; i++) {
        res[i].change_time = utilities.timestampToDatetime(res[i].change_time);
        this.membersChanges.push(res[i])
      }
      this.tableLength = response.all
      this.$store.commit('setLoadingModal', false)
    },

    getBuildingPerPage(item) {
      this.$store.commit('setLoadingModal', true)

      this.pageNumber = item.page

      let requestObj = this.$store.getters.selectedSeason
      requestObj.limit = item.itemsPerPage
      requestObj.offset = this.pageNumber - 1

      if (this.optionsMembers.sortBy.length > 0) {

        if (this.optionsMembers.sortDesc[0]) {
          requestObj.order = "ASC";
        } else {
          requestObj.order = "DESC";
        }
      }

      requests.postSecureRequest('history/get-members-changes', requestObj, this.getMembersChangesCallback)
    },

    downloadFile(place) {
      requests.downloadFileRequest('place/download-place-file/' + place.place_history.place_id + '/' + place.place_history.place_file.id)
    },

  },
  created() {
    this.getCouncilMembersChanges()
  }
}
</script>

<style scoped>

</style>