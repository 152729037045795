<template>


  <v-app>

    <ErrorSnackBar v-if="$store.getters.snackBar"/>
    <InfoSnackBar v-if="$store.getters.infoSnackBar"/>
    <LoadingModal :isLoading="$store.getters.loadingModal"/>
    <InfoDialog/>
    <ErrorDialog/>
    <div>
      <header class="govgr-header">
        <div class="govgr-header__container">
          <div class="govgr-header__content">
            <v-app-bar color="primary" app dark clipped-left style="border-color: red; border-bottom-width: 8px;">
              <v-app-bar-nav-icon @click.prevent="drawer = !drawer" v-if="$store.getters.menuItems.length > 0"/>
              <div style="height: 100%">
                <a href="https://www.gov.gr/" target="_blank" rel="noreferrer noopener">
                  <img class="govgr-header-logo"
                       src="https://www.gov.gr/gov_gr_logo.svg"
                       alt="gov.gr logo"
                       style="height: 100%"/>
                </a>
              </div>
              <div v-if="pageWidth >= 800" class="govgr-!-font-size-24"
                   style="margin-left: 10px; white-space: nowrap; position: relative;">
                Μητρώο Μελών Λεσχών Φιλάθλων
              </div>
              <v-spacer></v-spacer>

              <div class="govgr-!-font-size-14 mr-2"
                   v-if="isMyUserOfficials &&
                      pageWidth >= 800">
                {{
                  ($store.getters.loggedinUser.role == 'ΓΔΑ') ? "Επόπτης"
                      : ($store.getters.loggedinUser.role == 'ΑΤ') ? "Ελεγκτής"
                          : ($store.getters.loggedinUser.role == 'ΓΓΑ') ? "ΓΓΑ" : ''
                }}
              </div>
              <div class="govgr-!-font-size-14"
                   v-if="Object.keys($store.getters.loggedinUser).length > 0 && $store.getters.loggedinUser.user.first_name != null &&
                         $store.getters.loggedinUser.user.first_name != 'null' &&
                         pageWidth >= 800">
                {{
                  ($store.getters.loggedinUser.role == 'NEL') ? "ΕΛ"
                      : ($store.getters.loggedinUser.role == 'YLPL') ? "ΕΥ"
                          : ($store.getters.loggedinUser.role == 'NEAS') ? "ΕΑΣ" : ''
                }}
                {{ $store.getters.loggedinUser.user.first_name }}
              </div>
              <div class="govgr-!-font-size-14 ml-2 mr-2"
                   v-if="Object.keys($store.getters.loggedinUser).length > 0 && $store.getters.loggedinUser.user.last_name != null &&
                         $store.getters.loggedinUser.user.last_name != 'null' &&
                         pageWidth >= 800">
                {{ $store.getters.loggedinUser.user.last_name }}
              </div>
              <NotificationsBell v-if="$store.getters.notificationIcon"/>
              <v-btn style="color: #ffffff" @click="redirectToHomePage" icon v-if="utils.getToken(this) != null">
                <v-icon>
                  mdi-home
                </v-icon>
              </v-btn>
              <v-btn style="color: #ffffff" @click="logout" icon v-if="utils.getToken(this) != null">
                <v-icon>
                  logout
                </v-icon>
              </v-btn>
            </v-app-bar>
            <hr style="color:red; height:30px">
          </div>
        </div>
      </header>
    </div>

    <v-navigation-drawer width="300" v-if="$store.getters.menuItems.length > 0"
                         v-model="drawer"
                         fixed app clipped
                         hide-overlay
                         :style="{ top: $vuetify.application.top + 'px', zIndex: 4 }">
      <v-list rounded dense>
        <div v-for="(item, i) in $store.getters.menuItems" :key="i">
          <v-list-item v-if="!item.children"
                       :disabled="item.disabled"
                       @click="utils.redirectFromMenu(item.to, $route)"
                       :class="item.to == $route.fullPath ? 'grey lighten-1 white--text mb-1' : 'grey lighten-4 mb-1'">
            <v-list-item-content :class="(item.to == $route.fullPath)? 'govgr-!-font-weight-bold' : ''">
              <span v-if="item.providedIcon">
                {{ item.name }}
                <v-icon :color="item.providedColor">
                  {{ item.providedIcon }}
                </v-icon>
              </span>
              <span class="ml-1" v-else>
                {{ item.name }}
              </span>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-if="item.children"
                        :value="true"
                        class="grey lighten-4 mb-1"
                        style="border-radius: 25px">
            <template v-slot:activator>
              <v-list-item-content style="color: black">
                <span v-if="item.providedIcon">
                  {{ item.name }}
                  <v-icon :color="item.providedColor">
                    {{ item.providedIcon }}
                  </v-icon>
                </span>
                <span v-else>
                {{ item.name }}
                </span>
              </v-list-item-content>
            </template>

            <div v-for="(child,y) in item.children" :key="y">
              <v-list-item :disabled="child.disabled"
                           @click="utils.redirectFromMenu(child.to, $route)"
                           :class="child.to == $route.fullPath ? 'grey lighten-1 white--text mb-1' : ''">
                <v-list-item-content :class="(child.to == $route.fullPath)? 'govgr-!-font-weight-bold' : ''">
                <span v-if="child.providedIcon">
                  <section class="ml-2">
                    {{ child.name }}
                  </section>
                  <v-icon :color="child.providedColor">
                    {{ child.providedIcon }}
                  </v-icon>
                </span>
                  <span v-else>
                  <section class="ml-4">
                    {{ child.name }}
                  </section>
                </span>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <div class="mt-2" style="height: 100%; width: 90%; align-self: center">

      <v-main class="mt-0 full-height" v-if="ready">
        <router-view></router-view>
      </v-main>
    </div>
    <!-- <HomePage/>-->

    <footer class="govgr-footer" role="contentinfo">
      <div class="govgr-width-container">
        <div class="govgr-footer__meta">
          <div class="govgr-footer__meta-item govgr-footer__meta-item--grow">
            <div class="govgr-footer__content">
              <a href="https://mindigital.gr/" target="_blank" rel="noreferrer noopener" class="govgr-link">
                <img style="height: 48px; width: 250px;" src="./assets/logo-blue2.png" alt="government logo"/>
                <span class="govgr-visually-hidden">(ανοίγει σε καινούρια καρτέλα)</span>
              </a>
              <a href=" https://www.minedu.gov.gr/" target="_blank" rel="noreferrer noopener" class="govgr-link">
                <img class="govgr-footer__government-logo" src="./assets/mainlogo.png" alt="government logo"/>
                <span class="govgr-visually-hidden">(ανοίγει σε καινούρια καρτέλα)</span>
              </a>
            </div>
          </div>
        </div>
        <div class="govgr-footer__copyright">
          <p>Copyright © 2024 - Υλοποίηση από το Υπουργείο Ψηφιακής Διακυβέρνησης gov.gr</p>
        </div>
      </div>
    </footer>

    <!--     <footer class="govgr-footer" role="contentinfo">
          <div class="govgr-width-container">
            <div class="govgr-footer__meta">
              <div class="govgr-footer__meta-item govgr-footer__meta-item--grow">
                <div class="govgr-footer__content">
                  <p class="govgr-footer__licence-description">
                    Υλοποίηση από τη
                    <a href="https://www.gsis.gr/" target="_blank" rel="noreferrer noopener"
                       class="govgr-link">Γ.Γ.Π.Σ.Δ.Δ<span
                        class="govgr-visually-hidden">(ανοίγει σε καινούρια καρτέλα)</span></a> για το
                    <a href="https://mindigital.gr/" target="_blank" rel="noreferrer noopener" class="govgr-link"> Υπουργείο
                      Ψηφιακής Διακυβέρνησης <span class="govgr-visually-hidden">(ανοίγει σε καινούρια καρτέλα)</span></a>
                  </p>
                </div>
              </div>
              <div class="govgr-footer__meta-item">
                <a href="https://www.gov.gr/" target="_blank" rel="noreferrer noopener" class="govgr-link">
                  <img class="govgr-footer__government-logo" src="./assets/government-logo2.svg"
                       alt="government logo"/>

                  <span class="govgr-visually-hidden">(ανοίγει σε καινούρια καρτέλα)</span>
                </a>
              </div>
            </div>
          </div>
        </footer>   -->


    <v-dialog max-width="400px" persistent v-model="$store.getters.myClubsDialog">
      <v-card>
        <v-card-title class="justify-center primary">
          <span class="headline white--text">Επιλέξτε Λέσχη</span>
        </v-card-title>
        <v-card-text>
          <v-row v-for="(club, i) in $store.getters.myClubs" :key="i">
            <v-col>
              <v-card @click="selectClub(club)" class="py-2">
                <v-row>
                  <v-col cols="10" class="ml-2">
                    <h3>{{ club.name }}</h3>
                  </v-col>
                  <v-col class="text-end mr-1">
                    <v-icon color="secondary">flag</v-icon>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <memberfileParsingIntervalDialog/>
  </v-app>

</template>

<script>
import HelloWorld from './components/HelloWorld';

import requests from "./requests";
import LoadingModal from "./components/reusable/LoadingModal";
import ErrorSnackBar from "./components/reusable/ErrorSnackBar";
import InfoSnackBar from "@/components/reusable/InfoSnackBar.vue";

import utils from "./utilities";
import axios from "axios";
import MemberfileParsingIntervalDialog from "@/components/reusable/memberfileParsingIntervalDialog.vue";
import InfoDialog from "@/components/reusable/InfoDialog.vue";
import ErrorDialog from "@/components/reusable/ErrorDialog.vue";
import NotificationsBell from "@/components/reusable/NotificationsBell.vue";
import services from "@/services";

export default {
  name: 'App',
  components: {
    NotificationsBell,
    ErrorDialog,
    InfoDialog,
    MemberfileParsingIntervalDialog,
    LoadingModal,
    ErrorSnackBar,
    InfoSnackBar
  },

  data: () => ({
    myClubs: [],
    utils,
    drawer: true,
    ready: false,
    pageWidth: window.screen.width,
  }),
  methods: {


    logout() {

      services.clearGetNotificationsInterval()

      let token = utils.getToken(this)

      // utils.deleteLocalStorage('jwt_token')
      this.$store.commit('setLoggedinUser', {})

      requests.logout({redirect: this.$store.getters.homePath}, this.logoutCallback)

      // window.location.replace('https://www.gov.gr/')
    },
    logoutCallback(response) {
      this.$store.commit('setNotificationIcon', false)

      this.$cookies.remove('token')
      this.$cookies.remove('jwt_token')

      utils.deleteCookie('jwt_token');

      // var link = document.createElement('a')
      // link.setAttribute('href', response.data)
      // link.setAttribute('target', '_blank')
      // link.
      // link.click()
      utils.deleteLocalStorage('path');
      window.location.replace(response.data)
    },

    selectClub(club) {
      this.$store.commit('setSelectedClub', club)
      this.$store.commit('setMyClubsDialog', false)
    },

    redirectToHomePage() {


      if (this.$store.getters.homePath == '/role-selection') {
        this.$store.commit('setMenuItems', [])
        this.$router.push({path: '/role-selection'});
        return
      } else if (this.$store.getters.homePath == '/officials-card') {
        this.$router.push({path: '/officials-card'})
      } else {
        location.reload();
      }
    },

    // hasFirstName(){
    //   if(Object.keys(this.$store.getters.loggedinUser).length > 0){
    //     if(Object.keys(this.$store.getters.loggedinUser.user).length > 0){
    //       if(this.$store.getters.loggedinUser.user.first_name.trim().length > 0){
    //         return true
    //       }
    //     }
    //   }
    //   return false
    // },
    // hasLastName(){
    //   if(Object.keys(this.$store.getters.loggedinUser).length > 0){
    //     if(Object.keys(this.$store.getters.loggedinUser.user).length > 0){
    //       if(this.$store.getters.loggedinUser.user.last_name.trim().length > 0){
    //         return true
    //       }
    //     }
    //   }
    //   return false
    // },

    loginInformationCallabck(response) {

      this.$store.commit('', response)
    },

    handleResize() {
      this.pageWidth = window.innerWidth;
    }

  },

  computed: {

    isMyUserOfficials() {
      if (Object.keys(this.$store.getters.loggedinUser).length > 0
          &&
          this.$store.getters.loggedinUser.roles
          &&
          (this.$store.getters.loggedinUser.roles.map(r => r.id).includes(this.$store.getters.loggedinUser.CONSTANTS.USER.ROLE.AT)
              || this.$store.getters.loggedinUser.roles.map(r => r.id).includes(this.$store.getters.loggedinUser.CONSTANTS.USER.ROLE.GDA)
              || this.$store.getters.loggedinUser.roles.map(r => r.id).includes(this.$store.getters.loggedinUser.CONSTANTS.USER.ROLE.GGA))
      ) {
        return true;
      } else {
        return false;
      }
    },

  },

  mounted() {

    let url = window.location.href;
    let paramString = url.split('?')[1];
    let queryString = new URLSearchParams(paramString);

    for (let pair of queryString.entries()) {
      document.cookie = `${pair[0].replace(/\s/g, '')}=${pair[1].replace(/\s/g, '')}`;
    }

    let pathSplit = url.split('/');

    let path = pathSplit[pathSplit.length - 1].split('?')

    this.$store.commit('setHomePath', this.$route.path)
    if (path[0] == 'officials-card') {
      this.$router.push({path: '/officials-card'}).catch(() => {
      })
    }
    if (path[0] == 'role-selection') {
      this.$router.push({path: '/role-selection'}).catch(() => {
      })
    }
    if (path[0] == 'dev-login') {
      this.$router.push({path: '/dev-login'}).catch(() => {
      })
    }
    if (path[0] == 'dev-login-pa') {
      this.$router.push({path: '/dev-login-pa'}).catch(() => {
      })
    }
    this.ready = true;
  },

  created() {

    if (performance.navigation.type == 1) {

      // let token = this.$cookies.get('jwt_token');
      let token = utils.getToken(this)

      let path = utils.getLocalStorage('path');
      if (token != null && path != null && path != this.$route.path) {
        this.$router.push({path: path})
      }
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss">
.v-btn {
  text-transform: none !important;
  font-weight: bold !important;
}

.click-cursor {
  cursor: pointer !important;
}

.govgr-footer__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.govgr-link {
  margin-right: 10px; /* Adjust as needed for spacing within the link */
}

.govgr-footer__government-logo {
  margin-right: 5px; /* Adjust as needed for spacing between the logo and text */
}

.govgr-footer__copyright {
  text-align: center;
  font-size: 15px;
  margin-top: 10px; /* Adjust the spacing between the rows as needed */
  margin-bottom: -20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

</style>
