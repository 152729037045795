import { render, staticRenderFns } from "./ClubContactInfo.vue?vue&type=template&id=13704aa8&scoped=true&"
import script from "./ClubContactInfo.vue?vue&type=script&lang=js&"
export * from "./ClubContactInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13704aa8",
  null
  
)

export default component.exports