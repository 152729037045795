<template>
  <div>
    <v-data-table :footer-props="{page:1,
                                  pageCount:1,
                                  showCurrentPage:true,
                                  itemsPerPageOptions:[5,10,15,30],
                                  showFirstLastPage:true,
                                  itemsPerPageText:'Παραρτήματα ανά σελίδα:',
                                  itemsPerPageAllText:'Όλα'}"
                  no-data-text="Δέν υπάρχουν αποτελέσματα."
                  :headers="headers"
                  :items="branches"
                  :items-per-page="itemsPerPage"
                  class="elevation-3 mx-5 px-2"
    >
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-card color="primary"
                  class="mb-5"
                  height="60"
                  width="110">
            <v-row align="center"
                   justify="center">
              <v-col class=" mt-4  text-center white--text">
                {{ 'Παραρτήματα' }}
              </v-col>
            </v-row>
          </v-card>
          <v-toolbar-title>
            <v-row class="mt-3">
              <v-col>
                <v-text-field class="ml-5 mb-1"
                              append-icon="mdi-magnify"
                              hide-details
                              label="Αναζήτηση..."
                              single-line
                              v-model="search"
                              :rules="searchRules"
                              dense
                              @keydown.enter="searchWithParameter"
                              @click:append="searchWithParameter"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar-title>
          <!--          <v-divider-->
          <!--              class="mx-4"-->
          <!--              inset-->
          <!--              vertical-->
          <!--          ></v-divider>-->
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn @click="openDialog"
                     class="mb-2" color="primary" fab small v-on="on">
                <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                <v-icon>
                  add
                </v-icon>
              </v-btn>
            </template>
            <span>{{ 'Προσθήκη παραρτήματος' }}</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item="{item}">
        <tr>
          <td class="text-center d-sm-table-cell d-flex">
            {{ item.name }}
          </td>
          <td class="text-center d-sm-table-cell d-flex">
            {{ item.address }}
          </td>
          <td class="text-center d-sm-table-cell d-flex">
            {{ item.city }}
          </td>
          <td class="text-center d-sm-table-cell d-flex">
            {{ item.postal_code }}
          </td>
          <td class="text-center d-sm-table-cell d-flex">
            <v-chip v-if="item.branchstatus.id ==
                                 $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.ACTIVE"
                    class="white--text" color="green">
              {{ item.branchstatus.name }}
            </v-chip>
            <v-chip v-else-if="item.branchstatus.id ==
                                 $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.DELETED ||
                               item.branchstatus.id ==
                                 $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.IN_DESOLUTION ||
                               item.branchstatus.id ==
                                 $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.SUSPENDED ||
                               item.branchstatus.id ==
                                 $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.UNLICENSED"
                    class="white--text" color="red">
              {{ item.branchstatus.name }}
            </v-chip>
            <v-chip v-else>
              {{ item.branchstatus.name }}
            </v-chip>
          </td>
          <td class="text-center d-sm-table-cell d-flex click-cursor"
              @click="ekprosopoiDialog = true; selectedItem = item;">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <!--                <v-badge :content="item.user_branches.length" v-on="on">-->
                <v-btn icon>
                  <v-icon color="primary">
                    mdi-account
                  </v-icon>
                </v-btn>
                <!--                </v-badge>-->
              </template>
            </v-tooltip>

          </td>
          <td class="text-center d-sm-table-cell d-flex">
            <v-row align="center" justify="center">
              <v-btn icon @click="editBranch(item)"
                     elevation="5" class="ma-1"><!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                <v-icon color="blue"
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <!--              <v-btn @click="updateStatus(item)"-->
              <!--                     v-if="item.branchstatus_id == 3"-->
              <!--                     icon elevation="5" class="ma-1">&lt;!&ndash;:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"&ndash;&gt;-->
              <!--                <v-icon color="green"-->
              <!--                >-->
              <!--                  mdi-backup-restore-->
              <!--                </v-icon>-->
              <!--              </v-btn>-->
              <v-btn @click="openUpdateStatus(item)"
                     :disabled="item.branchstatus_id == $store.getters.loggedinUser.CONSTANTS.BRANCH.PENDING_APPROVE"
                     icon elevation="5" class="ma-1">
                <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                <v-icon color="red">
                  mdi-delete
                </v-icon>
              </v-btn>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog max-width="1200px" persistent scrollable v-model="dialog" @close="closeDialog">
      <v-card tile>
        <v-card-title class="primary">
          <span class="headline white--text">{{ 'Στοιχεία νέου παραρτήματος' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-2">
            <v-form ref="form">
              <v-row>
                <v-col cols="6">
                  <v-text-field dense label="Επωνυμία" v-model="newBranchName" required
                                :rules="nameRules"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Διεύθυνση (Δρόμος και αριθμός)" v-model="newBranchAddress" required
                                :rules="nameRules"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Δήμος" v-model="newBranchCity" required :rules="nameRules"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field dense label="Ταχυδρομικός κώδικας" v-model="newBranchPostalCode" required
                                :rules="nameRules"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <b>Στοιχεία εκπροσώπων</b>
                </v-col>
                <v-col cols="12">
                  <v-card v-for="newBranchUser in newBranchUsers" :key="newBranchUser.key" class="mb-2">
                    <v-card-title>
                      Υπεύθυνος λειτουργίας <!--{{ newBranchUser.key + 1 }}-->
                    </v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-text-field dense label="Α.Φ.Μ. υπεύθυνου λειτουργίας" v-model="newBranchUser.vat" required
                                      :rules="vatRule" counter="9" class="ml-2" type="number"
                                      hide-spin-buttons></v-text-field>
                        <v-text-field dense label="Α.Δ.Τ. υπεύθυνου λειτουργίας"
                                      v-model="newBranchUser.identification_document" required
                                      :rules="nameRules" class="ml-2"></v-text-field>
                      </v-row>
                      <v-row dense class="mt-3">
                        <v-text-field dense label="Όνομα υπεύθυνου λειτουργίας" v-model="newBranchUser.first_name"
                                      required
                                      :rules="nameRules" class="ml-2"></v-text-field>
                        <v-text-field dense label="Επώνυμο υπεύθυνου λειτουργίας" v-model="newBranchUser.last_name"
                                      required
                                      :rules="nameRules" class="ml-2"></v-text-field>
                        <v-text-field dense label="Πατρώνυμο υπεύθυνου λειτουργίας" v-model="newBranchUser.father_name"
                                      required
                                      :rules="nameRules" class="ml-2"></v-text-field>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!--Buttons for adding more than one YP              -->
              <!--              <v-row>
                              <v-btn rounded @click="popRepresentative" :disabled="!moreThanOneRepresentative" color="red">
                                <v-icon>mdi-minus</v-icon>
                              </v-btn>
                              <v-btn rounded @click="anotherRepresentative" :disabled="representativeFieldsAreEmpty" color="primary">
                                <v-icon>mdi-plus</v-icon>
                              </v-btn>
                            </v-row>-->
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog" text>{{ 'Κλείσιμο' }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="createBranch" :disabled="!formIsValid()" color="primary">{{ 'Αποθήκευση' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ekprosopoiDialog" max-width="800px" @close="selectedItem = false" persistent>
      <v-card tile>
        <v-card-title class="primary">
          <span class="headline white--text">{{ 'Στοιχεία υπεύθυνου λειτουργίας' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-for="user_branch in selectedItem.user_branches" :key="user_branch.id">
            <v-row class="my-1">
              <v-col cols="3">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user_branch.identification_document }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      ΑΔΤ
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="3">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user_branch.first_name }} {{ user_branch.last_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Ονοματεπώνυμο
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="3">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user_branch.father_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      Πατρώνυμο
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="3">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ user_branch.user.vat }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      ΑΦΜ
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <!--              <v-col cols="1">
                                <v-icon color="red"
                                        class="mt-4"
                                        :disabled="($store.getters.clubStatusToCheckForDissolution == 2)"
                                        @click="removeBranchRep(user_branch)">
                                  mdi-close
                                </v-icon>
                            </v-col>-->
            </v-row>
            <v-divider/>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn @click="ekprosopoiDialog = false; selectedItem = {}; getBranches()" color="primary" text>
            {{ 'Κλείσιμο' }}
          </v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="openAddRepresentativeDialog">
            <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
            Αντικατάσταση Εκπροσώπου
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="addRepresentativeDialog" max-width="800px" @close="closeAddRepresentativeDialog" persistent>
      <v-card tile>
        <v-card-title class="primary">
          <span class="headline white--text">{{ 'Προσθήκη υπεύθυνου λειτουργίας' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-for="newBranchUser in newBranchUsers" :key="newBranchUser.key">
            <v-row>
              <v-col cols="6">
                <v-text-field dense label="Α.Φ.Μ." v-model="newBranchUser.vat" required
                              :rules="nameRules" class="ml-2"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field dense label="Α.Δ.Τ." v-model="newBranchUser.identification_document" required
                              :rules="nameRules" class="ml-2"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-2">
              <v-col cols="4">
                <v-text-field dense label="Όνομα" v-model="newBranchUser.first_name" required
                              :rules="nameRules" class="ml-2"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field dense label="Επώνυμο" v-model="newBranchUser.last_name" required
                              :rules="nameRules" class="ml-2"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field dense label="Πατρώνυμο" v-model="newBranchUser.father_name" required
                              :rules="nameRules" class="ml-2"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="closeAddRepresentativeDialog">
            Άκυρο
          </v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="newRepresentativeAtExistingBranch" :disabled="representativeFieldsAreEmpty">
            Αντικατάσταση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editBranchDialog" max-width="600px" @close="closeEditBranchDialog" persistent>
      <v-card tile>
        <v-card-title class="primary">
          <span class="headline white--text">{{ 'Επεξεργασία παραρτήματος' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form class="mt-2">
              <v-row>
                <v-col cols="12">
                  <v-text-field dense label="Επωνυμία" v-model="branchToEdit.name" required
                                :rules="nameRules" class="ml-2"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense label="Διεύθυνση" v-model="branchToEdit.address" required
                                :rules="nameRules" class="ml-2"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense label="Δήμος" v-model="branchToEdit.city" required
                                :rules="nameRules" class="ml-2"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field dense label="Ταχυδρομικός Κώδικας" v-model="branchToEdit.postal_code" required
                                :rules="nameRules" class="ml-2"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="closeEditBranchDialog">Άκυρο</v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="postEditedBranch" :disabled="branchToEditFieldsAreEmpty">Αποθήκευση</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="deleteBranchDialog" width="400px">
      <v-card>
        <v-card-title class="primary white--text" style="word-break: normal">
          Αίτημα διαγραφής παραρτήματος
        </v-card-title>
        <v-card-text class="mt-5 govgr-!-font-size-19">
          Είστε σίγουρος/η για την ενέργεια σας;
        </v-card-text>
        <v-card-actions>
          <v-btn @click="deleteBranchDialog = false">
            Όχι
          </v-btn>
          <v-spacer/>
          <v-btn class="primary" @click="updateStatus()">
            Ναι, εκτέλεση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "@/requests";
import prop from "@/properties";

export default {
  name: "Branches",
  created() {
    this.getBranches()
  },
  data: () => ({
    headers: [
      {
        text: 'Επωνυμία',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Εκπρόσωπος',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      // {
      //   text: 'Όνομα',
      //   align: 'center',
      //   sortable: false,
      //   value: 'first_name',
      // },
      // {
      //   text: 'Επώνυμο',
      //   align: 'center',
      //   sortable: false,
      //   value: 'last_name',
      // },
      // {
      //   text: 'ΑΦΜ',
      //   align: 'center',
      //   sortable: false,
      //   value: 'vat',
      // },
      {
        text: 'Λειτουργίες',
        align: 'center',
        sortable: false,
      },
    ],
    itemsPerPage: 10,
    branches: [],
    ekprosopoiDialog: false,
    selectedItem: {},
    search: '',
    searchObj: {},
    club: {},
    dialog: false,
    newBranchName: '',
    newBranchAddress: '',
    newBranchCity: '',
    newBranchPostalCode: '',
    newBranchUserVat: '',
    newBranchUserFirstName: '',
    newBranchUserLastName: '',
    editBranchDialog: '',
    branchToEdit: {},
    selectedBranch: {},
    deleteBranchDialog: false,
    newBranchUsers: [{
      vat: '',
      identification_document: '',
      first_name: '',
      last_name: '',
      father_name: '',
      key: 0,
    }],
    key: 0,
    addRepresentativeDialog: false,
    searchRules: [
      v => (v.trim().length >= 3 || v.trim().length == 0) || 'Απαιτούνται τουλάχιστον 3 χαρακτήρες.'
    ],
    nameRules: [
      v => !!v || 'Το πεδίο είναι απαραίτητο.',
      v => !!v && v.trim() != '' || 'Το πεδίο είναι απαραίτητο.',
    ],
    vatRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο',
      v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
    ],
  }),
  methods: {
    tableRowClass(item) {
      if (item.branchstatus_id == 3) {
        return 'lightRed text-high-emphasis';
      } else {
        return '';
      }
    },

    getBranches() {
      this.$store.commit('setLoadingModal', true);
      this.searchObj.club = this.$store.getters.selectedClub
      this.searchObj.parameter = '' + this.search + ''

      requests.postSecureRequest('branch/branchesOnClubWithoutDefaultIncludeUsers', {searchObj: this.searchObj}, this.getBranchesCallback)
    },
    getBranchesCallback(response) {

      this.branches = []
      this.branches = response.branches
      // for (let i = 0; i < res.length; i++) {
      //   this.branches.push(res[i])
      // }

      this.$store.commit('setLoadingModal', false);
    },

    searchWithParameter() {
      if (this.search.trim().length < 3 && this.search.trim().length > 0) {
        prop.getErrorMessage({data: {message: 'Απαιτούνται τουλάχιστον 3 χαρακτήρες.'}})
        return
      }

      this.getBranches()
    },

    openDialog() {
      this.dialog = true
    },
    createBranch() {
      this.$store.commit('setLoadingModal', true)
      let putObj = {
        club_id: this.$store.getters.selectedClub.id,
        name: this.newBranchName,
        address: this.newBranchAddress,
        city: this.newBranchCity,
        postal_code: this.newBranchPostalCode,
        branch_users: this.newBranchUsers,
        //branchstatus_id: 3,
      }


      requests.postSecureRequest('branch/createBranchOnClubAndUsers', putObj, this.createBranchCallback)
    },
    createBranchCallback(response) {


      this.search = ''
      this.searchObj = {}

      this.getBranches()
      this.closeDialog()
      this.$store.commit('setThereWasAChangeInApplication', true)
      this.$store.commit('setLoadingModal', false)
    },
    closeDialog() {
      this.newBranchName = ''
      this.newBranchAddress = ''
      this.newBranchCity = ''
      this.newBranchPostalCode = ''
      this.newBranchUserVat = ''
      this.newBranchUsers = [{
        vat: '',
        first_name: '',
        last_name: '',
        key: this.key,
      }]

      this.dialog = false
    },

    updateStatus() {
      this.$store.commit('setLoadingModal', true)
      let putObj = {
        club_id: this.$store.getters.selectedClub.id,
        id: this.selectedBranch.id,
      }

      requests.putSecureRequest('branch/updateBranchstatus', putObj, this.updateStatusCallback)
    },
    updateStatusCallback(response) {

      this.$store.commit('setInfoDialogObj', {text: 'Η διαγραφή παραρτήματος ήταν επιτυχής.'})
      this.$store.commit('setInfoDialog', true)
      this.deleteBranchDialog = false
      this.$store.commit('setThereWasAChangeInApplication', true)
      this.getBranches()
    },
    formIsValid() {
      if (this.newBranchName.trim() == '' || this.newBranchAddress.trim() == '' || this.newBranchCity.trim() == '' || this.newBranchPostalCode.trim() == '') {
        return false
      }

      let userExists = false
      for (let user of this.newBranchUsers) {
        if (user.vat.trim() != '' && user.first_name.trim() != '' && user.last_name.trim() != '' && user.identification_document.trim() != '' && user.father_name.trim() != '') {
          userExists = true
        }
      }
      return userExists
    },

    anotherRepresentative() {
      this.key++
      this.newBranchUsers.push({
        vat: '',
        identification_document: '',
        first_name: '',
        last_name: '',
        father_name: '',
        key: this.key
      })
    },

    popRepresentative() {
      if (this.newBranchUsers.length > 1) {
        this.newBranchUsers.pop()
        this.key--
      }
    },

    openAddRepresentativeDialog() {
      this.addRepresentativeDialog = true

    },
    closeAddRepresentativeDialog() {
      this.newBranchUsers = [{
        vat: '',
        identification_document: '',
        first_name: '',
        last_name: '',
        father_name: '',
        key: 0,
      }]
      this.addRepresentativeDialog = false
    },
    newRepresentativeAtExistingBranch() {
      this.$store.commit('setLoadingModal', true)

      let obj = {
        branch_id: this.selectedItem.id,
        vat: this.newBranchUsers[0].vat,
        identification_document: this.newBranchUsers[0].identification_document,
        first_name: this.newBranchUsers[0].first_name,
        last_name: this.newBranchUsers[0].last_name,
        father_name: this.newBranchUsers[0].father_name,
      }

      // requests.postSecureRequest('branch/newRepresentativeOnExistingBranch', obj, this.newRepresentativeAtExistingBranchCallback)
      requests.postSecureRequest('branch/change-branch-rep', obj, this.newRepresentativeAtExistingBranchCallback)
    },
    newRepresentativeAtExistingBranchCallback(response) {
      this.closeAddRepresentativeDialog()
      this.$store.commit('setThereWasAChangeInApplication', true)
      this.$store.commit('setLoadingModal', false)
      this.ekprosopoiDialog = false
      // this.removeBranchRep(this.selectedItem.user_branches[0])

      if (response == true) {
        this.$store.commit('setInfoSnackBarObj', {text: 'Η αντικατάσταση υπευθύνου ήταν επιτυχής.', dialog: true})
        this.$store.commit('setInfoSnackBar', true)
        this.$store.commit('setThereWasAChangeInApplication', true)
      }

      this.ekprosopoiDialog = false
      this.selectedItem = {}
      this.getBranches()
    },

    editBranch(item) {
      this.editBranchDialog = true
      this.branchToEdit = JSON.parse(JSON.stringify(item))
    },
    postEditedBranch() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('branch/updateExistingBranchesNameOrAndFullAddress', this.branchToEdit, this.postEditedBranchCallback)
    },
    postEditedBranchCallback(response) {
      this.getBranches()
      this.closeEditBranchDialog()
      this.$store.commit('setThereWasAChangeInApplication', true)
    },
    closeEditBranchDialog() {
      this.branchToEdit = {}
      this.editBranchDialog = false
    },

    removeBranchRep(item) {

      this.$store.commit('setLoadingModal', true)

      let userbranch_branchrole = {}
      for (let branchrole of item.branchroles) {
        if (branchrole.id == 3) {
          userbranch_branchrole = branchrole.userbranch_branchrole
        }
      }
      let requestObj = {
        userbranch_branchrole: userbranch_branchrole,
        branch_id: item.branch_id
      }

      requests.postSecureRequest('user/remove-branch-rep', requestObj, this.removeBranchRepCallback)
    },
    removeBranchRepCallback(response) {
      if (response == true) {
        this.$store.commit('setInfoSnackBarObj', {text: 'Η αντικατάσταση υπευθύνου ήταν επιτυχής.', dialog: true})
        this.$store.commit('setInfoSnackBar', true)
        this.$store.commit('setThereWasAChangeInApplication', true)
      }

      this.ekprosopoiDialog = false
      this.selectedItem = {}
      this.getBranches()
    },
    openUpdateStatus(item) {
      this.selectedBranch = item
      this.deleteBranchDialog = true
    }
  },

  computed: {
    representativeFieldsAreEmpty() {
      let isEmpty = false
      for (let branchUser of this.newBranchUsers) {
        if (branchUser.vat == '') {
          isEmpty = true
          break
        }
        if (branchUser.identification_document == '') {
          isEmpty = true
          break
        }
        if (branchUser.first_name == '') {
          isEmpty = true
          break
        }
        if (branchUser.last_name == '') {
          isEmpty = true
          break
        }
        if (branchUser.father_name == '') {
          isEmpty = true
          break
        }
      }

      return isEmpty
    },

    moreThanOneRepresentative() {
      return this.newBranchUsers.length > 1;
    },

    branchToEditFieldsAreEmpty() {
      return (this.branchToEdit.name == '' ||
          this.branchToEdit.address == '' ||
          this.branchToEdit.city == '' ||
          this.branchToEdit.postal_code == '');
    },
  }
}
</script>

<style scoped>

</style>