<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tab href="#tab-1"
             :style="tab=='tab-1' ? 'background-color: aliceblue;' : 'background-color: '">
        Στοιχεια
      </v-tab>
      <v-tab href="#tab-2"
             :style="tab=='tab-2' ? 'background-color: aliceblue' : 'background-color: '"
             @click="getCouncilMembers()">
        Μελη Δ.Σ.
      </v-tab>
      <v-tab href="#tab-3"
             :style="tab=='tab-3' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getBranches()">
        Παραρτηματα
      </v-tab>
      <v-tab href="#tab-4"
             :style="tab=='tab-4' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getBranchesWithPlaces()">
        Χωροι
      </v-tab>
      <v-tab href="#tab-5"
             :style="tab=='tab-5' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getBranchesWithPlaces()">
        Μελη λεσχης
      </v-tab>
      <v-tab href="#tab-6"
             :style="tab=='tab-6' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getLicenceOfClub()">
        Εγγραφα
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card flat class="mt-4">
            <v-card-text>
              <v-row justify="center">
                <v-col cols="4">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip v-if="clubProp.clubstatus_id ==
                                  $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.ACTIVE"
                                style="background-color: green;"
                                class="govgr-!-font-size-14 white--text"
                        >
                          {{ clubProp.clubstatus.name }}
                        </v-chip>
                        <v-chip v-else-if="clubProp.clubstatus_id ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.SUSPENDED
                                       ||
                                       clubProp.clubstatus_id ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.IN_DISSOLUTION"
                                style="background-color: red;"
                                class="govgr-!-font-size-14 white--text"
                        >
                          {{ clubProp.clubstatus.name }}
                        </v-chip>
                        <v-chip v-else-if="clubProp.clubstatus_id ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.UNLICENSED"
                                style="background-color: red;"
                                class="govgr-!-font-size-14 white--text"
                        >
                          {{ clubProp.clubstatus.name }}
                        </v-chip>
                        <v-chip v-else
                                class="govgr-!-font-size-14"
                        >
                          {{ clubProp.clubstatus.name }}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Κατάσταση λέσχης
                        <div v-if=" effective_end_date!= null &&
                                effective_end_date!= 'null' &&
                                effective_end_date != ''"
                             style="display: inline">
                          εώς {{ effective_end_date }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ clubProp.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Επωνυμία</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ clubProp.vat }}</v-list-item-title>
                      <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ clubProp.user.vat }}
                        {{ ', ' + clubProp.user.first_name }}
                        {{ ', ' + clubProp.user.last_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Υπεύθυνος λέσχης (ΑΦΜ, Όνομα, Επώνυμο)</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ clubProp.branches[0].address }}
                        {{ ', ' + clubProp.branches[0].city }}
                        {{ ', ' + clubProp.branches[0].postal_code }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Διεύθυνση έδρας</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ clubProp.organization.name }}</v-list-item-title>
                      <v-list-item-subtitle>ΑΣ/ΤΑΑ/ΑΑΕ στο οποίο υπάγεται</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col cols="4">
                  <v-row>
                    <v-col class="text-h6">
                      Εκπρόσωπος αθλητικού σωματείου
                    </v-col>
                  </v-row>
                  <v-row v-if="councilOfOrganization.length > 0" align="center">
                    <div v-for="(item,index) in councilOfOrganization" :key="index">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ (item.relFirstname != "") ? item.relFirstname : '-' }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ (item.relFirst_Lastname != "") ? item.relFirst_Lastname : '-' }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{
                              (item.relRelatedAfm != "") ? item.relRelatedAfm : '-'
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-row>
                  <v-row v-else align="center">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ '-' }}</v-list-item-title>
                        <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ '-' }}</v-list-item-title>
                        <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ '-' }}</v-list-item-title>
                        <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-dialog persistent v-model="councilMemberDialog" width="600px">
            <v-card>
              <v-card-title class="primary white--text">
                Πληροφορίες εκπροσώπου αθλητικού σωματείου
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ selectedCouncilMember.relFirstname }}
                          {{ ' ' + selectedCouncilMember.relSecond_Lastname }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ selectedCouncilMember.relFirst_Lastname }}</v-list-item-title>
                        <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ selectedCouncilMember.relFathername }}</v-list-item-title>
                        <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ selectedCouncilMember.relRelatedAfm }}</v-list-item-title>
                        <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ selectedCouncilMember.relCategDescr }}</v-list-item-title>
                        <v-list-item-subtitle>Δραστηριότητα</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ selectedCouncilMember.relKindDescr }}</v-list-item-title>
                        <v-list-item-subtitle>Ιδιότητα</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="councilMemberDialog = false">
                  Κλείσιμο
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tab-item>
        <v-tab-item value="tab-2" class="mt-4">
          <v-row justify="center" align="center" class="mb-15">
            <v-card width="85%" class="mt-5 mb-4">
              <v-data-table
                  :items-per-page="5"
                  :headers="headersMembersDS"
                  :footer-props="{page:1,
                            pageCount:1,
                            showCurrentPage:true,
                            itemsPerPageOptions:[5,10,15,30],
                            showFirstLastPage:true,
                            itemsPerPageText:'Μέλη Δ.Σ. ανά σελίδα:',
                            itemsPerPageAllText:'Όλα'}"
                  no-data-text="Δέν υπάρχουν αποτελέσματα."
                  :items="councilMembers">
                <template v-slot:[`footer.page-text`]="items">
                  {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
                </template>
                <template v-slot:item="{item}">
                  <tr>
                    <td align="center" justify="center">{{ item.user.vat }}</td>
                    <td align="center" justify="center">{{ item.user.user_branches[0].first_name }}</td>
                    <td align="center" justify="center">{{ item.user.user_branches[0].last_name }}</td>
                    <td align="center" justify="center">{{ item.user.user_branches[0].father_name }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-3" class="mt-4">
          <v-row justify="center" align="center" class="mb-15">
            <v-card width="85%" class="mt-5 mb-4">
              <v-data-table :footer-props="{page:1,
                                      pageCount:1,
                                      showCurrentPage: true,
                                      itemsPerPageOptions:[5,10,15,30],
                                      showFirstLastPage:true,
                                      itemsPerPageText:'Παραρτήματα ανά σελίδα',
                                      itemsPerPageAllText:'Όλα'}"
                            no-data-text="Δεν υπάρχουν αποτελέσματα."
                            :headers="headersBranches"
                            :items="branches"
                            :items-per-page="5"
              >
                <template v-slot:[`footer.page-text`]="items">
                  {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>
                      <v-row class="mt-3">
                        <v-col>
                          <v-text-field class="ml-5 mb-1"
                                        append-icon="mdi-magnify"
                                        hide-details
                                        label="Αναζήτηση..."
                                        single-line
                                        v-model="search"
                                        :rules="searchRules"
                                        dense
                                        @keydown.enter="searchWithParameter"
                                        @click:append="searchWithParameter">
                            >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:item="{item}">
                  <tr>
                    <td class="text-center d-sm-table-cell d-flex">
                      {{ item.name }}
                    </td>
                    <td class="text-center d-sm-table-cell d-flex">
                      {{ item.address }}
                    </td>
                    <td class="text-center d-sm-table-cell d-flex">
                      {{ item.city }}
                    </td>
                    <td class="text-center d-sm-table-cell d-flex">
                      {{ item.postal_code }}
                    </td>
                    <td class="text-center d-sm-table-cell d-flex">
                      <v-chip v-if="item.branchstatus.id == $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.ACTIVE"
                              class="white--text" color="green">
                        {{ item.branchstatus.name }}
                      </v-chip>
                      <v-chip v-else-if="item.branchstatus.id == $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.DELETED
                                     || item.branchstatus.id == $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.IN_DESOLUTION
                                     || item.branchstatus.id == $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.SUSPENDED
                                     || item.branchstatus.id == $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.UNLICENSED"
                              color="red"
                              class="white--text">
                        {{ item.branchstatus.name }}
                      </v-chip>
                      <v-chip v-else>{{ item.branchstatus.name }}</v-chip>
                    </td>
                    <td class="text-center d-sm-table-cell d-flex click-cursor"
                        @click="ekprosopoiDialog = true; selectedItem = item;">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-badge :content="item.user_branches.length" v-on="on">
                            <v-icon>
                              mdi-account
                            </v-icon>
                          </v-badge>
                        </template>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-row>
          <v-dialog v-model="ekprosopoiDialog" max-width="900px" persistent>
            <v-card tile>
              <v-card-title class="primary">
                <span class="headline white--text">{{ 'Στοιχεία υπευθύνων λειτουργίας' }}</span>
              </v-card-title>
              <v-card-text>
                <v-container v-for="user_branch in selectedItem.user_branches" :key="user_branch.id">
                  <v-row class="my-1">
                    <v-col cols="3">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ user_branch.user.vat }}</v-list-item-title>
                          <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="4">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ user_branch.first_name }} {{ user_branch.last_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Ονοματεπώνυμο</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="2">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ user_branch.father_name }}</v-list-item-title>
                          <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="3">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ user_branch.identification_document }}</v-list-item-title>
                          <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn @click="ekprosopoiDialog = false; selectedItem = {};" color="primary" text>
                  {{ 'Κλείσιμο' }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tab-item>
        <v-tab-item value="tab-4" class="mt-4">
          <v-row justify="center" align="center" class="mb-15">
            <v-card width="85%" class="mt-5 mb-4">
              <v-data-table :headers="placesHeaders"
                            :items="places"
                            :items-per-page="10"
                            :footer-props="{page:1,
                                      pageCount:1,
                                      showCurrentPage:true,
                                      itemsPerPageOptions:[5,10,15,30],
                                      showFirstLastPage:true,
                                      itemsPerPageText:'Χώροι ανά σελίδα:',
                                      itemsPerPageAllText:'Όλα'}"
                            no-data-text="Δέν υπάρχουν αποτελέσματα."
              >
                <template v-slot:[`footer.page-text`]="items">
                  {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>
                      <v-row class="mt-5">
                        <v-col>
                          <v-select label="Παραρτήματα"
                                    :items="branchesWithPlaces"
                                    return-object
                                    :item-text="item =>item.name + ' (Χώροι:' + item.places_count + ')'"
                                    dense
                                    no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                    v-model="selectedBranch"
                                    @change="selectBranchToViewPlaces(selectedBranch)">
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:item.place_status.status="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    <v-chip v-if="item.place_status.id == 3" class="white--text" color="green">
                      {{ item.place_status.status }}
                    </v-chip>
                    <v-chip v-else-if="item.place_status.id == 1 || item.place_status.id == 2">
                      {{ item.place_status.status }}
                    </v-chip>
                    <v-chip v-else color="red" class="white--text">
                      {{ item.place_status.status }}
                    </v-chip>
                  </td>
                </template>
                <template v-slot:item.user="{ item }">
                  <td class="text-center d-sm-table-cell d-flex click-cursor" @click="openPlaceUserDialog(item)">
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" v-on="on">mdi-account</v-icon>
                      </template>
                      <span>
                    {{ item.user.user_branches[0].first_name }}
                    {{ item.user.user_branches[0].last_name }}
                    {{ item.user.vat }}
                  </span>
                    </v-tooltip>
                  </td>
                </template>
                <template v-slot:item.file="{ item }">
                  <td align="center" justify="center" v-if="item.place_file == null">{{ '-' }}</td>
                  <td align="center" justify="center" v-else>
                    <v-btn icon color="primary" @click="downloadFile(item)">
                      <v-icon>
                        mdi-download
                      </v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-row>
          <v-dialog v-model="placeUserDialog" persistent max-width="900px">
            <v-card>
              <v-card-title style="background-color: #003375" class="white--text">
                Υπεύθυνος Χώρου
              </v-card-title>
              <v-card-text>
                <v-row class="my-1">
                  <v-col cols="3">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ placeWithChangedUser.user.vat }}</v-list-item-title>
                        <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="4">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ placeWithChangedUser.user.user_branches[0].first_name }}
                          {{ placeWithChangedUser.user.user_branches[0].last_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Ονοματεπώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="2">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ placeWithChangedUser.user.user_branches[0].father_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="3">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ placeWithChangedUser.user.user_branches[0].identification_document }}
                        </v-list-item-title>
                        <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-spacer/>
              <v-card-actions>
                <v-btn text @click="closePlaceUserDialog">
                  Κλείσιμο
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-tab-item>
        <v-tab-item value="tab-5" class="mt-4">
          <v-row justify="center" align="center" class="mb-15">
            <v-card width="85%" class="mt-5 mb-4">
              <v-data-table
                  :headers="memberHeaders"
                  :items="members"
                  :items-per-page="itemsPerPage"
                  :page.sync="pageNumber"
                  :footer-props="{page:1,
                                      pageCount:1,
                                      showCurrentPage:true,
                                      itemsPerPageOptions:[5,10,15,30],
                                      showFirstLastPage:true,
                                      itemsPerPageText:'Μέλη ανά σελίδα:',
                                      itemsPerPageAllText:'Όλα'}"
                  :server-items-length="tableLength"
                  @update:options="getBuildingPerPage"
                  no-data-text="Δέν υπάρχουν αποτελέσματα."
              >
                <template v-slot:[`footer.page-text`]="items">
                  {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>
                      <v-row class="mt-5">
                        <v-col>
                          <v-select label="Παραρτήματα"
                                    :items="branchesWithPlacesForShowMembers"
                                    return-object
                                    :item-text="item =>item.name"
                                    dense
                                    no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                    v-model="selectedBranchToShowMembers"
                                    @change="getMembers()">
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-toolbar-title>
                  </v-toolbar>
                </template>
                <template v-slot:item.first_name="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.user_branches[0].first_name }}
                  </td>
                </template>
                <template v-slot:item.last_name="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.user_branches[0].last_name }}
                  </td>
                </template>
                <template v-slot:item.father_name="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.user_branches[0].father_name }}
                  </td>
                </template>
                <template v-slot:item.date_of_birth="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.user_branches[0].date_of_birth }}
                  </td>
                </template>
                <template v-slot:item.amka="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.user_branches[0].amka }}
                  </td>
                </template>
                <template v-slot:item.status="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    <v-chip
                        v-if="item.user_branches[0].userbranchbranchrolestatus.id  == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.ACTIVE"
                        class="white--text" color="green">
                      {{ item.user_branches[0].userbranchbranchrolestatus.status }}
                    </v-chip>
                    <v-chip v-else-if="item.user_branches[0].userbranchbranchrolestatus.id  == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.DELETED
                                    || item.user_branches[0].userbranchbranchrolestatus.id  == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.INACTIVE
                                    || item.user_branches[0].userbranchbranchrolestatus.id  == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.SUSPENDED
                                    || item.user_branches[0].userbranchbranchrolestatus.id  == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.INVALID"
                            color="red" class="white--text">
                      {{ item.user_branches[0].userbranchbranchrolestatus.status }}
                    </v-chip>
                    <v-chip v-else>
                      {{ item.user_branches[0].userbranchbranchrolestatus.status }}
                    </v-chip>
                  </td>
                </template>
                <!--            <template v-slot:item.phone_number="{ item }">
                              <td class="text-center d-sm-table-cell d-flex">
                                {{ item.user_branches[0].phone_number }}
                              </td>
                            </template>
                            <template v-slot:item.email="{ item }">
                              <td class="text-center d-sm-table-cell d-flex">
                                {{ item.user_branches[0].email }}
                              </td>
                            </template>-->
                <template v-slot:item.address="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.user_branches[0].address }}
                  </td>
                </template>
                <template v-slot:item.city="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.user_branches[0].city }}
                  </td>
                </template>
                <template v-slot:item.postal_code="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.user_branches[0].postal_code }}
                  </td>
                </template>
                <template v-slot:item.identification_document="{ item }">
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.user_branches[0].identification_document }}
                  </td>
                </template>
                <!--            <template v-slot:item.affirmation="{ item }">
                              <td class="text-center d-sm-table-cell d-flex">
                                <v-icon v-if="item.user_branches[0].affirmation == true" color="green darken-1">
                                  mdi-check
                                </v-icon>
                                <v-icon v-if="item.user_branches[0].affirmation == false" color="red darken-4">
                                  mdi-close
                                </v-icon>
                              </td>
                            </template>-->
              </v-data-table>
            </v-card>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-6" class="mt-4">
          <v-row justify="center" align="center" class="mt-2">
            <v-card class="ma-3" width="60%">
              <v-card class="mb-3">
                <v-row class="pa-4" align="center" v-if="licenceForClub.application_approval_file != null">
                  <v-col cols="11" class="govgr-!-font-size-19">
                    <b>Βεβαίωση νόμιμης λειτουργίας λέσχης παραγόμενη από την Γενική Γραμματεία Αθλητισμού</b>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-btn class="mt-4r" color="primary" icon @click="getApprovalDocument()">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="pa-4" v-else>
                  <v-col class="govgr-!-font-size-19">
                    <b> Δεν υπάρχει βεβαίωση νόμιμης λειτουργίας λέσχης από την Γενική Γραμματεία Αθλητισμού.</b>
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="mb-1">
                <v-card-title style="word-break: normal">
                  <v-select label="Επιλέξτε παράρτημα για την εμφάνιση
                               των σχετικών αρχείων για τους χώρους του παραρτήματος"
                            :items="branchesWithPlaces"
                            return-object
                            :item-text="item =>item.name"
                            dense
                            no-data-text="Δεν υπάρχουν καταχωρήσεις"
                            v-model="selectedBranchForGettingFiles"
                            @change="getDocumentsForOfficials(selectedBranchForGettingFiles)">
                  </v-select>
                </v-card-title>
                <v-card-text v-if="documentsForOfficials.length > 0">
                  <v-card class="mb-1"
                          :style="`background-color: ` + (index%2 == 0 ? `${$vuetify.theme.themes.light.documentBlue}` : '')"
                          v-for="(doc, index) in documentsForOfficials" :key="index"
                  >
                    <v-row class="pa-4">
                      <v-col cols="7"
                             v-if="doc.place_histories[0].place_licencing.licencing_type_id
                          == $store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.APPROVED ">
                        <b>{{ 'Έγγραφο έγκρισης για χώρο του παραρτήματος στην διεύθυνση: ' }}
                          {{ doc.address + ', ' + doc.city + ', ' + doc.postal_code }}</b>
                      </v-col>
                      <v-col cols="7"
                             v-if="doc.place_histories[0].place_licencing.licencing_type_id
                          == $store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.REJECTED">
                        <b>{{ 'Έγγραφο απόρριψης για χώρο του παραρτήματος στην διεύθυνση: ' }}
                          {{ doc.address + ', ' + doc.city + ', ' + doc.postal_code }}</b>
                      </v-col>
                      <v-col cols="4">
                        {{ '(' }}{{ doc.place_histories[0].place_licencing.filename }}{{ ')' }}
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="1">
                        <v-btn class="mt-4r"
                               color="primary"
                               icon
                               @click="getLicencingDocument(doc.place_histories[0].place_licencing)">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
                <v-card-text
                    v-if="documentsForOfficials.length == 0 &&
                      Object.keys(selectedBranchForGettingFiles).length != 0">
                  <v-row justify="center" align="center" class="mt-3 mb-3 govgr-!-font-size-19">
                    <b>{{ 'Δεν βρέθηκαν σχετικά αρχεία χώρων για το συγκεκριμένο παράρτημα.' }}</b>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import requests from "@/requests";
import prop from "@/properties";

export default {
  name: "ClubInfoForOfficials",
  props: {
    clubProp: Object
  },
  data: () => ({
    tab: null,
    headersNEAS: [
      {
        text: 'Ονοματεπώνυμο',
        align: 'center',
        sortable: false,
        value: 'relRelatedOnomasia'
      },
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'relRelatedAfm'
      },
    ],
    councilOfOrganization: [],
    selectedCouncilMember: {},
    councilMemberDialog: false,
    headersMembersDS: [
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'relRelatedAfm',
        width: '25%',
      },
      {
        text: 'Όνομα',
        align: 'center',
        sortable: false,
        value: 'relRelatedOnoma',
        width: '25%',
      },
      {
        text: 'Επώνυμο',
        align: 'center',
        sortable: false,
        value: 'relRelatedEpwnumo',
        width: '25%',
      },
      {
        text: 'Πατρώνυμο',
        align: 'center',
        sortable: false,
        value: 'relRelatedPatrwnumo',
        width: '25%',
      },
    ],
    councilMembers: [],

    searchObj: {},
    branches: [],
    search: '',
    searchRules: [
      v => (v.trim().length >= 3 || v.trim().length == 0) || 'Απαιτούνται τουλάχιστον 3 χαρακτήρες.'
    ],
    selectedItem: {},
    ekprosopoiDialog: false,
    headersBranches: [
      {
        text: 'Επωνυμία',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Εκπρόσωποι',
        align: 'center',
        sortable: false,
        value: 'representative',
      },
    ],

    branchesWithPlaces: [],
    branchesWithPlacesForShowMembers: [],
    places: [],
    selectedBranch: {},
    placeUserDialog: false,
    placeWithChangedUser: {
      user: {
        vat: '',
        first_name: '',
        last_name: '',
        father_name: '',
        identification_document: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
          }
        ]
      }
    },
    placesHeaders: [
      {
        text: 'Τύπος',
        align: 'center',
        sortable: false,
        value: 'place_type.type',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'place_status.status',
      },
      {
        text: 'Αρχείο',
        align: 'center',
        sortable: false,
        value: 'file',
      },
      {
        text: 'Υπεύθυνος',
        align: 'center',
        sortable: false,
        value: 'user'
      }
    ],

    memberHeaders: [
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'vat',
      },
      {
        text: 'Όνομα',
        align: 'center',
        sortable: false,
        value: 'first_name',
      },
      {
        text: 'Επώνυμο',
        align: 'center',
        sortable: false,
        value: 'last_name',
      },
      {
        text: 'Πατρώνυμο',
        align: 'center',
        sortable: false,
        value: 'father_name',
      },
      {
        text: 'Ημερομηνία γέννησης',
        align: 'center',
        sortable: false,
        value: 'date_of_birth',
      },
      {
        text: 'ΑΜΚΑ',
        align: 'center',
        sortable: false,
        value: 'amka',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      /*{
        text: 'Τηλέφωνο',
        align: 'center',
        sortable: false,
        value: 'phone_number',
      },
      {
        text: 'email',
        align: 'center',
        sortable: false,
        value: 'email',
      },*/
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Αριθμός ταυτότητας',
        align: 'center',
        sortable: false,
        value: 'identification_document',
      },
      /*{
        text: 'Υπεύθυνη δήλωση',
        align: 'center',
        sortable: false,
        value: 'affirmation',
      },*/
    ],
    members: [],
    selectedBranchToShowMembers: {},
    itemsPerPage: 5,
    pageNumber: 1,
    tableLength: 0,
    documentsForOfficials: [],
    licenceForClub: {},
    selectedBranchForGettingFiles: {},
    effective_end_date: '',
  }),
  methods: {
    openCouncilMemberDialog(item) {
      this.selectedCouncilMember = item
      this.councilMemberDialog = true
    },

    getCouncilOfOrganization() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('club/get-relations-array',
          {club_id: this.clubProp.id},
          this.getCouncilOfOrganizationCallback)
    },

    getCouncilOfOrganizationCallback(response) {
      this.councilOfOrganization = []
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.length; i++) {
        let name = res[i].relRelatedOnomasia.split(',')
        let obj = {
          relRelatedAfm: res[i].relRelatedAfm,
          relFirstname: name[2],
          relFirst_Lastname: name[0],
          relSecond_Lastname: name[1],
          relFathername: name[3],
          relCateg: res[i].relCateg,
          relCategDescr: res[i].relCategDescr,
          relKind: res[i].relKind,
          relKindDescr: res[i].relKindDescr,
          relStartDate: res[i].relStartDate,
        }
        this.councilOfOrganization.push(obj)
      }

      this.$store.commit('setLoadingModal', false)
    },

    getCouncilMembers() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_id: this.clubProp.id
      }
      requests.postSecureRequest('council/get-council-members',
          requestObj,
          this.getCouncilMembersCallBack)
    },

    getCouncilMembersCallBack(response) {
      this.councilMembers = []
      this.councilMembers = response
      this.$store.commit('setLoadingModal', false)
    },

    getBranches() {
      this.$store.commit('setLoadingModal', true);
      this.searchObj.club = this.clubProp
      this.searchObj.parameter = '' + this.search + ''

      requests.postSecureRequest('branch/branchesOnClubWithoutDefaultIncludeUsersForELLAS',
          {searchObj: this.searchObj},
          this.getBranchesCallback)
    },
    getBranchesCallback(response) {
      this.branches = []
      this.branches = response.branches
      this.$store.commit('setLoadingModal', false);
    },

    tableRowClass(item) {
      if (item.branchstatus_id == 2) {
        return 'lightRed text-high-emphasis';
      } else {
        return '';
      }
    },

    searchWithParameter() {
      if (this.search.trim().length < 3 && this.search.trim().length > 0) {
        prop.getErrorMessage({data: {message: 'Απαιτούνται τουλάχιστον 3 χαρακτήρες.'}})
        return
      }
      console.log('Searching. . .')
      this.getBranches();
    },

    getBranchesWithPlaces() {
      this.$store.commit('setLoadingModal', true);
      requests.postSecureRequest('branch/get-branches-include-places-for-ellas',
          {club: this.clubProp},
          this.getBranchesWithPlacesCallback)
    },
    getBranchesWithPlacesCallback(response) {
      this.branchesWithPlaces = []
      this.places = []
      this.branchesWithPlaces = response
      if (this.branchesWithPlaces.length > 1) {
        this.branchesWithPlaces.sort(function (x, y) {
          return x.is_default == true ? -1 : y.is_default == false ? 1 : 0;
        })
      }

      this.branchesWithPlacesForShowMembers = []
      if (this.$store.getters.loggedinUser.role == 'ΓΓΑ') {
        for (let item of this.branchesWithPlaces) {
          if (item.is_default == true) {
            this.branchesWithPlacesForShowMembers.push(item)
          }
        }
      } else {
        this.branchesWithPlacesForShowMembers = response
      }

      for (let branch of this.branchesWithPlaces) {
        branch.places_count = branch.places.length
      }

      if (this.branchesWithPlaces.length > 0) {
        if (Object.keys(this.selectedBranch).length == 0) {
          this.selectedBranch = this.branchesWithPlaces[0]
          this.places = this.selectedBranch.places
          console.log(this.places);
        } else {
          for (let branch of this.branchesWithPlaces) {
            if (branch.id == this.selectedBranch.id) {
              this.selectedBranch = branch
              this.places = this.selectedBranch.places
            }
          }
        }
      }
      console.log(this.places);
      if (this.places.length > 0) {
        this.placeWithChangedUser = this.places[0]
      }
      this.$store.commit('setLoadingModal', false)
    },
    selectBranchToViewPlaces(item) {
      this.selectedBranch = item
      console.log(JSON.parse(JSON.stringify(this.selectedBranch)));
      this.places = this.selectedBranch.places
    },

    openPlaceUserDialog(item) {
      this.placeUserDialog = !this.placeUserDialog
      this.placeWithChangedUser = item
    },

    closePlaceUserDialog() {
      this.placeUserDialog = !this.placeUserDialog

      this.replacedPlaceUser = {
        vat: '',
        first_name: '',
        last_name: '',
        father_name: '',
        identification_document: '',
      }
    },

    downloadFile(place) {
      requests.downloadFileRequest(
          'place/download-place-file/' + place.id + '/' + place.place_file.id)
    },

    getMembers() {
      this.$store.commit('setLoadingModal', true);
      this.searchObj.branch = this.selectedBranchToShowMembers
      this.searchObj.parameter = '' + this.search + ''

      this.pageNumber = 0
      this.itemsPerPage = 5

      requests.postSecureRequest('user/branchMembers',
          {
            searchObj: this.searchObj,
            itemsPerPage: 5,
            pageNumber: 0
          },
          this.getMembersCallback)
    },

    getMembersCallback(response) {
      this.members = []
      if (response.hasOwnProperty('error')) {
        this.$store.commit('setInfoDialogObj', {text: response.message})
        this.$store.commit('setInfoDialog', true)
        this.$store.commit('setLoadingModal', false);
        return
      }
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.members.length; i++) {
        this.members.push(res.members[i])
      }
      this.tableLength = res.membersCount
      console.log(this.members);
      this.$store.commit('setLoadingModal', false);
    },

    getBuildingPerPage(item) {
      if (Object.keys(this.selectedBranchToShowMembers).length === 0) {
        return
      }
      this.$store.commit('setLoadingModal', true);
      this.pageNumber = item.page
      this.itemsPerPage = item.itemsPerPage
      this.printDataTableOptions()

      requests.postSecureRequest('user/branchMembers',
          {
            searchObj: this.searchObj,
            itemsPerPage: this.itemsPerPage,
            pageNumber: this.pageNumber - 1
          },
          this.getMembersCallback)
    },

    printDataTableOptions() {
      console.log('Page: ' + this.pageNumber + '\nItems per page: ' + this.itemsPerPage)
    },

    getLicenceOfClub() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('club/get-gga-files',
          {club_id: this.clubProp.id},
          this.getLicenceOfClubCallback)
    },

    getLicenceOfClubCallback(response) {
      this.licenceForClub = response
      this.getBranchesWithPlaces()
    },

    getDocumentsForOfficials(selectedBranchForGettingFiles) {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('branch/get-at-files',
          {branch_id: selectedBranchForGettingFiles.id},
          this.getDocumentsForOfficialsCallback)
    },

    getDocumentsForOfficialsCallback(response) {
      this.documentsForOfficials = []
      this.documentsForOfficials = response
      this.$store.commit('setLoadingModal', false)
    },

    getLicencingDocument(item) {
      requests.downloadFileRequest('place/download-licencing/' + item.id)
    },

    getApprovalDocument() {
      requests.downloadFileRequestWithObj(
          'application/get-approval-file',
          {application_id: this.licenceForClub.application_id})
    },

    // getLastApplication() {
    //   this.$store.commit('setLoadingModal',true)
    //   requests.postSecureRequest('club/active-until',
    //       {club_id: this.clubProp.id},
    //       this.getlastApplicationCallback)
    // },
    //
    // getlastApplicationCallback(response) {
    //   this.lastApplication = response
    //   this.$store.commit('setLoadingModal',false)
    // }

    getEndDateOfStatus() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('club/get-status-until-date',
          {club_id: this.clubProp.id}, this.getEndDateOfStatusCallback)
    },
    getEndDateOfStatusCallback(response) {
      this.effective_end_date = response.date
      this.$store.commit('setLoadingModal', false)
    }

  },
  created() {
    this.getCouncilOfOrganization()
    this.getEndDateOfStatus()
  }
}
</script>

<style scoped>

</style>