<template>
  <div>
    <!--PARAPANO TOU ENOS ROLOU-->
    <v-row class="mt-10 mb-10" align="center" justify="center">
      <v-card v-if="selectedRole == 'chose'">
        <v-card-title>
          Επιλέξτε το ρόλο για τον οποίο θέλετε να ενεργήσετε
        </v-card-title>
        <v-card-text>
          <v-select :items="$store.getters.loggedinUser.roles" no-data-text="Δεν υπάρχουν καταχωρήσεις" item-text="name"
                    v-model="selectedRole" @change="saveSelectedRole"/>
        </v-card-text>
      </v-card>
    </v-row>

    <v-card>
      <v-card-title style="background-color: #003375; word-break: normal" class="white--text">
        <v-row align="center" justify="center" class="ma-2 text-h4">
          Στοιχεία χρήστη
        </v-row>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <div v-if="Object.keys($store.getters.loggedinUser).length > 0">
          <v-row justify="center" align="center">
            <v-col align="center">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{
                      ($store.getters.loggedinUser.user.first_name != null
                          && $store.getters.loggedinUser.user.first_name != "null") ? $store.getters.loggedinUser.user.first_name : '-'
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col align="center">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{
                      ($store.getters.loggedinUser.user.last_name != null
                          && $store.getters.loggedinUser.user.last_name != "null") ? $store.getters.loggedinUser.user.last_name : '-'
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col align="center">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ ($store.getters.loggedinUser.user.vat != null) ? $store.getters.loggedinUser.user.vat : '-' }}
                  </v-list-item-title>
                  <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col v-if="$store.getters.loggedinUser.hasOwnProperty('policestation') &&
                         ($store.getters.loggedinUser.policestation.name != null
                         || $store.getters.loggedinUser.policestation.org_number != null)" align="center">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      ($store.getters.loggedinUser.policestation.name) ? $store.getters.loggedinUser.policestation.name : $store.getters.loggedinUser.policestation.org_number
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Αστυνομικό τμήμα</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog fullscreen
              v-if="submittedApplicationsDialog"
              v-model="submittedApplicationsDialog"
              persistent
              @keydown.esc="submittedApplicationsDialog = false"
              width="90%">
      <v-card>
        <v-card-title class="primary">
          <v-spacer></v-spacer>
          <v-icon @click="submittedApplicationsDialog = false" color="white" medium>clear</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-5">
            <SubmittedApplications/>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "../../requests";
import SubmittedApplications from "./SubmittedApplications";
import utils from "../../utilities";
import SearchForMember from "@/components/reusable/SearchForMember.vue";

export default {
  name: "OfficialsCard",
  computed: {
    searchForMember() {
      return SearchForMember
    }
  },
  components: {SubmittedApplications},
  data: () => ({
    submittedApplicationsDialog: false,
    numberOfSubmittedApplications: 0,
    selectedRole: '',
  }),
  methods: {
    notifications() {
      this.$router.push({path: '/submitted'})
      // this.submittedApplicationsDialog = true;
    },

    clubs() {
      this.$router.push({path: '/clubs'})
    },

    memberSearch() {
      this.$router.push({path: '/search-for-members'})
    },

    placeLicencing() {
      this.$router.push({path: '/place-licencing'})
    },

    // getSubmittedApplications() {
    //   this.$store.commit('setLoadingModal', true)
    //   requests.postSecureRequest('application/findSubmitted', {}, this.getSubmittedApplicationsCallback)
    // },
    //
    // getSubmittedApplicationsCallback(response) {
    //   console.log(JSON.parse(JSON.stringify(response)));
    //
    //   this.numberOfSubmittedApplications = response.length
    //
    //   this.$store.commit('setSubmitedApplications', response)
    //   this.$store.commit('setLoadingModal', false)
    // },
    thereAreSubmittedApplications() {
      return this.numberOfSubmittedApplications > 0;
    },


    getLoginInformation() {
      requests.getSecureRequest('user/loginInformation', '', this.loginInformationCallabck)
    },

    loginInformationCallabck(response) {

      if (this.$store.getters.loggedinUser?.role) {
        return
      }
      this.$store.commit('setLoggedinUser', response)

      if (this.$store.getters.loggedinUser.roles.length == 0) {
        this.selectedRole = ''
      }
      if (this.$store.getters.loggedinUser.roles.length == 1) {
        this.selectedRole = this.$store.getters.loggedinUser.roles[0].name
        this.saveSelectedRole()
      }
      if (this.$store.getters.loggedinUser.roles.length > 1) {
        this.selectedRole = 'chose'
      }

      this.$store.commit('setNotificationIcon', true)
      requests.getSecureRequest('properties/', 5, this.getDevModeCallback)
    },

    getDevModeCallback(response) {
      this.$store.commit('setDevMode', response)
    },

    saveSelectedRole() {
      let user = this.$store.getters.loggedinUser
      user.role = this.selectedRole
      this.$store.commit('setLoggedinUser', user)
      // if(this.$store.getters.loggedinUser.role == 'ΓΓΑ'){
      //   this.getSubmittedApplications()
      // }

      let menuItems = []

      if (this.$store.getters.loggedinUser.role == 'ΓΓΑ') {
        menuItems = [
          {
            to: '/officials-card',
            name: 'Στοιχεία χρήστη'
          },
          {
            to: '/submitted',
            name: 'Υποβεβλημένες αιτήσεις'
          },
          {
            to: '/search-for-applications',
            name: 'Ιστορικό αιτήσεων'
          },
          {
            to: '/clubs',
            name: 'Λέσχες',
          },
          {
            to: '/notifications-page',
            name: 'Ειδοποιήσεις'
          },
        ]
      }
      if (this.$store.getters.loggedinUser.role == 'ΓΔΑ') {
        menuItems = [
          {
            to: '/officials-card',
            name: 'Στοιχεία χρήστη'
          },
          {
            to: '/clubs',
            name: 'Λέσχες',
          },
          {
            to: '/history-of-clubs-officials',
            name: 'Ιστορικό λεσχών',
          },
          {
            to: '/place-licencing',
            name: 'Έλεγχος χώρων',
          },
          {
            to: '/search-for-members',
            name: 'Αναζήτηση Μελών'
          },
          {
            to: '/notifications-page',
            name: 'Ειδοποιήσεις'
          },
        ]
      }

      if (this.$store.getters.loggedinUser.role == 'ΑΤ') {
        menuItems = [
          {
            to: '/officials-card',
            name: 'Στοιχεία χρήστη'
          },
          {
            to: '/place-licencing',
            name: 'Αδειοδότηση Χώρων'
          },
          {
            to: '/history-of-at',
            name: 'Ιστορικό χώρων',
          },
          {
            to: '/search-for-members',
            name: 'Αναζήτηση Μελών'
          },
          {
            to: '/notifications-page',
            name: 'Ειδοποιήσεις'
          },
        ]
      }
      this.$store.commit('setMenuItems', menuItems);
    },

    getAccessUrl() {
      requests.postRequest('properties/access-url', {}, this.getAccessUrlCallback)
    },

    getAccessUrlCallback(response) {
      this.$store.commit("setAccessUrl", response.data)
    },

    searchApplications() {
      this.$router.push({path: '/search-for-applications'})
    },
  },
  created() {

    utils.setLocalStorage('path', '/officials-card');
    this.$store.commit('setHomePath', '/officials-card');
    // const jwtToken = this.$cookies.get("jwt_token");
    // utils.setLocalStorage('jwt_token', jwtToken);
    this.selectedRole = '';
    this.getLoginInformation();
    this.getAccessUrl();
  },
  /*beforeDestroy() {


  }*/
}
</script>

<style scoped>

</style>
