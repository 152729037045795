<template>
  <div>
    <v-row justify="center" align="center" class="mb-4">
      <v-expansion-panels v-model="panel" multiple style="width: 93%">
        <v-expansion-panel expand>
          <v-expansion-panel-header>
            <v-row>
          <span class="govgr-!-font-size-24 ml-2">
            Στοιχεία αναζήτησης
          </span>
            </v-row>
            <v-spacer/>
            <template v-slot:actions>
              <v-icon color="primary">
                mdi-arrow-down-bold-circle-outline
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-divider/>
          <v-expansion-panel-content class="mt-6">
            <v-form v-model="isFormValid">
              <v-row>
                <v-col class="mt-1">
                  <v-select

                      :items="$store.getters.actionTypes"
                      v-model="selectedActiontypes"
                      item-value="id"
                      multiple
                      dense
                      no-data-text="Δεν υπάρχουν καταχωρήσεις"
                      item-text="name" return-object label="Τύπος ειδοποίησης"
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index === 0" class="mt-1 mb-1">
                        <span>{{ item.name }}</span>
                      </v-chip>
                      <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                      >
                    (+{{ selectedActiontypes.length - 1 }} ακόμα)
                  </span>
                    </template>
                  </v-select>
                </v-col>
                <v-col>
                  <v-dialog
                      ref="dialog"
                      v-model="startDateModal"
                      :return-value.sync="startDate"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="startDateText.toString().replaceAll('-', '/').replace(',', ' - ')"
                                    label="Επιλέξτε χρονικό διάστημα"
                                    prepend-icon="event"
                                    dense
                                    solo

                                    :rules="[v=>startDateText.length===2 || 'Παρακαλώ επιλέξτε εύρος ημερομηνιών']"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                        range
                        :max="maximumDate"
                        v-model="startDate"
                        scrollable
                        locale="el"
                        first-day-of-week="1">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="startDateModal = false">Κλείσιμο</v-btn>
                      <v-btn text color="primary" @click="selectStartDate">Επιλογή</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col class="mt-1">
                  <v-select
                      :items="radioOptions"
                      v-model="radioGroup"
                      item-value="id"
                      dense
                      item-text="text"
                      return-object
                      no-data-text="Δεν υπάρχουν καταχωρήσεις"
                      label="Επιλογή ειδοποιήσεων"/>
                </v-col>
              </v-row>
            </v-form>
            <v-row style="margin-top: -2%">
              <v-spacer/>
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <v-btn icon v-on="on" @click="clearSearch()" class="ma-2">
                    <v-icon color="primary">
                      mdi-broom
                    </v-icon>
                  </v-btn>
                </template>
                <span>
               Καθαρισμός
            </span>
              </v-tooltip>
              <v-btn @click="searchNotifications()" class="ma-2 primary" :disabled="!isFormValid">
                Αναζήτηση
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-card min-width="600" flat>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-data-table
                :page.sync="pageNumber"
                :footer-props="{page:1,
                              pageCount:1,
                              showCurrentPage:true,
                              itemsPerPageOptions:[5,10,15,30],
                              showFirstLastPage:true,
                              itemsPerPageText:'Ειδοποιήσεις ανά σελίδα:',
                              itemsPerPageAllText:'Όλα'}"
                no-data-text="Δέν υπάρχουν αποτελέσματα."
                :options.sync="options"

                multi-sort
                :headers="headers"
                :items="notifications"
                :items-per-page="itemsPerPage"
                :server-items-length="tableLength"

                @update:options="getBuildingPerPage"
                class="elevation-3 mx-5 px-2 custom-data-table"
            >
              <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-card color="primary"
                          class="mb-5"
                          height="80"
                          width="165">
                    <v-card-title style="word-break: normal" class="white--text">
                      {{ 'Ειδοποιήσεις' }}
                    </v-card-title>
                  </v-card>
                </v-toolbar>
              </template>
              <template v-slot:item="{item}">
                <tr :style="(!item['' + relationName+'s'][0]['notification_'+relationName].read) ? 'background-color: lightblue' : ''">
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.timestamp }}
                  </td>
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.actiontype.name }}
                  </td>
                  <td class="text-center d-sm-table-cell d-flex">
                    <v-tooltip left>
                      <template v-slot:activator="{on}">
                        <v-btn v-on="on" fab x-small @click="openNotificationDialog(item)">
                          <v-icon large color="primary">mdi-information-variant</v-icon>
                        </v-btn>
                      </template>
                      <span>
                    Λεπτομέρειες ειδοποίησης
                  </span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="notificationDialog" width="700px">
      <v-card>
        <v-card-title style="background-color: #003375; word-break: normal" class="white--text">
          <v-row align="center" justify="center" class="ma-2 govgr-!-font-size-24">
            Λεπτομέρειες ειδοποίησης
          </v-row>
        </v-card-title>
        <v-card-text class="text-h6">
          <v-row align="center" style="font-weight: bold" class="ml-1 mb-1 mt-2">
            <span>{{ notificationType }}</span>
          </v-row>
          <v-divider/>
          <p style="text-align: justify; text-justify: inter-word;" class="mt-1">{{ notificationInfo }}</p>
        </v-card-text>
        <v-card-actions>
          <v-chip>
            <v-icon>mdi-alarm</v-icon>
            <span class="ml-1">{{ notificationTimestamp }}</span>
          </v-chip>
          <v-spacer/>
          <v-btn @click="closeNotificationDialog">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import requests from "@/requests";
import utils from "@/utilities";

export default {
  name: "NotificationsPage",
  data: () => ({
    pageNumber: 1,
    notifications: [],
    itemsPerPage: 5,
    tableLength: 1,
    relationName: '',
    selectedActiontypes: [],
    panel: [0],
    searchObj: {},
    isFormValid: false,
    maximumDate: new Date().toISOString().substr(0, 10),
    startDate: [],
    startDateText: [new Date(Date.now() - 604800000).toISOString().substring(0, 10), new Date().toISOString().substr(0, 10)],
    headers: [
      {
        text: 'Ημερομηνία',
        align: 'center',
        sortable: true,
        value: 'timestamp',
      },
      {
        text: 'Είδος ειδοποίησης',
        align: 'center',
        sortable: false,
        value: 'notification_type',
      },
      {
        text: 'Λεπτομέρειες',
        align: 'center',
        sortable: false,
        value: 'description',
      },
    ],
    notificationDialog: false,
    notificationInfo: 0,
    notificationType: '',
    notificationTimestamp: '',
    selectedNotificationIdToRead: '',
    startDateModal: false,
    options: {},
    radioGroup: {},
    radioOptions: [
      {id: 1, text: 'Όλες'},
      {id: 2, text: 'Αναγνωσμένες'},
      {id: 3, text: 'Μη αναγνωσμένες'},
    ],
    utils
  }),
  methods: {


    searchNotifications() {
      this.$store.commit('setLoadingModal', true)

      if (this.$store.getters.loggedinUser.role == 'NEAS') {
        this.searchObj.club_id = this.$store.getters.selectedClub.id
      } else if (this.$store.getters.loggedinUser.role == 'NEL') {
        this.searchObj.user_club_id = this.$store.getters.selectedClub.id
      } else if (this.$store.getters.loggedinUser.role == 'YLPL') {
        this.searchObj.user_club_id = this.$store.getters.selectedBranchYPL.club_id
      }
      if (this.selectedActiontypes.length > 0) {
        this.searchObj.selected_actiontype_ids = this.selectedActiontypes.map(at => at.id)
      }

      this.searchObj.dateRange = [this.startDateText[0], this.startDateText[1] + 'T23:59:59'];

      if (this.radioGroup.id === 2) {
        this.searchObj.read = true
      } else if (this.radioGroup.id === 3) {
        this.searchObj.read = false
      } else if (this.radioGroup.id === 1) {
        delete this.searchObj.read
      }

      if (this.options.sortBy.length > 0) {

        if (this.options.sortDesc[0]) {
          this.searchObj.sortDirection = "ASC";
        } else {
          this.searchObj.sortDirection = "DESC";
        }

      }

      this.pageNumber = 0

      let requestObj = {
        "pageNumber": 0,
        "itemsPerPage": this.itemsPerPage,
        "searchObj": this.searchObj
      }


      requests.postSecureRequest('notification/findByCriteriaPagination', requestObj, this.getNotificationsCallback)
    },

    getActionTypes() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        role: this.$store.getters.loggedinUser.role
      }
      requests.postSecureRequest('actiontype/get-actiontypes', requestObj, this.getActiontypesCallback)
    },

    getActiontypesCallback(response) {
      this.$store.commit('setActionTypes', response)
      this.$store.commit('setLoadingModal', false)
    },

    getNotificationsCallback(response) {
      this.notifications = []
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.notifications.length; i++) {
        res.notifications[i].timestamp = utils.timestampToDatetime(res.notifications[i].timestamp);
        this.notifications.push(res.notifications[i])
      }


      this.tableLength = res.total;
      this.relationName = res.relationName;
      this.$store.commit('setLoadingModal', false)
    },

    getBuildingPerPage(item) {
      this.$store.commit('setLoadingModal', true)
      this.pageNumber = item.page
      this.itemsPerPage = item.itemsPerPage
      if (this.$store.getters.loggedinUser.role == 'NEAS') {
        this.searchObj.club_id = this.$store.getters.selectedClub.id
      } else if (this.$store.getters.loggedinUser.role == 'NEL') {
        this.searchObj.user_club_id = this.$store.getters.selectedClub.id
      } else if (this.$store.getters.loggedinUser.role == 'YLPL') {
        this.searchObj.user_club_id = this.$store.getters.selectedBranchYPL.club_id
      }


      if (this.radioGroup === 2) {
        this.searchObj.read = true
      } else if (this.radioGroup === 3) {
        this.searchObj.read = false
      } else if (this.radioGroup === 1) {
        delete this.searchObj.read
      }

      if (this.options.sortBy.length > 0) {
        let shortItems = [];

        if (this.options.sortDesc[0]) {
          this.searchObj.sortDirection = "ASC";
        } else {
          this.searchObj.sortDirection = "DESC";
        }

      }

      this.searchObj.dateRange = [this.startDateText[0], this.startDateText[1] + 'T23:59:59'];
      let requestObj = {
        "pageNumber": this.pageNumber - 1,
        "itemsPerPage": this.itemsPerPage,
        "searchObj": this.searchObj
      }

      requests.postSecureRequest('notification/findByCriteriaPagination', requestObj, this.getNotificationsCallback)
    },

    openNotificationDialog(item) {
      this.notificationInfo = item.text;
      this.notificationType = item.actiontype.name
      this.notificationTimestamp = item.timestamp
      this.selectedNotificationIdToRead = item.id;
      this.notificationDialog = true;
    },

    setNotificationAsRead() {
      this.$store.commit('setLoadingModal', true)

      let identifyObj = {};
      if (this.$store.getters.loggedinUser.role == 'NEAS') {
        identifyObj.club_id = this.$store.getters.selectedClub.id
      }

      let requestObj = {
        notification_id: this.selectedNotificationIdToRead,
        identify_obj: identifyObj
      }
      requests.postSecureRequest('notification/read-notification', requestObj, this.setNotificationAsReadCallback)
    },
    setNotificationAsReadCallback(response) {
      for (let notification of this.notifications) {
        if (notification.id == this.selectedNotificationIdToRead) {

          if (!notification['' + this.relationName + 's'][0]['notification_' + this.relationName].read) {

            // let previouslyReadNotifications = JSON.parse(JSON.stringify(this.$store.getters.readNotifications));
            // console.log(previouslyReadNotifications);
            // previouslyReadNotifications.push(notification);

            this.$store.commit('setReadNotifications', notification);
          }

          notification['' + this.relationName + 's'][0]['notification_' + this.relationName].read = true


        }
      }
      this.$store.commit('setLoadingModal', false)
    },


    closeNotificationDialog() {
      this.notificationDialog = false;
      this.setNotificationAsRead()
    },

    clearSearch() {
      this.selectedActiontypes = [];
      this.radioGroup = {}
      this.startDateText = [new Date(Date.now() - 604800000).toISOString().substring(0, 10), new Date().toISOString().substr(0, 10)],
          this.searchObj = {}
      if (this.$store.getters.loggedinUser.role == 'NEAS') {
        this.searchObj.club_id = this.$store.getters.selectedClub.id
      }
    },

    selectStartDate() {
      this.startDateText = utils.sortDatesArray(this.startDate);
      this.startDateModal = false;
    },
  },

  computed: {},
  created() {
    // this.getNotifications();
    this.getActionTypes();
  }

}
</script>


<style scoped>


</style>
