<template>
  <div>
    <v-row align="center" justify="center">
      <v-col xl="5" lg="6" md="8" sm="10" xs="12">
        <v-card>
          <v-row align="center" justify="center">
            <v-card-title style="word-break: normal">
              Επιλέξτε τη λέσχη φιλάθλων για την οποία επιθυμείτε να ενεργήσετε.
            </v-card-title>
          </v-row>

          <v-card-text align="center" justify="center">
            <v-col>
              <v-select :items="myClubs"
                        item-value="id"
                        v-model="selectedClub"
                        item-text="name"
                        @change="storeSelectedClub"
                        label="Λέσχη φιλάθλων"
                        no-data-text="Δεν υπάρχουν καταχωρήσεις"
                        class="click-cursor"
                        return-object>
              </v-select>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="goBack">
              Επιλογή ρόλου
            </v-btn>
            <v-spacer></v-spacer>

            <!--            <v-btn color="primary" @click="forDialogTest">-->
            <!--              Συνέχεια-->
            <!--            </v-btn>-->
            <v-btn color="primary" @click="proceedAsNEL">
              Συνέχεια
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import requests from "@/requests";
import services from "@/services";

export default {
  name: "SelectClub",
  data: () => ({
    myClubs: [],
    selectedClub: {},
  }),
  created() {
    services.clearGetNotificationsInterval()
    this.$store.commit('setMenuItems', [])
    this.$store.commit('setNotificationIcon', false)
    this.$store.commit('setggaApplication', {id: '', name: ''})
    this.$store.commit('setGgaApplicationProgress', null)
    this.$store.commit('setGgaApplicationId', null)
    this.$store.commit('setGgaApplicationDate', '')
    this.getMyClubs()
  },
  methods: {
    getMyClubs() {
      for (let club of this.$store.getters.loggedinUser.myClubs) {
        for (let branch of club.myBranchesOnClub) {
          for (let user_branch of branch.user_branches) {
            for (let branchrole of user_branch.branchroles) {
              if (branchrole.id == 2) this.myClubs.push(club)
            }
          }
        }
      }
    },

    storeSelectedClub() {

      this.$store.commit('setSelectedClub', this.selectedClub)
      this.getOrganization()
    },

    getOrganization() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('club/get-organization', {club_id: this.selectedClub.id}, this.getOrganizationCallback)
    },

    getOrganizationCallback(response) {
      this.$store.commit('setOrganization', response)
      this.$store.commit('setLoadingModal', false)
    },

    proceedAsNEL() {
      if (this.$store.getters.selectedClub != this.selectedClub) {
        this.$store.commit('setInfoSnackBarObj', {text: 'Παρακαλούμε επιλέξτε μια λέσχη φιλάθλων.'})
        this.$store.commit('setInfoSnackBar', true)
      } else {
        if (this.$store.getters.memberfileParsingInterval == true) {
          this.$store.commit('setCheckedRepresentative', true)
          this.$router.push({path: '/nel-dashboard'})
        } else {
          this.$store.commit('setLoadingModal', true)


          let requestObj = {
            user_branch_id: this.$store.getters.selectedClub.myBranchesOnClub[0].user_branch.id
          }
          requests.postSecureRequest('memberfile/check-for-ongoing-processing',
              requestObj,
              this.checkForOngoingMemberfileProcessingCallback
          )
        }
      }
    },

    checkForOngoingMemberfileProcessingCallback(response) {



      if (response.memberfile_id != null) {
        services.memberfileParsingInterval(response)
      }

      this.$store.commit('setCheckedRepresentative', true)
      this.$router.push({path: '/nel-dashboard'})
    },

    goBack() {
      this.$store.commit('setSelectedClub', null)
      this.$store.commit('setCheckedRepresentative', false)

      this.$router.push({path: '/role-selection'})
    },

    // forDialogTest(){
    //   // requests.getSecureRequest('user/get/', 1, this.forDialogTestCallback)
    //   // requests.postSecureRequest('user/post', {}, this.forDialogTestCallback)
    //   requests.putSecureRequest('user/put', {}, this.forDialogTestCallback)
    // },
    // forDialogTestCallback(response){
    //   console.log('OK')
    // },
  },
}
</script>

<style scoped>

</style>
