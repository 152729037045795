<template>
  <div>

    <!--        <div class="govgr-width-container">-->
    <!--            <div class="govgr-main-wrapper">-->


    <v-row align="center" justify="center">
      <v-card v-if="userIsPerson"><!--Fisiko Prosopo-->
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
                :complete="e1 > 1"
                :step="stepsNames[0]"
            >
              Εισαγωγή ΑΦΜ ΑΣ/ΤΑ.Α./ΑΑ.Ε
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="e1 > 2"
                :step="stepsNames[1]"
            >
              Εισαγωγή ΑΦΜ λέσχης
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :step="stepsNames[2]">
              Συναίνεση
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content :step="stepsNames[0]">
              <v-card class="mb-12"
                      flat>
                <v-row>
                  <v-col>
                    <h4 class="govgr-heading-s">
                      Εισάγετε τον ΑΦΜ του αθλητικού σωματείου, Τ.Α.Α ή της Α.Α.Ε. εκ μέρους του οποίου επιθυμείτε να
                      παράσχετε συναίνεση
                    </h4>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col xl="3" lg="5" md="8" sm="10" xs="12">
                    <v-text-field v-model="organization_vat"
                                  class="textField"
                                  outlined
                                  label="ΑΦΜ"></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <v-row class="ma-2">
                <v-btn text @click="redirectToDashboard">
                  Πίσω
                </v-btn>
                <v-spacer/>
                <v-btn color="primary"
                       @click="submit"
                       :disabled="organization_vat.trim().length<8">
                  Συνέχεια
                </v-btn>
              </v-row>

            </v-stepper-content>

            <v-stepper-content :step="stepsNames[1]">
              <v-card class="mb-12"
                      flat>
                <v-row>
                  <v-col>
                    <h4 class="govgr-heading-s">
                      Εισάγετε τον ΑΦΜ της αναγνωρισμένης λέσχης φιλάθλων για την οποία το αθλητικό σωματείο, Τ.Α.Α ή
                      της Α.Α.Ε. που εκπροσωπείτε έχει παράσχει τη συναίνεση του
                    </h4>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col xl="3" lg="5" md="8" sm="10" xs="12">
                    <v-text-field v-model="club_vat"
                                  class="textField"
                                  outlined
                                  label="ΑΦΜ"></v-text-field>
                  </v-col>
                </v-row>
              </v-card>

              <v-row class="ma-2">
                <v-btn text @click="e1--;">
                  Πίσω
                </v-btn>
                <v-spacer/>
                <v-btn color="primary"
                       @click="submit"
                       :disabled="club_vat.trim().length<9">
                  Συνέχεια
                </v-btn>
              </v-row>

            </v-stepper-content>

            <v-stepper-content :step="stepsNames[2]">
              <v-card class="mb-12"
                      flat>
                <v-card-text>
                  <v-row align="center" justify="center">
                    <v-col cols="10">
                      <v-text-field outlined
                                    v-model="club.onomasia"
                                    label="ΔΙΑΚΡΙΤΙΚΟΣ ΤΙΤΛΟΣ"
                                    :disabled="onomasiaClubFromSevrice != null"/>
                    </v-col>
                  </v-row>
                </v-card-text>

                <v-row>
                  <v-col cols="6">
                    <v-list-item two-line>
                      <v-list-item-content align="center" justify="center">
                        <v-list-item-title>
                          ΑΦΜ
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ club.afm }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item two-line>
                      <v-list-item-content align="center" justify="center">
                        <v-list-item-title>
                          Λήξη Περιόδου Ισχύος Συναίνεσης
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          30 Ιούν {{ (new Date()).getFullYear() + 1 }}
                          <!--                                                {{club.consent_end ? club.consent_end.toLocaleDateString() : '-'}}-->
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <!--                                      <v-row align="center" justify="center">-->
                <!--                                          <v-col cols="8">-->
                <!--                                            <v-row align="center" justify="center">-->
                <!--                                              Υπεύθυνος Λειτουργίας Λέσχης-->
                <!--                                              <v-btn fab small @click="openRepresentativeDialgog" class="ma-1">-->
                <!--                                                <v-icon  v-if="Object.keys(definedRef).length > 0">edit</v-icon>-->
                <!--                                                <v-icon  v-else>add</v-icon>-->
                <!--                                              </v-btn>-->
                <!--                                            </v-row>-->
                <!--                                          </v-col>-->
                <!--                                          <v-col cols="10">-->
                <!--                                              <table class="govgr-table">-->

                <!--                                                  <caption></caption>-->

                <!--                                                  <thead class="govgr-table__head">-->
                <!--                                                  <tr class="govgr-table__row">-->
                <!--                                                      <th class="govgr-table__header" scope="col">Όνομα</th>-->
                <!--                                                      <th class="govgr-table__header" scope="col">Επώνυμο</th>-->
                <!--                                                      <th class="govgr-table__header" scope="col">Πατρώνυμο</th>-->
                <!--                                                      <th class="govgr-table__header" scope="col">ΑΔΤ</th>-->
                <!--                                                      <th class="govgr-table__header" scope="col">ΑΦΜ</th>-->
                <!--                                                  </tr>-->
                <!--                                                  </thead>-->
                <!--                                                  <tbody class="govgr-table__body">-->
                <!--                                                  <tr class="govgr-table__row">-->
                <!--                                                      <td class="govgr-table__cell">{{definedRef.name}}</td>-->
                <!--                                                      <td class="govgr-table__cell">{{definedRef.lastname}}</td>-->
                <!--                                                      <td class="govgr-table__cell">{{definedRef.fathername}}</td>-->
                <!--                                                      <td class="govgr-table__cell">{{definedRef.adt}}</td>-->
                <!--                                                      <td class="govgr-table__cell">{{definedRef.afm}}</td>-->
                <!--                                                  </tr>-->
                <!--                                                  </tbody>-->
                <!--                                              </table>-->
                <!--                                          </v-col>-->
                <!--                                      </v-row>-->

                <v-row align="center" justify="center">
                  <v-data-table title="Υπεύθυνος Λειτουργίας Λέσχης"
                                hide-default-footer
                                :items="[definedRef]"
                                :items-per-page="1"
                                :headers="definedRefHeaders"
                                mobile-breakpoint="0">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>
                          Υπεύθυνος λειτουργίας λέσχης
                        </v-toolbar-title>
                        <v-btn icon x-large color="primary" @click="openRepresentativeDialgog" class="ma-1">
                          <v-icon v-if="Object.keys(definedRef).length > 0">edit</v-icon>
                          <v-icon v-else>add</v-icon>
                        </v-btn>
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </v-row>
              </v-card>

              <v-row class="ma-2">
                <v-btn text @click="e1--;">
                  Πίσω
                </v-btn>
                <v-spacer/>
                <v-btn color="primary"
                       :disabled="isConfirmationButtonValid"
                       @click="createClub">
                  Συναινώ
                </v-btn>
              </v-row>

            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>

      <v-card v-else><!--Nomiko Prosopo-->
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step
                :complete="e1 > 1"
                :step="stepsNames[1]"
            >
              Εισαγωγή ΑΦΜ Λέσχης
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :step="stepsNames[2]" :complete="e1 > 2">
              Συναίνεση
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>

            <v-stepper-content :step="stepsNames[1]">
              <v-card class="mb-12"
                      flat>
                <v-row>
                  <v-col>
                    <h4 class="govgr-heading-s">Εισάγετε τον ΑΦΜ της αναγνωρισμένης λέσχης φιλάθλων
                      για την οποία το αθλητικό σωματείο, Τ.Α.Α ή της Α.Α.Ε. που εκπροσωπείτε έχει
                      παράσχει τη συναίνεση της </h4>
                  </v-col>
                </v-row>
                <v-row align="center" justify="center">
                  <v-col xl="3" lg="5" md="8" sm="10" xs="12">
                    <v-text-field v-model="club_vat"
                                  class="textField"
                                  outlined
                                  label="ΑΦΜ"></v-text-field>
                  </v-col>
                </v-row>
              </v-card>
              <v-row class="ma-2">
                <v-btn text
                       @click="e1--;">
                  Πίσω
                </v-btn>
                <v-btn color="primary"
                       @click="submit">
                  Συνέχεια
                </v-btn>
              </v-row>
            </v-stepper-content>

            <v-stepper-content :step="stepsNames[2]">
              <v-card class="mb-12"
                      flat min-width="600px">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      Πλήρης επωνυμία και διακριτικός τίτλος
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ club.onomasia }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-row>
                  <v-col cols="6">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>
                          ΑΦΜ
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ club.afm }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="6">
                    <v-list-item two-line>
                      <v-list-item-content align="center" justify="center">
                        <v-list-item-title>
                          Λήξη περιόδου ισχύος συναίνεσης
                        </v-list-item-title>
                        <v-list-item-subtitle>

                          30 Ιούν {{ (new Date()).getFullYear() + 1 }}

                          <!--                                                {{club.consent_end ? club.consent_end.toLocaleDateString() : '-'}}-->
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>

                <!--                                        <v-row align="center" justify="center">-->
                <!--                                            <v-col cols="8">-->
                <!--                                                <table class="govgr-table">-->


                <!--                                                    <caption>Νόμιμοι Εκπρόσωποι</caption>-->
                <!--                                                    <thead class="govgr-table__head">-->
                <!--                                                    <tr class="govgr-table__row">-->
                <!--                                                        <th class="govgr-table__header" scope="col">Όνομα</th>-->
                <!--                                                        <th class="govgr-table__header" scope="col">Επώνυμο</th>-->
                <!--                                                        <th class="govgr-table__header" scope="col">ΑΦΜ</th>-->
                <!--                                                    </tr>-->
                <!--                                                    </thead>-->
                <!--                                                    <tbody class="govgr-table__body">-->
                <!--                                                    <tr class="govgr-table__row" v-for="(rep, index) in representatives"-->
                <!--                                                        :key="index">-->
                <!--                                                        <td class="govgr-table__cell">{{rep.firstname}}</td>-->
                <!--                                                        <td class="govgr-table__cell">{{rep.lastname}}</td>-->
                <!--                                                        <td class="govgr-table__cell">{{rep.afm}}</td>-->
                <!--                                                    </tr>-->
                <!--                                                    </tbody>-->
                <!--                                                </table>-->
                <!--                                            </v-col>-->
                <!--                                        </v-row>-->

                <v-row align="center" justify="center">
                  <v-data-table title="Υπεύθυνος Λειτουργίας Λέσχης"
                                hide-default-footer
                                :items="[definedRef]"
                                :items-per-page="1"
                                :headers="definedRefHeaders"
                                mobile-breakpoint="0">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title>
                          Υπεύθυνος λειτουργίας λέσχης
                        </v-toolbar-title>
                        <v-btn small @click="openRepresentativeDialgog" class="ma-1">
                          <v-icon v-if="Object.keys(definedRef).length > 0">edit</v-icon>
                          <v-icon v-else>add</v-icon>
                        </v-btn>
                      </v-toolbar>
                    </template>
                  </v-data-table>
                </v-row>
              </v-card>

              <v-row class="ma-2">
                <v-btn text
                       @click="e1--;">
                  Πίσω
                </v-btn>
                <v-btn color="primary"
                       :disabled="isConfirmationButtonValid"
                       @click="createClub">
                  Συναινώ
                </v-btn>
              </v-row>

            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-row>

    <v-dialog v-model="representativeDialog" max-width="500">
      <v-card>
        <v-card-title>Ορισμός υπευθύνου λειτουργίας λέσχης</v-card-title>
        <v-card-text>
          <v-form>
            <v-row class="mt-2" dense>
              <v-col cols="6">
                <v-text-field outlined label="ΑΦΜ" v-model="rep.afm"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="ΑΔΤ" v-model="rep.adt"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="Όνομα" v-model="rep.name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="Επώνυμο" v-model="rep.lastname"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="Πατρώνυμο" v-model="rep.fathername"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeRepresentativesDialog" flat>ΚΛΕΙΣΙΜΟ</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="repFormIsValid()" @click="saveRepresentative">ΕΙΣΑΓΩΓΗ</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>


import requests from "@/requests";
import prop from "@/properties";

export default {
  name: "ClubCard",
  components: {},
  data: () => ({
    representatives: [],
    dialog: false,
    newRepresentative: {},
    step: 1,
    vat: '',
    e1: 1,
    organization_vat: '046253950', // todo test AFM for testing; in prod will be ''
    club_vat: '998984411', // todo ILINK for testing; in prod will be ''
    userIsPerson: false,
    club: {},
    stepsNames: [],
    representativeDialog: false,
    rep: {},
    definedRef: {},
    hasSeparateAFM: false,
    organisation_name: '',
    onomasiaClubFromSevrice: null,
    definedRefHeaders: [
      {
        text: 'Όνομα',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Επώνυμο',
        sortable: false,
        value: 'lastname',
      },
      {
        text: 'Πατρώνυμο',
        sortable: false,
        value: 'fathername',
      },
      {
        text: 'ΑΔΤ',
        sortable: false,
        value: 'adt',
      },
      {
        text: 'ΑΦΜ',
        sortable: false,
        value: 'afm',
      },
    ]
  }),
  methods: {

    submit() {

      if (this.userIsPerson) {

        this.$store.commit('setLoadingModal', true)
        if (this.e1 === 1) {
          requests.getSecureRequest('checks/check-rep-is-valid/', this.organization_vat, this.elegxosAFMCallback)
        } else if (this.e1 === 2) {

          requests.getSecureRequest('checks/check-club-not-exists/', this.club_vat, this.checkClubNotExistsCallback)
        }
      } else {
        if (this.e1 === 1) {
          this.$store.commit('setLoadingModal', true)
          requests.getSecureRequest('checks/check-club-not-exists/', this.club_vat, this.checkClubNotExistsCallback)
        }
      }
    },

    checkClubNotExistsCallback(response) {

      if (response === true) {//if club not exists allow to create one
        this.hasSeparateAFM = this.club_vat != this.organization_vat;
        let requestObj = {
          club_vat: this.club_vat,
          hasSeparateAFM: this.hasSeparateAFM
        }
        requests.postSecureRequest('checks/get-corporation-info-by-AFM', requestObj, this.getClubInfoByAFMCallback)
      } else if (response === false) {// if club exists
        prop.getInfoMessageDialog('Η λέσχη Φιλάθλων έχει καταχωρηθεί.')
        this.$store.commit('setLoadingModal', false)
      }
    },

    submitWithoutAFM() {
      this.hasSeparateAFM = false;
      this.club = {};

      this.club.afm = this.organization_vat;
      this.club.hasSeparateAFM = this.hasSeparateAFM;
      this.getClubInfoByAFMCallback(this.club)
    },

    getClubInfoByAFMCallback(response) {
      this.club = response;
      this.club.hasSeparateAFM = this.hasSeparateAFM;
      this.onomasiaClubFromSevrice = this.club.onomasia

      //Comented: not needed
      // if(this.club.hasSeparateAFM){
      //   this.club.onomasia = ''
      // }

      this.e1++;
      // this.createMenu();
      this.$store.commit('setLoadingModal', false)
    },

    elegxosAFMCallback(response) {
      //from the response take organisation name
      this.organisation_name = response.onomasia


      //AFM is checked and user has a relation with that
      //step 2: check if AFM has KAD of athlitiko somateio bellow
      requests.getSecureRequest('checks/check-corp-kad/', this.organization_vat, this.checkCorpKadCallback)
    },
    checkCorpKadCallback(response) {
      this.e1++;
      this.$store.commit('setLoadingModal', false)
    },

    validKadCallback() {
      this.step = 2;
    },


    initData() {

      if (this.$store.getters.loggedinUser.user.user_type_id === 1) {//fisiko prosopo
        this.userIsPerson = true;
        this.e1 = 1;
        this.stepsNames.push('1')
        this.stepsNames.push('2')
        this.stepsNames.push('3')
      } else {//nomiko prosopo
        this.e1 = 1;
        this.stepsNames.push('')
        this.stepsNames.push('1')
        this.stepsNames.push('2')
      }


    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    createClub() {
      this.$store.commit('setLoadingModal', true)
      let obj = {
        organization: {
          vat: this.organization_vat,
          name: this.organisation_name,
        },
        club_user: {
          vat: this.definedRef.afm,
          identification_document: this.definedRef.adt,
          first_name: this.definedRef.name,
          last_name: this.definedRef.lastname,
          father_name: this.definedRef.fathername,
        },
        club: {
          vat: this.club.afm,
          name: this.club.onomasia,
          address: this.club.firmAddress,
          city: this.club.firmCity,
          postal_code: this.club.firmPostalCode,
        },
      }

      requests.postSecureRequest('club/', obj, this.createClubCallback);
    },
    createClubCallback(response) {
      prop.getInfoMessageDialog('Η λέσχη καταχωρήθηκε επιτυχώς.')
      this.e1 = 1
      this.$router.push({path: '/role-selection'})
      this.$store.commit('setLoadingModal', false);
    },
    openRepresentativeDialgog() {
      this.representativeDialog = true;
    },
    closeRepresentativesDialog() {
      this.rep = {}
      this.representativeDialog = false;
    },

    saveRepresentative() {
      requests.postSecureRequest('checks/validate-inserted-rep', this.rep, this.saveRepresentativeCallback)
    },
    saveRepresentativeCallback(response) {

      this.definedRef = response
      this.representativeDialog = false;
    },


    repFormIsValid() {
      return (!this.rep.name || this.rep.name.length === 0) ||
          (!this.rep.lastname || this.rep.lastname.length === 0) ||
          (!this.rep.fathername || this.rep.fathername.length === 0) ||
          (!this.rep.adt || this.rep.adt.length === 0) ||
          (!this.rep.afm || this.rep.afm.length === 0);
    },

    redirectToDashboard() {
      this.$router.push({path: '/role-selection'})
    },

    createMenu() {

      let menuItems = [
        {
          to: '/role-selection',
          name: 'Αρχική σελίδα'
        },
        {
          to: '/select-club',
          name: 'Χορήγηση συναίνεσης'
        },
        {
          to: '/nel-dashboard',
          name: 'Τροποποίηση υπευθύνου λειτουργίας Λέσχης'
        }];
      this.$store.commit('setMenuItems', menuItems);
    }

  },
  created() {
    this.initData();
  },
  computed: {
    isConfirmationButtonValid() {
      return (!this.club.onomasia || this.club.onomasia.length === 0) ||
          (!this.club.afm || this.club.afm.length === 0) ||
          (!this.definedRef || Object.keys(this.definedRef).length < 3)
    }
  }

}
</script>

<style scoped>

</style>
