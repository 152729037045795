<template>
  <v-main>
    <div>

      <v-row align="center" justify="center">
        <span
            class="headline">{{
            'Βρίσκεστε στο Μητρώο Λεσχών Φιλάθλων. Συνδεθείτε για να χρησιμοποιήσετε τις λειτουργίες της εφαρμογής.'
          }}</span>
      </v-row>
      <!--  <nav class="govgr-horizontal-nav">-->
      <!--    <div class="govgr-horizontal-nav__container">-->
      <!--      <a href="" class="govgr-horizontal-nav__item govgr-horizontal-nav__item-active"> Home </a>-->
      <!--      <a href="#" class="govgr-horizontal-nav__item"> Form registration </a>-->
      <!--      <a href="#" class="govgr-horizontal-nav__item"> News</a>-->
      <!--      <a href="#" class="govgr-horizontal-nav__item"> Contact </a>-->
      <!--    </div>-->
      <!--  </nav>-->
      <div class="govgr-width-container">
        <!--                <div class="govgr-main-wrapper">-->
        <!--                    <div class="govgr-grid-column-two-thirds">-->
        <!--                        &lt;!&ndash;        <h1 class="govgr-heading-xl">This is Heading 1</h1>&ndash;&gt;-->
        <!--                        <p class="govgr-body">Βρίσκεστε στο Μητρώο Λεσχών Φιλάθλων. Συνδεθείτε για να χρησιμοποιήσετε τις λειτουργίες της εφαρμογής.-->
        <!--                        </p>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--    <h1 class="govgr-heading-xl">Heading xl</h1>-->
        <!--    <h2 class="govgr-heading-l">Heading l</h2>-->
        <!--    <h3 class="govgr-heading-m">Heading m</h3>-->
        <!--    <h4 class="govgr-heading-s">Heading s</h4>-->


        <v-row class="mt-10" align="center" justify="center">
          <v-list align="center" justify="center">
            <v-list-item>
              <v-btn outlined class="elevation-8" min-width="400px" min-height="60px" @click="login(1)">Σύνδεση</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn outlined class="elevation-8 mt-4" min-width="400px" min-height="60px" @click="login(4)">Σύνδεση από
                ΓΓΑ
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn outlined class="elevation-8 mt-4" min-width="400px" min-height="60px" @click="login(5)">Σύνδεση από
                ΕΛΑΣ
              </v-btn>
            </v-list-item>
          </v-list>
        </v-row>
        <!--    <button class="govgr-btn govgr-btn-primary">Primary button</button>-->
        <!--    <button class="govgr-btn govgr-btn-secondary"> Secondary button </button>-->
        <!--    <button class="govgr-btn govgr-btn-warning"> Warning button </button>-->
      </div>

    </div>
  </v-main>
</template>

<script>
import requests from "@/requests";

export default {
  name: "HomePage",
  data: () => ({
    devMode: false,
    accessUrl: "",
  }),
  methods: {
    login(role) {

      if (role === 1 && this.devMode) {
        this.$router.push({path: '/dev-login'})
      } else if (role === 1 && !this.devMode) {
        window.open(this.accessUrl + 'fan-club-registry/external')
      } else if ((role === 4 || role === 5) && this.devMode) {
        this.$router.push({path: '/dev-login-pa'})
      } else if ((role === 4 || role === 5) && !this.devMode) {
        window.open(this.accessUrl + 'fan-club-registry/pa')
      }
    },
    getDevMode() {
      requests.postRequest('properties/dev-mode', {}, this.getDevModeCallback)
    },
    getDevModeCallback(response) {
      this.devMode = response.data
      this.getAccessUrl()
    },

    getAccessUrl() {
      requests.postRequest('properties/access-url', {}, this.getAccessUrlCallback)
    },

    getAccessUrlCallback(response) {
      this.accessUrl = response.data
    }
  },
  created() {
    this.getDevMode()
  }
}
</script>

<style scoped>

</style>
