<template>
  <div>
    <v-dialog max-width="500px" persistent scrollable v-model="$store.getters.addYourSelfAsMemberDialog">
      <v-card tile>
        <v-card-title class="primary">
          <span class="headline white--text">{{ 'Προσθέστε τον εαυτό σας ως μέλος' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="form" class="mt-2">
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="ΑΦΜ*" v-model.trim="$store.getters.loggedinUser.user.vat"
                                disabled required :rules="vatRule" counter="9" type="number"
                                hide-spin-buttons></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label='Όνομα*' v-model.trim="userBranchResponse.first_name"
                                disabled required :rules="nameRules"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="Επώνυμο*" v-model.trim="userBranchResponse.last_name"
                                disabled required :rules="nameRules"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="Πατρώνυμο*" v-model.trim="userBranchResponse.father_name"
                                disabled required :rules="nameRules"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="Μητρώνυμο"
                                v-model.trim="member.user_branches[0].mother_name"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-dialog
                      ref="dialog"
                      v-model="openDatePicker"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                          readonly
                          :value="member.user_branches[0].date_of_birth"
                          outlined
                          required
                          :rules="nameRules"
                          label="Ημερομηνία γέννησης*" dense clearable
                          v-on="on" @click:clear="clear"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="member.user_branches[0].date_of_birth"
                        scrollable
                        :max="true? new Date().toISOString().split('T')[0] : '' "
                        :active-picker.sync="activePicker"
                        locale="el"
                        color="primary">
                      <v-spacer></v-spacer>
                      <v-btn @click="closeDatePicker"
                             color="primary">
                        Κλείσιμο
                      </v-btn>
                      <v-btn
                          @click="selectDates"
                          color="primary">
                        Επιλογή
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="ΑΜΚΑ"
                                v-model.trim="member.user_branches[0].amka"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="Διεύθυνση*" v-model.trim="member.user_branches[0].address"
                                required :rules="nameRules"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="Δήμος*" v-model.trim="member.user_branches[0].city"
                                required :rules="nameRules"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="Ταχυδρομικός κώδικας*"
                                v-model.trim="member.user_branches[0].postal_code" required
                                :rules="nameRules"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="Τηλέφωνο"
                                v-model.trim="member.user_branches[0].phone_number"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="Email"
                                v-model.trim="member.user_branches[0].email"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="Αριθμός Ταυτότητας*"
                                v-model.trim="userBranchResponse.identification_document" disabled required
                                :rules="nameRules"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field dense color="primary" label="Αριθμός Μέλους*"
                                v-model.trim="member_number" required :rules="nameRules"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                      v-model="affirmation"
                      label="Επιβεβαιώνεται οτι η λέσχη διαθέτει υπεύθυνη δήλωση περί μη συνδρομής νομικού κωλύματος για την εγγραφή;"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="saveDisabled" @click="save()" color="primary" text>{{ 'Αποθήκευση' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "@/requests";

export default {
  name: "AddYourSelfAsMember",
  //watcher for the birthday - date picker
  watch: {
    openDatePicker(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  computed: {
    saveDisabled() {
      // if(this.member.confirmed != true){
      //   return true
      // }
      if (this.affirmation == false) {
        return true
      }
      if (this.valid == false) {
        return true
      }
      if (!Object.hasOwn(this.member.user_branches[0], 'father_name')) return true

      return false
    },
  },
  data: () => ({
    member: {
      vat: '',
      user_branches: [
        {
          first_name: '',
          last_name: '',
          father_name: '',
          identification_document: '',
          mother_name: '',
          date_of_birth: '',
          amka: '',
          address: '',
          city: '',
          postal_code: '',
          phone_number: '',
          email: '',
          member_number: '',
          is_valid: '',
          affirmation: '',
        }
      ]
    },
    userBranchResponse: {},
    singleSelectedDate: '',
    openDatePicker: false,

    //variable for the birthday - date picker
    activePicker: null,
    valid: false,
    identityCardTypes: [],
    affirmation: false,
    hasAffirmation: false,
    member_number: '',
    nameRules: [
      v => !!v || 'Το πεδίο είναι απαραίτητο.',
      v => !!v && v.trim() != '' || 'Το πεδίο είναι απαραίτητο.',
      v => !!v && (v.trim() == '' || v.trim().length >= 3) || 'Το πεδίο πρέπει να έχει τουλάχιστον τρεις χαρακτήρες.',
    ],
    vatRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο',
      v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
    ],
  }),
  methods: {
    save(newMember) {
      this.$store.commit('setLoadingModal', true);
      this.member.vat = this.$store.getters.loggedinUser.user.vat
      this.member.user_branches[0].first_name = this.userBranchResponse.first_name
      this.member.user_branches[0].last_name = this.userBranchResponse.last_name
      this.member.user_branches[0].father_name = this.userBranchResponse.father_name
      this.member.user_branches[0].identification_document = this.userBranchResponse.identification_document
      this.member.user_branches[0].affirmation = this.affirmation
      this.member.member_number = this.member_number
      if (!Object.hasOwn(this.member, 'father_name') || this.member.father_name == '' || this.member.father_name == 'null') this.member.father_name = null
      if (!Object.hasOwn(this.member, 'mother_name') || this.member.mother_name == '' || this.member.father_name == 'null') this.member.mother_name = null
      if (!Object.hasOwn(this.member, 'identification_document')) this.member.identification_document = null
      if (this.$store.getters.loggedinUser.role == "YLPL") {
        this.member.branch = this.$store.getters.selectedBranchYPL
      } else {
        this.member.branch = this.$store.getters.selectedClub.myBranchesOnClub[0]
      }
      requests.postSecureRequest('user/createMember', this.member, this.createMemberCallback)
    },
    createMemberCallback(response) {
      this.newMember = false
      this.member = {
        vat: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
            mother_name: '',
            date_of_birth: '',
            amka: '',
            address: '',
            city: '',
            postal_code: '',
            phone_number: '',
            email: '',
            member_number: '',
            is_valid: '',
            affirmation: '',
          }
        ]
      },
          this.dialog = false
      this.onEdit = false
      this.affirmation = false
      this.edit = false
      this.hasAffirmation = false
      this.member_number = ''

      /*if(Object.keys(this.selectedBranch).length != 0){
        this.members = []
      }*/
      this.checkLoggedInUserIsValidMember()
    },

    checkLoggedInUserIsValidMember() {
      this.$store.commit('setLoadingModal', true)
      if (this.$store.getters.loggedinUser.role == "YLPL") {
        requests.postSecureRequest('branch/check-logged-in-user-is-valid-member', {branch_id: this.$store.getters.selectedBranchYPL.id}, this.checkLoggedInUserIsValidMemberCallback)
      } else {
        requests.postSecureRequest('branch/check-logged-in-user-is-valid-member', {branch_id: this.$store.getters.selectedClub.myBranchesOnClub[0].id}, this.checkLoggedInUserIsValidMemberCallback)
      }
    },

    checkLoggedInUserIsValidMemberCallback(response) {
      if (!response.is_valid_member) {
        this.userBranchResponse = response.user_branch

        this.$store.commit('setAddYourSelfAsMemberDialog', true)
      } else {
        this.$store.commit('setAddYourSelfAsMemberDialog', false)
      }
      this.$store.commit('setLoadingModal', false)
    },

    closeDatePicker() {
      this.singleSelectedDate = '';
      this.openDatePicker = false;
    },

    selectDates() {
      this.openDatePicker = false;
    },

    clear() {
      this.singleSelectedDate = '';

    },
  },
  created() {
    this.checkLoggedInUserIsValidMember()
  }
}
</script>


<style scoped>

</style>
