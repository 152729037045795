<template>
  <div class="mb-6">
    <v-card min-width="600">
      <v-card-title>
        <v-toolbar flat>
          <v-row align="center">
            <v-col cols="2">
              <v-card color="primary"
                      class="mb-12"
                      height="80"
                      width="195">
                <v-card-title style="word-break: normal" class="white--text">
                  {{ 'Ιστορικό λεσχών' }}
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="3">
              <v-col cols="10">
                <v-select label="Επιλογή λέσχης"
                          return-object
                          :items="clubs"
                          :item-text="item =>item.name"
                          dense
                          no-data-text="Δεν υπάρχουν καταχωρήσεις"
                          v-model="selectedClub"
                          @change="getSeasons()"
                >
                </v-select>
              </v-col>
            </v-col>
            <v-col cols="3">
              <v-col cols="10">
                <v-select label="Επιλογή περιόδου/έτους"
                          return-object
                          :items="seasons"
                          item-text="season"
                          dense
                          no-data-text="Δεν υπάρχουν καταχωρήσεις"
                          :disabled="noSelectedClub || noSeasons"
                          v-model="selectedSeason"
                          @change="saveSeasonToStore()"
                >
                </v-select>
              </v-col>
            </v-col>
          </v-row>
        </v-toolbar>
      </v-card-title>
      <v-card-text v-if="!noSelectedClub && !noSeasons">
        <v-tabs v-model="tab" fixed-tabs>
          <v-tab href="#tab-1" :style="tab=='tab-1' ? 'background-color: aliceblue;' : 'background-color: '">
            Αρχικη κατασταση
          </v-tab>
          <v-tab href="#tab-2" :style="tab=='tab-2' ? 'background-color: aliceblue;' : 'background-color: '">
            Μεταγενεστερες Ενεργειες
          </v-tab>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1" class="mt-4">
              <ClubAndHistoryInfoForNeas :key="$store.getters.selectedSeasonKey" />
            </v-tab-item>
            <v-tab-item value="tab-2" class="mt-4">
              <ActionsAfterApproval />
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-card-text>
      <v-card-title v-if="noSeasons && !noSelectedClub" class="justify-center">
        {{'Δεν βρέθηκε περίοδος/έτος με εγκεκριμένη αίτηση για τη συγκεκριμένη λέσχη φιλάθλων.'}}
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import ActionsAfterApproval from "@/components/NEAS/ActionsAfterApproval.vue";
import ClubAndHistoryInfoForNeas from "@/components/NEAS/ClubAndHIstoryInfoForNeas.vue";
import requests from "@/requests";

export default {
  name: "HistoryOfClubsForOfficials",
  components: {ActionsAfterApproval, ClubAndHistoryInfoForNeas},
  data: () => ({
    selectedSeason:'0',
    seasons:[],
    tab:'',
    noSeasons: true,
    message:'',
    clubs:[],
    selectedClub:{},
    noSelectedClub: true,
  }),
  methods: {
    getClubs() {
      this.$store.commit('setLoadingModal',true)
      requests.getSecureRequest('club/','', this.getClubsCallback)
    },
    getClubsCallback(response) {
      this.clubs =[]
      this.clubs = response
      this.$store.commit('setLoadingModal',false)
    },
    saveSeasonToStore() {
      this.$store.commit('setSelectedSeason', this.selectedSeason)
      let count = this.$store.getters.selectedSeasonKey
      count+=1
      this.$store.commit('setSelectedSeasonKey', count)
    },

    getSeasons() {
      this.$store.commit('setLoadingModal',true)
      let requestObj = {
        club_id: this.selectedClub.id
      }
      requests.postSecureRequest('history/get-seasons',requestObj, this.getSeasonsCallback)
    },

    getSeasonsCallback(response) {
      this.noSelectedClub = false
      if(response.hasOwnProperty('message')) {
        this.seasons = []
        this.noSeasons = true
        this.message = response.message
      } else {
        this.noSeasons = false
        this.seasons = response
        this.selectedSeason = response[0];
        this.$store.commit('setSelectedSeason', this.selectedSeason)
        let count = this.$store.getters.selectedSeasonKey
        count+=1
        this.$store.commit('setSelectedSeasonKey', count)
      }
      this.$store.commit('setLoadingModal',false)
    }

  },
  beforeDestroy() {
    this.$store.commit('setSelectedSeasonKey',0)
  },
  created() {
    this.getClubs();
  }
}
</script>

<style scoped>

</style>