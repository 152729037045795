<template>

  <v-dialog max-width="400px" persistent v-model="$store.getters.errorDialog">
    <v-card>
      <v-card-title class="justify-center red">
        <v-icon color="white">mdi-alert-circle-outline</v-icon>
      </v-card-title>
      <v-card-text class="mt-4 govgr-!-font-size-19">
        {{$store.getters.errorDialogObj.text}}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeErrorDialog()">Κλείσιμο</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: "ErrorDialog",

  methods: {
    closeErrorDialog() {
      this.$store.commit('setErrorDialog', false)
    }
  }
}

</script>


<style scoped>

</style>