<template>
    <v-dialog persistent
              @keydown.esc="closeDialog"
              v-model="$store.getters.confirmationDialog"
              :retain-focus="false"
              width="600">
        <v-card>
            <v-card-title class="primary">
                <span class="headline white--text">Ερώτηση επιβεβαίωσης</span>
            </v-card-title>

            <v-card-text>
              <v-row v-if="!reject" align="center" justify="center" dense>
                <v-col>
                  <v-file-input class="ma-5" small-chips v-model="file" :accept="['.pdf', '.png', '.jpg', '.jpeg']"  label="Αρχείο βεβαίωσης νόμιμης λειτουργίας" append-icon="mdi-file-excel" ></v-file-input>
                </v-col>
              </v-row>
              <v-row v-else>
                <h3 class="mt-6">
                  Είστε σίγουρος/η;
                </h3>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                        color="primary"
                        text
                        @click="closeDialog">
                    Άκυρο
                </v-btn>
                <v-btn
                    color="primary"
                    :disabled="!reject && file==null"
                    @click="confirmItem">
                    Επιβεβαίωση
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    import {eventBus} from "../../main";
    import requests from "@/requests";
    export default {
        name: "ConfirmationDialog",
        data: () => ({
          file:null,
        }),
        props: {
          eventName: String,
          applicationId: Number,
          reject: Boolean,
        },

        methods: {

          closeDialog() {
              this.$store.commit('setConfirmationDialog', false)
          },

          confirmItem(){
            if(this.reject){
              this.$emit('reject');
            } else {
              this.uploadFile()
            }
          },



          uploadFile() {
            //this.$store.commit('setLoadingModal',true)
            let formData = new FormData();
            formData.append('file', this.file,  encodeURI(this.file.name));
            formData.append('applicationId', this.applicationId );
            this.uploadDialog = false
            requests.uploadFilePostAsync('application/upload-praktiko-approval', formData, this.uploadFileCallback)
          },

          uploadFileCallback(response) {
            this.$store.commit('setLoadingModal',false)
            this.$store.commit('setConfirmationDialog', false);
            //eventBus.$emit(this.eventName);
            this.$emit('approval')
          }
        }
    }
</script>

<style scoped>

</style>
