<template>
  <div>
    <v-card min-width="600" flat>
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-data-table
                :page.sync="pageNumber"
                :footer-props="{page:1,
                              pageCount:1,
                              showCurrentPage:true,
                              itemsPerPageOptions:[5,10,15,30],
                              showFirstLastPage:true,
                              itemsPerPageText:'Ενέργειες ανά σελίδα:',
                              itemsPerPageAllText:'Όλα'}"
                no-data-text="Δέν υπάρχουν αποτελέσματα."
                :headers="headers"
                :items="actions"
                :items-per-page="itemsPerPage"
                :server-items-length="tableLength"
                @update:options="getBuildingPerPage"
                class="elevation-3 mx-5 px-2"
            >
              <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-card color="primary"
                          class="mb-5"
                          height="80"
                          width="225">
                    <v-card-title style="word-break: normal" class="white--text">
                      {{ 'Ιστορικό ενεργειών' }}
                    </v-card-title>
                  </v-card>
                </v-toolbar>
              </template>
              <template v-slot:item="{item}">
                <tr>
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.action_time }}
                  </td>
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ item.actiontype.name }}
                  </td>
                  <td class="text-center d-sm-table-cell d-flex">
                    <!--                  <a @click="openActionInfoDialog(item.action_text)">Διαβάστε περισσότερα...</a>-->
                    <v-tooltip left>
                      <template v-slot:activator="{on}">
                        <v-btn v-on="on" fab x-small @click="openActionInfoDialog(item.action_text)">
                          <v-icon large color="primary">mdi-information-variant</v-icon>
                        </v-btn>
                      </template>
                      <span>
                      Λεπτομέρειες ενέργειας
                    </span>
                    </v-tooltip>
                  </td>
                  <td class="text-center d-sm-table-cell d-flex">
                    {{ (item.user.first_name != null ? item.user.first_name : '') + ' ' + item.user.last_name }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="actionInfoDialog" width="700px">
      <v-card>
        <v-card-title style="background-color: #003375; word-break: normal" class="white--text">
          <v-row align="center" justify="center" class="ma-2 text-h4">
            Πληροφορίες ενέργειας
          </v-row>
        </v-card-title>
        <v-card-text class="mt-2 govgr-!-font-size-19">
          {{ this.actionInfo }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="actionInfoDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import requests from "@/requests";
import utilities from "@/utilities";

export default {
  name: "HistoryOfActionsForNEAS",
  data: () => ({
    actions: [],
    actionInfo: '',
    actionInfoDialog: false,
    itemsPerPage: 5,
    tableLength: 0,
    pageNumber: 1,
    headers: [
      {
        text: 'Ημερομηνία',
        align: 'center',
        sortable: false,
        value: 'action_time',
      },
      {
        text: 'Ενέργεια',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Λεπτομέρειες',
        align: 'center',
        sortable: false,
        value: 'action_text',
      },
      {
        text: 'Χρήστης',
        align: 'center',
        sortable: false,
        value: 'name',
      },
    ]
  }),
  methods: {
    getActions() {
      this.$store.commit('setLoadingModal', true);
      let requestObj = {
        actiontype_id: null,
        responsible_user_id: null,
        club_id: this.$store.getters.selectedClub.id,
        userrole_id: null,
        branchrole_id: 1,
        limit: this.itemsPerPage,
        offset: this.pageNumber - 1
      }
      requests.postSecureRequest('action/get-actions', requestObj, this.getActionsCallback)
    },

    getActionsCallback(response) {
      this.actions = [];
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.actions.length; i++) {
        res.actions[i].action_time = utilities.timestampToDatetime(res.actions[i].action_time);
        this.actions.push(res.actions[i])
      }
      this.tableLength = res.all;
      this.$store.commit('setLoadingModal', false);
    },

    getBuildingPerPage(item) {
      this.$store.commit('setLoadingModal', true)

      this.pageNumber = item.page
      this.itemsPerPage = item.itemsPerPage
      this.printDataTableOptions()

      let requestObj = {
        actiontype_id: null,
        responsible_user_id: null,
        club_id: this.$store.getters.selectedClub.id,
        userrole_id: null,
        branchrole_id: 1,
        limit: this.itemsPerPage,
        offset: this.pageNumber - 1
      }

      requests.postSecureRequest('action/get-actions', requestObj, this.getActionsCallback)
    },

    printDataTableOptions() {
      console.log('Page: ' + this.pageNumber + '\nItems per page: ' + this.itemsPerPage)
    },

    openActionInfoDialog(item) {
      this.actionInfo = item;
      this.actionInfoDialog = true;

    }
  },
  created() {
    this.getActions();
  },

}


</script>

<style scoped>

</style>
