var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCriteria),expression:"showCriteria"}],staticClass:"mx-10"},[_c('v-card-text',[_c('SearchCriteria',{attrs:{"eventName":'asGGA'}})],1)],1),_c('v-row',{staticClass:"mt-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-6 mx-5 px-2",attrs:{"footer-props":{page:1,
                                          pageCount:1,
                                          showCurrentPage:true,
                                          itemsPerPageOptions:[5,10,15,30],
                                          showFirstLastPage:true,
                                          itemsPerPageText:'Αιτήσεις ανά σελίδα:',
                                          itemsPerPageAllText:'Όλα'},"no-data-text":"Δέν υπάρχουν αποτελέσματα.","headers":_vm.headers,"items":_vm.applications,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.tableLength,"page":_vm.pageNumber},on:{"update:page":function($event){_vm.pageNumber=$event},"update:options":_vm.getBuildingPerPage}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }