<template>
    <v-snackbar v-model="isShow">
        {{ $store.getters.snackBarObj.text }}
        <v-btn
                color="pink"
                text
                @click="closeSnackBar">
            Κλείσιμο
        </v-btn>
    </v-snackbar>
</template>

<script>
    export default {
        name: "ErrorSnackBar",

        data: () => ({
            isShow: true
        }),
        methods: {

            closeSnackBar() {

                this.$store.commit('setSnackBar', false);
            }
        },
        watch: {
            'isShow': function(newValue, oldValue) {
                if (!newValue) {
                    this.$store.commit('setSnackBar', false);
                }
            }
        }
    }
</script>

<style scoped>

</style>
