<template>
  <div>
    <v-row>
      <v-col class="pt-1">
        <v-data-table
            :footer-props="{page:1,
                            pageCount:1,
                            showCurrentPage:true,
                            itemsPerPageOptions:[5,10,15,30],
                            showFirstLastPage:true,
                            itemsPerPageText:'Αιτήσεις ανά σελίδα:',
                            itemsPerPageAllText:'Όλα'}"
            no-data-text="Δέν υπάρχουν αποτελέσματα."
            :headers="headers"
            :items="clubApplications"
            :search="search"
            class="elevation-3 mx-5 px-2"
            sort-by="calories"
        >
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>

              <v-card color="primary"
                      class="mb-5"
                      height="80"
                      width="90">
                <v-row align="center"
                       justify="center">
                  <v-col class=" mt-4  text-center white--text">
                    {{ 'Ιστορικό αιτήσεων' }}
                  </v-col>
                </v-row>
              </v-card>
              <v-toolbar-title>
                <br/>
                <v-text-field class="ml-5 mb-1"
                              append-icon="mdi-magnify"
                              hide-details
                              label="Αναζήτηση ..."
                              single-line
                              v-model="search"
                ></v-text-field>
              </v-toolbar-title>
              <!--              <v-divider-->
              <!--                  class="mx-4"-->
              <!--                  inset-->
              <!--                  vertical-->
              <!--              ></v-divider>-->
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item="{item}">
            <tr>
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.id }}
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                <v-chip
                    v-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.APPROVED"
                    class="white--text" color="green">
                  {{ item.applicationstatus.name }}
                </v-chip>
                <v-chip v-else-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.CANCELED ||
                                  item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.REJECTED"
                        class="white--text" color="red">
                  {{ item.applicationstatus.name }}
                </v-chip>
                <v-chip v-else>
                  {{ item.applicationstatus.name }}
                </v-chip>
              </td>
              <td v-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.APPROVED"
                  class="text-center d-sm-table-cell d-flex">
                <v-tooltip left>
                  <template v-slot:activator="{on}">
                    <v-btn class="mt-4r" v-on="on" color="primary" icon @click="getApprovalDocument(item)">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>
                      Βεβαίωση νόμιμης λειτουργίας
                  </span>
                </v-tooltip>
              </td>
              <td v-else class="text-center d-sm-table-cell d-flex">
                {{ '-' }}
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                <v-tooltip left>
                  <template v-slot:activator="{on}">
                    <v-btn fab
                           small
                           v-on="on"
                           @click="getApplicationHistory(item)">
                      <v-icon color="primary">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                      Λεπτομέρειες
                    </span>
                </v-tooltip>
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                <v-tooltip left>
                  <template v-slot:activator="{on}">
                    <v-btn fab
                           small
                           v-on="on"
                           @click="openApplicationDialog(item)">
                      <v-icon color="primary">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                      Προβολή αίτησης
                    </span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="applicationDialog" fullscreen>
      <v-card>
        <v-card-title class="primary white--text">
          Πληροφορίες αίτησης με αναγνωριστικό: #{{ applicationId }}
          <v-spacer/>
          <v-btn icon color="white" @click="applicationDialog = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ShowApplication :applicationObject="applicationObject" :applicationId="applicationId" :user="user"
                           :checkBox="false"/>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="800px" persistent v-model="rejectionReasonDialog">
      <v-card>
        <v-card-title style="word-break: normal" class="primary white--text">
          Αιτία απόρριψης αίτησης
        </v-card-title>
        <v-card-text class="mt-4 govgr-!-font-size-19">
          {{ rejectionReason }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="rejectionReasonDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog width="900px" persistent v-model="applicationHistoryDialog">
      <v-card v-if="applicationHistory.length > 0">
        <v-card-title style="word-break: normal" class="primary white--text">
          Ιστορικό αίτησης με αναγνωριστικό #{{ applicationHistory[0].application_id }}
        </v-card-title>
        <v-card-text class="mt-4 govgr-!-font-size-19">
          <v-data-table
              :footer-props="{page:1,
                            pageCount:1,
                            showCurrentPage:true,
                            itemsPerPageOptions:[5,10,15,30],
                            showFirstLastPage:true,
                            itemsPerPageText:'Αιτήσεις ανά σελίδα:',
                            itemsPerPageAllText:'Όλα'}"
              no-data-text="Δέν υπάρχουν αποτελέσματα."
              :headers="historyHeaders"
              :items="applicationHistory"
              class="elevation-3 mx-5 px-2"
              sort-by="calories"
          >
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:item="{item}">
              <tr>
                <td class="text-center d-sm-table-cell d-flex">
                  <p v-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.APPROVED">
                    {{ (item.approval_date != null) ? item.approval_date : '-' }}
                  </p>
                  <p v-else-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.REJECTED">
                    {{ (item.rejection_date != null) ? item.rejection_date : '-' }}
                  </p>
                  <p v-else-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.SUBMITTED">
                    {{ (item.submission_date != null) ? item.submission_date : '-' }}
                  </p>
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  <v-chip
                      v-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.APPROVED"
                      class="white--text" color="green">
                    {{ item.applicationstatus.name }}
                  </v-chip>
                  <v-chip v-else-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.CANCELED ||
                                  item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.REJECTED"
                          class="white--text" color="red">
                    {{ item.applicationstatus.name }}
                  </v-chip>
                  <v-chip v-else>
                    {{ item.applicationstatus.name }}
                  </v-chip>
                </td>
                <td class="text-center d-sm-table-cell d-flex"
                    v-if="item.rejection_file_name != null &&
                    item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.REJECTED">
                  <v-tooltip left>
                    <template v-slot:activator="{ download }">
                      <v-btn color="primary" fab small v-on="download" @click="downloadRejectionFile(item)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Αιτία απόρριψης</span>
                  </v-tooltip>
                </td>
                <td class="text-center d-sm-table-cell d-flex"
                    v-else-if="item.approval_file_name != null &&
                    item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.APPROVED">
                  <v-tooltip left>
                    <template v-slot:activator="{ download }">
                      <v-btn color="primary" fab small v-on="download" @click="downloadApprovedFile(item)">
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Αιτία απόρριψης</span>
                  </v-tooltip>
                </td>
                <td class="text-center d-sm-table-cell d-flex" v-else>
                  {{ '-' }}
                </td>
                <td v-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.REJECTED"
                    class="text-center d-sm-table-cell d-flex">
                  <v-btn fab x-small @click="openRejectionReason(item)">
                    <v-icon large color="primary">mdi-information-variant</v-icon>
                  </v-btn>
                </td>
                <td v-else class="text-center d-sm-table-cell d-flex">
                  {{ '-' }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="applicationHistoryDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-else>
        <v-card-title style="word-break: normal" class="primary white--text">
          Ιστορικό αίτησης
        </v-card-title>
        <v-card-text class="mt-4 govgr-!-font-size-19">
          {{ 'Δεν υπάρχει ιστορικό για την συγκεκριμένη αίτηση.' }}
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="applicationHistoryDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import requests from "@/requests";
import router from "@/router";
import ShowApplication from "@/components/NEL/ShowApplication.vue";

export default {
  name: "HistoryOfClub",
  components: {ShowApplication},
  created() {
    this.getClubApplications()
  },
  data: () => ({
    applicationDialog: false,
    applicationId: null,
    applicationObject: {},
    user: {},
    search: '',
    clubApplications: [],
    headers: [
      {
        text: 'Αρ. αίτησης',
        align: 'center',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Βεβαίωση νόμιμης λειτουργίας',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Ιστορικό αίτησης',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Προβολή αίτησης',
        align: 'center',
        sortable: false,
        value: 'utilities'
      }
    ],
    historyHeaders: [
      {
        text: 'Ημερομηνία',
        align: 'center',
        sortable: true,
        value: 'center',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Αρχείο',
        align: 'center',
        sortable: false,
        value: 'file',
      },
      {
        text: 'Αιτία απόρριψης',
        align: 'center',
        sortable: false,
        value: 'status',
      },
    ],
    rejectionReason: '',
    rejectionReasonDialog: false,
    applicationHistory: [],
    applicationHistoryDialog: false,
  }),
  methods: {
    getClubApplications() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {club: this.$store.getters.selectedClub}

      requests.postSecureRequest('application/all-club-applications', requestObj, this.getClubApplicationsCallback)
    },
    getClubApplicationsCallback(response) {

      this.clubApplications = response
      this.$store.commit('setLoadingModal', false)
    },

    redirectToApplicationInfo(item) {

      this.$store.commit('setSelectedApplicationForInfo', item)

      router.push('/application-info')
    },

    downloadRejectionFile(item) {
      requests.downloadRejectionFileRequest('application/download-rejection-file', item.rejection_file_name)
    },

    downloadApprovedFile(item) {
      requests.downloadRejectionFileRequest('application/download-rejection-file', item.approval_file_name)
    },

    openApplicationDialog(item) {
      this.applicationObject = item
      this.applicationId = item.id
      this.user = item.user
      this.applicationDialog = true
    },

    openRejectionReason(item) {
      this.rejectionReason = item.rejection_reason
      this.rejectionReasonDialog = true
    },
    getApprovalDocument(item) {
      requests.downloadFileRequestWithObj('application/get-approval-file', {application_id: item.id})
    },

    getApplicationHistory(item) {
      requests.postSecureRequest('application/get-history', {application_id: item.id}, this.getApplicationHistoryCallback)
    },
    getApplicationHistoryCallback(response) {
      this.applicationHistory = []
      this.applicationHistory = response
      this.applicationHistoryDialog = true
    }
  }
}
</script>

<style scoped>

</style>
