import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        login: '',
        loggedinUser: {},
        clubs: [],
        loadingModal: false,
        snackBar: false,
        snackBarObj: {},
        myClubsDialog: false,
        myClubs: [],
        submitedApplications: [],
        selectedClub: {},
        selectedApplication: {},
        editGGAApplicationDialog: false,
        confirmationDialog: false,
        checkedRepresentative: false,
        infoSnackBar: false,
        infoSnackBarObj: {},
        deleteDialog: false,
        selectedApplicationForInfo: {},
        selectedBranchYPL: {},
        organization: {},
        homePath: '',
        menuItems: [],
        memberfileParsingInterval: false,
        memberfileParsingIntervalObj: {user_branch: {branch: {club: {}}}},
        memberfileParsingIntervalDialog: false,
        initializationNEAS: {organization_vat: '', club_vat: ''},
        dev_mode: false,
        thereWasAChangeInApplication: false, //Αν γίνει οποιδήποτε αλλαγή στο stepper γινεται true, για να εμφανισουμε dialog αποθηκευσης μετα
        errorDialog: false,
        errorDialogObj: {},
        infoDialog: false,
        infoDialogObj: {},
        ggaApplication: {
            id: '',
            name: ''
        },
        ggaApplicationId: null,
        ggaApplicationProgress: null,
        ggaApplicationDate: '',
        clubStatusToCheckForDissolution: null,
        isTimeForApplications: true,
        selectedSeason: '',
        selectedSeasonKey: 0,
        notificationIcon: false,
        notificationNumber: 0,
        addYourSelfAsMemberDialog: false,
        notifications: [],
        actionTypes: [],
        readNotifications: null,
        accessUrl: '',

    },

    getters: {
        login: state => state.login,
        loggedinUser: state => state.loggedinUser,
        clubs: state => state.clubs,
        loadingModal: state => state.loadingModal,
        snackBar: state => state.snackBar,
        infoSnackBar: state => state.infoSnackBar,
        snackBarObj: state => state.snackBarObj,
        infoSnackBarObj: state => state.infoSnackBarObj,
        myClubsDialog: state => state.myClubsDialog,
        selectedClub: state => state.selectedClub,
        myClubs: state => state.myClubs,
        submitedApplications: state => state.submitedApplications,
        deleteDialog: state => state.deleteDialog,
        selectedApplication: state => state.selectedApplication,
        editGGAApplicationDialog: state => state.editGGAApplicationDialog,
        confirmationDialog: state => state.confirmationDialog,
        checkedRepresentative: state => state.checkedRepresentative,
        selectedApplicationForInfo: state => state.selectedApplicationForInfo,
        selectedBranchYPL: state => state.selectedBranchYPL,
        organization: state => state.organization,
        homePath: state => state.homePath,
        menuItems: state => state.menuItems,
        memberfileParsingInterval: state => state.memberfileParsingInterval,
        memberfileParsingIntervalObj: state => state.memberfileParsingIntervalObj,
        memberfileParsingIntervalDialog: state => state.memberfileParsingIntervalDialog,
        initializationNEAS: state => state.initializationNEAS,
        dev_mode: state => state.dev_mode,
        thereWasAChangeInApplication: state => state.thereWasAChangeInApplication,
        errorDialog: state => state.errorDialog,
        errorDialogObj: state => state.errorDialogObj,
        infoDialog: state => state.infoDialog,
        infoDialogObj: state => state.infoDialogObj,
        ggaApplication: state => state.ggaApplication,
        ggaApplicationId: state => state.ggaApplicationId,
        ggaApplicationDate: state => state.ggaApplicationDate,
        ggaApplicationProgress: state => state.ggaApplicationProgress,
        clubStatusToCheckForDissolution: state => state.clubStatusToCheckForDissolution,
        isTimeForApplications: state => state.isTimeForApplications,
        selectedSeason: state => state.selectedSeason,
        notificationIcon: state => state.notificationIcon,
        notificationNumber: state => state.notificationNumber,
        selectedSeasonKey: state => state.selectedSeasonKey,
        addYourSelfAsMemberDialog: state => state.addYourSelfAsMemberDialog,
        notifications: state => state.notifications,
        actionTypes: state => state.actionTypes,
        readNotifications: state => state.readNotifications,
        accessUrl: state => state.accessUrl,
    },
    mutations: {
        setLogin: function (state, loginData) {
            state.login = loginData
        },
        setLoggedinUser: function (state, data) {
            state.loggedinUser = data
        },
        setClubs: function (state, data) {
            state.clubs = data
        },
        setLoadingModal: function (state, data) {
            state.loadingModal = data
        },
        setSnackBarObj: function (state, snackBarObjData) {
            state.snackBarObj = snackBarObjData
        },
        setSnackBar: function (state, snackBarData) {
            state.snackBar = snackBarData
        },
        setMyClubsDialog: function (state, data) {
            state.myClubsDialog = data
        },
        setSelectedClub: function (state, data) {
            state.selectedClub = data
        },
        setMyClubs: function (state, data) {
            state.myClubs = data
        },
        setSubmitedApplications: function (state, data) {
            state.submitedApplications = data
        },
        setSelectedApplication: function (state, data) {
            state.selectedApplication = data
        },
        setEditGGAApplicationDialog: function (state, data) {
            state.editGGAApplicationDialog = data
        },
        setConfirmationDialog: function (state, data) {
            state.confirmationDialog = data
        },
        setCheckedRepresentative: function (state, data) {
            state.checkedRepresentative = data
        },
        setInfoSnackBar: function (state, data) {
            state.infoSnackBar = data
        },
        setInfoSnackBarObj: function (state, infoSnackBarObjData) {
            state.infoSnackBarObj = infoSnackBarObjData
        },
        setDeleteDialog: function (state, data) {
            state.deleteDialog = data
        },
        setSelectedApplicationForInfo: function (state, data) {
            state.selectedApplicationForInfo = data
        },
        setSelectedBranchYPL: function (state, data) {
            state.selectedBranchYPL = data
        },
        setOrganization: function (state, organization) {
            state.organization = organization
        },
        setHomePath: function (state, data) {
            state.homePath = data
        },
        setMenuItems: function (state, data) {
            state.menuItems = data
        },
        setMemberfileParsingInterval: function (state, data) {
            state.memberfileParsingInterval = data
        },
        setMemberfileParsingIntervalObj: function (state, data) {
            state.memberfileParsingIntervalObj = data
        },
        setMemberfileParsingIntervalDialog: function (state, data) {
            state.memberfileParsingIntervalDialog = data
        },
        setInitializationNEAS: function (state, data) {
            state.initializationNEAS = data
        },
        setDevMode: function (state, data) {
            state.dev_mode = data
        },
        setThereWasAChangeInApplication: function (state, thereWasAChangeInApplication) {
            state.thereWasAChangeInApplication = thereWasAChangeInApplication
        },
        setErrorDialog: function (state, data) {
            state.errorDialog = data
        },
        setErrorDialogObj: function (state, errorDialogData) {
            state.errorDialogObj = errorDialogData
        },
        setInfoDialog: function (state, data) {
            state.infoDialog = data
        },
        setInfoDialogObj: function (state, infoDialogData) {
            state.infoDialogObj = infoDialogData
        },
        setggaApplication: function (state, ggaApplication) {
            state.ggaApplication = ggaApplication
        },
        setGgaApplicationId: function (state, ggaApplicationId) {
            state.ggaApplicationId = ggaApplicationId
        },
        setGgaApplicationDate: function (state, ggaApplicationDate) {
            state.ggaApplicationDate = ggaApplicationDate
        },
        setGgaApplicationProgress: function (state, ggaApplicationProgress) {
            state.ggaApplicationProgress = ggaApplicationProgress
        },
        setClubStatusToCheckForDissolution: function (state, clubStatusToCheckForDissolution) {
            state.clubStatusToCheckForDissolution = clubStatusToCheckForDissolution
        },
        setIsTimeForApplications: function (state, isTimeForApplications) {
            state.isTimeForApplications = isTimeForApplications
        },
        setSelectedSeason: function (state, selectedSeason) {
            state.selectedSeason = selectedSeason
        },
        setNotificationIcon: function (state, notificationIcon) {
            state.notificationIcon = notificationIcon
        },
        setNotificationNumber: function (state, notificationNumber) {
            state.notificationNumber = notificationNumber
        },
        setSelectedSeasonKey: function (state, selectedSeasonKey) {
            state.selectedSeasonKey = selectedSeasonKey
        },
        setAddYourSelfAsMemberDialog: function (state, addYourSelfAsMemberDialog) {
            state.addYourSelfAsMemberDialog = addYourSelfAsMemberDialog
        },
        setNotifications: function (state, notifications) {
            state.notifications = notifications
        },
        setActionTypes: function (state, data) {
            state.actionTypes = data
        },
        setReadNotifications: function (state, data) {
            state.readNotifications = data
        },
        setAccessUrl: function (state, data) {
            state.accessUrl = data
        },
    },
    actions: {},
    modules: {}
});
