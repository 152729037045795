var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{style:(_vm.tab=='tab-1' ? 'background-color: aliceblue;' : 'background-color: '),attrs:{"href":"#tab-1"},on:{"click":function($event){return _vm.getCouncilMembersChanges()}}},[_vm._v(" Μελη Δ.Σ. ")]),_c('v-tab',{style:(_vm.tab=='tab-2' ? 'background-color: aliceblue;' : 'background-color: '),attrs:{"href":"#tab-2"},on:{"click":function($event){return _vm.getBranchesChanges()}}},[_vm._v(" Παραρτηματα ")]),_c('v-tab',{style:(_vm.tab=='tab-3' ? 'background-color: aliceblue;' : 'background-color: '),attrs:{"href":"#tab-3"},on:{"click":function($event){return _vm.getPlacesChanges()}}},[_vm._v(" Χωροι ")]),_c('v-tab',{style:(_vm.tab=='tab-4' ? 'background-color: aliceblue;' : 'background-color: '),attrs:{"href":"#tab-4"}},[_vm._v(" Μελη λεσχης ")]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"mt-4",attrs:{"value":"tab-1"}},[_c('v-data-table',{attrs:{"items-per-page":5,"headers":_vm.headersMembersDSActions,"footer-props":{page:1,
                          pageCount:1,
                          showCurrentPage:true,
                          itemsPerPageOptions:[5,10,15,30],
                          showFirstLastPage:true,
                          itemsPerPageText:'Ενέργειες ανά σελίδα:',
                          itemsPerPageAllText:'Όλα'},"items":_vm.councilMembersChanges,"no-data-text":"Δέν υπάρχουν αποτελέσματα."},scopedSlots:_vm._u([{key:`footer.page-text`,fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" εώς "+_vm._s(items.pageStop)+" από "+_vm._s(items.itemsLength)+" ")]}},{key:"item",fn:function({item}){return [_c('tr',[_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.user.user_branches[0].first_name)+" "+_vm._s(item.user.user_branches[0].last_name)+" ")]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s("ΑΛΛΑΓΗ ΜΕΛΩΝ Δ.Σ."))]),(item.in_council == true)?_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s("ΠΡΟΣΘΗΚΗ ΜΕΛΟΥΣ"))]):_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s("ΔΙΑΓΡΑΦΗ ΜΕΛΟΥΣ"))]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(_vm.utilities.timestampToDatetime(item.entry_time)))])])]}}],null,true)})],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('v-data-table',{attrs:{"items-per-page":5,"headers":_vm.headersBranchesActions,"footer-props":{page:1,
                          pageCount:1,
                          showCurrentPage:true,
                          itemsPerPageOptions:[5,10,15,30],
                          showFirstLastPage:true,
                          itemsPerPageText:'Ενέργειες ανά σελίδα:',
                          itemsPerPageAllText:'Όλα'},"items":_vm.branchesChanges,"no-data-text":"Δέν υπάρχουν αποτελέσματα."},scopedSlots:_vm._u([{key:`footer.page-text`,fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" εώς "+_vm._s(items.pageStop)+" από "+_vm._s(items.itemsLength)+" ")]}},{key:"item",fn:function({item}){return [_c('tr',[_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.name))]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.address)+" "+_vm._s(', ' + item.city)+" "+_vm._s(', ' + item.postal_code)+" ")]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.branch_history_type.type))]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.branchstatus.name))]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(" "+_vm._s((item.responsible_userrole_id != 1) ? item.userrole.name : (item.user.user_branches.length > 0) ? item.user.user_branches[0].first_name + ', ' + item.user.user_branches[0].last_name : item.user.first_name + ', ' + item.user.last_name)+" ")]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(_vm.utilities.timestampToDatetime(item.change_time)))])])]}}],null,true)})],1),_c('v-tab-item',{attrs:{"value":"tab-3"}},[_c('v-data-table',{attrs:{"items-per-page":5,"headers":_vm.headersPlacesActions,"footer-props":{page:1,
                          pageCount:1,
                          showCurrentPage:true,
                          itemsPerPageOptions:[5,10,15,30],
                          showFirstLastPage:true,
                          itemsPerPageText:'Ενέργειες ανά σελίδα:',
                          itemsPerPageAllText:'Όλα'},"items":_vm.placesChanges,"no-data-text":"Δέν υπάρχουν αποτελέσματα."},scopedSlots:_vm._u([{key:`footer.page-text`,fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" εώς "+_vm._s(items.pageStop)+" από "+_vm._s(items.itemsLength)+" ")]}},{key:"item",fn:function({item}){return [_c('tr',[_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.place_history.place.place_type.type))]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.place_history.place.address)+" "+_vm._s(', ' + item.place_history.place.city)+" "+_vm._s(', ' + item.place_history.place.postal_code)+" ")]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.place_history.place_history_type.type))]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.place_history.place_status.status))]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s((item.place_history.responsible_userrole_id != 1) ? item.place_history.userrole.name : (item.responsible_user.user_branches.length == 0) ? '-' : item.responsible_user.user_branches[0].first_name + ' ' + item.responsible_user.user_branches[0].last_name)+" ")]),(item.place_history.place_file == null)?_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s('-'))]):_c('td',{attrs:{"align":"center","justify":"center"}},[_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.downloadFile(item)}}},[_c('v-icon',[_vm._v(" mdi-download ")])],1)],1),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(_vm.utilities.timestampToDatetime(item.place_history.change_time))+" ")])])]}}],null,true)})],1),_c('v-tab-item',{attrs:{"value":"tab-4"}},[_c('v-data-table',{attrs:{"page":_vm.pageNumber,"footer-props":{page:1,
                          pageCount:1,
                          showCurrentPage:true,
                          itemsPerPageOptions:[5,10,15,30],
                          showFirstLastPage:true,
                          itemsPerPageText:'Ενέργειες ανά σελίδα:',
                          itemsPerPageAllText:'Όλα'},"no-data-text":"Δέν υπάρχουν αποτελέσματα.","headers":_vm.headersMembersActions,"items":_vm.membersChanges,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.tableLength,"options":_vm.optionsMembers},on:{"update:page":function($event){_vm.pageNumber=$event},"update:options":[function($event){_vm.optionsMembers=$event},_vm.getBuildingPerPage]},scopedSlots:_vm._u([{key:`footer.page-text`,fn:function(items){return [_vm._v(" "+_vm._s(items.pageStart)+" εώς "+_vm._s(items.pageStop)+" από "+_vm._s(items.itemsLength)+" ")]}},{key:"item",fn:function({item}){return [_c('tr',[_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.user_branch.first_name + ' ' + item.user_branch.last_name)+" ")]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.user_branch.branch.name))]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s("ΑΛΛΑΓΗ ΚΑΤΑΣΤΑΣΗΣ ΧΡΗΣΤΗ"))]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.userbranchbranchrolestatus.status))]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(" "+_vm._s((item.userrole.id != 1) ? item.userrole.name : (item.user.user_branches.length > 0) ? (item.user.user_branches[0].first_name == null && item.user.user_branches[0].last_name == null) ? '-' : ((item.user.user_branches[0].first_name == null) ? ' ' + item.user.user_branches[0].last_name : item.user.user_branches[0].first_name) + ' ' + ((item.user.user_branches[0].last_name == null) ? '' : item.user.user_branches[0].last_name) : item.user.first_name + ' ' + item.user.last_name)+" ")]),_c('td',{attrs:{"align":"center","justify":"center"}},[_vm._v(_vm._s(item.change_time))])])]}}],null,true)})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }