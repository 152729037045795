<template>
<div>

    <v-row align="center" justify="center">
        <v-col cols="8">
            <v-card
                    class="mb-12"
            >
                <h4 class="govgr-heading-s">Εισάγετε τον ΑΦΜ της αναγνωρισμένης λέσχης φιλάθλων την οποία
                    εκπροσωπείτε</h4>

                <v-text-field v-model="club_vat" class="textField" :rules="vatRule" counter="9" outlined label="ΑΦΜ"></v-text-field>


                <v-card-actions>

                    <v-btn
                            color="primary"
                            @click="submit"
                            :disabled="club_vat.trim().length<8"
                    >
                        Συνέχεια
                    </v-btn>

                    <v-btn text>
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

</div>
</template>

<script>
    import requests from "@/requests";

    export default {
        name: "ClubVATCheck",
        data: () => ({
          club_vat: '010540663',
          vatRule: [
            v => !!v || 'Το πεδίο είναι απαραίτητο',
            v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
          ],
            }),
        methods:{

            submit() {

                this.$store.commit('setLoadingModal', true)
                requests.getSecureRequest('checks/check-clubs-rep-is-valid/', this.club_vat, this.elegxosAFMCallback)
            },


            elegxosAFMCallback(response) {
                this.$store.commit('setLoadingModal', false)
                this.$store.commit('setSelectedClub', response.data)
                this.$store.commit('setCheckedRepresentative', true)
                this.$router.push({path: '/nel-dashboard'})
            },

            gotoMenu(){
                this.$router.go(-1);
            }


        }
    }
</script>

<style scoped>

</style>
