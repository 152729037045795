<template>
  <div>
    <v-card v-if="Object.keys(contactInfoEmep).length > 0" class="mb-2">
      <v-card-title class="primary white--text govgr-!-font-weight-bold govgr-!-font-size-24">
        Τα στοιχεία μου απο το Εθνικό Μητρώο Επικοινωνίας
      </v-card-title>
      <v-card-text>
        <v-row justify="center" align="center">
          <v-col cols="4" align="center">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ contactInfoEmep.mobile }}</v-list-item-title>
                <v-list-item-subtitle>Κινητό τηλέφωνο</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="4" align="center">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ contactInfoEmep.telephone }}</v-list-item-title>
                <v-list-item-subtitle>Σταθερό τηλέφωνο</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="4" align="center">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ contactInfoEmep.email }}</v-list-item-title>
                <v-list-item-subtitle>Email</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat outlined class="mb-2">
      <v-card-title class="primary white--text govgr-!-font-weight-bold govgr-!-font-size-24">
        Οι εγγραφές μου
      </v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="panel" v-if="registrations.length > 0">
          <v-expansion-panel v-for="(item, index) in registrations" v-bind:key="index" class="my-2">
            <v-expansion-panel-header class="govgr-!-font-size-19" @click="selectedHistoryToShow(item)">
              <v-row>
                <v-col class="govgr-!-font-weight-bold" cols="1">
                  {{ index + 1 }}.
                </v-col>
                <v-col cols="3">
                  {{ item.branch.club.name }} {{ '- ' + item.branch.name }}
                </v-col>
                <v-col cols="2">
                  <v-chip v-if=" item.userbranchbranchrolestatus.id ==
                                 $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.ACTIVE"
                          class="white--text" color="green">
                    {{ item.userbranchbranchrolestatus.status }}
                  </v-chip>
                  <v-chip v-else-if="item.userbranchbranchrolestatus.id == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.DELETED
                                      || item.userbranchbranchrolestatus.id == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.INVALID
                                      || item.userbranchbranchrolestatus.id == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.SUSPENDED"
                          color="red" class="white--text">
                    {{ item.userbranchbranchrolestatus.status }}
                  </v-chip>
                  <v-chip v-else>{{ item.userbranchbranchrolestatus.status }}</v-chip>
                </v-col>
                <v-col cols="2" class="mt-1">
                  <section v-if="item.userbranch_branchrole_histories.length > 0">
                    {{ utilities.prettifyTimestamp(item.userbranch_branchrole_histories[0].change_time) }}
                  </section>
                </v-col>
                <v-col cols="3" v-if="item.is_nel">
                  {{ 'Ιδιότητα εκπροσώπου λέσχης' }}
                </v-col>
                <v-col cols="3" v-else-if="item.is_ypl">
                  {{ 'Ιδιότητα εκπροσώπου παραρτήματος' }}
                </v-col>
                <v-col cols="3" v-else-if="item.is_place_rep">
                  {{ 'Ιδιότητα υπευθύνου χώρου' }}
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider/>
              <v-card flat>
                <v-card-title>
                  <v-row justify="center" align="center">
                    <v-col align="center">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.branch.name }}</v-list-item-title>
                          <v-list-item-subtitle>Παράρτημα στο οποίο ανήκει</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col align="center">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.branch.address }} {{ item.branch.city }},
                            {{ item.branch.postal_code }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Διεύθυνση παραρτήματος</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col align="center" v-if="item.is_rep_places.length > 0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.is_rep_places[0].address }} {{ item.is_rep_places[0].city }}
                            {{ item.is_rep_places[0].postal_code }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Υπεύθυνος στο χώρο με διεύθυνση</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                  <div>
                    <v-row class="mt-2 mb-4 ml-1 govgr-!-font-weight-bold">
                      Στοιχεία όπως καταχωρήθηκαν απο τον
                      {{
                        (item.branch.is_default == true) ?
                            'εκπρόσωπο της λέσχης φιλάθλων:' :
                            'υπεύθυνο παραρτήματος της λέσχης φιλάθλων:'
                      }}
                    </v-row>
                    <v-row justify="center" align="center" class="mb-1">
                      <v-col cols="3">
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.first_name }}</v-list-item-title>
                              <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.last_name }}</v-list-item-title>
                              <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ (item.mother_name == '' || item.mother_name == null) ? '-' : item.mother_name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>Μητρώνυμο</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                      </v-col>
                      <v-col cols="3">
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.father_name }}</v-list-item-title>
                              <v-list-item-subtitle>Παρώνυμο</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{
                                  (item.address == '' || item.address == null) ?
                                      '-' : (item.address + ', ' + item.city)
                                }}
                              </v-list-item-title>
                              <!--{{', ' + item.postal_code }}-->
                              <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{
                                  (item.member_number == '' || item.member_number == null) ? '-' : item.member_number
                                }}
                              </v-list-item-title>
                              <v-list-item-subtitle>Αρ. κάρτας μέλους</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                      </v-col>
                      <v-col cols="6">
                        <v-card outlined class="mt-3">
                          <v-card-title class="primary white--text mb-3" style="width: 100%">
                            Ιστορικό εγγραφής μέλους
                          </v-card-title>
                          <v-card-text>
                            <v-data-table dense
                                          :items="historyOfMember"
                                          :headers="historyHeaders"
                                          :items-per-page="5"
                                          :footer-props="{
                                    itemsPerPageOptions:[5,10,15],
                                    showFirstLastPage: true,
                                    itemsPerPageText:'Ενέργειες ανά σελίδα:',
                                    itemsPerPageAllText:'Όλα'}"
                                          no-data-text="Δέν υπάρχουν αποτελέσματα.">
                              <template v-slot:[`footer.page-text`]="items">
                                {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
                              </template>
                              <template v-slot:item="{item}">
                                <tr>
                                  <td class="text-center d-sm-table-cell d-flex">
                                    {{ utilities.timestampToDatetime(item.change_time) }}
                                  </td>
                                  <td class="text-center d-sm-table-cell d-flex">
                                    {{ item.userbranchbranchrolestatus.status }}
                                  </td>
                                  <td class="text-center d-sm-table-cell d-flex" v-if="item.penalty_ends_time != null">
                                    {{ item.penalty_ends_time.split('T')[0] }}
                                  </td>
                                  <td class="text-center d-sm-table-cell d-flex" v-else>
                                    {{ '-' }}
                                  </td>
                                  <td class="text-center d-sm-table-cell d-flex"
                                      v-if="item.suspension_reason != null"
                                      @click="openSuspensionReasonDialog(item.suspension_reason)">
                                    <v-tooltip left>
                                      <template v-slot:activator="{on}">
                                        <v-btn icon v-on="on">
                                          <v-icon color="primary">
                                            mdi-information-variant
                                          </v-icon>
                                        </v-btn>
                                      </template>
                                      <span>
                                        Λεπτομέρειες αναστολής
                                      </span>
                                    </v-tooltip>
                                  </td>
                                  <td class="text-center d-sm-table-cell d-flex" v-else>
                                    {{ '-' }}
                                  </td>
                                  <td class="text-center d-sm-table-cell d-flex">
                                    <v-tooltip left>
                                      <template v-slot:activator="{on}">
                                        <v-icon color="primary" v-on="on">
                                          mdi-account
                                        </v-icon>
                                      </template>
                                      <span>
                                        {{ item.user.first_name }} {{ item.user.last_name }}
                                      </span>
                                    </v-tooltip>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-divider/>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="item.userbranchbranchrolestatus.id ==
                              $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.ACTIVE"
                         class="primary white--text" @click="openCheckDialog(item)">
                    Αίτημα διαγραφής
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-dialog persistent v-model="checkDialog" width="600px">
                <v-card>
                  <v-card-title class="primary white--text" style="word-break: normal">
                    Αίτημα διαγραφής
                  </v-card-title>
                  <v-card-text class="mt-5">
                    <v-checkbox v-model="affirmation"
                                :label="dialogText">
                    </v-checkbox>
                    <section class="govgr-!-font-size-19">Είστε σίγουρος/η για την ενέργεια σας;</section>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="checkDialog = false">
                      Όχι
                    </v-btn>
                    <v-spacer/>
                    <v-btn class="primary" :disabled="!affirmation" @click="requestMyRemoval">
                      Ναι, εκτέλεση
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row justify="center" align="center" class="govgr-!-font-size-24 mt-10 mb-10" v-else>
          Δεν είστε εγγεγραμμένος/η στο Μητρώο μελών λεσχών φιλάθλων.
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="goToEMEPDialog" max-width="600px">
      <v-card>
        <v-card-title class="primary white--text" style="word-break: normal">
          Καταχωρίστε τα στοιχεία επικοινωνίας σας στο Εθνικό Μητρώο Επικοινωνίας (Ε.Μ.Επ.)
        </v-card-title>
        <v-card-text class="mt-2 govgr-!-font-size-19">
          {{ serviceText }}
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="goToEMEPDialog = false">
            Κλείσιμο
          </v-btn>
          <v-spacer/>
          <v-btn @click="redirectToEMEP" class="primary">
            Μετάβαση στο Ε.Μ.Επ.
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent width="400px" v-model="suspensionReasonDialog">
      <v-card>
        <v-card-title style="word-break: normal" class="primary white--text">
          Αιτιολογία αναστολής μέλους
        </v-card-title>
        <v-card-text class="mt-4 govgr-!-font-size-19">
          {{ suspensionReason }}
        </v-card-text>
        <v-card-actions>
          <v-btn @click="suspensionReasonDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import requests from "@/requests";
import utilities from "../../utilities";

export default {
  name: "MemberHistory",
  computed: {
    utilities() {
      return utilities
    }
  },
  data: () => ({
    registrations: [],
    panel: '',
    checkDialog: false,
    selectedItemToDelete: {},
    dialogText: '',
    contactInfoEmep: {},
    affirmation: false,
    historyHeaders: [
      {
        text: 'Χρονοσήμανση',
        align: 'center',
        sortable: false,
        value: 'change_time',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Ημ/νία λήξης αναστολής',
        align: 'center',
        sortable: false,
        value: 'penalty_ends_time',
      },
      {
        text: 'Λεπτομέρειες',
        align: 'center',
        sortable: false,
        value: 'sunspesion_reason',
      },
      {
        text: 'Υπεύθυνος',
        align: 'center',
        sortable: false,
        value: 'user',
      },
    ],
    historyOfMember: [],
    goToEMEPDialog: false,
    serviceText: '',
    suspensionReasonDialog: false,
    suspensionReason: '',
    devMode: 'false',
  }),
  methods: {
    getMyRegistrations() {
      this.$store.commit('setLoadingModal', true)
      requests.getSecureRequest('history/get-my-history-as-member', '', this.getMyRegistrationsCallback)
    },
    getMyRegistrationsCallback(response) {
      this.registrations = []
      this.registrations = response.user_branches
      this.$store.commit('setLoadingModal', false)
      if (this.registrations.length > 0) {
        this.getDevMode()
      }
    },

    selectedHistoryToShow(item) {

      this.historyOfMember = item.userbranch_branchrole_histories
    },
    openCheckDialog(item) {
      this.selectedItemToDelete = item
      this.dialogText = "Δηλώνω υπεύθυνα ότι ουδέποτε έχω εγγραφεί στη λέσχη " + this.selectedItemToDelete.branch.club.name + " και ως εκ τούτου αιτούμαι την άμεση διαγραφή μου από το ψηφιακό μητρώο μελών της Λέσχης."
      this.checkDialog = true
    },
    requestMyRemoval() {
      this.checkDialog = false
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('user/request-member-removal',
          {user_branch_id: this.selectedItemToDelete.id},
          this.requestMyRemovalCallback)
    },

    requestMyRemovalCallback(response) {
      this.$store.commit('setLoadingModal', false)
      let msg = 'Το αίτημα για την διαγραφή σας απο ' +
          ((this.selectedItemToDelete.branch.is_default == true)
              ? 'τη λέσχη φιλάθλων' : 'το παράρτημα της λέσχης φιλάθλων') +
          ' καταχωρήθηκε επιτυχώς'
      this.$store.commit('setInfoDialogObj', {text: msg})
      this.$store.commit('setInfoDialog', true)
      this.getMyRegistrations()
    },

    isRegistersInEMEP() {
      let requestObj = {}
      //gia na emfanizontai random kai stoixeia, kai apanthseis tou emep
      let random = Math.random();
      if (random <= 0.5 || this.devMode == false) {
        requestObj = {
          vat: this.$store.getters.loggedinUser.user.vat
        }
      } else {
        requestObj = {
          vat: '019285930'
        }
      }
      requests.postSecureRequest('user/get-nnc-identity', requestObj, this.isregisteredInEMEPCallback)
    },

    isregisteredInEMEPCallback(response) {
      this.serviceText = response.result.message
      if (response.result.is_registered == false) {
        this.goToEMEPDialog = true
      } else {
        this.goToEMEPDialog = false
        this.contactInfoEmep = response.user_data_from_EMEp
      }

      this.$store.commit('setLoadingModal', false)
    },

    redirectToEMEP() {
      window.open('https://www.gov.gr/ipiresies/polites-kai-kathemerinoteta/stoikheia-polite-kai-tautopoietika-eggrapha/ethniko-metroo-epikoinonias-emep', 'target=_blank')
      // this.closeGoToEMEPDialog()
    },

    openSuspensionReasonDialog(item) {
      this.suspensionReason = item;
      this.suspensionReasonDialog = true
    },

    getDevMode() {
      requests.getSecureRequest('properties/' + this.$store.getters.loggedinUser.CONSTANTS.PROPERTIES.DEV_MODE, '', this.getDevModeCallback)
    },

    getDevModeCallback(response) {
      this.devMode = response

      this.isRegistersInEMEP()
    }


  },
  created() {
    this.getMyRegistrations()
  }
}
</script>

<style scoped>

</style>