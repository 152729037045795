<template>
  <div>
    <v-row>
      <v-col class="pt-1">
        <v-data-table
            :footer-props="{page:1,
                                    pageCount:1,
                                    showCurrentPage:true,
                                    itemsPerPageOptions:[5,10,15,30],
                                    showFirstLastPage:true,
                                    itemsPerPageText:'Ειδοποιήσεις ανά σελίδα:',
                                    itemsPerPageAllText:'Όλα'}"
            no-data-text="Δέν υπάρχουν αποτελέσματα."
            :headers="headers"
            :items="$store.getters.submitedApplications"
            class="elevation-3 mx-5 px-2"
            sort-by="calories"
        >
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
          </template>

          <template v-slot:item.document="{ item }">

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary">
                  <v-icon @click="downloadDocument(item)"
                          v-on="on">mdi-download
                  </v-icon>
                </v-btn>
              </template>
              <span>Παραγόμενο έγγραφο αίτησης</span>
            </v-tooltip>
          </template>

          <template v-slot:item.action="{ item }">

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon color="primary">
                  <v-icon @click="editItem(item)"
                          v-on="on">edit
                  </v-icon>
                </v-btn>
              </template>
              <span>Διαχείριση αίτησης</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>


    <v-dialog fullscreen v-if="Object.keys($store.getters.selectedApplication).length > 0"
              v-model="$store.getters.editGGAApplicationDialog" persistent
              @keydown.esc="$store.commit('setEditGGAApplicationDialog', false)" width="90%">
      <v-card>
        <v-card-title class="primary white--text">
          Αίτηση με αναγνωριστικό: #{{ $store.getters.selectedApplication.id }}
          <v-spacer></v-spacer>
          <v-icon @click="$store.commit('setEditGGAApplicationDialog', false)" color="white" medium>clear</v-icon>
        </v-card-title>
        <v-card-text>
          <v-container class="mt-5 scrollable-content">
            <!--                        <EditApplicationon v-if="Object.keys($store.getters.selectedApplication).length > 0" :key="$store.getters.selectedApplication.id"/>-->
            <ShowApplication :key="componentKey" :applicationId="$store.getters.selectedApplication.id"
                             :user="$store.getters.selectedApplication.user" :checkBox="true"/>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EditApplicationon from "./EditApplicationon";
import requests from "@/requests";
import ShowApplication from "@/components/NEL/ShowApplication.vue";

export default {
  name: "SubmittedApplications",
  components: {ShowApplication, EditApplicationon},
  data: () => ({
    headers: [
      {
        text: 'ID',
        align: 'center',
        sortable: true,
        value: 'id',
      },
      {
        text: 'Λέσχη',
        align: 'center',
        sortable: true,
        value: 'club.name',
      },
      {
        text: 'Κατάσταση αίτησης',
        align: 'center',
        sortable: true,
        value: 'applicationstatus.name',
      },
      {
        text: 'Δημιουργία αίτησης',
        align: 'center',
        sortable: true,
        value: 'creation_date',
      },
      {
        text: 'Υποβολή αίτησης',
        align: 'center',
        sortable: true,
        value: 'submission_date',
      },
      {
        text: 'Έγγραφο Αίτησης',
        align: 'center',
        sortable: true,
        value: 'document',
      },
      {text: 'ΕΝΕΡΓΕΙΕΣ', align: 'center', value: 'action', sortable: false},
    ],
    item: {},
    componentKey: 0,
  }),
  methods: {
    editItem(item) {
      this.item = item
      this.$store.commit('setSelectedClub', this.item.club)
      this.$store.commit('setSelectedApplication', this.item)
      this.componentKey++
      this.$store.commit('setEditGGAApplicationDialog', true)
    },
    /*getUserApplicationCallback(response){
      // console.log('res', JSON.parse(JSON.stringify(response)));
      this.item.user_application = response.users
      // console.log('item', JSON.parse(JSON.stringify(this.item)));
      this.$store.commit('setSelectedApplication', this.item)
      this.$store.commit('setEditGGAApplicationDialog', true)
    },
*/
    downloadDocument(item) {
      let obj = {}
      obj.application_id = item.id
      requests.downloadFileRequestWithObj('application/get-aitisi', obj)
    },
    getSubmittedApplications() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('application/findSubmitted', {}, this.getSubmittedApplicationsCallback)
    },

    getSubmittedApplicationsCallback(response) {

      this.numberOfSubmittedApplications = response.length
      this.$store.commit('setSubmitedApplications', response)
      this.$store.commit('setLoadingModal', false)
      for (let i = 0; i < response.length; i++) {
        if (response[i].id == this.$store.getters.selectedApplication.id) {
          this.$store.commit('setSelectedApplication', response[i])
        }
      }
    },
  },
  created() {
    this.getSubmittedApplications()
  }
}
</script>

<style scoped>
.scrollable-content {
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-color: transparent transparent !important;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.scrollable-content::-webkit-scrollbar {
  width: 0.1em !important;
  display: none !important; /* Chrome, Safari, and Opera */
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: transparent !important;
}
</style>
