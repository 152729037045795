<template>
  <div>
    <div v-if="club && club.hasOwnProperty('application')">
      <v-alert type="error" title="Αιτία απόρριψης"
               v-if="(club?(club.application?(club.application.hasOwnProperty('rejection_reason') && club.application.rejection_reason != null):false):false)">
        <div class="text-h6">Αιτία απόρριψης</div>
        <div>
          <v-row>
            <v-col cols="11">
              {{ club.application.rejection_reason }}
            </v-col>
            <v-col>
              <v-btn v-if="club.application.rejection_file_name != null" rounded color="white"
                     @click="downloadRejectionFile()">
                <v-icon color="red">mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-alert>


      <!-- με ελεγχο του page width μπορουμε να αλλαξουμε μεγεθος γραμματοσειρας και να παιζει και σε 1500px      -->
      <v-stepper v-model="e13" alert="my message" class="mb-3">
        <v-stepper-header v-if="pageWidth>1732">
          <v-stepper-step step="#" :id="e13==0?'selectedStep':''" :color="(e13==0)?stepperStepColor():''">
            <span :id="e13==0?'selectedStepText':''">
              Κατάσταση αίτησης
            </span>
          </v-stepper-step>
          <v-stepper-step :editable="editButtonActivate" step="1" :id="e13==1?'selectedStep':''"
                          :color="(e13==1)?stepperStepColor():''">
            <span :id="e13==1?'selectedStepText':''">
              Αίτηση
            </span>
          </v-stepper-step>
          <v-stepper-step :editable="editButtonActivate" :rules="[() => true]" step="2" :id="e13==2?'selectedStep':''"
                          :color="(e13==2)?stepperStepColor():''">
            <span :id="e13==2?'selectedStepText':''">
              Παραρτήματα
            </span>
          </v-stepper-step>
          <v-stepper-step :editable="editButtonActivate" :rules="[() => true]" step="3" :id="e13==3?'selectedStep':''"
                          :color="(e13==3)?stepperStepColor():''">
            <span :id="e13==3?'selectedStepText':''">
              Χώροι
            </span>
          </v-stepper-step>
          <v-stepper-step :editable="editButtonActivate" :rules="[() => true]" step="4" :id="e13==4?'selectedStep':''"
                          :color="(e13==4)?stepperStepColor():''">
            <span :id="e13==4?'selectedStepText':''">
              Μέλη λέσχης
            </span>
          </v-stepper-step>
          <v-stepper-step :editable="editButtonActivate" :rules="[() => true]" step="5" :id="e13==5?'selectedStep':''"
                          :color="(e13==5)?stepperStepColor():''">
            <span :id="e13==5?'selectedStepText':''">
              Μέλη Δ.Σ.
            </span>
          </v-stepper-step>
          <v-stepper-step :editable="editButtonActivate" :rules="[() => true]" step="6" :id="e13==6?'selectedStep':''"
                          :color="(e13==6)?stepperStepColor():''">
            <span :id="e13==6?'selectedStepText':''">
              Δικαιολογητικά
            </span>
          </v-stepper-step>
          <v-stepper-step :editable="editButtonActivate" @click="checksForApplication()" :rules="[() => true]" step="7"
                          :id="e13==7?'selectedStep':''" :color="(e13==7)?stepperStepColor():''">
            <span :id="e13==7?'selectedStepText':''">
              Οριστική υποβολή
            </span>
          </v-stepper-step>
        </v-stepper-header>

        <!-- Μοναδικη διαφορα με το stepper-header πιο πανω ειναι οτι έχει tooltip στα steps γιατι δεν εμφανίζονται οι τίτλοι σε μικρή οθονη -->
        <v-stepper-header v-else>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <div v-on="on">
                <v-stepper-step step="#" :id="e13==0?'selectedStep':''" :color="(e13==0)?stepperStepColor():''">
                  <span :id="e13==0?'selectedStepText':''">
                    Κατάσταση αίτησης
                  </span>
                </v-stepper-step>
              </div>
            </template>
            <span>Κατάσταση αίτησης</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <div v-on="on">
                <v-stepper-step :editable="editButtonActivate" step="1" :id="e13==1?'selectedStep':''"
                                :color="(e13==1)?stepperStepColor():''">
                <span v-if="e13==1" :id="e13==1?'selectedStepText':''">
                  Αίτηση
                </span>
                </v-stepper-step>
              </div>
            </template>
            <span>Αίτηση</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <div v-on="on">
                <v-stepper-step :editable="editButtonActivate" :rules="[() => true]" step="2"
                                :id="e13==2?'selectedStep':''" :color="(e13==2)?stepperStepColor():''">
                  <span v-if="e13==2" :id="e13==2?'selectedStepText':''">
                    Παραρτήματα
                  </span>
                </v-stepper-step>
              </div>
            </template>
            <span>Παραρτήματα</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <div v-on="on">
                <v-stepper-step :editable="editButtonActivate" :rules="[() => true]" step="3"
                                :id="e13==3?'selectedStep':''" :color="(e13==3)?stepperStepColor():''">
                  <span v-if="e13==3" :id="e13==3?'selectedStepText':''">
                    Χώροι
                  </span>
                </v-stepper-step>
              </div>
            </template>
            <span>Χώροι</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <div v-on="on">
                <v-stepper-step :editable="editButtonActivate" :rules="[() => true]" step="4"
                                :id="e13==4?'selectedStep':''" :color="(e13==4)?stepperStepColor():''">
                  <span v-if="e13==4" :id="e13==4?'selectedStepText':''">
                    Μέλη λέσχης
                  </span>
                </v-stepper-step>
              </div>
            </template>
            <span>Μέλη λέσχης</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <div v-on="on">
                <v-stepper-step :editable="editButtonActivate" :rules="[() => true]" step="5"
                                :id="e13==5?'selectedStep':''" :color="(e13==5)?stepperStepColor():''">
                  <span v-if="e13==5" :id="e13==5?'selectedStepText':''">
                    Μέλη Δ.Σ.
                  </span>
                </v-stepper-step>
              </div>
            </template>
            <span>Μέλη Δ.Σ.</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <div v-on="on">
                <v-stepper-step :editable="editButtonActivate" :rules="[() => true]" step="6"
                                :id="e13==6?'selectedStep':''" :color="(e13==6)?stepperStepColor():''">
                  <span v-if="e13==6" :id="e13==6?'selectedStepText':''">
                    Δικαιολογητικά
                  </span>
                </v-stepper-step>
              </div>
            </template>
            <span>Δικαιολογητικά</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <div v-on="on">
                <v-stepper-step :editable="editButtonActivate" @click="checksForApplication()" :rules="[() => true]"
                                step="7" :id="e13==7?'selectedStep':''" :color="(e13==7)?stepperStepColor():''">
                  <span v-if="e13==7" :id="e13==7?'selectedStepText':''">
                    Οριστική υποβολή
                  </span>
                </v-stepper-step>
              </div>
            </template>
            <span>Οριστική υποβολή</span>
          </v-tooltip>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content align="center" step="0" :v-if="e13==0">
            <div :v-if="e13==0">
              <v-row justify="center" align="center" class="mt-1 mb-1">
                <v-card min-width="550px">
                  <div v-if="$store.getters.isTimeForApplications == false">
                    <v-card-title style="color: red; word-break: normal; font-size: 18px; " class="mt-1 justify-center">
                      Η περίοδος νέων αιτήσεων δεν έχει ξεκινήσει!
                    </v-card-title>
                    <v-divider/>
                  </div>
                  <v-card-text style="text-align: left">
                    <v-row>
                      <v-col style="width: 20%">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title> {{
                                (this.$store.getters.ggaApplicationId != null) ? '#' + this.$store.getters.ggaApplicationId : '-'
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Αριθμός αίτησης</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col style="width: 60%">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title v-if="this.$store.getters.ggaApplicationProgress == 0">
                              {{ 'Νέα αίτηση προς ΓΓΑ' }}
                            </v-list-item-title>
                            <v-list-item-title v-if="this.$store.getters.ggaApplicationProgress == 5">
                              {{ 'Δεν υποβάλατε αίτηση' }}
                            </v-list-item-title>
                            <v-list-item-title v-else>{{ this.$store.getters.ggaApplication.name }}</v-list-item-title>
                            <v-list-item-subtitle>Κατάσταση αίτησης</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col style="width: 20%">
                        <v-list-item two-line>
                          <v-list-item-content>
                            <v-list-item-title v-if="$store.getters.ggaApplicationProgress == 0">
                              {{ '-' }}
                            </v-list-item-title>
                            <v-list-item-title v-else>{{ this.$store.getters.ggaApplicationDate }}</v-list-item-title>
                            <v-list-item-subtitle>Ημερομηνία</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer/>
                    <div v-if="$store.getters.isTimeForApplications == true">
                      <v-btn v-if="$store.getters.ggaApplicationProgress == 0"
                             color="primary"
                             v-on:click="createApplication()"
                      >
                        Δημιουργία
                      </v-btn>
                      <v-btn v-else-if="$store.getters.ggaApplication.id == 1 || $store.getters.ggaApplication.id == 4"
                             color="primary"
                             v-on:click="editApplication()"
                      >
                        Επεξεργασία
                      </v-btn>
                      <v-btn v-if="this.$store.getters.isTimeForApplications &&
                          this.$store.getters.ggaApplicationProgress == 1"
                             color="red" class="white--text ml-2"
                             @click="openDeleteApplicationDialog()">
                        Διαγραφή αίτησης
                      </v-btn>
                      <v-dialog persistent v-model="deleteApplicationDialog" width="400px">
                        <v-card>
                          <v-card-title style="word-break: normal" class="primary white--text">
                            Διαγραφή αίτησης
                          </v-card-title>
                          <v-card-text class="mt-5 govgr-!-font-size-19">
                            Είστε σίγουρος/η για την διαγραφή της αίτησης;
                          </v-card-text>
                          <v-card-actions>
                            <v-btn @click="deleteApplicationDialog = false">
                              Όχι
                            </v-btn>
                            <v-spacer/>
                            <v-btn class="primary" @click="deleteApplication()">
                              Ναι, διαγραφή
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                    <v-spacer/>
                  </v-card-actions>
                </v-card>
              </v-row>
            </div>
          </v-stepper-content>
          <v-stepper-content step="1" :v-if="e13==1">
            <div class="ma-12" v-if="e13==1">
              <v-row>
                <v-col cols="6">
                  <v-card>
                    <v-card-title style="word-break: normal">Στοιχεία λέσχης</v-card-title>
                    <v-card-text>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ company.name }}</v-list-item-title>
                          <v-list-item-subtitle>Επωνυμία</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ company.vat }}</v-list-item-title>
                          <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ company.address + ', ' + company.city + ', ' + company.postal_code }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card>
                    <v-card-title style="word-break: normal">Υπεύθυνος λειτουργίας λέσχης</v-card-title>
                    <v-card-text>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ applicationState.user.user_branches[0].first_name }}</v-list-item-title>
                          <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ applicationState.user.user_branches[0].last_name }}</v-list-item-title>
                          <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ applicationState.user.vat }}</v-list-item-title>
                          <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
            <v-row class="ma-2">
              <v-btn text @click="e13--;">
                Πίσω
              </v-btn>
              <v-spacer/>
              <v-btn color="primary"
                     @click="e13++; getClubHasValidAmountOfMembers(); getClubHasValidAmountOfCouncilMember()">
                {{ nextStep }}
              </v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2" :v-if="e13==2">
            <div :v-if="e13==2">
              <v-spacer></v-spacer>
              <v-card min-width="600" flat>
                <v-card-title></v-card-title>
                <v-card-text>
                  <v-row align="center" justify="center">
                    <v-col cols="12">
                      <Branches v-if="e13==2"/>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-row class="ma-2">
                <v-btn text @click="--e13; getClubAFM()">
                  {{ prevStep }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="e13++;">
                  {{ nextStep }}
                </v-btn>
              </v-row>
              <!--              <v-btn text @click="e13&#45;&#45;;">-->
              <!--                {{ prevStep }}-->
              <!--              </v-btn>-->
            </div>
          </v-stepper-content>
          <v-stepper-content step="3" :v-if="e13==3">
            <div :v-if="e13==3">
              <v-spacer></v-spacer>
              <Places v-if="e13==3"/>
              <v-spacer></v-spacer>
              <v-row class="ma-2">
                <v-btn text @click="e13--;">
                  {{ prevStep }}
                </v-btn>
                <v-spacer/>
                <v-btn color="primary" @click="e13++;">
                  {{ nextStep }}
                </v-btn>
              </v-row>
            </div>
          </v-stepper-content>
          <v-stepper-content step="4" :v-if="e13==4">
            <div :v-if="e13==4">
              <v-spacer></v-spacer>
              <v-card min-width="600" flat>
                <v-card-title></v-card-title>
                <v-card-text>
                  <v-row align="center" justify="center">
                    <v-col cols="12">
                      <Members v-if="e13==4"/>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-spacer></v-spacer>
              <v-row class="ma-2">
                <v-btn text @click="e13--;">
                  {{ prevStep }}
                </v-btn>
                <v-spacer/>
                <v-btn color="primary" @click="editApplication()">
                  {{ nextStep }}
                </v-btn>
              </v-row>
            </div>
          </v-stepper-content>
          <v-stepper-content step="5" :v-if="e13==5">
            <div :v-if="e13==5">
              <v-spacer></v-spacer>
              <v-card min-width="600" flat>
                <v-card-title></v-card-title>
                <v-card-text>
                  <v-row align="center" justify="center">
                    <v-col cols="12">
                      <CouncilMembers v-if="e13==5"/>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-spacer></v-spacer>
              <v-row class="ma-2">
                <v-btn text @click="e13--;">
                  {{ prevStep }}
                </v-btn>
                <v-spacer/>
                <v-btn color="primary" @click="editApplication()">
                  {{ nextStep }}
                </v-btn>
              </v-row>
            </div>
          </v-stepper-content>
          <v-stepper-content step="6" :v-if="e13==6">
            <div :v-if="e13==6">
              <v-row align="center" justify="center"
                     v-if="$store.getters.selectedClub.is_registered == true && !fileExists">
                <v-col cols="8">
                  Άντληση δικαιολογητικών από την προηγούμενη εγκεκριμένη αίτηση:
                  <v-btn rounded color="primary" @click="fetchPrevApplicationDocuments()">
                    <v-icon>mdi-file-cloud</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="8">
                  <v-card min-width="600" class="mt-2">

                    <v-card-text
                        :style="`background-color: ` + (index%2 == 0 ? `${$vuetify.theme.themes.light.documentBlue}` : '')"
                        v-for="(doc, index) in documents" :key="index">
                      <v-row class="pa-0">
                        <v-col cols="11"><b>{{ index + 1 + '. ' + doc.description }}</b></v-col>
                        <v-col cols="1">
                          <v-spacer></v-spacer>
                          <v-icon v-if="existingDocument(doc.id)" color="green">mdi-check</v-icon>
                          <v-icon v-else color="red">mdi-close</v-icon>
                        </v-col>
                        <v-col cols="6">

                          <FileUploadReusable
                              :submit-button="true"
                              title="File"
                              event-to-emit-on-complete="onFileUpload"
                              number-of-sheets="1"
                              :doc="doc"
                              :id="doc.id.toString()"
                              :url="'application/upload'"
                              :application="application"
                              :file="getMyDocByDocId(doc.id)"
                              v-if="e13==6"
                          />
                        </v-col>
                        <v-col cols="4" align-self="center" align="center" justify="center">
                          <div v-if="existingDocument(doc.id) && (Object.keys(getMyDocByDocId(doc.id)).length >0)">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn :disabled="!existingDocument(doc.id)" color="primary" small
                                       @click="download(doc)" v-on="on">
                                  <v-icon color="white">mdi-download</v-icon>
                                </v-btn>
                              </template>
                              <span>
                                {{
                                  (Object.keys(getMyDocByDocId(doc.id)).length > 0) ? getMyDocByDocId(doc.id).filename + " " + utilities.prettifyTimestamp(getMyDocByDocId(doc.id).timestamp) : ''
                                }}
                              </span>
                            </v-tooltip>
                            <p style="font-size: 13px" class="mt-3">
                              Με τη μεταφόρτωση άλλου αρχείου, το προηγούμενο διαγράφεται.
                              <br/>
                              Μεταφορτωμένο αρχείο:
                              {{
                                (Object.keys(getMyDocByDocId(doc.id)).length > 0) ? getMyDocByDocId(doc.id).filename : ''
                              }}
                            </p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <!--                                <v-btn tile color="secondary" @click="tempSave">{{ 'Προσωρινή Αποθήκευση'}}</v-btn>-->
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="ma-2">
                <v-btn text @click="e13--;">
                  {{ prevStep }}
                </v-btn>
                <v-spacer/>
                <v-btn color="primary" @click="e13++; checksForApplication()">
                  {{ nextStep }}
                </v-btn>
              </v-row>
            </div>
          </v-stepper-content>
          <v-stepper-content step="7" :v-if="e13==7">
            <v-card :v-if="e13==7">
              <v-card-text>
                <v-row v-if="invalidBranches.length > 0"
                       align="center" justify="center">
                  <span v-for="branch in invalidBranches" :key="branch.id"
                        class="red--text">{{ 'Το παράρτημα "' }}  {{ branch.name }} {{
                      '" δεν πληροί το κριτήριο ελάχιστου πλήθους μελών για να συμπεριληφθεί στην αίτηση.'
                    }}</span>
                </v-row>
                <v-row v-if="allDocsAreUploaded == false" align="center" justify="center">
                  <span class="red--text">{{ 'Δεν υπάρχουν όλα τα δικαιολογητικά.' }}</span>
                </v-row>
                <v-row v-if="!clubHasValidAmountOfMembers" align="center" justify="center">
                  <!--    ΤΟ ορθογραφικό είναι σωστό απο κάτω "πληρείται"   -->
                  <span class="red--text">{{ 'Δεν πληρείται ο ορισμένος αριθμός μελών.' }}</span>
                </v-row>
                <v-row v-if="invalidMemberNumber > 0" align="center" justify="center">
                  <span class="red--text">
                    <v-icon>mdi-</v-icon>
                    {{ 'Υπάρχουν ' + invalidMemberNumber + ' μέλη τα οποία βρίσκονται σε κατάσταση ΜΗ ΕΓΚΥΡΟ.' }}
                  </span>
                </v-row>
                <v-row v-if="$store.getters.memberfileParsingInterval" align="center" justify="center">
                  <span class="red--text">{{ 'Υπάρχει αρχείο μελών σε επεξεργασία. Παρακαλώ περιμένετε ...' }}</span>
                </v-row>
                <v-row v-if="clubHasValidAmountOfCouncilMembers == false" align="center" justify="center">
                  <span class="red--text">{{ 'Δεν πληρείται ο ορισμένος αριθμός μελών Δ.Σ.' }}</span>
                </v-row>
                <v-row>
                  <v-btn text @click="e13--;">
                    {{ prevStep }}
                  </v-btn>
                  <v-spacer/>
                  <v-btn tile color="primary"
                         :disabled="!allDocsAreUploaded || !clubHasValidAmountOfMembers || $store.getters.memberfileParsingInterval || !clubHasValidAmountOfCouncilMembers"
                         @click="e13=0; applicationSubmission()">
                    {{ 'Οριστική υποβολή' }}
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>


</template>

<script>
import requests from "@/requests";
import axios from 'axios';
import FileUploadReusable from "../reusable/FileUploadReusable";
import Members from "@/components/NEL/Members.vue";
import {eventBus} from "@/main";
import Branches from "@/components/NEL/Branches.vue";
import Places from "@/components/NEL/Places.vue";
import dashboard from "@/components/Dashboard.vue";
import props from "../../properties";
import utilities from "../../utilities";
import CouncilMembers from "@/components/NEL/CouncilMembers.vue";
import router from "@/router";

export default {
  name: "ApplicationForm",
  components: {CouncilMembers, Places, Members, FileUploadReusable, Branches},
  created() {
    eventBus.$on('onFileUpload', this.onFileUploadComplete);
    this.getClubAFM()

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

  },

  beforeDestroy() {
    eventBus.$off('onFileUpload');
    window.removeEventListener('resize', this.handleResize);

    if (this.$store.getters.thereWasAChangeInApplication && this.editButtonActivate) {
      this.$store.commit('setInfoDialog', true)
      this.$store.commit('setInfoDialogObj', {text: 'Οι αλλαγές σας στην αίτηση έχουν αποθηκευτεί με επιτυχία.'})
      this.$store.commit('setThereWasAChangeInApplication', false)
    }
  },

  data() {
    return {
      props,
      e13: 0,
      club: null,
      documents: [],
      application: {},

      applicationState: {},
      fileExists: false,
      vat: '010540663',
      edit: false,
      editButtonActivate: false,
      pageWidth: window.innerWidth,
      company: {},
      message: '',
      myDocuments: [],
      clubHasValidAmountOfMembers: false,
      clubHasValidAmountOfCouncilMembers: false,
      nextStep: 'Επόμενο βήμα',
      prevStep: 'Προηγούμενο βήμα',
      isRegistered: false,
      deleteApplicationDialog: false,
      invalidBranches: [],
      invalidMemberNumber: 0,
    }
  },
  methods: {
    async getClubAFM() {
      this.$store.commit('setLoadingModal', true)
      await requests.postSecureRequest('club/vat-with-application', {club: this.$store.getters.selectedClub}, await this.getClubAFMCallback)
    },
    async getClubAFMCallback(response) {

      this.club = response.club
      this.club.application = response.application
      this.application = response.application
      this.applicationState = response.application
      this.edit = response.editApplication
      this.company = response.company
      this.isRegistered = this.club.is_registered

      for (let branch of this.company.myBranchesOnClub) {
        if (branch.is_default == true || branch.is_default == 'true') {
          this.company.address = branch.address
          this.company.city = branch.city
          this.company.postal_code = branch.postal_code
        }
      }

      if (this.applicationState != null) {
        this.applicationStatus = response.application.applicationstatus
        this.$store.commit('setggaApplication', response.application.applicationstatus)
        this.$store.commit('setGgaApplicationId', response.application.id)
        if (this.applicationStatus.id == 1) {
          this.$store.commit('setGgaApplicationDate', response.application.creation_date)
        } else if (this.applicationStatus.id == 4) {
          this.$store.commit('setGgaApplicationDate', response.application.rejection_date)
        }
      }

      this.$store.commit('setLoadingModal', false)
    },

    createApplication() {
      this.$store.commit('setLoadingModal', true)
      let obj = {
        club: {id: this.club.id}
      }

      requests.postSecureRequest('application/', obj, this.createApplicationCallback)
    },

    createApplicationCallback(response) {

      this.editButtonActivate = true;
      this.application = response;


      this.getAllDocuments();

      //χρειάζεται γιατι δεν ανανεώνεται η κατασταση της αίτησης
      this.$store.commit('setggaApplication', {id: 1, name: 'Υπο επεξεργασία'})
      this.$store.commit('setGgaApplicationProgress', 1)

      this.$store.commit('setLoadingModal', false)

      this.editApplication()
    },

    async editApplication() {

      this.editButtonActivate = true;
      await this.getClubAFM()
      //this.application = this.club.application;

      this.myDocuments = this.club.application.documents
      // if(this.club.application != null){
      //   this.myDocuments = this.club.application.documents
      // }else{
      //   this.myDocuments = []
      // }

      // else{
      //   if(this.isRegistered == false){
      //     for(let i = 0; i < 7; i++){
      //       this.myDocuments.push({id: i+1})
      //     }
      //   }else{
      //     for(let i = 0; i < 12; i++){
      //       this.myDocuments.push({id: i+1})
      //     }
      //   }
      // }
      this.getAllDocuments();
    },

    getAllDocuments() {
      this.$store.commit('setLoadingModal', true)
      requests.getSecureRequest('document/', this.club.id, this.getAllDocumentsCallback)
    },
    getAllDocumentsCallback(response) {

      this.documents = response;
      this.$store.commit('setLoadingModal', false)
      this.e13++
      this.getClubHasValidAmountOfMembers()
      this.getClubHasValidAmountOfCouncilMember()
      this.getInvalidBranches()
    },

    download(item) {
      requests.downloadFileRequest('application/download/' + this.application.id + '/' + item.id)
    },

    existingDocument(docID) {
      if (!!this.application &&
          Object.prototype.hasOwnProperty.call(this.application, 'documents') &&
          this.application.documents.map(d => d.id).indexOf(docID) !== -1) {
        this.fileExists = true
        return true
      } else {
        return false
      }
    },

    applicationSubmission() {
      this.$store.commit('setLoadingModal', true)
      let obj = {
        applicationId: this.application.id
      }

      requests.postSecureRequest('application/submission', obj, this.submissionCallback)
    },

    submissionCallback(response) {
      this.$store.commit('setThereWasAChangeInApplication', false)
      this.$store.commit('setInfoDialogObj', {text: 'Η οριστική υποβολή της αίτησης έγινε με επιτυχία.'})
      this.$store.commit('setInfoDialog', true)
      this.message = response.message
      this.$router.push('/nel-dashboard')
      this.$store.commit('setLoadingModal', false)
    },

    /* gotoMenu() {
       this.$router.push({path: '/edit-club-card'});
     },*/


    tempSave() {
      console.log('tempSave')
    },

    onFileUploadComplete(response) {
      let exists = false;

      let i = 0
      for (i = 0; i < this.myDocuments.length; i++) {
        if (this.myDocuments[i].id === response.item.document_id) {
          exists = true;
          break;
        }
      }
      if (exists) {
        this.myDocuments[i].application_document = response.item
      } else {
        this.myDocuments.push({
          id: response.item.document_id,
          description: this.documents[i].description,
          application_document: response.item
        })
      }

      this.application.documents = [...this.myDocuments];


      this.getMyDocByDocId(response.item.document_id)
    },
    getMyDocByDocId(docId) {
      if (this.myDocuments.find(d => d.id === docId) !== undefined) {
        // console.log(JSON.parse(JSON.stringify(this.myDocuments.find(d => d.id === docId))));
        return this.myDocuments.find(d => d.id === docId).application_document
      } else {
        return null
      }
    },

    getClubHasValidAmountOfMembers() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {application: this.application}
      requests.putSecureRequest('application/getClubHasValidAmountOfMembers', requestObj, this.getClubHasValidAmountOfMembersCallback)
    },
    getClubHasValidAmountOfMembersCallback(response) {

      this.clubHasValidAmountOfMembers = response
      this.$store.commit('setLoadingModal', false)
    },

    getClubHasValidAmountOfCouncilMember() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('council/get-club-has-valid-council-members', {club_id: this.$store.getters.selectedClub.id}, this.getClubHasValidAmountOfCouncilMemberCallback)
    },
    getClubHasValidAmountOfCouncilMemberCallback(response) {
      this.clubHasValidAmountOfCouncilMembers = response
      this.$store.commit('setLoadingModal', false)
    },

    downloadRejectionFile() {
      requests.downloadRejectionFileRequest('application/download-rejection-file', this.club.application.rejection_file_name)
    },

    fetchPrevApplicationDocuments() {


      let requestObj = {
        application: this.club.application
      }

      requests.postSecureRequest('application/fetch-documents', requestObj, this.fetchPrevApplicationDocumentsCallback)
    },
    async fetchPrevApplicationDocumentsCallback(response) {

      props.getInfoMessage(response)

      this.$store.commit('setLoadingModal', false)

      await requests.postSecureRequest('club/vat-with-application', {club: this.$store.getters.selectedClub}, await this.fetchPrevApplicationDocumentsCallback2)
    },
    async fetchPrevApplicationDocumentsCallback2(response) {

      this.club = response.club
      this.club.application = response.application
      this.edit = response.editApplication
      this.company = response.company
      this.isRegistered = this.club.is_registered

      this.application = this.club.application;
      this.myDocuments = this.club.application.documents

      this.$store.commit('setLoadingModal', false)
    },

    stepperStepColor() {
      return 'white text--primary'
    },
    handleResize() {
      this.pageWidth = window.innerWidth;
    },

    openDeleteApplicationDialog() {
      this.deleteApplicationDialog = true
    },

    deleteApplication() {
      this.deleteApplicationDialog = false
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('application/delete-draft-application',
          {application_id: this.application.id}, this.deleteApplicationCallback)
    },

    deleteApplicationCallback(response) {
      this.$store.commit('setInfoDialog', true)
      this.$store.commit('setInfoDialogObj', {text: response.message})
      this.$store.commit('setLoadingModal', false)
      this.$store.commit('setggaApplication', {})
      this.$store.commit('setGgaApplicationId', null)
      this.$store.commit('setGgaApplicationProgress', null)
      this.$store.commit('setGgaApplicationDate', '')
      this.$router.push({path: '/nel-dashboard'});
    },

    getInvalidBranches() {
      requests.postSecureRequest('branch/get-not-included-to-future-application', {applicationId: this.application.id}, this.getInvalidBranchesCallback)
    },
    getInvalidBranchesCallback(response) {
      this.invalidBranches = []
      this.invalidBranches = response
    },
    getInvalidMember() {
      requests.postSecureRequest('branch/get-invalid-branch-member',
          {branch_id: this.$store.getters.selectedClub.myBranchesOnClub[0].id}, this.getInvalidMemberCallback)
    },
    getInvalidMemberCallback(response) {

      this.invalidMemberNumber = response.counter
    },
    checksForApplication() {
      this.getInvalidMember()
      this.getInvalidBranches()
      this.getClubHasValidAmountOfMembers()
      this.getClubHasValidAmountOfCouncilMember()
    }
  },
  computed: {
    utilities() {
      return utilities
    },
    dashboard() {
      return dashboard
    },
    allDocsAreUploaded() {

      for (let i = 0; i < this.documents.length; i++) {
        let found = this.myDocuments.some(x => x.id === this.documents[i].id)
        if (found == false) {
          return false
        }
      }
      return true
    },
  },
}
</script>

<style scoped>
#selectedStep {
  background-color: v-bind('$vuetify.theme.themes.light.primary') !important;
  caret-color: white;
}

#selectedStepText {
  color: white;
}
</style>
