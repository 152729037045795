<template>

  <v-dialog max-width="400px" persistent v-model="$store.getters.infoDialog">
    <v-card>
      <v-card-title class="justify-center primary">
        <v-icon color="white">mdi-comment-alert-outline</v-icon>
      </v-card-title>
      <v-card-text class="mt-4 govgr-!-font-size-19">
        {{ $store.getters.infoDialogObj.text }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeInfoDialog()">Κλείσιμο</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
export default {
  name: "InfoDialog",

  methods: {
    closeInfoDialog() {
      this.$store.commit('setInfoDialog', false)
    }
  }
}
</script>

<style scoped>

</style>