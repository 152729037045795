<template>
  <div class="mx-2">
    <v-card>
      <v-card-text>
        <v-row justify="center" align="center">
          <v-col cols="4">
            <v-row justify="center" align="center">
              <v-col>
                <v-autocomplete label="Αθλητικά σωματεία"
                                :items="organizations"
                                return-object
                                dense
                                :item-text="item =>item.name"
                                no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                v-model="searchObj.organization"
                                @change="autoSelectClub()"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col>
                <v-autocomplete label="Επωνυμία λέσχης"
                                :items="clubs"
                                return-object
                                dense
                                :item-text="item =>item.name"
                                no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                v-model="searchObj.club"
                                @change="autoSelectOrganization()"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center" align="center">
              <v-col cols="6">
                <v-text-field
                    label="ΑΦΜ λέσχης"
                    type="number"
                    hide-spin-buttons
                    :disabled="disabledInput"
                    :rules="nameRules"
                    v-model="searchObj.club_vat"
                    dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select label="Κατάσταση λέσχης"
                          :items="clubStatuses"
                          return-object
                          dense
                          :item-text="item =>item.name"
                          v-model="searchObj.club_status"
                          :disabled="disabledInput"
                          no-data-text="Δεν υπάρχουν καταχωρήσεις"
                >
                </v-select>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical/>
          <v-col cols="4">
            <v-row>
              <v-col>
                <v-text-field
                    label="Όνομα υπευθύνου λέσχης"
                    v-model="searchObj.nel_first_name"
                    dense
                    :rules="nameRules"
                    :disabled="disabledInput"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="Επώνυμο υπευθύνου λέσχης"
                    v-model="searchObj.nel_last_name"
                    dense
                    :rules="nameRules"
                    :disabled="disabledInput"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    label="ΑΦΜ υπευθύνου λέσχης"
                    v-model="searchObj.nel_vat"
                    type="number"
                    hide-spin-buttons
                    dense
                    :rules="nameRules"
                    :disabled="disabledInput"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-divider vertical/>
          <v-col cols="4">
            <v-row>
              <v-col>
                <v-autocomplete label="Περιφέρεια"
                                :items="perifereies"
                                return-object
                                dense
                                no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                :item-text="item =>item.name"
                                v-model="searchObj.perifereia"
                                @change="autoSelectPerifereia()"
                                :disabled="perifereies.length == 0 || disabledInput"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete label="Περιφερειακή ενότητα"
                                :items="nomoi"
                                return-object
                                dense
                                no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                :item-text="item =>item.name"
                                v-model="searchObj.nomos"
                                @change="autoSelectNomos()"
                                :disabled="nomoi.length == 0"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete label="Δήμος"
                                :items="dhmoi"
                                return-object
                                dense
                                no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                :item-text="item =>item.name"
                                v-model="searchObj.dhmos"
                                :disabled="dhmoi.length == 0"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{on}">
            <v-btn icon v-on="on" @click="clear()" class="ma-2">
              <v-icon color="primary">
                mdi-broom
              </v-icon>
            </v-btn>
          </template>
          <span>
                    Καθαρισμός
                  </span>
        </v-tooltip>
        <v-btn class="primary white--text" :disabled="searchButtonValid" @click="search">
          Αναζήτηση
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-4 mb-3">
      <v-card-text>
        <v-data-table :page="pageNumber"
                      :footer-props="{page:1,
                                          pageCount:1,
                                          showCurrentPage:true,
                                          itemsPerPageOptions:[5,10,20,30],
                                          showFirstLastPage:true,
                                          itemsPerPageText:'Μέλη ανά σελίδα:',
                                          itemsPerPageAllText:'Όλα'}"
                      :headers="headers"
                      :items="clubsResponse"
                      :items-per-page="itemsPerPage"
                      no-data-text="Δέν υπάρχουν αποτελέσματα, παρακαλώ πατήστε αναζήτηση."
        >
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
          </template>
          <template v-slot:item="{item}">
            <tr class="click-cursor">
              <td class="text-center d-sm-table-cell d-flex" @click="openClubInfoDialog(item)">
                {{ item.name }}
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openClubInfoDialog(item)">
                {{ item.vat }}
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openClubInfoDialog(item)">
                <v-chip v-if="item.clubstatus_id == 1" style="background-color: green;"
                        class="govgr-!-font-size-14 white--text">
                  {{ item.clubstatus.name }}
                </v-chip>
                <v-chip v-else-if="item.clubstatus_id == 2 || item.clubstatus_id == 3"
                        style="background-color: red;" class="govgr-!-font-size-14 white--text">
                  {{ item.clubstatus.name }}
                </v-chip>
                <v-chip v-else-if="item.clubstatus_id == 5"
                        style="background-color: red;" class="govgr-!-font-size-14 white--text">
                  {{ item.clubstatus.name }}
                </v-chip>
                <v-chip v-else class="govgr-!-font-size-14">
                  {{ item.clubstatus.name }}
                </v-chip>
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openClubInfoDialog(item)">
                {{ item.branches[0].address }}
                {{ ', ' + item.branches[0].city }}
                {{ ', ' + item.branches[0].postal_code }}
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                <v-btn icon color="primary" @click="getNEL(item)">
                  <v-icon>mdi-account</v-icon>
                </v-btn>
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                <v-tooltip left>
                  <template v-slot:activator="{on}">
                    <v-btn v-on="on" fab x-small @click="openBranchesDialog(item)">
                      <v-icon large color="primary">mdi-information-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>
                      Παραρτήματα βάσει χωρικών κριτηρίων αναζήτησης
                  </span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="nelDialog" max-width="400px">
      <v-card>
        <v-card-title style="word-break: normal" class="primary white--text">
          Στοιχεία υπευθύνου λέσχης
        </v-card-title>
        <v-card-text class="mt-3">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ selectedUser.first_name }}</v-list-item-title>
              <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ selectedUser.last_name }}</v-list-item-title>
              <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ selectedUser.vat }}</v-list-item-title>
              <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ selectedUser.identification_document }}</v-list-item-title>
              <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ selectedUser.father_name }}</v-list-item-title>
              <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                {{
                  (selectedUser.phone_number == '' ||
                      selectedUser.phone_number == null) ?
                      '-' : selectedUser.phone_number
                }}
              </v-list-item-title>
              <v-list-item-subtitle>Τηλέφωνο επικοινωνίας</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                {{
                  (selectedUser.email == '' ||
                      selectedUser.email == null) ?
                      '-' : selectedUser.email
                }}
              </v-list-item-title>
              <v-list-item-subtitle>Email</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="nelDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="clubInfoDialog" fullscreen>
      <v-card>
        <v-card-title class="primary white--text">
          Στοιχεία της λέσχης: {{ selectedClub.name }}
          <v-spacer/>
          <v-btn icon color="white" @click="clubInfoDialog = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3">
          <ClubInfoForOfficials :key="componentKey" :clubProp="selectedClub"/>
        </v-card-text>
        <v-card-actions class="custom-card-actions" v-if="$store.getters.loggedinUser.role == 'ΓΓΑ'">
          <!--          <v-btn :disabled="selectedClub.clubstatus_id != 2"
                           @click="enableClub()" class="primary white&#45;&#45;text govgr-!-font-size-19">
                      Ενεργοποίηση (dev mode)
                    </v-btn>-->
          <v-spacer/>
          <v-btn :disabled="selectedClub.clubstatus_id == 2" @click="dissolutionClubDialog = true"
                 style="background-color: red" class="white--text govgr-!-font-size-19">
            Διάλυση λέσχης
          </v-btn>
        </v-card-actions>
        <v-card-actions class="custom-card-actions" v-if="$store.getters.loggedinUser.role == 'ΓΔΑ'">
          <v-spacer></v-spacer>
          <v-btn v-if="selectedClub.clubstatus_id == $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.ACTIVE"
                 style="background-color: red" @click="recallClubDialog = true"
                 class="white--text govgr-!-font-size-19">
            Ανάκληση
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-dialog persistent v-model="recallClubDialog" width="600px">
        <v-card>
          <v-card-title class="primary white--text" style="word-break: normal">
            Ανάκληση άδειας λέσχης {{ selectedClub.name }}
            <v-spacer/>
            <v-btn icon color="white" @click="recallClubDialog = false, clearRecallDialog()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row justify="center" align="center" class="mt-3">
              <div>
                <v-text-field dense style="width: 380px" outlined
                              placeholder="Αριθμός/Έτος" label="Αριθμός απόφασης"
                              v-model="courtDecisionNumber"></v-text-field>
                <v-dialog
                    ref="dialog"
                    v-model="openDatePicker"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        style="width: 380px"
                        readonly
                        :value="dateOfEnd"
                        outlined
                        required
                        label="Διάρκεια ισχύος (τουλάχιστον 2 έτη)" dense clearable
                        v-on="on" @click:clear="clearRecallDialog"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dateOfEnd"
                      :show-current="twoYearsFromNow"
                      scrollable
                      :min="(new Date(new Date().setFullYear(new Date().getFullYear() + 2)).toISOString().split('T')[0])"
                      locale="el"
                      color="primary">
                    <v-spacer></v-spacer>
                    <v-btn @click="openDatePicker = false"
                           color="primary">
                      Κλείσιμο
                    </v-btn>
                    <v-btn
                        @click="openDatePicker = false"
                        color="primary">
                      Επιλογή
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-textarea
                    dense
                    style="width: 380px"
                    class="mt-1" outlined
                    auto-grow
                    label="Αιτιολογία ανάκλησης" v-model="RecallReason"></v-textarea>
              </div>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn rounded
                   :disabled="RecallValid"
                   class="red--text"
                   outlined
                   @click="openCheckRecallDialog()"
            >
              <v-icon>
                mdi-cancel
              </v-icon>
              Ανάκληση
            </v-btn>
            <v-spacer/>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="dissolutionClubDialog" max-width="450">
        <v-card>
          <v-card-title style="word-break: normal" class="primary white--text govgr-!-font-size-22">
            Συμπλήρωση στοιχείων:
          </v-card-title>
          <v-card-text class="mt-2 govgr-!-font-size-19">
            <v-row align="center" justify="center" class="ma-1">
              <v-text-field class="mt-3" outlined placeholder="Αριθμός/Έτος"
                            label="Αριθμός απόφασης" v-model="CourtDecisionNumber">
              </v-text-field>
            </v-row>
            <v-row align="center" justify="center" class="ma-1">
              <v-textarea
                  dense
                  class="mt-1" outlined
                  auto-grow
                  label="Αιτιολογία διάλυσης" v-model="dissolutionReason"></v-textarea>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="dissolutionClubDialog = false, CourtDecisionNumber = ''">
              Κλείσιμο
            </v-btn>
            <v-spacer/>
            <v-btn class="primary white--text govgr-!-font-size-19"
                   :disabled="(this.CourtDecisionNumber.trim() == '')" @click="saveCourtOrder()">
              Αποθήκευση
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="checkBeforeDissolutionClubDialog" max-width="450">
        <v-card>
          <v-card-title class="justify-center primary">
            <v-icon color="white">mdi-comment-alert-outline</v-icon>
          </v-card-title>
          <v-card-text class="mt-2 govgr-!-font-size-19">
            <!--            <v-row align="center" justify="center" class="ma-1">
                          <v-textarea class="mt-3" outlined readonly v-model="this.courtDecisionText"></v-textarea>
                        </v-row>-->
            <v-row align="center" justify="center" class="ma-1 mt-3">
              Είστε σίγουρος/η για την διάλυση της λέσχης;
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="checkBeforeDissolutionClubDialog = false">
              Όχι
            </v-btn>
            <v-spacer/>
            <v-btn style="background-color: red" class="white--text govgr-!-font-size-19" @click="dissolutionClub()">
              Ναι, διάλυση
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>

    <v-dialog persistent v-model="recallDialog" max-width="450">
      <v-card>
        <v-card-title class="justify-center primary">
          <v-icon color="white">mdi-comment-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="mt-2 govgr-!-font-size-19">
          <v-row align="center" justify="center" class="ma-1 mt-3">
            Είστε σίγουρος/η για την ανάκληση της άδειας της λέσχης;
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="recallDialog = false">
            Όχι
          </v-btn>
          <v-spacer/>
          <v-btn style="background-color: red" class="white--text govgr-!-font-size-19" @click="recallClub">
            Ναι, ανάκληση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog persistent v-model="branchesDialog" width="1100px">
      <v-card>
        <v-card-title v-if="searchObj.perifereia.name == '' ||
        searchObj.perifereia.name == null ||
        searchObj.perifereia.name == undefined"
                      class="primary white--text">
          Παραρτήματα στην περιοχή αναζήτησης: Ελλάδα
        </v-card-title>
        <v-card-title v-else class="primary white--text" style="word-break: normal">
          Παραρτήματα στην περιοχή αναζήτησης:{{ searchObj.perifereia.name }}
          {{
            (searchObj.nomos.name == ''
                || searchObj.nomos.name == null || searchObj.nomos.name == undefined) ?
                '' : ', ' + searchObj.nomos.name
          }}
          {{
            (searchObj.dhmos.name == '' ||
                searchObj.dhmos.name == null || searchObj.dhmos.name == undefined) ?
                '' : ', ' + searchObj.dhmos.name
          }}
        </v-card-title>
        <v-card-text>
          <div v-for="item in selectedBranches" :key="item.id">
            <v-row justify="center" align="center">
              <v-col cols="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>Επωνυμία παραρτήματος</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="5">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.address }}
                      {{ ', ' + item.city }}
                      {{ ', ' + item.postal_code }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="3">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip v-if="item.branchstatus_id == 2" class="white--text" color="green">
                        {{ item.branchstatus.name }}
                      </v-chip>
                      <v-chip v-else-if="item.branchstatus_id == 3 ||
                                          item.branchstatus_id == 5 ||
                                          item.branchstatus_id == 6" color="red"
                              class="white--text">{{ item.branchstatus.name }}
                      </v-chip>
                      <v-chip v-else>{{ item.branchstatus.name }}</v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle>Κατάσταση</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-divider/>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="branchesDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "@/requests";
import ShowApplication from "@/components/NEL/ShowApplication.vue";
import ClubInfoForOfficials from "@/components/officials/ClubInfoForOfficials.vue";
import router from "@/router";

export default {
  name: "SearchClubs",
  components: {ClubInfoForOfficials},
  computed: {
    searchButtonValid() {
      if (this.searchObj.nel_vat.trim() != '' && this.searchObj.nel_vat.trim().length < 3) {
        return true
      }
      if (this.searchObj.nel_first_name.trim() != '' && this.searchObj.nel_first_name.trim().length < 3) {
        return true
      }
      if (this.searchObj.nel_last_name.trim() != '' && this.searchObj.nel_last_name.trim().length < 3) {
        return true
      }
      if (this.searchObj.club_vat.trim() != '' && this.searchObj.club_vat.trim().length < 3) {
        return true
      }
      return false
    },

    RecallValid() {
      if (this.courtDecisionNumber == '') {
        return true
      }
      if (this.dateOfEnd == '' || this.dateOfEnd == null) {
        return true
      }
      if (this.RecallReason == '') {
        return true
      }

      return false
    }
  },
  data: () => ({
    searchObj: {
      organization: '',
      club: '',
      club_vat: '',
      club_status: '',
      nel_first_name: '',
      nel_last_name: '',
      nel_vat: '',
      perifereia: {
        name: ''
      },
      nomos: {
        name: ''
      },
      dhmos: {
        name: ''
      },
    },
    nameRules: [
      v => !!v || 'Το πεδίο πρέπει να έχει τουλάχιστον τρεις χαρακτήρες.',
      v => !!v && (v.trim() == '' || v.trim().length >= 3) || 'Το πεδίο πρέπει να έχει τουλάχιστον τρεις χαρακτήρες.',
    ],
    organizations: [],
    clubs: [],
    clubsResponse: [],
    pageNumber: 1,
    itemsPerPage: 5,
    headers: [
      {
        text: 'Επωνυμία λέσχης',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'ΑΦΜ λέσχης',
        align: 'center',
        sortable: false,
        value: 'vat',
      },
      {
        text: 'Κατάσταση λέσχης',
        align: 'center',
        sortable: false,
        value: 'consent',
      },
      {
        text: 'Διεύθυνση έδρας',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Υπεύθυνος λέσχης',
        align: 'center',
        sortable: false,
        value: 'user',
      },
      {
        text: 'Παραρτήματα',
        align: 'center',
        sortable: false,
        value: 'branches',
      },
    ],
    nelDialog: false,
    selectedUser: {},
    disabledInput: false,
    clubInfoDialog: false,
    selectedClub: {},
    componentKey: 0,
    perifereies: [],
    nomoi: [],
    dhmoi: [],
    dissolutionClubDialog: false,
    CourtDecisionNumber: '',
    courtDecisionText: '',
    checkBeforeDissolutionClubDialog: false,
    branchesDialog: false,
    selectedBranches: {},
    clubStatuses: [],
    recallClubDialog: false,
    courtDecisionNumber: '',
    // dateOfEnd: (new Date(new Date().setFullYear(new Date().getFullYear() + 2)).toISOString().split('T')[0]),
    dateOfEnd: '',
    twoYearsFromNow: (new Date(new Date().setFullYear(new Date().getFullYear() + 2)).toISOString().split('T')[0]),
    openDatePicker: false,
    RecallReason: '',
    dissolutionReason: '',
    recallDialog: false,
  }),
  methods: {
    clear() {
      this.searchObj = {
        organization: '',
        club: '',
        club_vat: '',
        club_status: '',
        nel_first_name: '',
        nel_last_name: '',
        nel_vat: '',
        perifereia: '',
        nomos: '',
        dhmos: '',
      }
      this.nomoi = []
      this.dhmoi = []
      this.disabledInput = false
      this.search()
    },

    clearFromAutoselection() {
      this.searchObj.club_vat = ''
      this.searchObj.club_status = ''
      this.searchObj.nel_first_name = ''
      this.searchObj.nel_last_name = ''
      this.searchObj.nel_vat = ''
      this.searchObj.perifereia = ''
      this.searchObj.nomos = ''
      this.searchObj.dhmos = ''

      this.nomoi = []
      this.dhmoi = []
    },
    getOrganizations() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('organization/', {}, this.getOrganizationsCallback)
    },
    getOrganizationsCallback(response) {
      this.organizations = []
      for (let i = 0; i < response.length; i++) {
        if (response[i].club.clubstatus_id != this.$store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.UNDER_CREATION) {
          this.organizations.push(response[i])
        }
      }
      this.searchObj.organization = null
      requests.getSecureRequest('region/', '', this.getRegionCallback)
    },
    getClubs() {
      requests.getSecureRequest('club/', '', this.getClubsCallback)
    },
    getClubsCallback(response) {
      this.clubs = []
      for (let i = 0; i < response.length; i++) {
        if (response[i].clubstatus_id != this.$store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.UNDER_CREATION) {
          this.clubs.push(response[i])
        }
      }
      this.getOrganizations()
    },
    getRegionCallback(response) {
      this.perifereies = []
      this.perifereies = response
      this.$store.commit('setLoadingModal', false)
      this.getClubStatuses()
    },

    autoSelectClub() {
      for (let i = 0; i < this.clubs.length; i++) {
        if (this.searchObj.organization.id == this.clubs[i].organization_id) {
          this.searchObj.club = this.clubs[i]
        }
      }
      this.clearFromAutoselection()
      this.disabledInput = true
    },

    autoSelectOrganization() {
      if (this.searchObj.club == '' || this.searchObj.club == undefined || this.searchObj.club == null) this.autoSelectClub()
      for (let i = 0; i < this.organizations.length; i++) {
        if (this.searchObj.club.organization_id == this.organizations[i].id) {
          this.searchObj.organization = this.organizations[i]
        }
      }
      this.clearFromAutoselection()
      this.disabledInput = true
    },

    autoSelectPerifereia() {
      this.nomoi = []
      this.dhmoi = []
      for (let nomos of this.searchObj.perifereia.regional_units) {
        this.nomoi.push(nomos)
      }
    },

    autoSelectNomos() {
      this.dhmoi = []
      for (let dhmos of this.searchObj.nomos.municipalities) {
        this.dhmoi.push(dhmos)
      }
    },

    search() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_id: this.searchObj.club.id,
        club_vat: this.searchObj.club_vat,
        club_status_id: this.searchObj.club_status.id,
        nel_first_name: this.searchObj.nel_first_name,
        nel_last_name: this.searchObj.nel_last_name,
        nel_vat: this.searchObj.nel_vat,
        region_id: this.searchObj.perifereia.id,
        regional_unit_id: this.searchObj.nomos.id,
        municipality_id: this.searchObj.dhmos.id,
      }
      requests.postSecureRequest('club/search-clubs', requestObj, this.searchCallback)
    },

    searchCallback(response) {
      this.clubsResponse = []
      //this.clubsResponse = response.clubs
      for (let i = 0; i < response.clubs.length; i++) {
        if (response.clubs[i].clubstatus_id != this.$store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.UNDER_CREATION) {
          this.clubsResponse.push(response.clubs[i])
        }
      }
      this.$store.commit('setLoadingModal', false)
    },

    getNEL(item) {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_vat: item.vat
      }
      requests.postSecureRequest('user/get-nel-by-club-vat',
          requestObj, this.getNelByClubVatCallback)
    },
    getNelByClubVatCallback(response) {
      console.log(JSON.parse(JSON.stringify(response)));
      this.selectedUser = response
      this.nelDialog = true
      this.$store.commit('setLoadingModal', false);
    },

    getClubStatuses() {
      requests.getSecureRequest('club/get-club-statuses', '', this.getClubStatusesCallback)
    },
    getClubStatusesCallback(response) {
      for (let i = 0; i < response.length; i++) {
        if (response[i].id != this.$store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.UNDER_CREATION) {
          this.clubStatuses.push(response[i])
        }
      }
      console.log(this.clubStatuses);
    },
    openClubInfoDialog(item) {
      this.selectedClub = item
      this.componentKey++
      this.clubInfoDialog = true
    },

    enableClub() {
      this.$store.commit("setLoadingModal", true)
      let requestObj = {
        club_id: this.selectedClub.id,
        clubstatus_id: 1,
      }
      requests.postSecureRequest('club/neas-change-club-status',
          requestObj, this.enableClubCallBack)
    },

    enableClubCallBack(response) {
      console.log(JSON.parse(JSON.stringify(response)));
      this.$store.commit('setSelectedClub', response.club)
      this.$store.commit("setInfoDialogObj", {text: response.message})
      this.$store.commit("setInfoDialog", true)
      this.$store.commit("setLoadingModal", false)
      this.clubInfoDialog = false
      this.search()
    },
    saveCourtOrder() {
      /* this.courtDecisionText ='Ως νόμιμος εκπρόσωπος του Αθλητικού Σωματείου,' +
           ' δηλώνω υπεύθυνα την διάλυση της λέσχης φιλάθλων,' + '"' + this.selectedClub.name + '"' +
           ' όπως αυτή επιβλήθηκε με απόφαση υπ´αριθμό ' + this.CourtDecisionNumber*/
      this.checkBeforeDissolutionClubDialog = true;
    },

    dissolutionClub() {
      this.$store.commit("setLoadingModal", true)
      let requestObj = {
        club_id: this.selectedClub.id,
        clubstatus_id: 2,
        reason: this.CourtDecisionNumber,
        dissolution_reason: this.dissolutionReason,
      }
      requests.postSecureRequest('club/neas-change-club-status',
          requestObj, this.dissolutionClubCallBack)
    },

    dissolutionClubCallBack(response) {
      console.log(JSON.parse(JSON.stringify(response)));
      this.dissolutionClubDialog = false
      this.CourtDecisionNumber = ''
      this.checkBeforeDissolutionClubDialog = false
      this.$store.commit('setSelectedClub', response.club)
      this.$store.commit("setInfoDialogObj", {text: response.message})
      this.$store.commit("setInfoDialog", true)
      this.$store.commit("setLoadingModal", false)
      this.clubInfoDialog = false
      this.search()
    },

    openBranchesDialog(item) {
      this.selectedBranches = item.filter
      this.branchesDialog = true
    },

    clearRecallDialog() {
      this.dateOfEnd = ''
      if (this.recallClubDialog == false) {
        this.courtDecisionNumber = ''
        this.RecallReason = ''
      }
    },

    openCheckRecallDialog() {
      this.recallDialog = true
    },

    recallClub() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_id: this.selectedClub.id,
        number: this.courtDecisionNumber,
        reason: this.RecallReason,
        recall_until_date: this.dateOfEnd
      }
      requests.postSecureRequest('club/license-recall', requestObj, this.recallClubCallback)
    },
    recallClubCallback(response) {
      this.courtDecisionNumber = ''
      this.RecallReason = ''
      this.dateOfEnd = ''

      this.recallDialog = false
      this.recallClubDialog = false
      this.clubInfoDialog = false

      this.pageNumber = 1
      this.search()
    },

  },
  created() {
    this.getClubs();
  }
}
</script>

<style scoped>

.custom-card-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 30px;
  margin-inline: 15px;
}

</style>
