<template>
<div align="center" justify="center">
        <v-file-input
                dense
                v-model="file"
                clearable
                chips
                placeholder="Upload your document"
                label="Αρχείο"
                :success-messages="messageFile"
                prepend-icon="mdi-file"
                append-outer-icon="info"
                :loading="fileLoading"
                :accept="fileSuffix"
        >
            <template v-slot:selection="{ text }">
                <v-chip
                        small
                        label
                        color="primary"
                >
                    {{ text }}
                </v-chip>
            </template>
        </v-file-input>
        <v-btn v-if="submitButton" text :disabled="file==null" @click="submitFile()">ΜΕΤΑΦΟΡΤΩΣΗ</v-btn>
</div>
</template>

<script>
    import requests from "@/requests";
    import {eventBus} from "@/main";

    export default {
        name: "FileUploadReusable",
        data:()=>({
            file : null,
            fileLoading:false,
            messageFile:'',
            showInfoFlag:false,
            fileSuffix: ['.pdf', '.png', '.jpeg']
        }),
        props:{
            id: String,
            url:String,
            submitButton:  Boolean,
            doc: Object,
            application: Object,
            eventToEmitOnComplete: String,
        },
        methods:{

            submitFile() {
                this.messageFile ='';
                this.fileLoading= true;
                /*
                  Initialize the form data
                */
                let formData = new FormData();

                formData.append('file', this.file,  encodeURI(this.file.name));
                formData.append('id', this.doc.id);
                formData.append('applicationId', this.application.id);

                requests.uploadFilePostAsync(this.url, formData, this.uploadFilePostCallBack)
            },
            uploadFilePostCallBack(response) {
                this.fileLoading=false;
                this.messageFile = 'new file uploaded successfully';
                this.$store.commit('setThereWasAChangeInApplication', true)
                this.file=null;
                //this.$store.commit('setUploadDialog', false);
                eventBus.$emit(this.eventToEmitOnComplete, response);
            },


        }
    }
</script>

<style scoped>

</style>
