<template>
  <div>
    <v-card>
      <v-card-title class="primary white--text govgr-!-font-size-30" style="word-break: normal">
        Χορήγηση ετήσιας συναίνεσης
      </v-card-title>
      <v-card-text class="mt-2 govgr-!-font-size-19">
        <v-row>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
            <v-card-subtitle class="govgr-!-font-weight-bold govgr-!-font-size-24">
              Λέσχη
            </v-card-subtitle>
            <v-row class="ma-4">
              <v-col>
                <v-row dense>
                  <v-col class="govgr-!-font-weight-bold">
                    Επωνυμία:
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-if="!$store.getters.initializationNEAS.hasSeparateAFM && !$store.getters.initializationNEAS.exists"
                        v-model="$store.getters.initializationNEAS.club_name"
                    />
                    <span v-else>
                      {{ $store.getters.initializationNEAS.club_name }}
                    </span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="govgr-!-font-weight-bold">
                    ΑΦΜ:
                  </v-col>
                  <v-col>
                    {{ $store.getters.initializationNEAS.club_vat }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row class="mr-2 ml-4">
              <v-textarea v-if="consent_end != ''" outlined readonly v-model="consentText" height="150px"
                          :label="`Ισχύς συναίνεσης απο: ${(consent_start == '')? ' - ' : consent_start} εως: ${(consent_end == '')? ' - ' : consent_end}`"/>
            </v-row>
          </v-col>
          <v-divider v-if="pageWidth>960" vertical/>
          <v-divider class="mx-1 mt-3" v-if="pageWidth<=960"/>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
            <v-card-subtitle class="govgr-!-font-weight-bold govgr-!-font-size-24">
              Υπεύθυνος λειτουργίας
              <v-btn icon v-if="!$store.getters.initializationNEAS.exists" @click="openRepresentativeDialgog">
                <v-icon v-if="nel.vat != ''">edit</v-icon>
                <v-icon v-else color="primary">add</v-icon>
              </v-btn>
            </v-card-subtitle>
            <v-row class="ma-4">
              <v-col>
                <v-row dense>
                  <v-col class="govgr-!-font-weight-bold">
                    ΑΦΜ:
                  </v-col>
                  <v-col>
                    {{ (nel.vat == '') ? '-' : nel.vat }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="govgr-!-font-weight-bold">
                    ΑΔΤ:
                  </v-col>
                  <v-col>
                    {{ (nel.identification_document == '') ? '-' : nel.identification_document }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="govgr-!-font-weight-bold">
                    Όνομα:
                  </v-col>
                  <v-col>
                    {{ (nel.first_name == '') ? '-' : nel.first_name }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="govgr-!-font-weight-bold">
                    Επώνυμο:
                  </v-col>
                  <v-col>
                    {{ (nel.last_name == '') ? '-' : nel.last_name }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col class="govgr-!-font-weight-bold">
                    Πατρώνυμο:
                  </v-col>
                  <v-col>
                    {{ (nel.father_name == '') ? '-' : nel.father_name }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn v-if="!$store.getters.initializationNEAS.exists" class="primary govgr-!-font-size-19"
               @click="openDialogToConsent">
          Χορήγηση συναίνεσης
        </v-btn>
        <v-btn v-if="$store.getters.initializationNEAS.exists" class="primary govgr-!-font-size-19"
               @click="openDialogToConsent">
          Ανανέωση συναίνεσης
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="representativeDialog" max-width="500">
      <v-card>
        <v-card-title>Ορισμός υπευθύνου λειτουργίας λέσχης</v-card-title>
        <v-card-text>
          <v-form>
            <v-row class="mt-2" dense>
              <v-col cols="6">
                <v-text-field outlined label="ΑΦΜ" :rules="vatRule" counter="9" v-model="rep.afm"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="ΑΔΤ" v-model="rep.adt"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="Όνομα" v-model="rep.name"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="Επώνυμο" v-model="rep.lastname"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined label="Πατρώνυμο" v-model="rep.fathername"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeRepresentativesDialog" text>ΚΛΕΙΣΙΜΟ</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" :disabled="repFormIsValid()" @click="saveRepresentative">ΕΙΣΑΓΩΓΗ</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogToConsent" width="750px" persistent>
      <v-card>
        <v-card-title class="primary white--text">
          Χορήγηση συναίνεσης
        </v-card-title>
        <v-card-text>
          <v-textarea outlined readonly class="mt-8" v-model="consentText"/>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="closeDialogToConsent">
            Κλείσιμο
          </v-btn>
          <v-spacer/>
          <v-btn class="primary" @click="createOrGiveYearlyConsent">
            Συναινώ
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import requests from "@/requests";
import prop from "@/properties";
import utilities from "@/utilities";
import {indexOf} from "core-js/internals/array-includes";

export default {
  name: "ClubConsent",
  data: () => ({
    nel: {
      vat: '',
      identification_document: '',
      first_name: '',
      last_name: '',
      father_name: '',
    },
    pageWidth: window.innerWidth,
    representativeDialog: false,
    rep: {},
    dialogToConsent: false,
    consentText: '',
    consent_end: '',
    consent_start: '',
    vatRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο',
      v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
    ],
  }),
  methods: {
    getNEL() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_vat: this.$store.getters.initializationNEAS.club_vat
      }
      requests.postSecureRequest('user/get-nel-by-club-vat', requestObj, this.getNelByClubVatCallback)
    },
    getNelByClubVatCallback(response) {
      this.nel.vat = response.vat
      this.nel.identification_document = response.identification_document
      this.nel.first_name = response.first_name
      this.nel.last_name = response.last_name
      this.nel.father_name = response.father_name

      this.consentText = 'Ως νόμιμος εκπρόσωπος του Αθλητικού Σωματείου συναινώ, ' +
          'βάσει της παρ. 3 του άρθρου 41Β του ν. 2725/1999, ' +
          'στην αναγνώριση και την εκ νέου συνέχιση της λειτουργίας της Λέσχης φιλάθλων με την  επωνυμία "' + this.$store.getters.initializationNEAS.club_name + '". ' +
          'Υπεύθυνος λειτουργίας ορίζεται ο/η ' + this.nel.last_name + ' ' + this.nel.first_name + ' του ' + this.nel.father_name + ' με ΑΦΜ ' + this.nel.vat + '.'


      this.getClub()
    },

    getClub() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_vat: this.$store.getters.initializationNEAS.club_vat
      }
      requests.postSecureRequest('club/get-club-by-vat', requestObj, this.getClubCallback)
    },
    getClubCallback(response) {
      if (response != null) {
        if (response.consent_end != null) {
          if ((new Date()).toISOString() < response.consent_end) {
            this.consent_end = utilities.formatDate(response.consent_end)
          }
        }
        if (response.consent_start != null) {
          this.consent_start = utilities.formatDate(response.consent_start)
        }
      }


      let club = this.$store.getters.selectedClub
      let temp = this.$store.getters.initializationNEAS

      if (Object.keys(club).length > 0) {
        temp.club_name = response.name
        club.name = temp.club_name
        this.$store.commit('setSelectedClub', club)
      }
      this.$store.commit('setLoadingModal', false)
    },

    createOrGiveYearlyConsent() {
      this.$store.commit('setLoadingModal', true)
      if (this.$store.getters.initializationNEAS.exists) {
        this.giveYearlyConsent()
      } else {
        this.createClubWithConsent()
      }
    },

    giveYearlyConsent() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_vat: this.$store.getters.initializationNEAS.club_vat
      }
      requests.postSecureRequest('club/give-yearly-consent', requestObj, this.giveYearlyConsentCallback)
    },
    giveYearlyConsentCallback(response) {
      this.$store.commit('setInfoSnackBarObj', {text: 'Η χορήγηση συναίνεσης ήταν επιτυχής.<br>Ισχύς μέχρι ' + response.consent_end})
      this.$store.commit('setInfoSnackBar', true)
      this.$store.commit('setLoadingModal', false)
      this.closeDialogToConsent()
      this.getNEL()
    },

    createClubWithConsent() {
      this.$store.commit('setLoadingModal', true)
      let obj = {
        organization: {
          vat: this.$store.getters.initializationNEAS.organization_vat,
          name: this.$store.getters.initializationNEAS.organization_name,
        },
        club_user: {
          vat: this.nel.vat,
          identification_document: this.nel.identification_document,
          first_name: this.nel.first_name,
          last_name: this.nel.last_name,
          father_name: this.nel.father_name,
        },
        club: {
          vat: this.$store.getters.initializationNEAS.club_vat,
          name: this.$store.getters.initializationNEAS.club_name,
          address: this.$store.getters.initializationNEAS.club_address,
          city: this.$store.getters.initializationNEAS.club_city,
          postal_code: this.$store.getters.initializationNEAS.club_postal_code,
        },
      }

      requests.postSecureRequest('club/', obj, this.createClubWithConsentCallback);
    },
    createClubWithConsentCallback(response) {
      let consent_end = response.club.consent_end
      prop.getInfoMessageDialog('Η λέσχη καταχωρίσθηκε επιτυχώς και η περίοδος ισχύος της συναίνεσης λήγει στις ' + utilities.formatDate(consent_end.substring(0, consent_end.indexOf('T'))) + '.')

      this.$store.commit('setInitializationNEAS', {
        organization_vat: this.$store.getters.initializationNEAS.organization_vat,
        club_vat: this.$store.getters.initializationNEAS.club_vat,
        organization_name: this.$store.getters.initializationNEAS.organization_name,
        hasSeparateAFM: this.$store.getters.initializationNEAS.hasSeparateAFM,
        club_name: this.$store.getters.initializationNEAS.club_name,
        club_address: this.$store.getters.initializationNEAS.firmAddress,
        club_city: this.$store.getters.initializationNEAS.firmCity,
        club_postal_code: this.$store.getters.initializationNEAS.firmPostalCode,
        exists: true
      })
      this.$store.commit('setSelectedClub', response.club)

      this.setMenuItems()
      this.closeDialogToConsent()
      this.getNEL()
    },

    openRepresentativeDialgog() {
      this.representativeDialog = true;
    },
    closeRepresentativesDialog() {
      this.rep = {}
      this.representativeDialog = false;
    },

    saveRepresentative() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('checks/validate-inserted-rep', this.rep, this.saveRepresentativeCallback)
    },
    saveRepresentativeCallback(response) {

      this.nel.vat = response.afm
      this.nel.identification_document = response.adt
      this.nel.first_name = response.name
      this.nel.last_name = response.lastname
      this.nel.father_name = response.fathername
      this.representativeDialog = false;
      this.$store.commit('setLoadingModal', false)
    },

    repFormIsValid() {
      return (!this.rep.name || this.rep.name.length === 0) ||
          (!this.rep.lastname || this.rep.lastname.length === 0) ||
          (!this.rep.fathername || this.rep.fathername.length === 0) ||
          (!this.rep.adt || this.rep.adt.length === 0) ||
          (!this.rep.afm || this.rep.afm.length === 0);
    },

    setMenuItems() {
      let menuItems = [
        {
          to: '/role-selection',
          name: 'Αρχική σελίδα'
        },
        {
          to: '/club-info',
          name: 'Στοιχεία Λέσχης',
          disabled: !this.$store.getters.initializationNEAS.exists
        },
        {
          to: '/history-of-club-by-season',
          name: 'Ιστορικό λέσχης',
          disabled: !this.$store.getters.initializationNEAS.exists
        },
        {
          name: 'Ενέργειες',
          children: [
            {
              to: '/club-consent',
              name: ' Χορήγηση/Ανανέωση συναίνεσης',
              //disabled: (this.$store.getters.selectedClub.clubstatus_id==2)
            },
            {
              to: '/change-club-representative',
              name: ' Αλλαγή υπευθύνου λέσχης φιλάθλων',
              disabled: (!this.$store.getters.initializationNEAS.exists) /*|| this.$store.getters.selectedClub.clubstatus_id==2*/
            },
            {
              to: '/history-of-actions',
              name: ' Ιστορικό ενεργειών',
              disabled: !this.$store.getters.initializationNEAS.exists
            },

            {
              to: '/dissolution-club',
              name: ' Διάλυση λέσχης',
              disabled: !this.$store.getters.initializationNEAS.exists
            },
          ]
        },
        {
          to: '/search-for-members',
          name: 'Αναζήτηση μελών λέσχης',
          disabled: !this.$store.getters.initializationNEAS.exists
        },
        {
          to: '/notifications-page',
          name: 'Ειδοποιήσεις',
          disabled: false
        },

      ]
      this.$store.commit('setMenuItems', menuItems);
    },

    openDialogToConsent() {
      if (this.$store.getters.initializationNEAS.club_name.trim() == '' || this.$store.getters.initializationNEAS.club_name == null || this.$store.getters.initializationNEAS.club_name == undefined) {
        this.$store.commit('setSnackBarObj', {text: 'Η επωνυμία της λέσχης δεν πρέπει να είναι κενή.'})
        this.$store.commit('setSnackBar', true)
        return
      }
      if (this.nel.vat == '') {
        this.$store.commit('setSnackBarObj', {text: 'Δεν έχει οριστεί υπεύθυνος λειτουργίας λέσχης φιλάθλων.'})
        this.$store.commit('setSnackBar', true)
        return
      }
      if (this.$store.getters.initializationNEAS.exists) {
        this.consentText = 'Ως νόμιμος εκπρόσωπος του Αθλητικού Σωματείου συναινώ, ' +
            'βάσει της παρ. 3 του άρθρου 41Β του ν. 2725/1999, ' +
            'στην αναγνώριση και την εκ νέου συνέχιση της λειτουργίας της Λέσχης φιλάθλων με την επωνυμία "' + this.$store.getters.initializationNEAS.club_name + '". ' +
            'Υπεύθυνος λειτουργίας ορίζεται ο/η ' + this.nel.last_name + ' ' + this.nel.first_name + ' του ' + this.nel.father_name + ' με ΑΦΜ ' + this.nel.vat + '.'
      } else {
        this.consentText = 'Ως νόμιμος εκπρόσωπος του Αθλητικού Σωματείου συναινώ, ' +
            'βάσει της παρ. 3 του άρθρου 41Β του ν. 2725/1999, ' +
            'στην αναγνώριση και την λειτουργία της Λέσχης φιλάθλων με την επωνυμία "' + this.$store.getters.initializationNEAS.club_name + '". ' +
            'Υπεύθυνος λειτουργίας ορίζεται ο/η ' + this.nel.last_name + ' ' + this.nel.first_name + ' του ' + this.nel.father_name + ' με ΑΦΜ ' + this.nel.vat + '.'

      }
      this.dialogToConsent = true
    },
    closeDialogToConsent() {
      this.dialogToConsent = false
    },
    handleResize() {
      this.pageWidth = window.innerWidth;
    }

  },
  created() {
    if (this.$store.getters.initializationNEAS.exists) {
      this.getNEL()
    }

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>

</style>
