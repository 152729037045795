<template>
  <div>
    <v-row class="mb-1">
      <v-col class="mx-auto text-center font-weight-light headline">
        {{ 'Αναζήτηση παρελθοντικών αιτήσεων ανά λέσχη φιλάθλων' }}
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
      </v-card-title>
      <v-card-subtitle>
        <v-select placeholder="Επίλεξε Λέσχη Φιλάθλων"
                  v-model="selectedClub"
                  :items="clubs"
                  item-text="name"
                  no-data-text="Δεν υπάρχουν καταχωρήσεις"
                  @change="fetchApplications"
                  return-object/>
      </v-card-subtitle>
      <v-card-text>
        <v-data-table :headers="headers"
                      :items="applications"
                      :items-per-page="10"
                      :footer-props="{page:1,
                                      pageCount:1,
                                      showCurrentPage:true,
                                      itemsPerPageOptions:[5,10,15,30],
                                      showFirstLastPage:true,
                                      itemsPerPageText:'Αιτήσεις ανά σελίδα:',
                                      itemsPerPageAllText:'Όλα'}"
                      no-data-text="Δέν υπάρχουν αποτελέσματα.">
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
          </template>
          <template v-slot:item="{item}">
            <tr>
              <td>{{ item.id }}</td>
              <td>{{ item.applicationstatus.name }}</td>
              <td>{{ item.creation_date }}</td>
              <td>{{ item.submission_date }}</td>
              <td>{{ item.rejection_date }}</td>
              <td>{{ item.approval_date }}</td>
              <td class="text-center d-sm-table-cell d-flex">
                <v-chip
                    v-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.APPROVED"
                    class="white--text" color="green">{{ item.applicationstatus.name }}
                </v-chip>
                <v-chip v-else-if="item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.PREVIOUS_YEAR
                 || item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.DRAFT
                 || item.applicationstatus.id == $store.getters.loggedinUser.CONSTANTS.APPLICATION.STATUS.SUBMITTED">
                  {{ item.applicationstatus.name }}
                </v-chip>
                <v-chip v-else color="red" class="white--text">{{ item.applicationstatus.name }}</v-chip>
              </td>
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <td v-on="on" @click="openUserInfoDialog(item.user)">{{ item.user.first_name }}
                    {{ item.user.last_name }}
                  </td>
                </template>
                <span>
                  Πατήστε για περισσότερες πληροφορίες.
                </span>
              </v-tooltip>
              <td class="text-center d-sm-table-cell d-flex">
                <v-tooltip left>
                  <template v-slot:activator="{on}">
                    <v-btn fab
                           small
                           v-on="on"
                           @click="openApplicationDialog(item)">
                      <v-icon color="primary">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>
                      Προβολή αίτησης
                    </span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog persistent max-width="600px" v-if="applications.length>0" v-model="this.userInfoDialog">
      <v-card>
        <v-card-title class="primary white--text govgr-!-font-size-30 mb-3" style="word-break: normal">
          <v-row align="center" justify="center" class="ma-2 text-h4">
            Στοιχεία αιτούντος
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              Όνομα:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.first_name != null) ? this.selectedUser.first_name : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Επώνυμο:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.last_name != null) ? this.selectedUser.last_name : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Πατρώνυμο:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.father_name != null) ? this.selectedUser.father_name : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Μητρώνυμο:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.mother_name != null) ? this.selectedUser.mother_name : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Ημερομηνία γέννησης:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.date_of_birth != null) ? this.selectedUser.date_of_birth : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Διεύθυνση:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.address != null) ? this.selectedUser.address : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Πόλη:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.city != null) ? this.selectedUser.city : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              ΤΚ:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.postal_code != null) ? this.selectedUser.postal_code : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Αριθμός επικοινωνίας:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.phone_number != null) ? this.selectedUser.phone_number : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              Ηλεκτρονικό ταχυδρομείο:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.email != null) ? this.selectedUser.email : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              ΑΦΜ:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.vat != null) ? this.selectedUser.vat : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              ΑΜΚΑ:
            </v-col>
            <v-col cols="6">
              {{ (this.selectedUser.amka != null) ? this.selectedUser.amka : '-' }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              ΑΔΤ:
            </v-col>
            <v-col cols="6">
              {{
                (this.selectedUser.identification_document != null) ? this.selectedUser.identification_document : '-'
              }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="userInfoDialog = false" text>Κλείσιμο</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="applicationDialog" v-if="applications.length>0" fullscreen>
      <v-card>
        <v-card-title class="primary white--text">
          Πληροφορίες αίτησης με αναγνωριστικό: #{{ applicationId }}
          <v-spacer/>
          <v-btn icon color="white" @click="applicationDialog = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ShowApplication :key="componentKey" :applicationObject="selectedApplication" :applicationId="applicationId"
                           :user="user" :checkBox="false"/>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "@/requests";
import ShowApplication from "@/components/NEL/ShowApplication.vue";

export default {
  name: "SearchApplications",
  components: {ShowApplication},
  data: () => ({
    selectedClub: {},
    clubs: [],
    selectedUser: {},
    userInfoDialog: false,
    componentKey: 0,
    headers: [
      {
        text: 'Αναγνωριστικό Αίτησης',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Κατάσταση',
        sortable: false,
        value: 'applicationstatus.name',
      },
      {
        text: 'Ημερομηνία δημιουργίας',
        sortable: false,
        value: 'creation_date',
      },
      {
        text: 'Ημερομηνία υποβολής',
        sortable: false,
        value: 'submission_date',
      },
      {
        text: 'Ημερομηνία απόρριψης',
        sortable: false,
        value: 'rejection_date',
      },
      {
        text: 'Ημερομηνία έγκρισης',
        sortable: false,
        value: 'approval_date',
      },
      {
        text: 'Κατάσταση αίτησης',
        sortable: false,
        value: 'user',
      },
      {
        text: 'Ο αιτών',
        sortable: false,
        value: 'user',
      },
      {
        text: 'Προβολή αίτησης',
        align: 'center',
        sortable: false,
        value: 'utilities'
      }
    ],
    applications: [],
    applicationId: '',
    selectedApplication: {},
    applicationDialog: false,
    user: {},
  }),
  created() {
    this.fetchClubs()
  },
  methods: {
    fetchClubs() {
      requests.getSecureRequest('club/', '', this.fetchClubsCallback)
    },
    fetchClubsCallback(response) {
      this.clubs = response
    },

    fetchApplications() {
      if (this.selectedClub.length == 0) {
        return
      }

      let requestObj = {
        club: this.selectedClub
      }
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('application/all-club-applications', requestObj, this.fetchApplicationsCallback)
    },
    fetchApplicationsCallback(response) {

      this.applications = response
      this.$store.commit('setLoadingModal', false)
    },
    openUserInfoDialog(info) {
      this.selectedUser = info;
      this.userInfoDialog = true;
    },

    openApplicationDialog(item) {
      this.$store.commit('setSelectedClub', this.selectedClub)
      this.selectedApplication = item
      this.applicationId = item.id
      this.user = item.user
      this.componentKey++
      this.applicationDialog = true
    }
  },
}
</script>

<style scoped>

</style>