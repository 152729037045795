<template>
  <div>
    <v-data-table :footer-props="{page:1,
                                  pageCount:1,
                                  showCurrentPage:true,
                                  itemsPerPageOptions:[5,10,15,30],
                                  showFirstLastPage:true,
                                  itemsPerPageText:'Μέλη Δ.Σ. ανά σελίδα:',
                                  itemsPerPageAllText:'Όλα'}"
                  no-data-text="Δέν υπάρχουν αποτελέσματα."
                  :headers="headers"
                  :items="councilMembers"
                  :items-per-page="itemsPerPage"
                  class="elevation-3 mx-5 px-2"
    >
      <template v-slot:[`footer.page-text`]="items">
        {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-card color="primary"
                  class="mb-5"
                  height="80"
                  width="130">
            <v-card-title class="white--text">
              {{ 'Μέλη Δ.Σ.' }}
            </v-card-title>
          </v-card>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-btn @click="ActionForSearchMemberDialog"
                     class="mb-2" color="primary" fab small v-on="on">
                <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>{{ 'Προσθήκη μέλους Δ.Σ.' }}</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item="{item}">
        <tr>
          <td class="text-center d-sm-table-cell d-flex">
            {{ item.user.vat }}
          </td>
          <td class="text-center d-sm-table-cell d-flex">
            {{ item.user.user_branches[0].first_name }}
          </td>
          <td class="text-center d-sm-table-cell d-flex">
            {{ item.user.user_branches[0].last_name }}
          </td>
          <td class="text-center d-sm-table-cell d-flex">
            <v-row align="center" justify="center">
              <v-btn @click="ActionForCheckBeforeDeleteDialog(item.user);"
                     icon elevation="5" class="ma-1">
                <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog v-model="searchMemberDialog" persistent max-width="600px">
      <v-card>
        <v-card-title style="background-color: #003375" class="white--text">
          Αναζήτηση μέλους
        </v-card-title>
        <v-card-text>
          <v-text-field
              type="number"
              hide-spin-buttons
              v-model="user_vat"
              label="ΑΦΜ"
              :rules="number9digitRule"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="ActionForSearchMemberDialog()">
            Κλείσιμο
          </v-btn>
          <v-spacer/>
          <v-btn class="primary" :disabled="(this.user_vat.trim() == '')" @click="searchUser()">
            Αναζήτηση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addCouncilMemberDialog" persistent max-width="600px">
      <v-card>
        <v-card-title style="background-color: #003375" class="white--text">
          Στοιχεία μέλους
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ user.vat }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ user.first_name }}</v-list-item-title>
                  <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ user.last_name }}</v-list-item-title>
                  <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ user.identification_document }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="ma-1">
            Είστε σίγουρος/η για την προσθήκη αυτού του μέλους στο διοικητικό συμβούλιο της λέσχης;
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="ActionForAddCouncilMemberDialog()">
            Όχι
          </v-btn>
          <v-spacer/>
          <v-btn class="primary" @click="addMember()">
            Ναι, προσθήκη
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="checkBeforeDeleteDialog" persistent max-width="600px">
      <v-card>
        <v-card-title style="background-color: #003375" class="white--text">
          Διαγραφή μέλους Δ.Σ.
        </v-card-title>
        <v-card-text class="mt-4 govgr-!-font-size-19">
          Είστε σίγουρος/η για την διαγραφή του μέλους απο το διοικητικό συμβούλιο της λέσχης;
        </v-card-text>
        <v-card-actions>
          <v-btn @click="checkBeforeDeleteDialog = false">
            Όχι
          </v-btn>
          <v-spacer/>
          <v-btn class="red white--text" @click="deleteMember()">
            Ναι, διαγραφή
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import requests from "@/requests";

export default {
  name: "CouncilMembers",
  data: () => ({
    headers: [
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'vat',
        width: '25%'
      },
      {
        text: 'Όνομα',
        align: 'center',
        sortable: false,
        value: 'name',
        width: '25%'
      },
      {
        text: 'Επώνυμο',
        align: 'center',
        sortable: false,
        value: 'last_name',
        width: '25%'
      },
      {
        text: 'Λειτουργίες',
        align: 'center',
        sortable: false,
        value: 'last_name',
        width: '25%'
      },
    ],
    user_vat: '',
    user: {},
    councilMembers: [],
    itemsPerPage: 5,
    searchMemberDialog: false,
    number9digitRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο.',
      v => ('' + v).length == 9 || 'Ο ΑΦΜ πρέπει να αποτελείτε απο 9 ψηφία.'
    ],
    addCouncilMemberDialog: false,
    checkBeforeDeleteDialog: false,
  }),
  methods: {

    getCouncilMembers() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_id: this.$store.getters.selectedClub.id
      }
      requests.postSecureRequest('council/get-council-members', requestObj, this.getCouncilMembersCallBack)
    },

    getCouncilMembersCallBack(response) {
      this.councilMembers = response
      this.$store.commit('setLoadingModal', false)
    },

    searchUser() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_id: this.$store.getters.selectedClub.id,
        vat: this.user_vat
      }
      requests.postSecureRequest('council/get-club-member-to-be-in-council', requestObj, this.searchUserCallback)
    },

    searchUserCallback(response) {
      this.user = response
      this.$store.commit('setLoadingModal', false)
      this.addCouncilMemberDialog = true;
    },

    addMember() {
      this.user_vat = ''
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_id: this.$store.getters.selectedClub.id,
        user_id: this.user.id
      }
      requests.postSecureRequest('council/add-in-council', requestObj, this.addMemberCallBack)
    },

    addMemberCallBack(response) {
      this.councilMembers = response.councilMembers
      this.ActionForSearchMemberDialog()
      this.ActionForAddCouncilMemberDialog()
      this.$store.commit('setInfoDialogObj', {text: response.message})
      this.$store.commit('setInfoDialog', true)
      this.$store.commit('setThereWasAChangeInApplication', true)
      this.$store.commit('setLoadingModal', false)
    },

    deleteMember() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_id: this.$store.getters.selectedClub.id,
        user_id: this.user.id
      }
      requests.postSecureRequest('council/remove-from-council', requestObj, this.deleteMemberCallback)
    },

    deleteMemberCallback(response) {
      this.councilMembers = response.councilMembers
      this.checkBeforeDeleteDialog = false
      this.$store.commit('setInfoDialogObj', {text: response.message})
      this.$store.commit('setInfoDialog', true)
      this.$store.commit('setThereWasAChangeInApplication', true)
      this.$store.commit('setLoadingModal', false)
    },

    ActionForSearchMemberDialog() {
      this.user_vat = ''
      this.searchMemberDialog = !this.searchMemberDialog
    },

    ActionForAddCouncilMemberDialog() {
      this.user_vat = ''
      this.addCouncilMemberDialog = !this.addCouncilMemberDialog
    },

    ActionForCheckBeforeDeleteDialog(item) {
      this.user = item;
      this.checkBeforeDeleteDialog = !this.checkBeforeDeleteDialog
    }
  },
  created() {
    this.getCouncilMembers()
  }
}
</script>


<style scoped>

</style>