<template>
  <div>
    <v-row align="center" justify="center">
      <v-col xl="5" lg="6" md="8" sm="10" xs="12">
        <v-card>
          <v-row align="center" justify="center">
            <v-card-title style="word-break: normal">
              Επιλέξτε το παράρτημα για το οποίο επιθυμείτε να ενεργήσετε.
            </v-card-title>
          </v-row>

          <v-card-text align="center" justify="center">
            <v-col>
              <v-autocomplete :items="$store.getters.loggedinUser.YPLonBranches"
                              label="Παραρτήματα"
                              item-text="name"
                              dense
                              no-data-text="Δεν υπάρχουν καταχωρήσεις"
                              return-object
                              v-model="selectedBranch"
                              class="click-cursor"
                              @change="selectedBranchFroYPL">
              </v-autocomplete>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="goBack">
              Επιλογή ρόλου
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="proceedAsYPL">
              Συνέχεια
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import requests from "@/requests";
import services from "@/services";

export default {
  name: "SelectBranch",
  data: () => ({
    selectedBranch: {}
  }),
  methods: {
    selectedBranchFroYPL() {
      this.$store.commit('setSelectedBranchYPL', this.selectedBranch)
    },

    proceedAsYPL() {
      if (Object.keys(this.$store.getters.selectedBranchYPL).length > 0) {
        if (this.$store.getters.memberfileParsingInterval == true) {
          this.$router.push({path: '/ypl-dashboard'})
        } else {
          this.$store.commit('setLoadingModal', true)

          let requestObj = {
            user_branch_id: this.$store.getters.selectedBranchYPL.user_branch.id
          }
          requests.postSecureRequest('memberfile/check-for-ongoing-processing',
              requestObj,
              this.checkForOngoingMemberfileProcessingCallback
          )
        }
      } else {
        this.$store.commit('setInfoSnackBarObj', {text: 'Παρακαλούμε επιλέξτε παράρτημα λέσχης.'})
        this.$store.commit('setInfoSnackBar', true)
      }
    },
    checkForOngoingMemberfileProcessingCallback(response) {
      this.$store.commit('setLoadingModal', false)

      if (response.memberfile_id != null) {
        services.memberfileParsingInterval(response)
      }

      this.$router.push({path: '/ypl-dashboard'})
    },

    goBack() {
      this.$router.push({path: '/role-selection'})
    },
  },
  created() {
    this.$store.commit('setMenuItems', [])
    this.$store.commit('setSelectedBranchYPL', {})
  }
}
</script>

<style scoped>

</style>