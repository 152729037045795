<template>
<div class="mb-6">
  <v-card min-width="600">
    <v-card-title>
        <v-toolbar flat>
          <v-row align="center">
            <v-col cols="2">
              <v-card color="primary"
                      class="mb-12"
                      height="80"
                      width="190">
                <v-card-title style="word-break: normal" class="white--text">
                  {{ 'Ιστορικό λέσχης' }}
                </v-card-title>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-col cols="5">
              <v-select label="Επιλογή περιόδου/έτους"
                        return-object
                        :items="seasons"
                        item-text="season"
                        dense
                        no-data-text="Δεν υπάρχουν καταχωρήσεις"
                        v-model="selectedSeason"
                        @change="saveSeasonToStore()"
              >
              </v-select>
              </v-col>
            </v-col>
          </v-row>
        </v-toolbar>
    </v-card-title>
    <v-card-text v-if="!noSeasons">
          <v-tabs v-model="tab" fixed-tabs>
            <v-tab href="#tab-1" :style="tab=='tab-1' ? 'background-color: aliceblue;' : 'background-color: '">
              Αρχικη κατασταση
            </v-tab>
            <v-tab href="#tab-2" :style="tab=='tab-2' ? 'background-color: aliceblue;' : 'background-color: '">
              Μεταγενεστερες Ενεργειες
            </v-tab>

            <v-tabs-items v-model="tab">
              <v-tab-item value="tab-1" class="mt-4">
                <ClubAndHistoryInfoForNeas v-if="$store.getters.selectedSeasonKey > 0" :key="$store.getters.selectedSeasonKey" />
              </v-tab-item>
              <v-tab-item value="tab-2" class="mt-4">
                <ActionsAfterApproval />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
    </v-card-text>
    <v-card-title v-else class="justify-center">
      {{'Δεν βρέθηκε περίοδος/έτος με εγκεκριμένη αίτηση για τη συγκεκριμένη λέσχη φιλάθλων.'}}
    </v-card-title>
  </v-card>
</div>
</template>

<script>
import ClubAndHistoryInfoForNeas from "@/components/NEAS/ClubAndHIstoryInfoForNeas.vue";
import requests from "@/requests";
import ActionsAfterApproval from "@/components/NEAS/ActionsAfterApproval.vue";

export default {
  name: "HistoryOfClubBySeason",
  components: {ActionsAfterApproval, ClubAndHistoryInfoForNeas},
  data: () => ({
    selectedSeason:'0',
    seasons:[],
    tab:'',
    noSeasons: false,
    message:''
  }),
  methods: {
    saveSeasonToStore() {
      this.$store.commit('setSelectedSeason', this.selectedSeason)
      let count = this.$store.getters.selectedSeasonKey
      count+=1
      this.$store.commit('setSelectedSeasonKey', count)
    },

    getSeasons() {
      this.$store.commit('setLoadingModal',true)
      let requestObj = {
        club_id: this.$store.getters.selectedClub.id
      }
      requests.postSecureRequest('history/get-seasons',requestObj, this.getSeasonsCallback)
    },

    getSeasonsCallback(response) {
      if(response.hasOwnProperty('message')) {
        this.noSeasons = true
        this.message = response.message
      } else {
        this.seasons = response
        this.selectedSeason = response[0];
        this.$store.commit('setSelectedSeason', this.selectedSeason)
        let count = this.$store.getters.selectedSeasonKey
        count+=1
        this.$store.commit('setSelectedSeasonKey', count)
      }
      this.$store.commit('setLoadingModal',false)
    }

  },
  beforeDestroy() {
    this.$store.commit('setSelectedSeasonKey',0)
  },
  created() {
    this.getSeasons();
  }
}
</script>

<style scoped>

</style>