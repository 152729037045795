import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({

    theme: {
        themes: {
            light: {
                primary: '#003375',
                secondary: '#0065b3',
                goldColor: '#CBA052',
                greyColor: '#BDBDBD',
                lightSeaGreen: '#20b2aa',
                indianRed: '#cd5c5c',
                lightRed: '#FF9999',
                whiteBlue: '#E0F7FA',
                documentBlue: '#82b2e8',
            },
            dark: {
                primary: '#0D47A1',
                secondary: '#F65275',
                goldColor: '#CBA052',
                greyColor: '#BDBDBD'
            },

        },
    },
    dark: true
});
