<template>
  <div class="mb-3">
    <v-row class="mb-1">
      <v-col v-if="$store.getters.loggedinUser.role=='ΑΤ'" class="mx-auto text-center font-weight-light headline">
        {{ 'Αδειοδότηση χώρων' }}
      </v-col>
      <v-col v-else class="mx-auto text-center font-weight-light headline">
        {{ 'Έλεγχος χώρων' }}
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="mb-4">
      <v-expansion-panels v-model="panel" multiple style="width: 93%">
        <v-expansion-panel expand>
          <v-expansion-panel-header>
            <v-row>
              <span class="govgr-!-font-size-24 ml-2">
                Στοιχεία αναζήτησης
              </span>
            </v-row>
            <v-spacer/>
            <template v-slot:actions>
              <v-icon color="primary">
                mdi-arrow-down-bold-circle-outline
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-divider/>
          <v-expansion-panel-content class="mt-6">
            <v-form v-model="isFormValid">
              <v-row>
                <v-col>
                  <v-select
                      :items="clubs"
                      v-model="searchObj.club"
                      dense
                      no-data-text="Δεν υπάρχουν καταχωρήσεις"
                      outlined
                      item-text="name"
                      return-object label="Λέσχη"
                      @change="enableBranches()"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                      :items="branches"
                      v-model="searchObj.branch"
                      item-value="id"
                      dense
                      no-data-text="Δεν υπάρχουν καταχωρήσεις"
                      outlined
                      :disabled="disabledBranches"
                      item-text="name" return-object label="Παράρτημα"
                  >
                  </v-select>
                </v-col>
                <v-col>
                  <v-select
                      :items="radioOptions"
                      v-model="searchObj.radioOption"
                      item-value="id"
                      dense
                      no-data-text="Δεν υπάρχουν καταχωρήσεις"
                      outlined
                      item-text="status"
                      return-object
                      label="Κατάσταση χώρων"/>
                </v-col>
              </v-row>
            </v-form>
            <v-row style="margin-top: -2%">
              <v-spacer/>
              <v-tooltip top>
                <template v-slot:activator="{on}">
                  <v-btn icon v-on="on" @click="clearSearch()" class="ma-2">
                    <v-icon color="primary">
                      mdi-broom
                    </v-icon>
                  </v-btn>
                </template>
                <span>
               Καθαρισμός
            </span>
              </v-tooltip>
              <v-btn @click="getPlacesForLicencing(), tableLength = 0" class="ma-2 primary" :disabled="!isFormValid">
                Εμφάνιση χώρων
              </v-btn>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
    <v-row justify="center" align="center">
      <v-card style="width: 100%" class="mt-3" flat>
        <v-card-text>
          <v-data-table
              :page.sync="pageNumber"
              :items="places"
              :headers="placeHeaders"
              :items-per-page="itemsPerPage"
              :server-items-length="tableLength"
              :footer-props="{
                                      page:1,
                                      pageCount:1,
                                      showCurrentPage:true,
                                      itemsPerPageOptions:[5,10,15],
                                      showFirstLastPage: true,
                                      itemsPerPageText:'Χώροι ανά σελίδα:',
                                      itemsPerPageAllText:'Όλα'}"
              no-data-text="Δέν υπάρχουν αποτελέσματα."
              class="elevation-3 mx-5 px-2"
              @update:options="getBuildingPerPage"
          >
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-card color="primary"
                        class="mb-5"
                        height="80"
                        width="100">
                  <v-card-title style="word-break: normal" class="white--text">
                    {{ 'Χώροι' }}
                  </v-card-title>
                </v-card>
              </v-toolbar>
            </template>
            <template v-slot:item="{item}">
              <tr>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.place_type.type }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.address }} {{ ', ' + item.city }} {{ ', ' + item.postal_code }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.branch.club.name }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.branch.name }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  <v-chip v-if="item.place_status.id == 3" class="white--text" color="green">
                    {{ item.place_status.status }}
                  </v-chip>
                  <v-chip v-else-if="item.place_status.id == 1 || item.place_status.id == 2">
                    {{ item.place_status.status }}
                  </v-chip>
                  <v-chip v-else color="red" class="white--text">{{ item.place_status.status }}</v-chip>
                </td>
                <td class="text-center d-sm-table-cell d-flex click-cursor"
                    v-if="item.place_histories.length > 0 && item.place_histories[0].place_licencing_id != null">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon color="primary" small
                             target="_blank"
                             @click="downloadFile(item)">
                        <v-icon color="primary"
                                v-on="on"
                        >
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ 'Κατέβασμα αρχείου' }}</span>
                  </v-tooltip>
                </td>
                <td class="text-center d-sm-table-cell d-flex click-cursor" v-else>
                  {{ '-' }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  <v-btn @click="openLicencingDialog(item)" icon fab small color="primary"
                         :disabled="(item.place_status.id != 2 && item.place_status.id != 3) || ($store.getters.loggedinUser.role == 'ΓΔΑ' && item.place_status.id != 3 )">
                    <v-icon>
                      mdi-table-edit
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>

    <v-dialog persistent v-model="licencingDialog" v-if="places.length >0" width="650px">
      <v-card>
        <v-card-title v-if="$store.getters.loggedinUser.role=='ΑΤ'" class="primary white--text"
                      style="word-break: normal">
          Έγκριση/Απόρριψη/Αναστολή/Ανάκληση άδειας χώρου
          <v-spacer/>
          <v-btn icon color="white" @click="licencingDialog = false, clear()">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-title v-else class="primary white--text" style="word-break: normal">
          Αναστολή χώρου
          <v-spacer/>
          <v-btn icon color="white" @click="licencingDialog = false, clear()">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="Object.keys(selectedPlace).length > 0">
          <v-row>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedPlace.place_type.type }}</v-list-item-title>
                  <v-list-item-subtitle>Τύπος</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedPlace.address }}</v-list-item-title>
                  <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row class="mt-3">
              <span class="govgr-!-font-size-19 ml-6">
                Υπεύθυνος χώρου:
              </span>
          </v-row>
          <v-row>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedPlace.user.user_branches[0].first_name }}</v-list-item-title>
                  <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedPlace.user.user_branches[0].last_name }}</v-list-item-title>
                  <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedPlace.user.vat }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{
                      selectedPlace.user.user_branches[0].identification_document
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <v-divider/>
          <v-row justify="center" align="center" class="mt-2 mr-2">
            <v-spacer/>
            <v-file-input v-if="selectedPlace.place_status_id == 2" small-chips v-model="file"
                          :accept="['.pdf', '.png', '.jpg', '.jpeg']" clearable label="Αρχείο"
                          append-icon="mdi-file"></v-file-input>
            <v-spacer/>
          </v-row>
          <v-row justify="center" align="center" class="mt-2">
            <div v-if="selectedPlace.place_status_id == 3" class="mt-4">
              <v-row v-if="$store.getters.loggedinUser.role == 'ΑΤ'" justify="center" align="center">
                <v-col>
                  <v-select label="Επιλογή ενέργειας"
                            :items="options"
                            item-text="name"
                            dense
                            outlined
                            no-data-text="Δεν υπάρχουν καταχωρήσεις"
                            v-model="suspensionRecallSwitch"
                            @change="clearSuspensionRecallInputs()"/>
                </v-col>
              </v-row>
              <div v-if="suspensionRecallSwitch">
                <v-text-field dense style="width: 380px" outlined class="mt-5"
                              placeholder="Αριθμός/Έτος" label="Αριθμός απόφασης"
                              v-model="courtDecisionNumber"></v-text-field>
                <v-textarea
                    dense
                    style="width: 380px"
                    class="mt-1" outlined
                    auto-grow
                    label="Αιτιολογία ανάκλησης" v-model="RecallReason"></v-textarea>
              </div>
              <div v-else>
                <v-checkbox
                    v-model="criminalCharges"
                    :label="`Αναστολή λόγω ποινικών διώξεων`"
                    @change="courtDecisionNumber = ''"
                ></v-checkbox>
                <v-text-field dense
                              :disabled="criminalCharges"
                              style="width: 380px" outlined
                              placeholder="Αριθμός/Έτος" label="Αριθμός απόφασης"
                              v-model="courtDecisionNumber"></v-text-field>
                <v-dialog
                    ref="dialog"
                    v-model="openDatePicker"
                    persistent
                    width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        style="width: 380px"
                        readonly
                        :value="dateOfEnd"
                        outlined
                        required
                        label="Ημερομηνία λήξης αναστολής (1-12 μήνες)" dense clearable
                        v-on="on" @click:clear="clear"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="dateOfEnd"
                      scrollable
                      :min="true ? (new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0]) : ''"
                      :max="true ? (new Date(new Date().setMonth(new Date().getMonth() + 12)).toISOString().split('T')[0]) : ''"
                      :show-current="sixMonthsFromNow"
                      locale="el"
                      color="primary">
                    <v-spacer></v-spacer>
                    <v-btn @click="openDatePicker = false"
                           color="primary">
                      Κλείσιμο
                    </v-btn>
                    <v-btn
                        @click="openDatePicker = false"
                        color="primary">
                      Επιλογή
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-textarea
                    dense
                    style="width: 380px"
                    outlined
                    auto-grow
                    label="Αιτιολογία αναστολής" v-model="suspensionReason"></v-textarea>
              </div>
            </div>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="selectedPlace.place_status_id == 2">
          <v-btn rounded
                 :disabled="file==null"
                 class="green--text"
                 outlined
                 @click="openCheckDialog($store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.APPROVED)">
            <v-icon>
              mdi-check
            </v-icon>
            Έγκριση
          </v-btn>
          <v-spacer/>
          <v-btn rounded
                 :disabled="file==null"
                 class="red--text"
                 outlined
                 @click="openCheckDialog($store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.REJECTED)">
            <v-icon>
              mdi-cancel
            </v-icon>
            Απόρριψη
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer/>
          <v-btn rounded
                 v-if="!suspensionRecallSwitch"
                 :disabled="suspensionValid"
                 class="red--text"
                 outlined
                 @click="openCheckDialog($store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.SUSPENDED)">
            <v-icon>
              mdi-cancel
            </v-icon>
            Αναστολή
          </v-btn>
          <v-btn rounded
                 v-else
                 :disabled="RecallValid"
                 class="red--text"
                 outlined
                 @click="openCheckRecallDialog()"
          >
            <v-icon>
              mdi-cancel
            </v-icon>
            Ανάκληση
          </v-btn>
          <v-spacer/>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="checkDialog" width="500px">
      <v-card>
        <v-card-title class="primary white--text" style="word-break: normal">
          <div v-if="statusForCheckDialog == $store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.APPROVED ">
            Έγκριση χώρου
          </div>
          <div v-else-if="statusForCheckDialog == $store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.REJECTED">
            Απόρριψη χώρου
          </div>
          <div v-else>
            Αναστολή χώρου
          </div>
        </v-card-title>
        <v-card-text class="mt-5">
          <section v-if="criminalCharges" class="mb-3 govgr-!-font-size-16">
            Με την ενέργεια αναστολής χώρου "λόγω ποινικών διώξεων",
            θα αναστείλετε την λειτουργία της λέσχης και όλων των παρατημάτων της.
          </section>
          <section class="govgr-!-font-size-19">
            Είστε σίγουρος/η για την ενέργεια σας;
          </section>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="checkDialog = false">
            Όχι
          </v-btn>
          <v-spacer/>
          <v-btn class="primary" @click="uploadFileAcceptOrReject(selectedPlace, statusForCheckDialog)">
            Ναι, εκτέλεση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="recallDialog" max-width="450">
      <v-card>
        <v-card-title class="justify-center primary">
          <v-icon color="white">mdi-comment-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="mt-2 govgr-!-font-size-19">
          <v-row align="center" justify="center" class="ma-1 mt-3 govgr-!-font-size-19">
            Είστε σίγουρος/η για την ανάκληση της άδειας του χώρου;
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="recallDialog = false">
            Όχι
          </v-btn>
          <v-spacer/>
          <v-btn style="background-color: red" class="white--text govgr-!-font-size-19" @click="licenceRecall">
            Ναι, ανάκληση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import requests from "@/requests";

export default {
  name: "PlaceLicencing",
  components: {},
  data: () => ({
    sixMonthsFromNow: JSON.parse(JSON.stringify(new Date(new Date().setMonth(new Date().getMonth() + 1)))),
    panel: [0],
    pageNumber: 1,
    itemsPerPage: 5,
    tableLength: 0,
    isFormValid: true,
    clubs: [],
    branches: [],
    places: [],
    selectedPlace: {},
    file: null,
    searchObj: {
      club: '',
      branch: '',
      radioOption: '',
      check_loc_code: true,
    },
    radioOptions: [],
    licencingDialog: false,
    checkDialog: false,
    statusForCheckDialog: '',
    courtDecisionNumber: '',
    openDatePicker: false,
    dateOfEnd: '',
    placeHeaders: [
      {
        text: 'Τύπος',
        align: 'center',
        sortable: false,
        value: 'type',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Λέσχη',
        align: 'center',
        sortable: false,
        value: 'club',
      },
      {
        text: 'Παράρτημα',
        align: 'center',
        sortable: false,
        value: 'branch',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Αρχείο',
        align: 'center',
        sortable: false,
        value: 'file',
      },
      {
        text: 'Έλεγχος',
        align: 'center',
        sortable: false,
        value: 'action',
      },
    ],
    disabledBranches: true,
    suspensionReason: '',
    criminalCharges: false,
    suspensionRecallSwitch: false,
    options: [
      {id: 1, value: false, name: 'Αναστολή'},
      {id: 2, value: true, name: 'Ανάκληση'},
    ],
    RecallReason: '',
    recallDialog: false,
  }),
  computed: {
    suspensionValid() {
      if (this.dateOfEnd == '' || this.suspensionReason == '') {
        return true
      }
      if (!this.criminalCharges && this.courtDecisionNumber == '') {
        return true
      }
      return false
    },

    RecallValid() {
      if (this.courtDecisionNumber == '') {
        return true
      }
      if (this.RecallReason == '') {
        return true
      }

      return false
    }
  },
  methods: {
    getPlacesForLicencing() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_id: this.searchObj.club.id,
        branch_id: this.searchObj.branch.id,
        place_status_id: this.searchObj.radioOption.id,
        loc_code_check: this.searchObj.check_loc_code,
        limit: this.itemsPerPage,
        offset: 0
      }
      if (this.$store.getters.loggedinUser.role == "ΓΔΑ") {
        requestObj.loc_code_check = false
      }
      requests.postSecureRequest('place/get-places-with-everything', requestObj, this.getPlacesForLicencingCallback)
    },

    getPlacesForLicencingCallback(response) {
      this.places = []
      if (response.hasOwnProperty('all')) {
        this.places = response.places
        this.tableLength = response.all
      }
      this.$store.commit('setLoadingModal', false)
    },

    getBuildingPerPage(item) {
      this.$store.commit('setLoadingModal', true)
      this.pageNumber = item.page
      this.itemsPerPage = item.itemsPerPage
      let requestObj = {
        club_id: this.searchObj.club.id,
        branch_id: this.searchObj.branch.id,
        place_status_id: this.searchObj.radioOption.id,
        loc_code_check: this.searchObj.check_loc_code,
        limit: this.itemsPerPage,
        offset: this.pageNumber - 1
      }
      if (this.$store.getters.loggedinUser.role == "ΓΔΑ") {
        requestObj.loc_code_check = false
      }
      requests.postSecureRequest('place/get-places-with-everything', requestObj, this.getPlacesForLicencingCallback)
    },

    getClubs() {
      this.$store.commit('setLoadingModal', true)
      this.selectedClub = {}
      requests.getSecureRequest('club/', '', this.getClubsCallback)
    },

    getClubsCallback(response) {
      this.clubs = []
      for (let i = 0; i < response.length; i++) {
        if (response[i].clubstatus_id != this.$store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.UNDER_CREATION) {
          this.clubs.push(response[i])
        }
      }
      this.$store.commit('setLoadingModal', false)
    },

    getBranches() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('branch/get-branches-of-club', {club: this.searchObj.club}, this.getBranchesWithPlacesCallback)
    },

    getBranchesWithPlacesCallback(response) {
      this.branches = response
      this.$store.commit('setLoadingModal', false)
    },

    getPlaceStatuses() {
      this.$store.commit('setLoadingModal', true)
      requests.getSecureRequest('place/get-place-statuses', '', this.getPlaceStatusesCallback)
    },

    getPlaceStatusesCallback(response) {
      this.radioOptions = []
      for (let i = 0; i < response.length; i++) {
        if (response[i].id != this.$store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.DRAFT) {
          this.radioOptions.push(response[i])
        }
      }
      this.$store.commit('setLoadingModal', false)
    },

    clearSearch() {
      this.searchObj = {
        club: '',
        branch: '',
        radioOption: '',
        check_loc_code: true,
      }
      this.branches = []
      this.disabledBranches = true
    },

    openLicencingDialog(item) {
      this.selectedPlace = item
      this.licencingDialog = !this.licencingDialog
    },

    enableBranches() {
      this.getBranches()
      this.disabledBranches = false
    },

    uploadFileAcceptOrReject(item, licencing_type_id) {
      this.$store.commit('setLoadingModal', true)
      let formData = new FormData();
      if (!(licencing_type_id == this.$store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.SUSPENDED)) {
        formData.append('file', this.file, encodeURI(this.file.name));
      } else {
        formData.append('criminal_charges', this.criminalCharges);
        formData.append('suspension_number', this.courtDecisionNumber);
        formData.append('suspension_until_date', this.dateOfEnd);
        formData.append('suspension_reason', this.suspensionReason);
      }
      formData.append('place_id', item.id);
      formData.append('licencing_type_id', licencing_type_id);
      this.licencingDialog = false
      requests.uploadFilePostAsync('place/upload-licencing', formData, this.uploadFileAcceptOrRejectCallback)

    },

    uploadFileAcceptOrRejectCallback(response) {
      this.$store.commit('setInfoDialogObj', {text: 'Επιτυχής ενέργεια.'})
      this.$store.commit('setInfoDialog', true)
      let item = {
        itemsPerPage: this.itemsPerPage,
        page: this.pageNumber
      }
      this.checkDialog = false
      this.file = null
      this.recallDialog = false
      this.licencingDialog = false
      this.clearSuspensionRecallInputs()
      this.getBuildingPerPage(item)
    },

    openCheckDialog(status) {
      this.statusForCheckDialog = status
      this.checkDialog = true
    },

    clear() {
      this.dateOfEnd = '';
      if (this.licencingDialog == false) {
        this.courtDecisionNumber = ''
        this.suspensionReason = ''
        this.criminalCharges = false
      }
      this.file = null
    },

    downloadFile(item) {
      requests.downloadFileRequest('place/download-licencing/' + item.place_licencings[0].id)
    },

    openCheckRecallDialog() {
      this.recallDialog = true
    },

    clearSuspensionRecallInputs() {
      this.criminalCharges = false
      this.courtDecisionNumber = ''
      this.dateOfEnd = ''
      this.suspensionReason = ''
      this.RecallReason = ''
    },

    licenceRecall() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        place_id: this.selectedPlace.id,
        recall_number: this.courtDecisionNumber,
        recall_reason: this.RecallReason
      }
      requests.postSecureRequest('place/licence_recall', requestObj, this.uploadFileAcceptOrRejectCallback)
    },
    // licenceRecallCallback(response){
    //
    //   this.$store.commit('setLoadingModal', false)
    // },

  },
  created() {
    this.getClubs()
    this.getPlaceStatuses()
  }
}
</script>

<style scoped>
.v-select-list {
  margin-top: 12px;
}
</style>