<template>
  <div>
    <v-dialog v-model="$store.getters.memberfileParsingIntervalDialog" persistent width="75%" max-width="700px">
      <v-card>
        <v-card-title class="primary white--text">
          Ολοκληρώθηκε η μεταφόρτωση του αρχείου μελών
        </v-card-title>
        <v-card-subtitle class="govgr-!-font-weight-bold mt-2">
          Αποτελέσματα:
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              {{
                ($store.getters.memberfileParsingIntervalObj.user_branch.branch.is_default == true) ? 'Έδρα ' : 'Παράρτημα \"' + $store.getters.memberfileParsingIntervalObj.user_branch.branch.name + '\" '
              }}
              της λέσχης φιλάθλων
              {{ '\"' + $store.getters.memberfileParsingIntervalObj.user_branch.branch.club.name + '\"' }}
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="12">
              {{ utilities.timestampToDatetime($store.getters.memberfileParsingIntervalObj.upload_time) }}
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col xl="6" lg="6" md="6" sm="12" xs="12">
              Επιτυχείς εισαγωγές μελών:
              {{ $store.getters.memberfileParsingIntervalObj.added_members }}
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon @click="download($store.getters.memberfileParsingIntervalObj, true)"
                          color="primary"
                          v-on="on">
                    mdi-download
                  </v-icon>
                </template>
                <span>Λήψη Αρχείου</span>
              </v-tooltip>
            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12" xs="12">
              Αποτυχημένες εγγραφές μελών:
              {{ $store.getters.memberfileParsingIntervalObj.not_added_members }}
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon @click="download($store.getters.memberfileParsingIntervalObj, false)"
                          color="primary"
                          v-on="on">
                    mdi-download
                  </v-icon>
                </template>
                <span>Λήψη Αρχείου</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="closeDialog" class="primary">Κλείσιμο</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import utilities from "../../utilities";
import requests from "@/requests";

export default {
  name: "memberfileParsingIntervalDialog",
  computed: {
    utilities() {
      return utilities
    }
  },
  data() {
    return {}
  },
  methods: {
    closeDialog() {
      this.$store.commit('setMemberfileParsingIntervalDialog', false)
      this.$store.commit('setMemberfileParsingIntervalObj', {user_branch: {branch: {club: {}}}})
    },

    download(item, added) {
      //added boolean if true download addedMemberfile else download notAddedMemberfile

      let minioFile
      if (added == true) {
        minioFile = 'membersAdded'
        if (item.added_members == 0) {
          this.$store.commit('setSnackBarObj', {text: 'Δεν υπάρχουν εγγραφές.'})
          this.$store.commit('setSnackBar', true)
          return
        }
      } else {
        minioFile = 'membersNotAdded'
        if (item.not_added_members == 0) {
          this.$store.commit('setSnackBarObj', {text: 'Δεν υπάρχουν εγγραφές.'})
          this.$store.commit('setSnackBar', true)
          return
        }
      }

      requests.downloadRequest('memberfile/minio_download/', item.user_branch_id, item.id, minioFile)
    },
  },
  created() {

  },
}
</script>

<style scoped>

</style>