<template>
  <div>
    <v-card>
      <v-card-title style="background-color: #003375; word-break: normal" class="white--text">
        <v-row align="center" justify="center" class="ma-2 text-h4">
          Στοιχεία χρήστη
        </v-row>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row>
          <v-col>
            <v-row>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ ($store.getters.loggedinUser.user.first_name != "")? $store.getters.loggedinUser.user.first_name : '-' }}</v-list-item-title>
                  <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <v-row>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ ($store.getters.loggedinUser.user.last_name != "")? $store.getters.loggedinUser.user.last_name : '-' }}</v-list-item-title>
                  <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <v-row>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ ($store.getters.loggedinUser.user.vat != "")? $store.getters.loggedinUser.user.vat : '-' }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <v-row>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ ($store.getters.loggedinUser.user.identification_document != "")? $store.getters.loggedinUser.user.identification_document : '-' }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </v-col>
          <v-col>
            <v-row>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ ($store.getters.loggedinUser.user.father_name != "")? $store.getters.loggedinUser.user.father_name : '-' }}</v-list-item-title>
                  <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <v-row>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ ($store.getters.loggedinUser.user.mother_name != "")? $store.getters.loggedinUser.user.mother_name : '-' }}</v-list-item-title>
                  <v-list-item-subtitle>Μητρώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <v-row>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ ($store.getters.loggedinUser.user.date_of_birth)? $store.getters.loggedinUser.user.date_of_birth : '-'}}</v-list-item-title>
                  <v-list-item-subtitle>Ημερομηνία γέννησης</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <v-row>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{
                      ($store.getters.loggedinUser.user.address != "" )?
                          $store.getters.loggedinUser.user.address +
                          ' ' +
                          $store.getters.loggedinUser.user.city +
                          ' ' +
                          $store.getters.loggedinUser.user.postal_code :
                          '-'
                    }}</v-list-item-title>
                  <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="goToEMEPDialog" max-width="600px">
      <v-card>
        <v-card-title class="primary white--text">
          Καταχωρίστε τα στοιχεία επικοινωνίας σας στο Εθνικό Μητρώο Επικοινωνίας (Ε.Μ.Επ.)
        </v-card-title>
        <v-card-text class="mt-2 govgr-!-font-size-19">
            &ensp;Για την ενημέρωση σχετικά με τις ειδοποιήσεις που αφορούν την εγγραφή σας στο μητρώο,
            προτείνετε να εγγραφείτε στην υπηρεσία "Εθνικό Μητρώο Επικοινωνίας (Ε.Μ.Επ.)".
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="closeGoToEMEPDialog">
            Κλείσιμο
          </v-btn>
          <v-spacer/>
          <v-btn @click="redirectToEMEP" class="primary">
            Μετάβαση στο Ε.Μ.Επ.
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "@/requests";

export default {
  name: "MemberDashboard",
  data: () => ({
    goToEMEPDialog: false,
  }),
  methods: {
    setMenuItems(){
      let menuItems = [
        {
          to: '/role-selection',
          name: 'Αρχική σελίδα'
        },
        {
          to: '/member-dashboard',
          name: 'Στοιχεία χρήστη'
        },
        {
          to: '/member-history',
          name: 'Ιστορικότητα εγγραφών',
          disabled: false
        },
        {
          to: '/member-current',
          name: 'Τρέχουσα κατάσταση',
          disabled: false
        },
      ]

      this.$store.commit('setMenuItems', menuItems);
    },

    isRegistersInEMEP(){
      let requestObj = {
        vat: this.$store.getters.loggedinUser.user.vat
      }

      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('user/is-registered-in-emep', requestObj, this.isregisteredInEMEPCallback)
    },
    isregisteredInEMEPCallback(response){
      if(response == false){
        this.goToEMEPDialog = true
      }else{
        this.goToEMEPDialog = false
      }

      this.$store.commit('setLoadingModal', false)
    },

    redirectToEMEP(){
      window.open('https://www.gov.gr/ipiresies/polites-kai-kathemerinoteta/stoikheia-polite-kai-tautopoietika-eggrapha/ethniko-metroo-epikoinonias-emep', 'target=_blank')
      // this.closeGoToEMEPDialog()
    },

    closeGoToEMEPDialog(){
      this.goToEMEPDialog = false
    },

    findOneGenericUser(){
      requests.postSecureRequest('user/get-current-user', {user_id: this.$store.getters.loggedinUser.user.id}, this.findOneGenericUserCallback)
    },
    findOneGenericUserCallback(response){
      console.log(JSON.parse(JSON.stringify(response)));
    },

  },
  created() {
    this.isRegistersInEMEP()
    this.setMenuItems()
  },
}
</script>

<style scoped>

</style>
