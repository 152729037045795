import utils from './utilities';
import store from './store'


let properties = {

    checkIfNotAuthorized(error) {

        console.log(error);
        if (error == 'Unauthorized' || error == 'No token provided!') {
            let path = utils.getLocalStorage('path');
            if (path == 'role-selection') {
                window.open("/fan-club-registry/passport/external", "_self")
                return;
            }
            window.open("/fan-club-registry/pa", "_self")
        }
    },

    getErrorMessage(response) {

        var message = response.data.message;
        this.checkIfNotAuthorized(message);
        store.commit('setSnackBarObj', {text: message});
        store.commit('setSnackBar', true)
    },

    getErrorMessageDialog(response) {
        var message = response.data.message;
        store.commit('setErrorDialogObj', {text: message});
        store.commit('setErrorDialog', true);

        if (response.status == 401) {
            utils.deleteCookie('jwt_token');
        }
    },
    getErrorMessageDialogForBlob(response) {
        var message = response.message;
        store.commit('setErrorDialogObj', {text: message});
        store.commit('setErrorDialog', true);
    },

    getInfoMessage(response) {
        var message = response;
        store.commit('setInfoSnackBarObj', {text: message});
        store.commit('setInfoSnackBar', true);
    },

    getInfoMessageDialog(response) {
        var message = response;
        store.commit('setInfoDialogObj', {text: message});
        store.commit('setInfoDialog', true)
    }
}

export default properties
