<template>
  <div class="mb-6">
    <v-card>
      <v-card-title style="background-color: #003375; word-break: normal" class="white--text">
        <v-row align="center" justify="center" class="ma-2 text-h4">
          Διάλυση λέσχης
          <v-icon class="ml-2 mt-2" style="background-color:#003375; color:yellow;" large>
            mdi-alert
          </v-icon>
        </v-row>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <ClubAndHistoryInfoForNeas/>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <!--        <v-btn :disabled="$store.getters.selectedClub.clubstatus_id != 2" @click="enableClub()" class="primary white&#45;&#45;text govgr-!-font-size-19">
                  Ενεργοποίηση (dev mode)
                </v-btn>-->
        <v-spacer/>
        <v-btn :disabled="!($store.getters.selectedClub.clubstatus_id == 1)" @click="dissolutionClubDialog = true"
               style="background-color: red" class="white--text govgr-!-font-size-19">
          Διάλυση
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog persistent v-model="dissolutionClubDialog" max-width="450">
      <v-card>
        <v-card-title style="word-break: normal" class="primary white--text govgr-!-font-size-22">
          Συμπλήρωση στοιχείων:
        </v-card-title>
        <v-card-text class="mt-2 govgr-!-font-size-19">
          <v-row align="center" justify="center" class="ma-1">
            <v-text-field class="mt-3" outlined placeholder="Αριθμός/Έτος" label="Αριθμός απόφασης"
                          v-model="CourtDecisionNumber"></v-text-field>
          </v-row>
          <v-row align="center" justify="center" class="ma-1">
            <v-textarea
                dense
                class="mt-1" outlined
                auto-grow
                label="Αιτιολογία διάλυσης" v-model="dissolutionReason"></v-textarea>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="dissolutionClubDialog = false">
            Κλείσιμο
          </v-btn>
          <v-spacer/>
          <v-btn class="primary white--text govgr-!-font-size-19" :disabled="(this.CourtDecisionNumber.trim() == '')"
                 @click="saveCourtOrder()">
            Αποθήκευση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="checkBeforeDissolutionClubDialog" max-width="450">
      <v-card>
        <v-card-title class="justify-center primary">
          <v-icon color="white">mdi-comment-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="mt-2 govgr-!-font-size-19">
          <v-row align="center" justify="center" class="ma-1">
            <v-textarea class="mt-3" outlined readonly v-model="this.courtDecisionText"></v-textarea>
          </v-row>
          <v-row align="center" justify="center" class="ma-1">
            Είστε σίγουρος/η για την διάλυση της λέσχης;
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="checkBeforeDissolutionClubDialog = false">
            Όχι
          </v-btn>
          <v-spacer/>
          <v-btn style="background-color: red" class="white--text govgr-!-font-size-19" @click="dissolutionClub()">
            Ναι, διάλυση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "@/requests";
import router from "@/router";
import ClubAndHistoryInfoForNeas from "@/components/NEAS/ClubAndHIstoryInfoForNeas.vue";

export default {
  name: "dissolutionClub",
  components: {ClubAndHistoryInfoForNeas},
  /*computed: {
    formattedText: {
      get() {
        return this.CourtDecisionNumber;
      },
      set(newValue) {
        // Ensure that the input is not longer than 5 characters
        if (newValue.length < 4) {
          this.CourtDecisionNumber = newValue;
        } else if(newValue.length == 4) {
          // If the input is longer than 5 characters, replace the 5th character with "/"
          this.CourtDecisionNumber = newValue.slice(0,3) + '/' + newValue;
        }
      },
    },
  },*/
  data: () => ({
    dissolutionClubDialog: false,
    checkBeforeDissolutionClubDialog: false,
    CourtDecisionNumber: '',
    courtDecisionText: '',
    headers: [
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'relRelatedAfm',
      },
      {
        text: 'Ονοματεπώνυμο',
        align: 'center',
        sortable: true,
        value: 'relRelatedOnomasia',
      },
    ],
    dissolutionReason: '',
  }),
  methods: {
    dissolutionClub() {
      this.$store.commit("setLoadingModal", true)
      let requestObj = {
        club_id: this.$store.getters.selectedClub.id,
        clubstatus_id: 2,
        reason: this.CourtDecisionNumber,
        dissolution_reason: this.dissolutionReason,
      }
      requests.postSecureRequest('club/neas-change-club-status', requestObj, this.dissolutionClubCallBack)
    },

    dissolutionClubCallBack(response) {

      this.dissolutionClubDialog = false
      this.checkBeforeDissolutionClubDialog = false
      this.$store.commit('setSelectedClub', response.club)
      this.$store.commit("setInfoDialogObj", {text: response.message})
      this.$store.commit("setInfoDialog", true)
      this.$store.commit("setLoadingModal", false)
      router.push('/club-info')
    },

    enableClub() {
      this.$store.commit("setLoadingModal", true)
      let requestObj = {
        club_id: this.$store.getters.selectedClub.id,
        clubstatus_id: 1,
      }
      requests.postSecureRequest('club/neas-change-club-status', requestObj, this.enableClubCallBack)
    },

    enableClubCallBack(response) {

      this.$store.commit('setSelectedClub', response.club)
      this.$store.commit("setInfoDialogObj", {text: response.message})
      this.$store.commit("setInfoDialog", true)
      this.$store.commit("setLoadingModal", false)
      router.push('/club-info')
    },

    saveCourtOrder() {
      this.courtDecisionText = 'Σύμφωνα με την απόφαση υπ αριθμ. ' + this.CourtDecisionNumber
          + ', η λέσχη φιλάθλων με επωνυμία ' + this.$store.getters.selectedClub.name + ' τίθεται σε διάλυση. '
      this.checkBeforeDissolutionClubDialog = true;
    }

  },
}
</script>


<style scoped>

</style>