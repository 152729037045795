<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div :class="{'blur-content': $store.getters.addYourSelfAsMemberDialog}">
    <v-card min-width="100%" class="mb-3">
      <v-card-title style="background-color: #003375; word-break: normal" class="white--text">
        <v-row align="center" justify="center" class="ma-2 text-h4">
          Στοιχεία λέσχης φιλάθλων
        </v-row>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row class="ma-1">
          <v-col cols="3">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $store.getters.selectedClub.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Επωνυμία λέσχης
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="3">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>
                  <v-chip small v-if="$store.getters.selectedClub.clubstatus_id ==
                                  $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.ACTIVE"
                          style="background-color: green;"
                          class="govgr-!-font-size-14 white--text"
                  >
                    {{ $store.getters.selectedClub.clubstatus.name }}
                  </v-chip>
                  <v-chip small v-else-if="$store.getters.selectedClub.clubstatus_id ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.SUSPENDED
                                       ||
                                       $store.getters.selectedClub.clubstatus_id  ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.IN_DISSOLUTION
                                       ||
                                       $store.getters.selectedClub.clubstatus_id  ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.UNLICENSED"
                          style="background-color: red;"
                          class="govgr-!-font-size-14 white--text"
                  >
                    {{ $store.getters.selectedClub.clubstatus.name }}
                  </v-chip>
                  <v-chip v-else small
                          class="govgr-!-font-size-14"
                  >
                    {{ $store.getters.selectedClub.clubstatus.name }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Κατάσταση λέσχης
                  <div v-if=" effective_end_date!= null &&
                                effective_end_date!= 'null' &&
                                effective_end_date != ''"
                       style="display: inline">
                    εώς {{ effective_end_date }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="3">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $store.getters.selectedClub.vat }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Α.Φ.Μ. λέσχης
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="3">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $store.getters.organization.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  ΑΣ/ΤΑΑ/ΑΑΕ στο οποίο υπάγεται
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row class="pa-4" align="center" justify="center" v-if="licenceForClub.application_approval_file != null">
          <v-col cols="11" class="govgr-!-font-size-19">
            <b>Βεβαίωση νόμιμης λειτουργίας λέσχης παραγόμενη από την Γενική Γραμματεία Αθλητισμού</b>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="1">
            <v-btn class="mt-4r" color="primary" icon @click="getApprovalDocument()">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="pa-4" v-else>
          <v-col class="govgr-!-font-size-19">
            <b> Δεν υπάρχει βεβαίωση νόμιμης λειτουργίας λέσχης από την Γενική Γραμματεία Αθλητισμού.</b>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row class="ma-1">
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
            <v-row>
              <v-col class="text-h6">
                Έδρα
              </v-col>
            </v-row>
            <v-row>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ clubAddress }}</v-list-item-title>
                  <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
            <div :style="pageWidth>960 ? 'height: 60px' : 'height: 20px'"/>
            <v-row align="center" justify="center">
              <div v-if="$store.getters.isTimeForApplications == false">
                <!--&& $store.getters.clubStatusToCheckForDissolution !=2-->
                <p style="color: red; word-break: normal; font-size: 18px" class="mt-1">
                  Η περίοδος νέων αιτήσεων δεν έχει ξεκινήσει!
                </p>
              </div>
            </v-row>
            <v-row align="center" justify="center">
              <v-card class="mr-5 " max-width="550px">
                <v-card-text style="text-align: left" v-if="clubStatus != 2">
                  <v-row>
                    <v-col style="width: 20%">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title> {{
                              (applicationState != null) ? '#' + applicationState.id : '-'
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Αριθμός αίτησης</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col style="width: 60%">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{
                              (applicationState != null) ? applicationStatus.name : '-'
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Κατάσταση αίτησης</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col style="width: 20%">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title v-if="applicationState == null">
                            {{ '-' }}
                          </v-list-item-title>
                          <v-list-item-title v-if="applicationStatus.id == 1">
                            {{ applicationState.creation_date }}
                          </v-list-item-title>
                          <v-list-item-title v-if="applicationStatus.id == 2">
                            {{ applicationState.submission_date }}
                          </v-list-item-title>
                          <v-list-item-title v-if="applicationStatus.id == 3">
                            {{ applicationState.approval_date }}
                          </v-list-item-title>
                          <v-list-item-title v-if="applicationStatus.id == 4">
                            {{ applicationState.rejection_date }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Ημερομηνία</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text v-else>
                  <h1 class="govgr-!-font-size-19">
                    Κατάσταση λέσχης:
                    <v-chip class="white--text" style="background-color: red; font-weight: bold">
                      ΣΕ ΔΙΑΛΥΣΗ
                    </v-chip>
                  </h1>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn class="primary"
                         @click="redirectToApplicationForm"

                  > <!--:disabled="clubStatus==2"-->
                    {{ 'Μετάβαση στην αίτηση' }}
                  </v-btn>
                  <v-spacer/>
                </v-card-actions>
              </v-card>
            </v-row>
          </v-col>
          <v-divider v-if="pageWidth>960" vertical/>
          <v-divider class="mx-1 mt-3" v-if="pageWidth<=960"/>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
            <v-row>
              <v-col class="text-h6">
                Εκπρόσωπος αθλητικού σωματείου
              </v-col>
            </v-row>
            <v-row v-if="councilOfOrganization.length > 0" align="center">
              <div v-for="(item,index) in councilOfOrganization" :key="index">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ (item.relFirstname != "") ? item.relFirstname : '-' }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ (item.relFirst_Lastname != "") ? item.relFirst_Lastname : '-' }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ (item.relRelatedAfm != "") ? item.relRelatedAfm : '-' }}</v-list-item-title>
                    <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-row>
            <v-row v-else align="center">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ '-' }}</v-list-item-title>
                  <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ '-' }}</v-list-item-title>
                  <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ '-' }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="councilMemberDialog" width="600px">
      <v-card>
        <v-card-title class="primary white--text">
          Πληροφορίες εκπροσώπου αθλητικού σωματείου
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedCouncilMember.relFirstname }}
                    {{ ' ' + selectedCouncilMember.relSecond_Lastname }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedCouncilMember.relFirst_Lastname }}</v-list-item-title>
                  <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedCouncilMember.relFathername }}</v-list-item-title>
                  <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedCouncilMember.relRelatedAfm }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedCouncilMember.relCategDescr }}</v-list-item-title>
                  <v-list-item-subtitle>Δραστηριότητα</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ selectedCouncilMember.relKindDescr }}</v-list-item-title>
                  <v-list-item-subtitle>Ιδιότητα</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="councilMemberDialog = false">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AddYourSelfAsMember/>
  </div>
</template>

<script>
import requests from "@/requests";
import AddYourSelfAsMember from "@/components/reusable/AddYourSelfAsMember.vue";

export default {
  name: "EditClubCard",
  components: {AddYourSelfAsMember},
  data: () => ({
    buttons: [
      {
        id: 7,
        name: 'Αναζήτησή μελών'
      },
      {
        id: 2,
        name: 'Παραρτήματα'
      },
      {
        id: 8,
        name: 'Χώροι παραρτημάτων λέσχης'
      },
      {
        id: 6,
        name: 'Ιστορικό μεταφορτώσεων αρχείων μελών'
      },
      {
        id: 3,
        name: 'Υποβολή αίτησης προς ΓΓΑ'
      },
      {
        id: 4,
        name: 'Ιστορικό αιτήσεων'
      },
      // {
      //   id: 1,
      //   name: 'Εισαγωγή ή Επιβεβαίωση Στοιχείων Λέσχης'
      // },
      // {
      //     id: 5,
      //     name: 'Άδειες Λειτουργίας Παραρτημάτων'
      // },


    ],
    applicationState: {},
    applicationStatus: {},
    clubStatus: null,
    pageWidth: window.innerWidth,
    applicationFormEnabled: true,
    progress: null,
    isStatementPeriod: false,
    isRejected: false,
    ggaApplication: 'Υποβολή αίτησης προς Γ.Γ.Α.',
    clubAddress: '',
    disabledApplicationButton: true,
    applicationButtonName: '',
    applicationButtonIcon: '',
    requestCount: 0,
    councilOfOrganization: [],
    selectedCouncilMember: {},
    councilMemberDialog: false,
    headersNEAS: [
      {
        text: 'Ονοματεπώνυμο',
        align: 'center',
        sortable: false,
        value: 'relRelatedOnomasia'
      },
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'relRelatedAfm'
      },
    ],
    effective_end_date: '',
    licenceForClub: {},
  }),

  methods: {

    callToAction(action) {

      if (action === 1) {
        this.$router.push({path: '/club-contact-info'})
      } else if (action === 2) {
        this.$router.push({path: '/view-branches'})
      } else if (action === 3) {
        this.$router.push({path: '/application-form'})
      } else if (action === 4) {
        this.$router.push({path: '/history-of-club'})
      } else if (action === 6) {
        this.$router.push({path: '/history-of-uploaded-memberfiles'})
      } else if (action === 7) {
        this.$router.push({path: '/search-for-members'})
      } else if (action === 8) {
        this.$router.push({path: '/view-places'})
      }
    },

    setMenuItems() {
      let menuItems = [
        {
          to: '/role-selection',
          name: 'Αρχική σελίδα'
        },
        {
          to: '/select-club',
          name: 'Επιλογή λέσχης'
        },
        {
          name: 'Λέσχη',
          children: [
            {
              to: '/nel-dashboard',
              name: ' Στοιχεία λέσχης',
            },
            {
              to: '/view-branches',
              name: ' Παραρτήματα'
            },
            {
              to: '/view-places',
              name: ' Χώροι παραρτημάτων'
            },
            {
              to: '/members',
              name: ' Μέλη λέσχης',
            },
            {
              to: '/council-members',
              name: ' Μέλη Δ.Σ.',
            },
            {
              to: '/search-for-members',
              name: ' Αναζήτηση μελών',
            },
            {
              to: '/application-form',
              name: ' Αίτηση',
            },
          ]
        },
        {
          to: '/history-of-club',
          name: 'Ιστορικό αιτήσεων'
        },
        {
          to: '/history-of-uploaded-memberfiles',
          name: 'Ιστορικό μεταφορτώσεων αρχείων μελών',
        },
        {
          to: '/notifications-page',
          name: 'Ειδοποιήσεις',
        }]

      this.$store.commit('setMenuItems', menuItems);
      this.$store.commit('setLoadingModal', false)
    },

    getStatementPeriod() {
      requests.postSecureRequest('application/statement-period', {}, this.statementPeriodCallback)
    },

    statementPeriodCallback(response) {

      this.requestCount++

      this.isStatementPeriod = response.isStatementPeriod
      /*if (this.isStatementPeriod == false){
        this.ggaApplication = 'Η περίοδος νέων αιτήσεων δεν έχει ξεκινήσει.'
        this.applicationFormEnabled = false
        this.$store.commit('setggaApplication',this.ggaApplication);
        this.$store.commit('setIsTimeForApplications', false)
      }*/
      this.$store.commit('setIsTimeForApplications', this.isStatementPeriod)
      if (this.isStatementPeriod) {
        this.getApplicationProgress();
      } else {
        this.getSubmittedOrApprovedApplication()
      }
    },

    getSubmittedOrApprovedApplication() {
      requests.postSecureRequest('club/sent-or-approved-application',
          {club_id: this.$store.getters.selectedClub.id},
          this.getSubmittedOrApprovedApplicationCallback)
    },


    getSubmittedOrApprovedApplicationCallback(response) {

      this.requestCount++
      this.applicationState = response
      if (response != null) {
        this.applicationStatus = response.applicationstatus
        this.$store.commit('setggaApplication', response.applicationstatus)
        this.$store.commit('setGgaApplicationId', response.id)
        if (this.applicationStatus.id == 2) {
          this.$store.commit('setGgaApplicationDate', response.submission_date)
          this.$store.commit('setGgaApplicationProgress', 2)
        } else if (this.applicationStatus.id == 3) {
          this.$store.commit('setGgaApplicationProgress', 3)
          this.$store.commit('setGgaApplicationDate', response.approval_date)
        }
        this.clubStatus = response.club.clubstatus_id
        this.$store.commit('setClubStatusToCheckForDissolution', this.clubStatus)
      } else {
        this.$store.commit('setGgaApplicationProgress', 5)
        this.$store.commit('setGgaApplicationDate', '-')
      }

    },

    getApplicationProgress() {

      this.$store.commit('setLoadingModal', true);
      requests.postSecureRequest('application/get-application-progress', {club_id: this.$store.getters.selectedClub.id}, this.getApplicationProgressCallback)
      this.$store.commit('setLoadingModal', false);
    },

    getApplicationProgressCallback(response) {

      this.requestCount++;
      this.progress = response
      this.$store.commit('setGgaApplicationProgress', response.progress)
      if (response.progress == 2 || response.progress == 3) {
        this.getCurrentApplicationInStatementPeriod()
      } else {
        this.getClubAFM()
      }
    },

    getCurrentApplicationInStatementPeriod() {
      requests.postSecureRequest('application/get-current-application-in-statement-period', {club_id: this.$store.getters.selectedClub.id}, this.getCurrentApplicationInStatementPeriodCallback)
    },

    getCurrentApplicationInStatementPeriodCallback(response) {

      this.requestCount++
      this.applicationState = response[0]
      this.clubStatus = response[0].club.clubstatus_id
      this.$store.commit('setClubStatusToCheckForDissolution', this.clubStatus)
      if (response != null) {
        this.applicationStatus = response[0].applicationstatus
        this.$store.commit('setggaApplication', response[0].applicationstatus)
        this.$store.commit('setGgaApplicationId', response[0].id)
        // this.effective_end_date = response[0].effective_end_date
        if (this.applicationStatus.id == 2) {
          this.$store.commit('setGgaApplicationDate', response[0].submission_date)
        } else if (this.applicationStatus.id == 3) {
          this.$store.commit('setGgaApplicationDate', response[0].approval_date)
        }
      }

    },

    redirectToApplicationForm() {
      this.$router.push({path: '/application-form'})
    },

    async getClubAFM() {
      this.$store.commit('setLoadingModal', true)
      await requests.postSecureRequest('club/vat-with-application', {club: this.$store.getters.selectedClub}, await this.getClubAFMCallback)
    },
    async getClubAFMCallback(response) {

      this.requestCount++;

      this.applicationState = response.application
      this.clubStatus = response.club.clubstatus_id //checking for dissolution
      this.$store.commit('setClubStatusToCheckForDissolution', this.clubStatus)
      if (this.applicationState != null) {
        this.applicationStatus = response.application.applicationstatus
        this.$store.commit('setggaApplication', response.application.applicationstatus)
        this.$store.commit('setGgaApplicationId', response.application.id)
        if (this.applicationStatus.id == 1) {
          this.$store.commit('setGgaApplicationDate', response.application.creation_date)
        } else if (this.applicationStatus.id == 4) {
          this.$store.commit('setGgaApplicationDate', response.application.rejection_date)
        }
      }

      this.$store.commit('setLoadingModal', false)
    },

    getCouncilOfOrganization() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('club/get-relations-array', {club_id: this.$store.getters.selectedClub.id}, this.getCouncilOfOrganizationCallback)
    },

    getCouncilOfOrganizationCallback(response) {
      this.councilOfOrganization = []
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.length; i++) {
        let name = res[i].relRelatedOnomasia.split(',')
        let obj = {
          relRelatedAfm: res[i].relRelatedAfm,
          relFirstname: name[2],
          relFirst_Lastname: name[0],
          relSecond_Lastname: name[1],
          relFathername: name[3],
          relCateg: res[i].relCateg,
          relCategDescr: res[i].relCategDescr,
          relKind: res[i].relKind,
          relKindDescr: res[i].relKindDescr,
          relStartDate: res[i].relStartDate,
        }
        this.councilOfOrganization.push(obj)
      }

      this.$store.commit('setLoadingModal', false)
    },

    openCouncilMemberDialog(item) {
      this.selectedCouncilMember = item
      this.councilMemberDialog = true
    },

    checkLoggedInUserIsValidMember() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('branch/check-logged-in-user-is-valid-member', {branch_id: this.$store.getters.selectedClub.myBranchesOnClub[0].id}, this.checkLoggedInUserIsValidMemberCallback)
    },

    checkLoggedInUserIsValidMemberCallback(response) {
      if (!response.is_valid_member) {
        this.$store.commit('setAddYourSelfAsMemberDialog', true)
      }
      this.$store.commit('setLoadingModal', false)
    },

    handleResize() {
      this.pageWidth = window.innerWidth;
    },

    getLicenceOfClub() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('club/get-gga-files', {club_id: this.$store.getters.selectedClub.id}, this.getLicenceOfClubCallback)
    },

    getLicenceOfClubCallback(response) {
      this.licenceForClub = response
    },

    getApprovalDocument() {
      requests.downloadFileRequestWithObj('application/get-approval-file', {application_id: this.licenceForClub.application_id})
    },

    getEndDateOfStatus() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('club/get-status-until-date',
          {club_id: this.$store.getters.selectedClub.id}, this.getEndDateOfStatusCallback)
    },
    getEndDateOfStatusCallback(response) {
      this.effective_end_date = response.date
      this.$store.commit('setLoadingModal', false)
    }

  },

  watch: {

    'requestCount': function (val) {

      if (this.isStatementPeriod) {
        if (val == 3) {
          this.setMenuItems();
        }

      } else {
        if (val == 2) {
          this.setMenuItems();
        }
      }
    }
  },

  created() {
    for (let branch of this.$store.getters.selectedClub.myBranchesOnClub) {
      if (branch.is_default == true || branch.is_default == 'true') {
        this.clubAddress = branch.address + ' ' + branch.city + ' ' + branch.postal_code
        break
      }
    }
    this.checkLoggedInUserIsValidMember();
    this.getStatementPeriod();
    this.getCouncilOfOrganization()
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.getLicenceOfClub()
    this.getEndDateOfStatus()

    this.$store.commit('setNotificationIcon', true)
  },
  beforeDestroy() {

    // this.$store.commit('setNotificationIcon', false)
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>
.blur-content {
  filter: blur(6px);
}
</style>
