<template>
  <div>
    <v-row v-if="Object.keys(selectedBranch).length > 0">
      <v-col class="pt-1">
        <v-data-table
            :page.sync="pageNumber"
            show-select
            v-model="selectedRows"
            :footer-props="{page:1,
                            pageCount:1,
                            showCurrentPage:true,
                            itemsPerPageOptions:[5,10,15,30],
                            showFirstLastPage:true,
                            itemsPerPageText:'Μέλη ανά σελίδα:',
                            itemsPerPageAllText:'Όλα'}"
            no-data-text="Δέν υπάρχουν αποτελέσματα."
            :headers="headers"
            :items="members"
            :options.sync="options"
            :search="search"
            :items-per-page="itemsPerPage"
            sort-by="last_name"
            sort-desc
            :server-items-length="tableLength"
            @update:options="getBuildingPerPage"
            class="elevation-3 mx-5 px-2"
        >
          <template v-slot:[`footer.page-text`]="items">
            {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>

              <v-card color="primary"
                      class="mb-5"
                      height="80"
                      width="90">
                <v-card-title class="white--text">
                  {{ 'Μέλη' }}
                </v-card-title>
              </v-card>
              <v-toolbar-title>

              </v-toolbar-title>

              <v-tooltip top style="background-color: lightgray">
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-2" v-on="on">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>
                 Η κόκκινη χρωματική ένδειξη των μελών υποδηλώνει μη έγκυρα στοιχεία.
                </span>
              </v-tooltip>
              <v-text-field class="ml-5 mt-5"
                            append-icon="mdi-magnify"
                            label="Αναζήτηση μέλους.."
                            v-model="search"
                            :rules="searchRules"
                            dense
                            @keydown.enter="searchWithParameter"
                            @click:append="searchWithParameter"
              ></v-text-field>
              <v-text-field class="ml-5 mt-5"
                            append-icon="mdi-magnify"
                            label="Αναζήτηση μέλους με ΑΦΜ.."
                            v-model="searchVat"
                            :rules="searchRules"
                            dense
                            @keydown.enter="searchWithParameter"
                            @click:append="searchWithParameter"
              ></v-text-field>
              <v-select placeholder="Επίλεξε κατάσταση μέλους"
                        class="ml-5 mt-5"
                        style="width: 200px"
                        dense
                        v-model="searchStatus"
                        :items="memberStatuses"
                        item-text="status"
                        no-data-text="Δεν υπάρχουν καταχωρήσεις"
                        @change="getMembers"
                        return-object/>
              <v-spacer></v-spacer>
              <TemplateDownloadButton/>
              <AddMembersFromFile/>
              <v-dialog max-width="500px" persistent scrollable v-model="dialog">
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="openDialog({}, false, true), newMember = true" class="mb-2" color="primary" dark
                             fab small v-on="on">
                        <v-icon>add</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ 'Προσθήκη μέλους' }}</span>
                  </v-tooltip>
                </template>
                <v-card tile>
                  <v-card-title class="primary">
                    <span class="headline white--text">{{ 'Στοιχεία μέλους' }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form v-model="valid" ref="form">
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="ΑΦΜ*" v-model.trim="member.vat"
                                          :disabled="readOnly || onEdit" required :rules="vatRule" counter="9"
                                          type="number" hide-spin-buttons></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label='Όνομα*'
                                          v-model.trim="member.user_branches[0].first_name" :disabled="readOnly"
                                          required :rules="nameRules"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="Επώνυμο*"
                                          v-model.trim="member.user_branches[0].last_name" :disabled="readOnly" required
                                          :rules="nameRules"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="Πατρώνυμο*"
                                          v-model.trim="member.user_branches[0].father_name" required :rules="nameRules"
                                          :disabled="readOnly"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="Μητρώνυμο"
                                          v-model.trim="member.user_branches[0].mother_name"
                                          :disabled="readOnly"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-dialog
                                ref="dialog"
                                v-model="openDatePicker"
                                persistent
                                width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    readonly
                                    :value="member.user_branches[0].date_of_birth"
                                    outlined
                                    required
                                    :rules="nameRules"
                                    :disabled="readOnly"
                                    label="Ημερομηνία γέννησης*" dense clearable
                                    v-on="on" @click:clear="clear"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                  v-model="member.user_branches[0].date_of_birth"
                                  scrollable
                                  :max="true? new Date().toISOString().split('T')[0] : '' "
                                  :active-picker.sync="activePicker"
                                  locale="el"
                                  color="primary">
                                <v-spacer></v-spacer>
                                <v-btn @click="closeDatePicker"
                                       color="primary">
                                  Κλείσιμο
                                </v-btn>
                                <v-btn
                                    @click="selectDates"
                                    color="primary">
                                  Επιλογή
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="ΑΜΚΑ" v-model.trim="member.user_branches[0].amka"
                                          :disabled="readOnly"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="Διεύθυνση*"
                                          v-model.trim="member.user_branches[0].address" :disabled="readOnly" required
                                          :rules="nameRules"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="Δήμος*" v-model.trim="member.user_branches[0].city"
                                          :disabled="readOnly" required :rules="nameRules"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="Ταχυδρομικός Κώδικας*"
                                          v-model.trim="member.user_branches[0].postal_code" :disabled="readOnly"
                                          required :rules="nameRules"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="Τηλέφωνο"
                                          v-model.trim="member.user_branches[0].phone_number"
                                          :disabled="readOnly"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="Email" v-model.trim="member.user_branches[0].email"
                                          :disabled="readOnly"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="Αριθμός Ταυτότητας*"
                                          v-model.trim="member.user_branches[0].identification_document"
                                          :disabled="readOnly" required :rules="nameRules"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field color="primary" label="Αριθμός Μέλους*"
                                          v-model.trim="member_number" :disabled="readOnly" required
                                          :rules="cardNumberMemberRule"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12"><!--v-if="!readOnly"-->
                            <v-checkbox
                                v-model="affirmation"
                                label="Επιβεβαιώνεται οτι η Λέσχη διαθέτει υπεύθυνη δήλωση περί μη συνδρομής νομικού κωλύματος για την εγγραφή;"
                                :disabled="readOnly || (edit && hasAffirmation == true)"
                            ></v-checkbox>
                          </v-col>
                        </v-row>

                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn @click="close" color="primary" text>{{ 'Κλείσιμο' }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="readOnly" @click="openSuspensionDialog()" class="primary">Αναστολή μέλους</v-btn>
                    <v-btn :disabled="saveDisabled" @click="save(newMember)" color="primary" text v-if="!readOnly">
                      {{ 'Αποθήκευση' }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="editItem(item)"
                        color="green"
                        small
                        v-on="on">edit
                </v-icon>
              </template>
              <span>{{ 'Επεξεργασία' }}</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="openModalToDeleteItem(item)"
                        color="red"
                        small
                        v-on="on">delete
                </v-icon>
              </template>
              <span>{{ 'Διαγραφή' }}</span>
            </v-tooltip>
          </template>
          <template v-slot:header.data-table-select="{props,on}">
            <section v-if="selectedRows.length > 0" align="center">
              <v-chip small>
                {{ '+' + selectedRows.length }}
              </v-chip>
            </section>
            <!--            <v-simple-checkbox color="primary" v-model="selectAllMembers" v-ripple v-bind="props" v-on="on"></v-simple-checkbox>-->
          </template>
          <template v-slot:item="{item,isSelected,select}">
            <tr>
              <td class="text-center d-sm-table-cell d-flex">
                <v-simple-checkbox v-ripple :value="isSelected" @input="select($event)"></v-simple-checkbox>
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openDialog(item, true, false)">
                {{ item.vat }}
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openDialog(item, true, false)">
                {{ item.user_branches[0].first_name }}
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openDialog(item, true, false)">
                {{ item.user_branches[0].last_name }}
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openDialog(item, true, false)">
                {{ item.user_branches[0].father_name }}
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openDialog(item, true, false)">
                {{ item.user_branches[0].date_of_birth }}
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                <v-chip
                    v-if="item.user_branches[0].userbranchbranchrolestatus.id
                    == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.ACTIVE"
                    class="white--text" color="green">
                  {{ item.user_branches[0].userbranchbranchrolestatus.status }}
                </v-chip>
                <v-chip v-else-if="item.user_branches[0].userbranchbranchrolestatus.id  == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.DELETED
                                    || item.user_branches[0].userbranchbranchrolestatus.id  == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.INACTIVE
                                    || item.user_branches[0].userbranchbranchrolestatus.id  == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.SUSPENDED
                                    || item.user_branches[0].userbranchbranchrolestatus.id  == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.INVALID"
                        color="red" class="white--text">
                  {{ item.user_branches[0].userbranchbranchrolestatus.status }}
                </v-chip>
                <!--                <v-chip v-else-if="item.user_branches[0].userbranchbranchrolestatus.id  == 4"
                                        class="white&#45;&#45;text" color="green">
                                  {{ item.user_branches[0].userbranchbranchrolestatus.status }}
                                </v-chip>-->
                <v-chip v-else>
                  {{ item.user_branches[0].userbranchbranchrolestatus.status }}
                </v-chip>
              </td>
              <!--              <td class="text-center d-sm-table-cell d-flex" @click="openDialog(item, true, false)">
                              {{ item.user_branches[0].amka }}
                            </td>-->
              <td class="text-center d-sm-table-cell d-flex" @click="openDialog(item, true, false)">
                {{ item.user_branches[0].address }}
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openDialog(item, true, false)">
                {{ item.user_branches[0].city }}
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openDialog(item, true, false)">
                {{ item.user_branches[0].postal_code }}
              </td>
              <td class="text-center d-sm-table-cell d-flex" @click="openDialog(item, true, false)">
                {{ item.user_branches[0].identification_document }}
              </td>
              <td class="text-center d-sm-table-cell d-flex">
                <v-row>
                  <v-btn rounded @click="openDialog(item, false, false), newMember = false, onEdit = true" icon
                         elevation="5" class="ma-1">
                    <v-icon color="blue">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn rounded icon @click="openModalToDeleteItem(item)"
                         :disabled="(item.vat == $store.getters.loggedinUser.user.vat)" elevation="5" class="ma-1">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </v-row>
              </td>
            </tr>
          </template>
          <template v-slot:footer.prepend>
            <v-btn :disabled="selectedRows.length < 2"
                   color="red" class="white--text" small
                   @click="openDeleteMembersDialog()">
              Μαζική διαγραφή μελών
            </v-btn>
            <div v-if="selectedRows.length > 1">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn @click="clearSelectAll()"
                         icon small class="ml-1">
                    <v-icon v-on="on">
                      mdi-broom
                    </v-icon>
                  </v-btn>
                </template>
                <span>Καθαρισμός επιλογών</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!--    <DeleteDialog event-name="MembersDeleteEvent"/>-->
    <v-dialog persistent width="450px" v-model="suspensionDialog">
      <v-card>
        <v-card-title style="word-break: normal" class="primary white--text">
          Αναστολή μέλους {{ member.user_branches[0].first_name + ' ' + member.user_branches[0].last_name }}
        </v-card-title>
        <v-card-text>
          <v-dialog
              ref="dialog"
              v-model="openDateSuspensionPicker"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  style="width: 380px"
                  class="mt-4"
                  readonly
                  :value="endOfSuspension"
                  outlined
                  required
                  label="Ημερομηνία λήξης αναστολής"
                  dense
                  clearable
                  v-on="on" @click:clear="clear"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="endOfSuspension"
                scrollable
                :min="true ? (new Date(new Date().setMonth(new Date().getMonth())).toISOString().split('T')[0]) : ''"
                locale="el"
                color="primary">
              <v-spacer></v-spacer>
              <v-btn @click="openDateSuspensionPicker = false"
                     color="primary">
                Κλείσιμο
              </v-btn>
              <v-btn
                  @click="openDateSuspensionPicker = false"
                  color="primary">
                Επιλογή
              </v-btn>
            </v-date-picker>
          </v-dialog>
          <!--          :min="true ? (new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().split('T')[0]) : ''"
                    :max="true ? (new Date(new Date().setMonth(new Date().getMonth() + 12)).toISOString().split('T')[0]) : ''"
                    :show-current="sixMonthsFromNow"-->
          <v-textarea
              style="width: 380px;"
              class="mt-1"
              label="Αιτιολογία αναστολής"
              outlined
              v-model="suspensionReasoning"
              required>
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="suspensionDialog = false">Κλείσιμο</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="suspendMember()" :disabled="isValidSuspencionForm" class="primary">Αναστολή μέλους</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent width="650" v-model="deleteMembersDialog">
      <v-card>
        <v-card-title class="primary white--text mb-4" style="word-break: normal">
          Μαζική διαγραφή μελών
          <v-spacer/>
          <v-btn icon color="white" @click="deleteMembersDialog = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <section class="govgr-!-font-size-19" style="font-weight: bold">
            Έχετε επιλέξει να διαγράψετε {{ selectedRows.length }} μέλη:
          </section>
          <div v-for="(member,index) in limitedItems" :key="index" class="mt-2">
            <v-row justify="center" align="center">
              <v-col cols="1">
                <section class="govgr-!-font-size-19">
                  {{ index + 1 + '.' }}
                </section>
              </v-col>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ member.first_name }}</v-list-item-title>
                    <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ member.last_name }}</v-list-item-title>
                    <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ member.vat }}</v-list-item-title>
                    <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-divider/>
          </div>
          <div v-if="(selectedRows.length - membersShowLimit) > 0">
            <section class="govgr-!-font-size-19 mt-2" v-if="(selectedRows.length - membersShowLimit) == 1">
              {{ '+' + (selectedRows.length - membersShowLimit) + ' μέλος ακόμα' }}
            </section>
            <section class="govgr-!-font-size-19 mt-2" v-else>
              {{ '+' + (selectedRows.length - membersShowLimit) + ' μέλη ακόμα' }}
            </section>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="deleteItem()" color="red" class="white--text">
            Διαγραφή μελών
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="failedDeletionsDialog" width="900px">
      <v-card>
        <v-card-title style="word-break: normal" class="primary white--text">
          Ενέργεια διαγραφής μελών
          <v-spacer/>
          <v-btn icon color="white" @click="failedDeletionsDialog = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="center" align="center" class="mb-2">
            <v-col cols="4">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Μέλη προς διαγραφή: {{ failedDeletionsResponse.all }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Επιτυχημένα: {{ failedDeletionsResponse.success_count }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>Αποτυχημένα: {{ failedDeletionsResponse.failure_count }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
          <section class="govgr-!-font-size-19 mb-1" style="font-weight: bold">
            Αποτυχημένες διαγραφές:
          </section>
          <v-row v-for="(item,index) in failedDeletionsResponse.failure_array" :key="index"
                 justify="center" align="center">
            <v-col cols="1">
              <section class="govgr-!-font-size-19">
                {{ index + 1 + '.' }}
              </section>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ item.member.user_branches[0].first_name }}
                    {{ item.member.user_branches[0].last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Ονοματεπώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <!--            <v-col cols="2">
                          <v-list-item two-line>
                            <v-list-item-content>
                              <v-list-item-title></v-list-item-title>
                              <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>-->
            <v-col cols="2">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ item.member.vat }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="6">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title style="white-space: normal">{{ item.message }}</v-list-item-title>
                  <v-list-item-subtitle>Αιτιολογία</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent
              v-model="deleteSingleMemberDialog"
              :retain-focus="false"
              width="400">
      <v-card>
        <v-card-title class="primary">
          <span class="headline white--text">Διαγραφή</span>
        </v-card-title>

        <v-card-text>
          <v-textarea label="Αιτιολογία διαγραφής" outlined class="mt-8" v-model="deleteMemberReason"/>
          <h3 class="mt-1 govgr-!-font-size-19">
            Είστε σίγουρος για την διαγραφή;
          </h3>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="deleteSingleMemberDialog = false, deleteMemberReason = ''"
          >
            Άκυρο
          </v-btn>
          <v-btn
              color="primary"
              text
              @click="deleteItem"
          >
            Διαγραφή
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import DeleteDialog from "@/components/reusable/DeleteDialog.vue";
import AddMembersFromFile from "@/components/reusable/AddMembersFromFile.vue";
import {eventBus} from "@/main";
import requests from "@/requests";
import prop from "@/properties";
import TemplateDownloadButton from "@/components/reusable/TemplateDownloadButton.vue";

export default {
  name: "BranchMembers",
  components: {DeleteDialog, AddMembersFromFile, TemplateDownloadButton},
  //watcher for the birthday - date picker
  watch: {
    openDatePicker(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    changeMemberfileParsingIntervalDialog(val2) {
      if (val2) {
        this.getMembers()
      }
    },
  },
  computed: {
    saveDisabled() {
      // if(this.member.confirmed != true){
      //   return true
      // }
      if (this.affirmation == false) {
        return true
      }
      if (this.valid == false) {
        return true
      }
      if (this.newMember == true) {
        if (this.selectedBranchFromAdd.id == undefined) {
          return true
        }
      }

      if (!Object.hasOwn(this.member.user_branches[0], 'father_name')) return true

      return false
    },
    changeMemberfileParsingIntervalDialog: function () {
      return this.$store.getters.memberfileParsingIntervalDialog
    },

    isValidSuspencionForm: function () {
      if (this.endOfSuspension == '') {
        return true
      }
      if (this.suspensionReasoning == '') {
        return true
      }

      return false
    },

    limitedItems() {
      return this.selectedRows.slice(0, this.membersShowLimit);
    }

  },
  data: () => ({
    member: {
      vat: '',
      user_branches: [
        {
          first_name: '',
          last_name: '',
          father_name: '',
          identification_document: '',
          mother_name: '',
          date_of_birth: null,
          amka: '',
          address: '',
          city: '',
          postal_code: '',
          phone_number: '',
          email: '',
          member_number: '',
          is_valid: '',
          affirmation: '',
        }
      ]
    },
    singleSelectedDate: '',
    openDatePicker: false,

    //variable for the birthday - date picker
    activePicker: null,

    headers: [
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'vat',
      },
      {
        text: 'Όνομα',
        align: 'center',
        sortable: true,
        value: 'first_name',
      },
      {
        text: 'Επώνυμο',
        align: 'center',
        sortable: true,
        value: 'last_name',
      },
      {
        text: 'Πατρώνυμο',
        align: 'center',
        sortable: false,
        value: 'father_name',
      },
      {
        text: 'Ημ. γέννησης',
        align: 'center',
        sortable: true,
        value: 'date_of_birth',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      /*      {
              text: 'ΑΜΚΑ',
              align: 'center',
              sortable: true,
              value: 'amka',
            },*/
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Αρ. ταυτότητας',
        align: 'center',
        sortable: false,
        value: 'identification_document',
      },
      {
        text: 'Λειτουργίες',
        align: 'center',
        sortable: false,
      },
    ],
    search: '',
    searchVat: '',
    dialog: false,
    valid: false,
    identityCardTypes: [],
    members: [],
    branches: [],
    selectedBranch: {},
    selectedBranchFromAdd: {},
    searchObj: {},
    readOnly: false,
    newMember: false,
    onEdit: false,
    affirmation: false,
    hasAffirmation: false,
    edit: false,
    member_number: '',
    itemsPerPage: 5,
    pageNumber: 1,
    tableLength: 0,
    deleteObj: [],
    nameRules: [
      v => !!v || 'Το πεδίο είναι απαραίτητο.',
      v => !!v && v.trim() != '' || 'Το πεδίο είναι απαραίτητο.',
      v => !!v && (v.trim() == '' || v.trim().length >= 3) || 'Το πεδίο πρέπει να έχει τουλάχιστον τρεις χαρακτήρες.',
    ],
    fatherNameRules: [
      // v => (!!v || !!this.member.mother_name) || 'Το πεδίο είναι απαραίτητο.',
      v => !!v && v.trim() != '' || 'Το πεδίο είναι απαραίτητο.',
    ],
    vatRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο',
      v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
    ],

    selectRules: [
      v => Boolean(Object.keys(v || {})[0]) || 'Το πεδίο είναι απαραίτητο.',
    ],

    cardNumberMemberRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο.',
    ],

    searchRules: [
      v => (v.trim().length >= 3 || v.trim().length == 0) || 'Απαιτούνται τουλάχιστον 3 χαρακτήρες.'
    ],
    suspensionDialog: false,
    endOfSuspension: '',
    openDateSuspensionPicker: false,
    suspensionReasoning: '',
    selectedRows: [],
    deleteMembersDialog: false,
    selectAllMembers: false,
    membersShowLimit: 20,
    failedDeletionsResponse: {},
    failedDeletionsDialog: false,
    memberStatuses: [],
    searchStatus: '',
    deleteSingleMemberDialog: false,
    deleteMemberReason: '',
    options: {
      sortBy: ['last_name'],
      sortDesc: [true]
    },
  }),
  async created() {
    eventBus.$on('MembersDeleteEvent', (() => {
      this.deleteItem();
    }));

    this.selectedBranch = this.$store.getters.selectedBranchYPL
    this.selectedBranchFromAdd = this.$store.getters.selectedBranchYPL
    this.getMembers()
    this.getMemberStatuses()
  },
  beforeDestroy() {
    eventBus.$off('MembersDeleteEvent')
  },
  methods: {
    addButton() {
      this.dialog = true;
    },

    deleteItem() {
      this.$store.commit('setLoadingModal', true)
      if (this.deleteObj.length == 1) {
        this.deleteObj[0].deleteReason = this.deleteMemberReason
      }
      requests.deleteSecureRequest('user/deleteMember', this.deleteObj, this.deleteItemCallback)
    },

    deleteItemCallback(response) {
      this.$store.commit('setThereWasAChangeInApplication', true)
      this.deleteMemberReason = ''
      this.selectedRows = []
      this.failedDeletionsResponse = response
      if (response.failure_count > 0) {
        this.failedDeletionsDialog = true
      } else {
        this.$store.commit('setInfoDialogObj', {text: 'Επιτυχής ενέργεια'})
        this.$store.commit('setInfoDialog', true)
      }
      this.deleteMembersDialog = false
      this.deleteSingleMemberDialog = false
      this.getMembers()
    },

    openModalToDeleteItem(item) {
      this.deleteObj = []
      this.deleteObj.push(item);
      this.deleteSingleMemberDialog = true
    },

    getMembers() {
      this.$store.commit('setLoadingModal', true);
      this.pageNumber = 1
      this.searchObj.branch = this.selectedBranch
      this.searchObj.parameter = '' + this.search + ''
      this.searchObj.parameterVat = '' + this.searchVat + ''
      this.searchObj.userbranchbranchrolestatus_id = this.searchStatus.id


      if (this.options.sortBy.length > 0) {

        this.searchObj.sortField = this.options.sortBy[0]

        if (this.options.sortDesc[0]) {
          this.searchObj.sortDirection = "ASC";
        } else {
          this.searchObj.sortDirection = "DESC";
        }
      }

      requests.postSecureRequest('user/branchMembers',
          {
            searchObj: this.searchObj,
            itemsPerPage: this.itemsPerPage,
            pageNumber: this.pageNumber - 1
          },
          this.getMembersCallback)
    },

    getMembersCallback(response) {
      this.selectAllMembers = false
      this.members = []
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.members.length; i++) {
        this.members.push(res.members[i])
      }
      console.log(JSON.parse(JSON.stringify(this.members)));
      this.tableLength = res.membersCount
      this.$store.commit('setLoadingModal', false);
    },

    editItem() {

    },

    mailTo(email) {
      if (email.length > 0) {
        document.location = "mailto:" + email
      }
    },

    close() {
      this.dialog = false;
      //this.$refs.form.reset();
      setTimeout(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1
      }, 300)

      this.newMember = false
      this.member = {
        vat: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
            mother_name: '',
            date_of_birth: null,
            amka: '',
            address: '',
            city: '',
            postal_code: '',
            phone_number: '',
            email: '',
            member_number: '',
            is_valid: '',
            affirmation: '',
          }
        ]
      }
      this.dialog = false
      this.onEdit = false
      this.affirmation = false
      this.edit = false
      this.hasAffirmation = false
      this.member_number = ''
    },

    // loadBranchesOnDropdown(){
    //   this.branches = []
    //   for(let i = 0; i < this.$store.getters.selectedClub.myBranchesOnClub.length; i++){
    //     this.branches.push(this.$store.getters.selectedClub.myBranchesOnClub[i])
    //   }
    //
    //   if (this.$store.getters.loggedinUser.role == 'NEL') {
    //     for (let i = 0; i < this.branches.length; i++) {
    //       if (this.branches[i].is_default == true) {
    //         this.selectedBranch = this.branches[i]
    //         this.selectedBranchFromAdd = this.branches[i]
    //         break;
    //       }
    //     }
    //     this.getMembers()
    //   }
    // },

    // tableRowClass(item){
    //   if (item.is_valid == false) {
    //     return 'lightRed text-high-emphasis';
    //   } else {
    //     return '';
    //   }
    // },

    openDialog(item, readOnly, newMember) {
      console.log('open dialog')
      this.readOnly = readOnly

      this.member = JSON.parse(JSON.stringify(item))
      if (Object.keys(item).length == 0) {
        this.member = {
          vat: '',
          user_branches: [
            {
              first_name: '',
              last_name: '',
              father_name: '',
              identification_document: '',
              mother_name: '',
              date_of_birth: null,
              amka: '',
              address: '',
              city: '',
              postal_code: '',
              phone_number: '',
              email: '',
              member_number: '',
              is_valid: '',
              affirmation: '',
            }
          ]
        }
      }

      if (newMember == false) {
        this.edit = true
        this.affirmation = this.member.user_branches[0].affirmation
        this.member_number = this.member.user_branches[0].member_number
        this.hasAffirmation = this.affirmation
      } else {
        this.affirmation = false
      }

      console.log(JSON.parse(JSON.stringify(this.member)));
      console.log(this.affirmation)
      // this.member.user_branches[0].date_of_birth = this.member.user_branches[0].date_of_birth != '' ? new Date(this.member.user_branches[0].date_of_birth).toISOString().substring(0,10) : ''
      this.dialog = true;
    },

    save(newMember) {
      this.$store.commit('setLoadingModal', true);
      this.member.affirmation = this.affirmation
      this.member.member_number = this.member_number
      if (!Object.hasOwn(this.member, 'father_name') || this.member.father_name == '' || this.member.father_name == 'null') this.member.father_name = null
      if (!Object.hasOwn(this.member, 'mother_name') || this.member.mother_name == '' || this.member.father_name == 'null') this.member.mother_name = null
      if (!Object.hasOwn(this.member, 'identification_document')) this.member.identification_document = null
      if (newMember == true) {
        this.member.branch = this.selectedBranchFromAdd
        requests.postSecureRequest('user/createMember', this.member, this.createMemberCallback)
      } else {
        this.member.branch = this.selectedBranch
        requests.putSecureRequest('user/updateMember', this.member, this.updateMemberCallback)
      }
    },

    createMemberCallback(response) {
      this.memberCallback(response)
    },
    updateMemberCallback(response) {
      this.memberCallback(response)
    },
    memberCallback(response) {
      console.log(JSON.parse(JSON.stringify(response)));

      // this.member.date_of_birth = this.singleSelectedDate//.toISOString().substring(0,10)
      this.newMember = false
      this.member = {
        vat: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
            mother_name: '',
            date_of_birth: null,
            amka: '',
            address: '',
            city: '',
            postal_code: '',
            phone_number: '',
            email: '',
            member_number: '',
            is_valid: '',
            affirmation: '',
          }
        ]
      }
      this.dialog = false
      this.onEdit = false
      this.affirmation = false
      this.edit = false
      this.hasAffirmation = false
      this.member_number = ''

      if (Object.keys(this.selectedBranch).length != 0) {
        this.members = [this.member]
        this.getMembers()
      }
      this.$store.commit('setLoadingModal', false);
    },

    closeDatePicker() {
      this.singleSelectedDate = '';
      this.openDatePicker = false;
    },

    selectDates() {
      this.openDatePicker = false;
    },

    clear() {
      this.singleSelectedDate = '';
      this.endOfSuspension = '';
    },

    parentNameRules(motherName, fatherName) {
      if (!motherName && !fatherName) {
        return 'Ενα εκ τών πεδίων πατρώνυμο, μητρώνυμο είναι απαραίτητο.'
      }
      return true

    },

    getBuildingPerPage(item) {
      this.$store.commit('setLoadingModal', true)
      if (Object.keys(this.selectedBranch).length === 0) {
        return
      }
      this.pageNumber = item.page
      this.itemsPerPage = item.itemsPerPage
      this.printDataTableOptions()
      if (this.options.sortBy.length > 0) {

        console.log("sort");
        console.log(this.options);
        console.log(this.options.sortDesc[0]);

        this.searchObj.sortField = this.options.sortBy[0]

        if (this.options.sortDesc[0]) {
          this.searchObj.sortDirection = "ASC";
        } else {
          this.searchObj.sortDirection = "DESC";
        }

      }

      requests.postSecureRequest('user/branchMembers',
          {
            searchObj: this.searchObj,
            itemsPerPage: this.itemsPerPage,
            pageNumber: this.pageNumber - 1
          },
          this.getMembersCallback)
    },

    printDataTableOptions() {
      console.log('Page: ' + this.pageNumber + '\nItems per page: ' + this.itemsPerPage)
    },
    openHelperSnackbar() {
      prop.getInfoMessageDialog('Η κόκκινη χρωματική ένδειξη δείχνει μη έγκυρη εγγραφή.')
    },

    searchWithParameter() {
      if (this.search.trim().length < 3 && this.search.trim().length > 0) {
        prop.getErrorMessage({data: {message: 'Απαιτούνται τουλάχιστον 3 χαρακτήρες.'}})
        return
      }
      if (Object.keys(this.selectedBranch).length == 0) {
        prop.getErrorMessage({data: {message: 'Δεν έχει επιλεγεί παράρτημα.'}})
        return
      }
      console.log('Searching. . .')
      this.getMembers()

    },

    openSuspensionDialog() {
      this.suspensionDialog = true
    },

    suspendMember() {
      console.log(this.member.user_branches[0]);
      console.log(this.endOfSuspension);
      console.log(this.suspensionReasoning);
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        user_branch_id: this.member.user_branches[0].id,
        suspension_end_date: this.endOfSuspension,
        suspension_reason: this.suspensionReasoning
      }

      requests.postSecureRequest('user/submit-member-suspension',
          requestObj,
          this.suspendMemberCallback)

      this.suspensionDialog = false;
    },

    suspendMemberCallback(response) {
      this.dialog = false
      this.$store.commit('setInfoDialogObj', {text: 'Η αναστολή μέλους ήταν επιτυχής.'})
      this.$store.commit('setInfoDialog', true)
      this.$store.commit('setLoadingModal', false)
      this.getMembers()
    },


    openDeleteMembersDialog() {
      this.deleteObj = []
      this.deleteObj = this.selectedRows
      this.deleteMembersDialog = true
    },

    deleteMembersFromSelection() {
      console.log(this.selectedRows);
    },

    clearSelectAll() {
      this.selectedRows = []
    },

    getMemberStatuses() {
      requests.getSecureRequest('user/get-member-statuses', '', this.getMemberStatusesCallback)
    },

    getMemberStatusesCallback(response) {
      this.memberStatuses = []
      this.memberStatuses = response
    },


  },
}
</script>

<style scoped>
#fre:hover {
  background: v-bind('$vuetify.theme.themes.light.indianRed') !important;
}
</style>
