import axios from 'axios';
import store from './store';
import utils from './utilities';
import prop from './properties';

export const BASE_URL = '/api/';
// export const BASE_URL = '/fun-club-registry/api/';

var requests = {


    getRequest(urlPart, id, callBack) {

        axios.get(BASE_URL + urlPart + id, {
            headers: {
                "Content-Type": "application/json"
            }
        }).then((response) => {
            callBack(response.data);
        }).catch(error => {
            store.commit('setLoadingModal', false);
            prop.getErrorMessage(error.response.data.message);
        })
    },

    postRequest(urlPart, requestObj, callBack) {

        axios.post(BASE_URL + urlPart, requestObj, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            callBack(response)
        }).catch(error => {
            store.commit('setLoadingModal', false);
            prop.getErrorMessage(error.response);
        })
    },


    putRequest(token, urlPart, requestObj, callBack) {

        axios.put(BASE_URL + urlPart, requestObj, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            callBack(response.data)
        }).catch(error => {
            store.commit('setLoadingModal', false);
            prop.getErrorMessage(error.response.data);
        })
    },

    uploadFilePostAsync(url, data, callBack) {

        axios.put(BASE_URL + url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }).then((response) => {
            console.log(response);
            callBack(response.data)
        }).catch(error => {
            store.commit('setLoadingModal', false);
            if (Object.hasOwn(error.response.data, 'dialog')) {
                if (error.response.data.dialog == true) {
                    prop.getErrorMessageDialog(error.response);
                } else {
                    prop.getErrorMessage(error.response);
                }
            } else {
                prop.getErrorMessage(error.response);
            }
        })
    },

    deleteRequest(token, urlPart, objId, callBack) {

        axios.delete(BASE_URL + urlPart, {
            data: objId,
            headers: {
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            callBack(response.data)
        }).catch(error => {
            store.commit('setLoadingModal', false);
            prop.getErrorMessage(error.response.data);
        })
    },

    deleteSecureRequest(urlPart, requestObj, callBack) {
        if (!document.cookie) {
            window.location.href = store.getters.accessUrl;
            return;
        }
        axios.delete(BASE_URL + urlPart, {
            data: requestObj,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            callBack(response.data)
        }).catch(error => {
            store.commit('setLoadingModal', false);
            prop.getErrorMessage(error.response);
        })
    },


    getSecureRequest(urlPart, id, callBack) {
        if (!document.cookie) {
            window.location.href = store.getters.accessUrl;
            return;
        }
        axios.get(BASE_URL + urlPart + id, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response) => {
            callBack(response.data);
        }).catch(error => {
            store.commit('setLoadingModal', false);
            // prop.getErrorMessage(error.response);
            console.log(JSON.parse(JSON.stringify(error.response.data)));
            if (Object.hasOwn(error.response.data, 'dialog')) {
                if (error.response.data.dialog == true) {
                    prop.getErrorMessageDialog(error.response);
                } else {
                    prop.getErrorMessage(error.response);
                }
            } else {
                prop.getErrorMessage(error.response);
            }
        })
    },


    postSecureRequest(urlPart, requestObj, callBack) {
        if (!document.cookie) {
            window.location.href = store.getters.accessUrl;
            return;
        }
        axios.post(BASE_URL + urlPart, requestObj, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(JSON.parse(JSON.stringify(response)));
            callBack(response.data)
        }).catch(error => {
            store.commit('setLoadingModal', false);
            // prop.getErrorMessage(error.response);
            console.log(JSON.parse(JSON.stringify(error.response.data)));
            if (Object.hasOwn(error.response.data, 'dialog')) {
                if (error.response.data.dialog == true) {
                    prop.getErrorMessageDialog(error.response);
                } else {
                    prop.getErrorMessage(error.response);
                }
            } else {
                prop.getErrorMessage(error.response);
            }
        })
    },

    postSecureRequestWithErrorCallBack(urlPart, requestObj, callBack, errorCallBack) {
        if (!document.cookie) {
            window.location.href = store.getters.accessUrl;
            return;
        }
        axios.post(BASE_URL + urlPart, requestObj, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            console.log(JSON.parse(JSON.stringify(response)));
            callBack(response.data)
        }).catch(error => {
            errorCallBack(error.response);
            store.commit('setLoadingModal', false);
        })
    },

    putSecureRequest(urlPart, requestObj, callBack) {
        if (!document.cookie) {
            window.location.href = store.getters.accessUrl;
            return;
        }
        axios.put(BASE_URL + urlPart, requestObj, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            callBack(response.data)
        }).catch(error => {
            store.commit('setLoadingModal', false)
            // prop.getErrorMessage(error.response)
            if (Object.hasOwn(error.response.data, 'dialog')) {
                if (error.response.data.dialog == true) {
                    prop.getErrorMessageDialog(error.response);
                } else {
                    prop.getErrorMessage(error.response);
                }
            } else {
                prop.getErrorMessage(error.response);
            }
        })
    },

    logout(data, callBack) {
        let urlPart = 'user/logout'

        axios.post(BASE_URL + urlPart, data, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((response) => {
            callBack(response)
        }).catch(error => {
            store.commit('setLoadingModal', false);
            prop.getErrorMessage(error.response.data);
        })
    },

    downloadRequest(urlPart, user_id, id, minioFile, callBack) {

        axios.get(BASE_URL + urlPart + user_id + '/' + id + '/' + minioFile + '.txt', {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response => {
            // callBack(response.data);
            window.open(response.data, '_blank');
        })).catch(error => {
            console.log(error);
            // store.commit('setLoadingModal', false);
            // prop.getErrorMessage(error.response.data.message);
        })
    },

    downloadFileRequest(urlPart, callBack) {
        console.log(urlPart);

        axios.get(BASE_URL + urlPart, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response => {
            // callBack(response.data);
            window.open(response.data, '_blank');
        })).catch(error => {
            console.log(error);
            // store.commit('setLoadingModal', false);
            // prop.getErrorMessage(error.response.data.message);
        })
    },

    downloadRejectionFileRequest(urlPart, minioFilePathFileName) {
        axios.post(BASE_URL + urlPart, {minioFilePathFileName: minioFilePathFileName}, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response => {
            window.open(response.data, '_blank');
        })).catch(error => {
            console.log(error)
        })
    },

    downloadFileRequestWithObj(urlPart, obj) {
        axios.post(BASE_URL + urlPart, obj, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then(response => {
            window.open(response.data, '_blank');
        }).catch(error => {
            console.log(error)
            prop.getErrorMessage(error.response);
        })
    },

    downloadXlsxTemplate(path, obj) {

        axios.post(BASE_URL + path, obj, {
            responseType: 'blob',
            // headers: {
            //     'Authorization': 'x-access-token',
            //     // "x-access-token": utils.getLocalStorage('jwt_token') ,
            // },
        }).then(response => {
            console.log(response);
            utils.downloadXlsx(response);
            store.commit('setLoadingModal', false);
        }).catch(async (error) => {
            console.log((await error));
            store.commit('setLoadingModal', false);
            prop.getErrorMessageDialogForBlob(JSON.parse(await error.response.data.text()));
            console.log(JSON.parse(await error.response.data.text()));
        });
    },

    downloadDocTemplate(path, obj) {

        axios.post(BASE_URL + path, obj, {
            responseType: 'blob',
            // headers: {
            //     'Authorization': 'x-access-token',
            //     // "x-access-token": utils.getLocalStorage('jwt_token') ,
            // },
        }).then(response => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            //link.setAttribute('download', 'file.docx'); //or any other extension
            let headerLine = response.headers['content-disposition'];
            link.download = headerLine;
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            store.commit('setLoadingModal', false);
        }).catch(async (error) => {
            console.log((await error));
            store.commit('setLoadingModal', false);
            prop.getErrorMessageDialogForBlob(JSON.parse(await error.response.data.text()));
            console.log(JSON.parse(await error.response.data.text()));
        });
    },

}

export default requests
