import Vue from 'vue'
import store from './store'
import requests from './requests'
import props from './properties'
import prop from "@/properties";
import utilities from "@/utilities";

export var interval = null;
export var intervalNotification = null;
export var firstIntervalRun = true;

var Serv = {


    memberfileParsingInterval(parsingObj) {
        store.commit('setMemberfileParsingInterval', true)

        let memberfile_id = parsingObj.memberfile_id;
        let userbranch_id = parsingObj.userbranch_id;

        if (interval == null) {
            interval = setInterval(() => {
                this.postMemberfileParsingInterval(memberfile_id, userbranch_id)
            }, 10000)
        }

    },

    postMemberfileParsingInterval(memberfile_id, userbranch_id) {
        let requestObj = {
            memberfile_id: memberfile_id,
            userbranch_id: userbranch_id
        };
        requests.postSecureRequestWithErrorCallBack('memberfile/check-file-is-done', requestObj, this.postMemberfileParsingIntervalCallback, this.errorCallBack)
    },

    postMemberfileParsingIntervalCallback(response) {
        // if(firstIntervalRun == true){
        //     firstIntervalRun = false
        //     return
        // }
        console.log('interval response: ', response)
        if (response.isDone == true) {
            console.log('if isDone == true')
            if (response.memberfile == null) {
                console.log('if memberfile == null')
                store.commit('setErrorDialogObj', {text: 'Ο ΑΦΜ ή η επωνυμία της λέσχης δεν είναι σωστά. Παρακαλώ δοκιμάστε ξανά ...'});
                store.commit('setErrorDialog', true)
            } else {
                console.log('if memberfile != null')
                store.commit('setMemberfileParsingIntervalObj', response.memberfile);
                store.commit('setMemberfileParsingIntervalDialog', true);
            }
            clearInterval(interval)
            store.commit('setMemberfileParsingInterval', false);
            interval = null
        } else {
            console.log('else isDone == false')
            store.commit('setInfoSnackBarObj', {text: 'Γίνεται επεξεργασία του αρχείου μελών ...'})
            store.commit('setInfoSnackBar', true)
        }
    },

    errorCallBack(response) {
        this.$store.commit('setMemberfileParsingInterval', false);
        this.$store.commit('setMemberfileParsingIntervalObj', null);

        // props.getErrorMessage(response);
        if (Object.hasOwn(response.data, 'dialog')) {
            if (response.data.dialog == true) {
                prop.getErrorMessageDialog(response);
            } else {
                prop.getErrorMessage(response);
            }
        } else {
            prop.getErrorMessage(response);
        }

        clearInterval(interval);
        interval = null
    },

    clearMemberfileParsingInterval() {

        clearInterval(interval)
        interval = null
    },

    getNotificationsInterval(requestObj) {
        console.log('check interval');
        if (intervalNotification == null) {
            console.log('check enabled');
            intervalNotification = setInterval(() => {
                this.getNotificationsForInterval(requestObj)
            }, 30000)
        }
    },

    getNotificationsForInterval(requestObj) {
        requests.postSecureRequest('notification/findByCriteriaPagination',
            requestObj, this.getNotificationsForIntervalCallback)
    },

    getNotificationsForIntervalCallback(response) {

        let notifications = []

        for (let i = 0; i < response.notifications.length; i++) {
            response.notifications[i].timestamp = utilities.timestampToDatetime(response.notifications[i].timestamp);
            notifications.push(response.notifications[i])
        }
        console.log(response.unread);
        console.log(notifications);
        store.commit('setNotifications', notifications)
        store.commit('setNotificationNumber', response.unread)

    },

    clearGetNotificationsInterval() {
        clearInterval(intervalNotification)
        intervalNotification = null
        console.log('interval cleared');
    },


};

export default Serv