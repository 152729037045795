<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{on}">
        <v-btn color="primary" v-on="on" class="ma-4" @click="downloadTemplate">
          <v-icon>mdi-download</v-icon>
        </v-btn>
      </template>
      <span>
        Πρότυπο αρχείου μελών
      </span>
    </v-tooltip>
  </div>
</template>

<script>
import requests from "@/requests";
import store from "@/store";

export default {
  name: "TemplateDownloadButton",
  data:() => ({
    file: null,
  }),
  methods:{
    downloadTemplate() {
      this.$store.commit('setLoadingModal', true);
      requests.downloadXlsxTemplate('memberfile/download-template',  {})
    },





    // downloadTemplateCallvack(response) {
    //   response.blob().then(response => {
    //     const aElement = document.createElement('a')
    //     aElement.setAttribute('download', 'Members.xlsx')
    //     const href = URL.createObjectURL(response)
    //     aElement.href = href
    //     aElement.setAttribute('target', '_blank')
    //     aElement.click()
    //     URL.revokeObjectURL(href)
    //   })
    // },
  },
}
</script>

<style scoped>

</style>