<template>
  <div>
    <v-card v-if="$store.getters.initializationNEAS.organization_vat == '' && user_type == 1">
      <v-card-title class="primary white--text">
        <span class="govgr-!-font-weight-bold govgr-!-font-size-24" style="word-break: normal">
          1. Εισάγετε τον ΑΦΜ του αθλητικού σωματείου, Τ.Α.Α ή της Α.Α.Ε. εκ μέρους του οποίου επιθυμείτε να παράσχετε συναίνεση:
        </span>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-row class="ma-2" align="center" justify="center">
          <v-col xl="3" lg="4" md="5" sm="10" xs="12">
            <v-text-field v-model="organization_vat"
                          type="number"
                          :rules="vatRule" counter="9"
                          hide-spin-buttons
                          class="textField"
                          outlined
                          label="ΑΦΜ"/>
          </v-col>
        </v-row>
        <v-row v-if="$store.getters.dev_mode == true" align="center" justify="center">
          <v-col xl="5" lg="6" md="8" sm="10" xs="12">
            <v-text-field placeholder="Εισάγετε ένα ΚΑΔ αθλητικού σωματείου: (14130000, 93190000)"
                          type="number"
                          hide-spin-buttons
                          v-model="valid_kad"
                          :rules="number8digitRule"
                          outlined/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="redirectToHome">
          Αρχική σελίδα
        </v-btn>
        <v-spacer/>
        <v-btn class="primary" @click="proceedToClubVat">
          Επόμενο
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="$store.getters.initializationNEAS.organization_vat.trim().length > 0">
      <v-card-title class="primary white--text">
        <span class="govgr-!-font-weight-bold govgr-!-font-size-24" style="word-break: normal">
          2. Εισάγετε τον ΑΦΜ της αναγνωρισμένης λέσχης φιλάθλων για την οποία το αθλητικό σωματείο, Τ.Α.Α ή της Α.Α.Ε. που εκπροσωπείτε έχει παράσχει τη συναίνεση του:
          <br/>
          <section style="font-size: 18px">*Αν η Λέσχη Φιλάθλων δεν διαθέτει δικό της ΑΦΜ, παρακαλούμε καταχωρίστε ξανά τον ΑΦΜ του Αθλητικού Σωματείου στο οποίο υπάγεται.</section>
        </span>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-row class="ma-2" align="center" justify="center">
          <v-col xl="3" lg="4" md="5" sm="10" xs="12">
            <v-text-field v-model="club_vat"
                          type="number"
                          :rules="vatRule" counter="9"
                          hide-spin-buttons
                          class="textField"
                          outlined
                          label="ΑΦΜ"/>
          </v-col>
        </v-row>
        <v-row v-if="$store.getters.dev_mode == true" align="center" justify="center">
          <v-col xl="5" lg="6" md="8" sm="10" xs="12">
            <v-text-field placeholder="Εισάγετε ένα ΚΑΔ λέσχης φιλάθλων ή αθλητικού σωματείου: (14130000, 93190000)"
                          type="number"
                          hide-spin-buttons
                          v-model="valid_club_kad"
                          :rules="number8digitRule"
                          outlined/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="redirectToHome">
          Αρχική σελίδα
        </v-btn>
        <v-spacer/>
        <v-btn class="primary" @click="proceedToClubConsent">
          Επόμενο
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import requests from "@/requests";
import router from "@/router";

export default {
  name: "ClubIdentification",
  data: () => ({
    user_type: 0,
    organization_vat: '',
    club_vat: '',
    organization_name: '',
    message: '',
    hasSeparateAFM: false,
    onomasiaClubFromSevrice: '',
    valid_kad: '',
    valid_club_kad: '',
    number8digitRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο.',
      v => ('' + v).length == 8 || 'Ο ΚΑΔ πρέπει να αποτελείτε απο 8 ψηφία.'
    ],
    vatRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο',
      v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
    ],
  }),
  methods: {
    init() {
      this.$store.commit('setInitializationNEAS', {organization_vat: '', club_vat: ''})
      if (this.$store.getters.loggedinUser.user.user_type_id === 1) {
        this.user_type = 1
      } else {
        this.user_type = 2
        this.$store.commit('setInitializationNEAS', {
          organization_vat: this.$store.getters.loggedinUser.user.vat,
          organization_name: this.$store.getters.loggedinUser.user.last_name,
          club_vat: ''
        })
      }
    },

    redirectToHome() {
      router.push('/role-selection')
    },

    proceedToClubVat() {
      this.$store.commit('setLoadingModal', true)
      if (this.organization_vat.trim().length == 0) {
        this.$store.commit('setSnackBarObj', {text: 'Το Α.Φ.Μ. δεν πρέπει να είναι κενό.'})
        this.$store.commit('setSnackBar', true)
        this.$store.commit('setLoadingModal', false)
        return
      }
      this.$store.commit('setLoadingModal', true)
      requests.getSecureRequest('checks/check-rep-is-valid/', this.organization_vat, this.elegxosAFMCallback)
    },

    elegxosAFMCallback(response) {


      this.organization_name = response.onomasia
      this.message = response.message

      //AFM is checked and user has a relation with that
      //step 2: check if AFM has KAD of athlitiko somateio bellow
      if (this.$store.getters.dev_mode == true) {
        if (('' + this.valid_kad).trim() == '') {
          this.$store.commit('setSnackBarObj', {text: 'Πρέπει να συμπληρώσεται ΚΑΔ.'})
          this.$store.commit('setSnackBar', true)
          this.$store.commit('setLoadingModal', false);
          return
        } else {
          if (('' + this.valid_kad).length != 8) {
            this.$store.commit('setSnackBarObj', {text: 'Ο ΚΑΔ πρέπει να αποτελείτε απο 8 ψηφία.'})
            this.$store.commit('setSnackBar', true)
            this.$store.commit('setLoadingModal', false);
            return
          } else {
            requests.getSecureRequest('checks/check-corp-kad/', this.valid_kad, this.checkCorpKadCallback)
          }
        }
      } else {
        requests.getSecureRequest('checks/check-corp-kad/', this.organization_vat, this.checkCorpKadCallback)
      }
    },

    checkCorpKadCallback(response) {


      this.$store.commit('setInfoSnackBarObj', {text: this.message + '<br>' + response.message})
      this.$store.commit('setInfoSnackBar', true)

      this.$store.commit('setInitializationNEAS', {organization_vat: this.organization_vat, club_vat: ''})
      this.$store.commit('setLoadingModal', false);
    },

    proceedToClubConsent() {
      if (this.club_vat.trim().length == 0) {
        this.$store.commit('setSnackBarObj', {text: 'Το Α.Φ.Μ. δεν πρέπει να είναι κενό.'})
        this.$store.commit('setSnackBar', true)
        return
      }
      if (this.organization_vat == '') {
        this.organization_vat = this.$store.getters.loggedinUser.user.vat
      }
      this.hasSeparateAFM = this.club_vat != this.organization_vat;
      let requestObj = {
        club_vat: this.club_vat,
        hasSeparateAFM: this.hasSeparateAFM,
        organisation_vat: this.organization_vat
      }

      //##### DEV MODE #####
      if (this.$store.getters.dev_mode == true) {
        if (('' + this.valid_club_kad).trim() == '') {
          this.$store.commit('setSnackBarObj', {text: 'Πρέπει να συμπληρώσεται ΚΑΔ.'})
          this.$store.commit('setSnackBar', true)
          return
        } else {
          if (('' + this.valid_club_kad).length != 8) {
            this.$store.commit('setSnackBarObj', {text: 'Ο ΚΑΔ πρέπει να αποτελείτε απο 8 ψηφία.'})
            this.$store.commit('setSnackBar', true)
            return
          } else {
            this.$store.commit('setLoadingModal', true)
            requestObj.kad = this.valid_club_kad
            requests.postSecureRequest('checks/get-corporation-info-by-AFM', requestObj, this.getClubInfoByAFMCallback)
          }
        }
      } else {//##### END DEV MODE #####
        this.$store.commit('setLoadingModal', true)
        requests.postSecureRequest('checks/get-corporation-info-by-AFM', requestObj, this.getClubInfoByAFMCallback)
      }
    },

    getClubInfoByAFMCallback(response) {

      this.club = response
      this.club.hasSeparateAFM = this.hasSeparateAFM
      this.onomasiaClubFromSevrice = this.club.onomasia

      this.$store.commit('setInitializationNEAS', {
        organization_vat: this.$store.getters.initializationNEAS.organization_vat,
        club_vat: this.club_vat,
        organization_name: this.organization_name,
        hasSeparateAFM: this.hasSeparateAFM,
        club_name: this.onomasiaClubFromSevrice,
        club_address: this.club.firmAddress,
        club_city: this.club.firmCity,
        club_postal_code: this.club.firmPostalCode,
      })

      requests.getSecureRequest('checks/check-club-not-exists/', this.club_vat, this.clubNotExistsCallback)
    },
    clubNotExistsCallback(response) {
      let clubExists = false
      if (response == true) {//club does NOT exist
        router.push('/club-info')
        clubExists = false
        this.$store.commit('setLoadingModal', false)
      } else if (response == false) {//club exists
        clubExists = true

        this.$store.commit('setInitializationNEAS', {
          organization_vat: this.$store.getters.initializationNEAS.organization_vat,
          club_vat: this.$store.getters.initializationNEAS.club_vat,
          organization_name: this.$store.getters.initializationNEAS.organization_name,
          hasSeparateAFM: this.$store.getters.initializationNEAS.hasSeparateAFM,
          club_name: this.$store.getters.initializationNEAS.club_name,
          club_address: this.$store.getters.initializationNEAS.club_address,
          club_city: this.$store.getters.initializationNEAS.club_city,
          club_postal_code: this.$store.getters.initializationNEAS.club_postal_code,
          exists: clubExists
        })

        let requestObj = {
          club_vat: this.club_vat
        }

        requests.postSecureRequest('club/get-club-by-vat', requestObj, this.getClubCallback)

      }

    },

    getClubCallback(response) {
      this.$store.commit('setSelectedClub', response)
      router.push('/club-info')
      this.$store.commit('setLoadingModal', false)
    }
  },
  created() {
    this.$store.commit('setMenuItems', []);
    this.init()
  },
}
</script>

<style scoped>

</style>