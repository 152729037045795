<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <v-dialog v-model="dialogOpen"
              max-width="600"
              class="align-center">
      <template v-slot:activator="{ on }">
        <v-btn color="primary"
               v-on="on"
               class="ma-5"
               :disabled="$store.getters.memberfileParsingInterval">
          Μαζική αποθήκευση
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          Μαζική αποθήκευση μελών
        </v-card-title>
        <v-card-text>
          <!--            <v-row align="center" justify="center" dense>-->
          <!--              <v-col cols="10">-->
          <!--                <v-select v-if="($store.getters.loggedinUser.role != 'NEL' && $store.getters.loggedinUser.role != 'YLPL')"-->
          <!--                          class="ma-5"-->
          <!--                          label="Παράρτημα"-->
          <!--                          :items="branches"-->
          <!--                          return-object-->
          <!--                          item-text="name"-->
          <!--                          v-model="selectedBranch">-->
          <!--                </v-select>-->
          <!--              </v-col>-->
          <!--            </v-row>-->
          <v-row align="center" justify="center" dense>
            <v-col cols="10">
              <v-file-input class="ma-5" small-chips v-model="file" :accept="['.xlsx', '.xls']" label=".xlsx"
                            append-icon="mdi-file-excel"></v-file-input>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" dense>
            <v-col cols="9">
              <v-checkbox v-model="withUpdate" label="Ενημέρωση στοιχείων ήδη υπαρχόντων μελών"></v-checkbox>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" dense>
            <!--              commented to keep affirmation always false-->
            <!--              un-comment this block if needs change and affirmation needs to be specified before the file upload-->
            <v-col cols="9">
              <v-checkbox v-model="affirmation" label="Διαθέτω υπεύθυνη δήλωση για όλα τα μέλη. "></v-checkbox>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog" text>Άκυρο</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="uploadAndCloseDialog()" class="primary align-self"
                 :disabled="!branchIsSelected || file == null || affirmation == false">Αποθήκευση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>


</template>

<script>

import requests from "@/requests";
import prop from "@/properties";
import services from "../../services";

export default {
  name: "AddMembersFromFile",
  data() {
    return {
      snackbar: false,
      file: null,
      response: null,
      withUpdate: false,
      dialogOpen: false,
      affirmation: false,
      threadRes: '',
      branches: [],
      selectedBranch: {},
      loggedInUserIsYPL: false,
      loggedInUserIsNEL: false,
      branchIsSelected: false,
      fileIsDone: false,
    }
  },
  created() {
    this.checkLoggedInUserIsYPL()
    this.checkLoggedInUserIsNEL()
    // this.loadBranchesOnDropdown()
    this.checkBranchIsSelected()
  },
  methods: {
    checkLoggedInUserIsNEL() {
      if (this.$store.getters.loggedinUser.role == "NEL") {
        this.loggedInUserIsNEL = true

        for (let i = 0; i < this.$store.getters.selectedClub.myBranchesOnClub.length; i++) {
          this.branches.push(this.$store.getters.selectedClub.myBranchesOnClub[i])
        }


        if (this.$store.getters.loggedinUser.role == 'NEL') {
          for (let branch of this.branches) {
            if (branch.is_default == true) {
              this.selectedBranch = branch
              break;
            }
          }
        }
      }
      this.loggedInUserIsNEL = false
    },

    checkLoggedInUserIsYPL() {
      if (Object.keys(this.$store.getters.selectedBranchYPL).length > 0) {
        this.selectedBranch = this.$store.getters.selectedBranchYPL
        this.loggedInUserIsYPL = true
        return
      }
      this.loggedInUserIsYPL = false
    },

    postFile() {

      let formData = new FormData();

      // formData.append('file', this.file,  encodeURI(this.file.name));
      formData.append('file', this.file);
      formData.append('withUpdate', this.withUpdate);
      formData.append('selectedBranchId', this.selectedBranch.id);
      formData.append('affirmation', this.affirmation);
      // formData.append('user_branch', this.getUserBranch());

      //send post request to back for file upload here
      this.$store.commit("setLoadingModal", true)
      requests.uploadFilePostAsync("user/upload", formData, this.postFileCallBack)
    },

    postFileCallBack(response) {
      this.$store.commit('setThereWasAChangeInApplication', true)
      this.$store.commit("setLoadingModal", false)
      prop.getInfoMessage(response.message)


      this.affirmation = false;
      this.file = null

      services.memberfileParsingInterval(response.parsingObj)

    },

    uploadAndCloseDialog() {
      this.dialogOpen = !this.dialogOpen
      if (this.file !== null) {
        this.postFile()
      }
    },

    loadBranchesOnDropdown() {
      // for(let i = 0; i < this.$store.getters.loggedinUser.myClubs.length; i++){
      //   if(this.$store.getters.loggedinUser.myClubs[i].id == this.$store.getters.selectedClub.id){
      //     for(let j = 0; j < this.$store.getters.loggedinUser.myClubs[i].myBranchesOnClub.length; j++){
      //       this.branches.push(this.$store.getters.loggedinUser.myClubs[i].myBranchesOnClub[j]);
      //     }
      //   }
      // }

      this.branches = []
      if (this.loggedInUserIsNEL) {

        for (let i = 0; i < this.$store.getters.selectedClub.myBranchesOnClub.length; i++) {
          this.branches.push(this.$store.getters.selectedClub.myBranchesOnClub[i])
        }
      } else if (this.loggedInUserIsYPL) {
        this.branches.push(this.$store.getters.selectedBranchYPL)
      }
    },

    checkBranchIsSelected() {

      this.branchIsSelected = Object.keys(this.selectedBranch).length > 0;

    },

    closeDialog() {
      this.dialogOpen = !this.dialogOpen
    },
  },

  computed: {}
}
</script>

<style scoped>

</style>