<template>
<div>

    <div class="govgr-width-container">
            <h4 class="govgr-heading-s">Εισάγετε τον ΑΦΜ της αναγνωρισμένης λέσχης φιλάθλων για την οποία το αθλητικό σωματείο, Τ.Α.Α ή της Α.Α.Ε. που εκπροσωπείτε έχει παράσχει τη συναίνεση του</h4>

            <input class="govgr-input"  type="text"  name="business-name" />

            <v-text-field v-model="vat" :rules="vatRule" counter="9" class="textField" outlined  label="ΑΦΜ"></v-text-field>
            <br/>

            <button @click="submit" class="govgr-btn govgr-btn-primary govgr-btn-cta">
                Υποβολή
                <svg viewBox="0 0 24 24" class="govgr-arrow--right" focusable="false" aria-hidden="true"> <path d="M8.5,2L6.1,4.3l7.6,7.7l-7.6,7.6L8.5,22l10-10L8.5,2z"/> </svg>
            </button>

    </div>



</div>
</template>

<script>
    import requests from "@/requests";

    export default {
        name: "InsertClubVATForCheck",
        data: () => ({
          vat: '',
          vatRule: [
            v => !!v || 'Το πεδίο είναι απαραίτητο',
            v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
          ],
        }),
        methods: {
            submit(){
              this.$store.commit('setLoadingModal', true)
                requests.getRequest('/valid-kad/check/', this.vat, this.validKadCallback)

            },
            validKadCallback(){

              this.$router.push({path: '/club-card'})
              this.$store.commit('setLoadingModal', false);
            }
        }
    }
</script>

<style scoped>
    .textField:focus {
        outline: 4px solid var(--color-focus);
        outline-offset: 0;
        -webkit-box-shadow: inset 0 0 0 2px;
        box-shadow: inset 0 0 0 2px;
    }

</style>
