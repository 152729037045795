<template>
  <div>
    <v-card flat outlined>
      <v-card-title class="primary white--text govgr-!-font-weight-bold govgr-!-font-size-24">
        Οι εγγραφές μου
      </v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel v-for="(item, index) in registrations" v-bind:key="index" class="my-2">
            <v-expansion-panel-header class="govgr-!-font-size-19">
              <v-row>
                <v-col class="govgr-!-font-weight-bold" cols="1">
                  {{ index + 1 }}.
                </v-col>
                <v-col cols="3">
                  {{ item.branch.club.name }}
                </v-col>
                <v-col cols="8">
                  <v-chip v-if=" item.history.userbranchbranchrolestatus.id == 1"
                          class="white--text" color="green">
                    {{item.history.userbranchbranchrolestatus.status}}
                  </v-chip>
                  <v-chip v-else-if="item.history.userbranchbranchrolestatus.id == 2
                                      || item.history.userbranchbranchrolestatus.id == 5
                                      || item.history.userbranchbranchrolestatus.id == 3"
                          color="red" class="white--text">{{item.history.userbranchbranchrolestatus.status}}</v-chip>
                  <v-chip v-else >{{item.history.userbranchbranchrolestatus.status}}</v-chip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card flat>
                <v-card-title class="mb-2">
                  <v-row>
                    <v-col>
                      <v-row>
                        {{ item.branch.name }}
                      </v-row>
                      <v-row class="govgr-!-font-weight-regular govgr-!-font-size-16">
                        &ensp;{{ item.branch.address }} {{ item.branch.city }}, {{ item.branch.postal_code }}
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                  <div>
                    <v-row class="mt-2 mb-4 ml-1 govgr-!-font-weight-bold">
                      Στοιχεία όπως καταχωρήθηκαν απο τον
                      {{ (item.branch.is_default == true)
                        ? 'εκπρόσωπο της λέσχης φιλάθλων:' : 'υπεύθυνο παραρτήματος της λέσχης φιλάθλων:'}}
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.first_name }}</v-list-item-title>
                              <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.last_name }}</v-list-item-title>
                              <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ (item.mother_name == '' || item.mother_name == null)? '-' : item.mother_name }}
                              </v-list-item-title>
                              <v-list-item-subtitle>Μητρώνυμο</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.father_name }}</v-list-item-title>
                              <v-list-item-subtitle>Παρώνυμο</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.address }}
                                {{', ' + item.city }}
                                {{', ' + item.postal_code }}
                              </v-list-item-title>
                              <v-list-item-subtitle>Διεύθυνση</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                        <v-row>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.member_number }}</v-list-item-title>
                              <v-list-item-subtitle>Αρ. κάρτας μέλους</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="primary white--text" @click="openCheckDialog(item)">
                    Αίτημα διαγραφής
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="checkDialog" width="400px">
      <v-card>
        <v-card-title class="primary white--text" style="word-break: normal">
          Αίτημα διαγραφής
        </v-card-title>
        <v-card-text class="mt-5 govgr-!-font-size-19">
          Είστε σίγουρος/η για την ενέργεια σας;
        </v-card-text>
        <v-card-actions>
          <v-btn @click="checkDialog = false">
            Όχι
          </v-btn>
          <v-spacer/>
          <v-btn class="primary" @click="requestMyRemoval">
            Ναι, εκτέλεση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import requests from "@/requests";
export default {
  name: "MemberCurrent",
  data: () => ({
    registrations: [],
    panel: '',
    checkDialog: false,
    selectedItemToDelete: {},
  }),
  methods: {
    getMyRegistrations(){
      this.$store.commit('setLoadingModal', true)
      requests.getSecureRequest('user/get-my-registrations', '', this.getMyRegistrationsCallback)
    },
    getMyRegistrationsCallback(response){

      this.registrations = response.user_branches
      this.$store.commit('setLoadingModal', false)
    },

    requestMyRemoval(){
      this.checkDialog = false
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('user/request-member-removal',
          {user_branch_id: this.selectedItemToDelete.id},
          this.requestMyRemovalCallback)
    },

    requestMyRemovalCallback(response) {
      this.$store.commit('setLoadingModal', false)
      let msg = 'Το αίτημα για την διαγραφή σας απο ' +
          ((this.selectedItemToDelete.branch.is_default == true)
              ? 'τη λέσχη φιλάθλων' : 'το παράρτημα της λέσχης φιλάθλων') +
          ' καταχωρήθηκε επιτυχώς'
      this.$store.commit('setInfoDialogObj', {text: msg})
      this.$store.commit('setInfoDialog', true)
    },

    openCheckDialog(item) {
      this.selectedItemToDelete = item
      this.checkDialog = true
    },

  },
  created(){
    this.getMyRegistrations()
  }
}
</script>

<style scoped>

</style>