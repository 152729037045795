import { render, staticRenderFns } from "./DevLoginPa.vue?vue&type=template&id=13df4241&scoped=true&"
import script from "./DevLoginPa.vue?vue&type=script&lang=js&"
export * from "./DevLoginPa.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13df4241",
  null
  
)

export default component.exports