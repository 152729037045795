<template>
  <div :class="{'blur-content': $store.getters.addYourSelfAsMemberDialog}">

    <v-card>
      <v-card-title style="background-color: #003375; word-break: normal;" class="white--text">
        <v-row align="center" justify="center" class="ma-2 text-h4">
          Στοιχεία παραρτήματος λέσχης φιλάθλων
        </v-row>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-row class="ma-1">
          <v-col cols="3">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ club.name }}</v-list-item-title>
                <v-list-item-subtitle>Επωνυμία λέσχης</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="3">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title v-if="Object.keys($store.getters.selectedClub).length > 0">
                  <v-chip small v-if="$store.getters.selectedClub.clubstatus.id ==
                                  $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.ACTIVE"
                          style="background-color: green;"
                          class="govgr-!-font-size-14 white--text"
                  >
                    {{ $store.getters.selectedClub.clubstatus.name }}
                  </v-chip>
                  <v-chip small v-else-if="$store.getters.selectedClub.clubstatus_id ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.SUSPENDED
                                       ||
                                       $store.getters.selectedClub.clubstatus_id  ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.IN_DISSOLUTION
                                       ||
                                       $store.getters.selectedClub.clubstatus_id  ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.UNLICENSED"
                          style="background-color: red;"
                          class="govgr-!-font-size-14 white--text"
                  >
                    {{ $store.getters.selectedClub.clubstatus.name }}
                  </v-chip>
                  <v-chip v-else small
                          class="govgr-!-font-size-14"
                  >
                    {{ $store.getters.selectedClub.clubstatus.name }}
                  </v-chip>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Κατάσταση λέσχης
                  <div v-if=" effective_end_date!= null &&
                                effective_end_date!= 'null' &&
                                effective_end_date != ''"
                       style="display: inline">
                    εώς {{ effective_end_date }}
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="2">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ club.vat }}</v-list-item-title>
                <v-list-item-subtitle>Α.Φ.Μ. λέσχης</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="4">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>{{ defaultBranch.address }} {{ ', ' + defaultBranch.city }}
                  {{ ', ' + defaultBranch.postal_code }}
                </v-list-item-title>
                <v-list-item-subtitle>Διεύθυνση λέσχης</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row class="ma-1">
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
            <v-row>
              <v-col class="text-h6">
                Στοιχεία παραρτήματος
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $store.getters.selectedBranchYPL.name }}</v-list-item-title>
                    <v-list-item-subtitle>Επωνυμία παραρτήματος</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ $store.getters.selectedBranchYPL.address }}
                      {{ ', ' + $store.getters.selectedBranchYPL.city }}
                      {{ ', ' + $store.getters.selectedBranchYPL.postal_code }}
                    </v-list-item-title>
                    <v-list-item-subtitle>Διεύθυνση παραρτήματος</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row style="margin-top: -2%">
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-chip
                          v-if="$store.getters.selectedBranchYPL.branchstatus.id == $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.ACTIVE"
                          class="white--text" color="green">
                        {{ $store.getters.selectedBranchYPL.branchstatus.name }}
                      </v-chip>
                      <v-chip v-else-if="$store.getters.selectedBranchYPL.branchstatus.id == $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.DELETED ||
                                         $store.getters.selectedBranchYPL.branchstatus.id == $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.IN_DESOLUTION ||
                                         $store.getters.selectedBranchYPL.branchstatus.id == $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.SUSPENDED ||
                                         $store.getters.selectedBranchYPL.branchstatus.id == $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.UNLICENSED"
                              class="white--text" color="red">
                        {{ $store.getters.selectedBranchYPL.branchstatus.name }}
                      </v-chip>
                      <v-chip v-else>
                        {{ $store.getters.selectedBranchYPL.branchstatus.name }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle>Κατάσταση παραρτήματος</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-col>
          <v-divider v-if="pageWidth>960" vertical/>
          <v-divider class="mx-1 mt-2" v-if="pageWidth<=960"/>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
            <v-row>
              <v-col class="text-h6">
                Εκπρόσωπος λέσχης
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ nel.first_name }}</v-list-item-title>
                    <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ nel.vat }}</v-list-item-title>
                    <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ nel.last_name }}</v-list-item-title>
                    <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ nel.identification_document }}</v-list-item-title>
                    <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ nel.father_name }}</v-list-item-title>
                    <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <AddYourSelfAsMember/>
  </div>
</template>

<script>
import requests from "@/requests";
import AddYourSelfAsMember from "@/components/reusable/AddYourSelfAsMember.vue";

export default {
  name: "EditClubCardAsYPLP",
  components: {AddYourSelfAsMember},
  data: () => ({
    pageWidth: window.innerWidth,
    nel: {},
    club: {},
    defaultBranch: {},
    effective_end_date: '',
  }),
  methods: {
    setMenuItems() {
      let menuItems = [
        {
          to: '/role-selection',
          name: 'Αρχική σελίδα'
        },
        {
          to: '/select-branch',
          name: 'Επιλογή παραρτήματος λέσχης'
        },
        {
          to: '/ypl-dashboard',
          name: 'Στοιχεία παραρτήματος'
        },
        {
          to: '/branch-places',
          name: 'Χώροι παραρτήματος'
        },
        {
          to: '/branch-members',
          name: 'Μέλη παραρτήματος',
        },
        {
          to: '/history-of-uploaded-memberfiles',
          name: 'Ιστορικό μεταφορτώσεων αρχείων μελών',
        },
        {
          to: '/search-for-members',
          name: 'Αναζήτηση μελών παραρτήματος',
        },
        {
          to: '/notifications-page',
          name: 'Ειδοποιήσεις',
        },
      ];
      this.$store.commit('setMenuItems', menuItems);
    },
    callToAction(action) {

      if (action === 1) {
        this.$router.push({path: '/club-contact-info'})
      } else if (action === 2) {
        this.$router.push({path: '/branch-members'})
      } else if (action === 3) {
        this.$router.push({path: '/history-of-uploaded-memberfiles'})
      }

    },

    getClub() {
      //this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_vat: this.$store.getters.selectedBranchYPL.club.vat
      }
      requests.postSecureRequest('club/get-club-by-vat', requestObj, this.getClubCallback)
    },
    getClubCallback(response) {
      this.club = response
      this.defaultBranch = response.branches[0];
      this.organization = this.club.organization;
      this.$store.commit('setSelectedClub', this.club);
      this.getNEL();
    },

    getNEL() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_vat: this.$store.getters.selectedBranchYPL.club.vat
      }
      requests.postSecureRequest('user/get-nel-by-club-vat', requestObj, this.getNELCallback)
    },

    getNELCallback(response) {
      this.nel = response
      this.$store.commit('setLoadingModal', false)
      this.getEndDateOfStatus()
    },

    checkLoggedInUserIsValidMember() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('branch/check-logged-in-user-is-valid-member',
          {branch_id: this.$store.getters.selectedBranchYPL.id},
          this.checkLoggedInUserIsValidMemberCallback)
    },

    checkLoggedInUserIsValidMemberCallback(response) {
      if (!response.is_valid_member) {
        this.$store.commit('setAddYourSelfAsMemberDialog', true)
      }
      this.$store.commit('setLoadingModal', false)
    },

    handleResize() {
      this.pageWidth = window.innerWidth;
    },

    getEndDateOfStatus() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('club/get-status-until-date',
          {club_id: this.$store.getters.selectedClub.id}, this.getEndDateOfStatusCallback)
    },
    getEndDateOfStatusCallback(response) {
      this.effective_end_date = response.date
      this.$store.commit('setLoadingModal', false)
    }

  },
  created() {
    this.checkLoggedInUserIsValidMember()
    this.setMenuItems()
    this.getClub()

    this.$store.commit('setNotificationIcon', true)

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style scoped>
.blur-content {
  filter: blur(6px);
}
</style>
