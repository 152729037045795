import Vue from 'vue';
import store from "./store";
import router from "./router"
import requests, {BASE_URL} from "@/requests";


let interval = null
var Utils = {

    //set the token to localStorage
    setLocalStorage(storageName, data) {
        localStorage.setItem(storageName, JSON.stringify(data));
    },

    //get the token from localStorage
    getLocalStorage(storageName) {
        return JSON.parse(localStorage.getItem(storageName));
    },

    // delete the token from localStorage
    deleteLocalStorage(storageName) {
        localStorage.removeItem(storageName);
    },


    createPickerCurrentDate(date) {

        var currentDate = new Date(date);
        var formatDate = (("0" + (currentDate.getDate())).slice(-2)) + "-" + (("0" + (currentDate.getMonth() + 1)).slice(-2)) + "-" + currentDate.getFullYear();
        const [day, month, year] = formatDate.split('-');
        return `${year}-${month}-${day}`;
    },

    downloadXlsx(response) {

        let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml"
        }); // 2. Get the blob setting file type in the response object returned by the request. Here is excel as an example.
        let url = window.URL.createObjectURL(blob); // 3. Create a temporary url pointing to the blob object

        // 4. After creating the url, you can simulate a series of operations on this file object, for example: preview, download
        let a = document.createElement("a");
        a.href = url;

        let headerLine = response.headers['content-disposition'];
        a.download = headerLine;
        a.click();
        // 5. Release this temporary object url
        window.URL.revokeObjectURL(url);
    },

    //converts Date.getMonth() to greek shorthand
    monthNumberToShorthand(monthNumber) {
        //if outside accepted values 0to11 return the number as is
        if (monthNumber > 11 || monthNumber < 0) {
            return monthNumber
        }

        switch (monthNumber) {
            case 0:
                return "Ιαν"
            case 1:
                return "Φεβ"
            case 2:
                return "Μάρ"
            case 3:
                return "Απρ"
            case 4:
                return "Μάη"
            case 5:
                return "Ιούν"
            case 6:
                return "Ιούλ"
            case 7:
                return "Αύγ"
            case 8:
                return "Σεπ"
            case 9:
                return "Οκτ"
            case 10:
                return "Νοέ"
            case 11:
                return "Δεκ"
        }
    },

    prettifyTimestamp(timestamp) {
        console.log("ts" + new Date(Date.parse(timestamp)))
        timestamp = new Date(Date.parse(timestamp))
        return timestamp.getDate() + ' '
            + this.monthNumberToShorthand(timestamp.getMonth()) + ' '
            + timestamp.getFullYear() + ' '
            + ((((timestamp.getHours() < 10) ? ('0' + (timestamp.getHours())) : (timestamp.getHours())))) + ':'
            + ((timestamp.getMinutes() < 10) ? ('0' + timestamp.getMinutes()) : (timestamp.getMinutes())) + ':'
            + ((timestamp.getSeconds() < 10) ? ('0' + timestamp.getSeconds()) : (timestamp.getSeconds()))
        // return timestamp.getDate() + ' '
        //     + this.monthNumberToShorthand(timestamp.getMonth() + 1) + ' '
        //     + timestamp.getFullYear() + ' '
        //     + ((((timestamp.getHours() - (timestamp.getTimezoneOffset()/60))%24) < 10)? ('0' + ((timestamp.getHours() - (timestamp.getTimezoneOffset()/60))%24)) : ((timestamp.getHours() - (timestamp.getTimezoneOffset()/60))%24)) + ':'
        //     + ((timestamp.getMinutes() < 10)? ('0' + timestamp.getMinutes()) : (timestamp.getMinutes())) + ':'
        //     + ((timestamp.getSeconds() < 10)? ('0' + timestamp.getSeconds()) : (timestamp.getSeconds()))
    },

    timestampToDatetime(timestamp) {
        let date = new Date(timestamp)
        let day = ''
        switch (date.getDay()) {
            case 0:
                day = 'Κυρ'
                break;
            case 1:
                day = 'Δευ'
                break;
            case 2:
                day = 'Τρι'
                break;
            case 3:
                day = 'Τετ'
                break;
            case 4:
                day = 'Πεμ'
                break;
            case 5:
                day = 'Παρ'
                break;
            case 6:
                day = 'Σαβ'
                break;
        }
        let dayOfMonth = date.getDate()
        if (dayOfMonth < 10) {
            dayOfMonth = '0' + dayOfMonth
        }
        let month = this.monthNumberToShorthand(date.getMonth())

        let hours = date.getHours()
        if (hours < 10) {
            hours = '0' + hours
        }
        let minuites = date.getMinutes()
        if (minuites < 10) {
            minuites = '0' + minuites
        }
        return day + ' ' + dayOfMonth + ' ' + month + ' ' + date.getFullYear() + '  ' + hours + ':' + minuites
    },

    redirectFromMenu(path, route) {

        if (path != route.path) {
            router.push({path: path})
        }
    },

    formatDate(date) {
        const [year, month, day] = date.split("-");
        return day + '-' + month + '-' + year;
    },


    sortDatesArray(dates) {

        if (dates.length > 0) {
            if (dates.length == 2) {
                return dates.sort((a, b) => new Date(a) - new Date(b));
            }
        }
        return dates;
    },

    formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        const formattedDate = String(date.getDate() + 100).slice(-2) + '/' +
            String(date.getMonth() + 101).slice(-2) + '/' +
            String(date.getFullYear()).slice(-2);
        return formattedDate;
    },

    getToken(obj) {
        return obj.$cookies.get('jwt_token')
    },

    deleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

}


export default Utils
