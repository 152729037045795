<template>
<div>
    <v-container>
    <v-card v-show="showCriteria"  class="mx-10" >
        <v-card-text>
                <SearchCriteria :eventName="'asGGA'"/>
        </v-card-text>
    </v-card>

    <v-row align="center" justify="center" class="mt-4">
        <v-col>
            <v-data-table :footer-props="{page:1,
                                          pageCount:1,
                                          showCurrentPage:true,
                                          itemsPerPageOptions:[5,10,15,30],
                                          showFirstLastPage:true,
                                          itemsPerPageText:'Αιτήσεις ανά σελίδα:',
                                          itemsPerPageAllText:'Όλα'}"
                            no-data-text="Δέν υπάρχουν αποτελέσματα."
                            :headers="headers"
                            :items="applications"
                            :items-per-page="itemsPerPage"
                            :server-items-length="tableLength"
                            :page.sync="pageNumber"
                            class="elevation-6 mx-5 px-2"
                            @update:options="getBuildingPerPage">
            </v-data-table>

        </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
    import SearchCriteria from "../reusable/SearchClubs.vue";
    import requests from "@/requests";
    import {eventBus} from "@/main";
    export default {
        name: "Clubs",
        components:{SearchCriteria},
        data: function() {

            return {
                showCriteria: true,
                searchObj:{},
              headers: [
                {
                  text: 'Αριθμός αίτησης',
                  align: 'center',
                  sortable: true,
                  value: 'id',
                },
                {
                  text: 'Κατάσταση',
                  align: 'center',
                  sortable: true,
                  value: 'application_status.status',
                },
                {
                  text: 'Λέσχη',
                  align: 'center',
                  sortable: true,
                  value: 'club.name',
                },
                {
                  text: 'Ημ/νία δημιουργίας',
                  align: 'center',
                  sortable: true,
                  value: 'creation_date',
                },
                {
                  text: 'Ημ/νία υποβολής',
                  align: 'center',
                  sortable: true,
                  value: 'submission_date',
                },
                {
                  text: 'Ημ/νία διεκπεραίωσης',
                  align: 'center',
                  sortable: true,
                  value: 'approval_date',
                },
                {
                  text: 'Ημ/νία λήξης άδειας',
                  align: 'center',
                  sortable: true,
                  value: 'effective_end_date',
                },
              ],
              applications: [],
              pageNumber: 1,
              itemsPerPage: 10,
              tableLength: 0,
            }
        },

        methods:{
          setItemsPerPage(item){

            this.itemsPerPage = item.itemsPerPage
          },
          mySearch() {
            this.$store.commit('setLoadingModal', true);

            this.pageNumber = 1

            this.searchWithPagination()
          },
          getBuildingPerPage(item){
            this.$store.commit('setLoadingModal', true);
            // if(this.applications.length == 0){
            //   this.$store.commit('setLoadingModal', false);
            //   return
            // }

            this.pageNumber = item.page
            this.itemsPerPage = item.itemsPerPage

            this.searchWithPagination()
          },
          searchWithPagination(){


            requests.postSecureRequest('application/findByCriteriaPagination', {
              searchObj: this.searchObj,
              pageNumber: this.pageNumber - 1,
              itemsPerPage: this.itemsPerPage,
            }, this.searchCallback)
          },
          searchCallback(response) {

            this.applications = response.applications
            this.pageNumber = response.pageNumber + 1
            this.itemsPerPage = response.itemsPerPage

            this.$store.commit('setLoadingModal', false);
          },

          searchEvent(){
            eventBus.$on('asGGA', function(searchObj){

              // Object.assign(this.searchObj, searchObj)
              this.searchObj = searchObj

            })
            eventBus.$on('asGGA', this.mySearch)
          },

        },
        created() {
          this.searchEvent()
        },
        beforeDestroy() {
            eventBus.$off('asGGA')
        }
    }
</script>

<style scoped>

</style>
