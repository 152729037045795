<template>
    <div>

        <v-breadcrumbs :items="items" divider=">">
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item :to="item.href" @click="showSelectedRow2(item)">
                    {{ item.text }}
                </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>

        <v-row align="center" justify="center">
        <v-card>
            <v-card-title class="primary">
                <span class="headline white--text">{{'Στοιχεία επικοινωνίας λέσχης φιλάθλων'}}</span>
                <v-btn @click="openDialog" icon color="white" fab><v-icon>edit</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <p class="govgr-body">

                    <v-list-item two-line >
                        <v-list-item-content >
                            <v-list-item-title>{{ '-'}}</v-list-item-title>
                            <v-list-item-subtitle>Διεύθυνση έδρας</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line >
                        <v-list-item-content >
                            <v-list-item-title>{{ '-'}}</v-list-item-title>
                            <v-list-item-subtitle>Τηλέφωνο επικοινωνίας</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line >
                        <v-list-item-content >
                            <v-list-item-title>{{ '-'}}</v-list-item-title>
                            <v-list-item-subtitle>Email</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </p>
            </v-card-text>
        </v-card>
        </v-row>


        <v-dialog v-model="dialog" max-width="500">
            <v-card>
                <v-toolbar color="primary" dark>
                    <v-toolbar-title>{{ 'Στοιχεία Επικοινωνίας' }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field v-model="object.name" label="Διεύθυνση έδρας"></v-text-field>
                        <v-text-field v-model="object.description" label="Τηλέφωνο επικοινωνίας"></v-text-field>
                        <v-text-field v-model="object.quantity" label="Email"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="save">{{'Save' }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "ClubContactInfo",

        data: () => ({
            mode: 'add',
            dialog: false,
            object: {},
            items: [
                {
                    text: 'Dashboard',
                    disabled: false,
                    href: 'breadcrumbs_dashboard',
                },
                {
                    text: 'Link 1',
                    disabled: false,
                    href: '/dashboard',
                },
                {
                    text: 'Link 2',
                    disabled: true,
                    href: '/club-contact-info',
                }
            ],
        }),
        methods:{
            openDialog() {
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
            },
            save(){

            },
            itemIdx(itm) {
                return this.items.findIndex(i => i.id === itm.id)
            },
            showSelectedRow2(item) {
                //this.items = this.items.map(el => this.itemIdx(el) > this.itemIdx(item) ? {...el, disabled: true} : el)
                this.items = this.items.filter(el => this.itemIdx(el) <= this.itemIdx(item))
            }
        }
    }
</script>

<style scoped>

</style>
