<template>
  <div>
    <v-tabs v-model="tab" fixed-tabs>
      <v-tab v-if="this.$store.getters.selectedSeasonKey==0"
             href="#tab-1"
             :style="tab=='tab-1' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getClub()">
        Στοιχεια
      </v-tab>
      <v-tab href="#tab-2"
             :style="tab=='tab-2' ? 'background-color: aliceblue' : 'background-color: '"
             @click="getCouncilMembers()">
        Μελη Δ.Σ.
      </v-tab>
      <v-tab href="#tab-3"
             :style="tab=='tab-3' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getBranches()">
        Παραρτηματα
      </v-tab>
      <v-tab href="#tab-4"
             :style="tab=='tab-4' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getBranchesWithPlaces()">
        Χωροι
      </v-tab>
      <v-tab href="#tab-5"
             :style="tab=='tab-5' ? 'background-color: aliceblue;' : 'background-color: '"
             @click="getBranchesWithPlaces()">
        Μελη λεσχης
      </v-tab>
      <v-tab href="#tab-6" v-if="$store.getters.selectedSeasonKey!=0 && $store.getters.loggedinUser.role != 'ΓΔΑ'">
        Δικαιολογητικα
      </v-tab>
      <v-tab href="#tab-7" @click="getLicenceOfClub()">
        Εγγραφα
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item v-if="$store.getters.selectedSeasonKey==0" value="tab-1">
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip v-if="$store.getters.selectedClub.clubstatus.id ==
                                  $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.ACTIVE"
                                style="background-color: green;"
                                class="govgr-!-font-size-14 white--text"
                        >
                          {{ $store.getters.selectedClub.clubstatus.name }}
                        </v-chip>
                        <v-chip v-else-if="$store.getters.selectedClub.clubstatus.id ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.SUSPENDED
                                       ||
                                       $store.getters.selectedClub.clubstatus.id  ==
                                       $store.getters.loggedinUser.CONSTANTS.CLUB.STATUS.IN_DISSOLUTION"
                                style="background-color: red;"
                                class="govgr-!-font-size-14 white--text"
                        >
                          {{ $store.getters.selectedClub.clubstatus.name }}
                        </v-chip>
                        <v-chip v-else
                                class="govgr-!-font-size-14"
                        >
                          {{ $store.getters.selectedClub.clubstatus.name }}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        Κατάσταση λέσχης
                        <div v-if=" endDate!= null &&
                                endDate!= 'null' &&
                                endDate != ''"
                             style="display: inline">
                          εώς {{ endDate }}
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ club.name }}
                        <v-btn icon v-if="!$store.getters.initializationNEAS.hasSeparateAFM
                                    && $store.getters.loggedinUser.role=='NEAS'
                                    && editable == true"
                               @click="openEditClubNameDialog()">
                          <!--&& $store.getters.selectedClub.clubstatus_id != 2-->
                          <v-icon color="primary">mdi-pencil</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>Επωνυμία</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ club.vat }}</v-list-item-title>
                      <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ ClubUserNEl.vat }}
                        {{ ', ' + ClubUserNEl.first_name }}
                        {{ ', ' + ClubUserNEl.last_name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Υπεύθυνος λέσχης (ΑΦΜ, Όνομα, Επώνυμο)</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ branch.address }} {{ ', ' + branch.city }} {{ ', ' + branch.postal_code }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Διεύθυνση έδρας</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item two-line>
                    <v-list-item-content>
                      <v-list-item-title>{{ organization.name }}</v-list-item-title>
                      <v-list-item-subtitle>ΑΣ/ΤΑΑ/ΑΑΕ στο οποίο υπάγεται</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-divider v-if="pageWidth>960" vertical/>
                <v-divider class="mx-1 mt-2" v-if="pageWidth<=960"/>
                <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12">
                  <v-row justify="center" align="center">
                    <v-col class="text-h6">
                      Εκπρόσωπος αθλητικού σωματείου
                    </v-col>
                  </v-row>
                  <v-row v-if="councilOfOrganization.length > 0" align="center">
                    <div v-for="(item,index) in councilOfOrganization" :key="index">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ (item.relFirstname != "") ? item.relFirstname : '-' }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ (item.relFirst_Lastname != "") ? item.relFirst_Lastname : '-' }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{
                              (item.relRelatedAfm != "") ? item.relRelatedAfm : '-'
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-row>
                  <v-row v-else align="center">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ '-' }}</v-list-item-title>
                        <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ '-' }}</v-list-item-title>
                        <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ '-' }}</v-list-item-title>
                        <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
            <v-dialog persistent v-model="councilMemberDialog" width="600px">
              <v-card>
                <v-card-title class="primary white--text">
                  Πληροφορίες εκπροσώπου αθλητικού σωματείου
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ selectedCouncilMember.relFirstname }}
                            {{ ' ' + selectedCouncilMember.relSecond_Lastname }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Όνομα</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ selectedCouncilMember.relFirst_Lastname }}</v-list-item-title>
                          <v-list-item-subtitle>Επώνυμο</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ selectedCouncilMember.relFathername }}</v-list-item-title>
                          <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ selectedCouncilMember.relRelatedAfm }}</v-list-item-title>
                          <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ selectedCouncilMember.relCategDescr }}</v-list-item-title>
                          <v-list-item-subtitle>Δραστηριότητα</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{ selectedCouncilMember.relKindDescr }}</v-list-item-title>
                          <v-list-item-subtitle>Ιδιότητα</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="councilMemberDialog = false">
                    Κλείσιμο
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2" class="mt-4">
          <v-data-table
              :items-per-page="5"
              :headers="headersMembersDS"
              :footer-props="{page:1,
                              pageCount:1,
                              showCurrentPage:true,
                              itemsPerPageOptions:[5,10,15,30],
                              showFirstLastPage:true,
                              itemsPerPageText:'Μέλη Δ.Σ. ανά σελίδα:',
                              itemsPerPageAllText:'Όλα'}"
              no-data-text="Δέν υπάρχουν αποτελέσματα."
              :items="councilMembers">
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:item="{item}">
              <tr>
                <td align="center" justify="center">{{ item.user.vat }}</td>
                <td align="center" justify="center">{{ item.user.user_branches[0].first_name }}</td>
                <td align="center" justify="center">{{ item.user.user_branches[0].last_name }}</td>
                <td align="center" justify="center">{{ item.user.user_branches[0].father_name }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-data-table :footer-props="{page:1,
                                      pageCount:1,
                                      showCurrentPage: true,
                                      itemsPerPageOptions:[5,10,15,30],
                                      showFirstLastPage:true,
                                      itemsPerPageText:'Παραρτήματα ανά σελίδα',
                                      itemsPerPageAllText:'Όλα'}"
                        no-data-text="Δεν υπάρχουν αποτελέσματα."
                        :headers="headersBranches"
                        :items="branches"
                        :items-per-page="5"
          >
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  <v-row class="mt-3">
                    <v-col>
                      <v-text-field class="ml-5 mb-1"
                                    append-icon="mdi-magnify"
                                    hide-details
                                    label="Αναζήτηση..."
                                    single-line
                                    v-model="search"
                                    :rules="searchRules"
                                    dense
                                    @keydown.enter="searchWithParameter"
                                    @click:append="searchWithParameter">
                        >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:item="{item}">
              <tr>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.name }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.address }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.city }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  {{ item.postal_code }}
                </td>
                <td class="text-center d-sm-table-cell d-flex">
                  <v-chip v-if="item.branchstatus.id ==
                          $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.ACTIVE"
                          class="white--text" color="green">
                    {{ item.branchstatus.name }}
                  </v-chip>
                  <v-chip v-else-if="item.branchstatus.id ==
                                 $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.DELETED ||
                               item.branchstatus.id ==
                                $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.IN_DESOLUTION ||
                               item.branchstatus.id ==
                                 $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.SUSPENDED ||
                               item.branchstatus.id ==
                               $store.getters.loggedinUser.CONSTANTS.BRANCH.STATUS.UNLICENSED"
                          class="white--text" color="red">
                    {{ item.branchstatus.name }}
                  </v-chip>
                  <v-chip v-else>
                    {{ item.branchstatus.name }}
                  </v-chip>
                </td>
                <td class="text-center d-sm-table-cell d-flex click-cursor"
                    @click="ekprosopoiDialog = true; selectedItem = item;">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-badge :content="item.user_branches.length" v-on="on">
                        <v-icon>
                          mdi-account
                        </v-icon>
                      </v-badge>
                    </template>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-4">
          <v-data-table :headers="placesHeaders"
                        :items="places"
                        :items-per-page="10"
                        :footer-props="{page:1,
                                      pageCount:1,
                                      showCurrentPage:true,
                                      itemsPerPageOptions:[5,10,15,30],
                                      showFirstLastPage:true,
                                      itemsPerPageText:'Χώροι ανά σελίδα:',
                                      itemsPerPageAllText:'Όλα'}"
                        no-data-text="Δέν υπάρχουν αποτελέσματα."
          >
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  <v-row class="mt-5">
                    <v-col>
                      <v-select label="Παραρτήματα"
                                :items="branchesWithPlaces"
                                return-object
                                :item-text="item =>item.name + ' (Χώροι:' + item.places_count + ')'"
                                dense
                                no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                v-model="selectedBranch"
                                @change="selectBranchToViewPlaces(selectedBranch)">
                      </v-select>
                    </v-col>
                  </v-row>
                </v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:item.status="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                <v-chip v-if="item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.APPROVED"
                        class="white--text" color="green">
                  {{ item.place_status.status }}
                </v-chip>
                <v-chip v-else-if="item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.DELETED ||
                        item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.SUSPENDED ||
                        item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.INACTIVE ||
                        item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.REJECTED ||
                        item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.UNLICENSED"
                        class="white--text" color="red">
                  {{ item.place_status.status }}
                </v-chip>
                <v-chip v-else>
                  {{ item.place_status.status }}
                </v-chip>
              </td>
            </template>
            <template v-slot:item.user="{ item }">
              <td class="text-center d-sm-table-cell d-flex click-cursor" @click="openPlaceUserDialog(item)">
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon color="primary" v-on="on">mdi-account</v-icon>
                  </template>
                  <span>{{
                      item.user.user_branches[0].first_name
                    }} {{ item.user.user_branches[0].last_name }} {{ item.user.vat }}</span>
                </v-tooltip>
              </td>
            </template>
            <template v-slot:item.file="{ item }">
              <td align="center" justify="center" v-if="item.place_file == null">{{ '-' }}</td>
              <td align="center" justify="center" v-else>
                <v-btn icon color="primary" @click="downloadFile(item)">
                  <v-icon>
                    mdi-download
                  </v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-5">
          <v-data-table
              :headers="memberHeaders"
              :items="members"
              :items-per-page="itemsPerPage"
              :page.sync="pageNumber"
              :footer-props="{page:1,
                                      pageCount:1,
                                      showCurrentPage:true,
                                      itemsPerPageOptions:[5,10,15,30],
                                      showFirstLastPage:true,
                                      itemsPerPageText:'Μέλη ανά σελίδα:',
                                      itemsPerPageAllText:'Όλα'}"
              no-data-text="Δέν υπάρχουν αποτελέσματα."
              :server-items-length="tableLength"
              @update:options="getBuildingPerPage"
          >
            <template v-slot:[`footer.page-text`]="items">
              {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
            </template>
            <!--                    :server-items-length="tableLength"
                        @update:options="getBuildingPerPage"-->
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  <v-row class="mt-5">
                    <v-col>
                      <v-select label="Παραρτήματα"
                                :items="branchesWithPlaces"
                                return-object
                                :item-text="item =>item.name"
                                dense
                                no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                v-model="selectedBranchToShowMembers"
                                @change="getMembers()">
                      </v-select>
                    </v-col>
                  </v-row>
                </v-toolbar-title>
              </v-toolbar>
            </template>
            <template v-slot:item.first_name="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branches[0].first_name }}
              </td>
            </template>
            <template v-slot:item.last_name="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branches[0].last_name }}
              </td>
            </template>
            <template v-slot:item.father_name="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branches[0].father_name }}
              </td>
            </template>
            <template v-slot:item.date_of_birth="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branches[0].date_of_birth }}
              </td>
            </template>
            <template v-slot:item.amka="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branches[0].amka }}
              </td>
            </template>
            <template v-slot:item.status="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                <v-chip v-if="item.user_branches[0].userbranchbranchrolestatus.id
              == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.ACTIVE"
                        class="white--text" color="green">
                  {{ item.user_branches[0].userbranchbranchrolestatus.status }}
                </v-chip>
                <v-chip v-else-if="item.user_branches[0].userbranchbranchrolestatus.id
              == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.DELETED
                                    || item.user_branches[0].userbranchbranchrolestatus.id
                                    == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.SUSPENDED
                                    || item.user_branches[0].userbranchbranchrolestatus.id
                                    == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.INVALID
                                    || item.user_branches[0].userbranchbranchrolestatus.id
                                    == $store.getters.loggedinUser.CONSTANTS.USERBRANCH.BRANCHROLE.STATUS.INACTIVE"
                        color="red" class="white--text">
                  {{ item.user_branches[0].userbranchbranchrolestatus.status }}
                </v-chip>
                <v-chip v-else>
                  {{ item.user_branches[0].userbranchbranchrolestatus.status }}
                </v-chip>
              </td>
            </template>
            <template v-slot:item.address="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branches[0].address }}
              </td>
            </template>
            <template v-slot:item.city="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branches[0].city }}
              </td>
            </template>
            <template v-slot:item.postal_code="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branches[0].postal_code }}
              </td>
            </template>
            <template v-slot:item.identification_document="{ item }">
              <td class="text-center d-sm-table-cell d-flex">
                {{ item.user_branches[0].identification_document }}
              </td>
            </template>

          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-6">
          <v-row justify="center" align="center" class="mt-2">
            <v-card class="ma-3" width="60%">
              <v-card class="mb-1"
                      :style="`background-color: ` + (index%2 == 0 ? `${$vuetify.theme.themes.light.documentBlue}` : '')"
                      v-for="(doc, index) in documents" :key="index"
              >
                <v-row class="pa-4">
                  <v-col cols="7"><b>{{ doc.document_id + '. ' + doc.document.description }}</b></v-col>
                  <v-col cols="4">
                    {{ '(' }}{{ doc.filename }}{{ ')' }}
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-btn class="mt-4r" color="primary" icon @click="download(doc)">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-7">
          <v-row justify="center" align="center" class="mt-2">
            <v-card class="ma-3" width="60%">
              <v-card class="mb-3">
                <v-row class="pa-4" align="center" v-if="licenceForClub.application_approval_file != null">
                  <v-col cols="11" class="govgr-!-font-size-19">
                    <b>Βεβαίωση νόμιμης λειτουργίας λέσχης παραγόμενη από την Γενική Γραμματεία Αθλητισμού</b>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="1">
                    <v-btn class="mt-4r" color="primary" icon @click="getApprovalDocument()">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="pa-4" v-else>
                  <v-col class="govgr-!-font-size-19">
                    <b> Δεν υπάρχει βεβαίωση νόμιμης λειτουργίας λέσχης από την Γενική Γραμματεία Αθλητισμού.</b>
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="mb-1">
                <v-card-title style="word-break: normal">
                  <v-select
                      label="Επιλέξτε παράρτημα για την εμφάνιση των σχετικών αρχείων για τους χώρους του παραρτήματος"
                      :items="branchesWithPlaces"
                      return-object
                      :item-text="item =>item.name"
                      dense
                      no-data-text="Δεν υπάρχουν καταχωρήσεις"
                      v-model="selectedBranchForGettingFiles"
                      @change="getDocumentsForClubAndHisoryForNeas()">
                  </v-select>
                </v-card-title>
                <v-card-text v-if="documentsForOfficials.length > 0">
                  <v-card class="mb-1"
                          :style="`background-color: ` + (index%2 == 0 ? `${$vuetify.theme.themes.light.documentBlue}` : '')"
                          v-for="(doc, index) in documentsForOfficials" :key="index"
                  >
                    <v-row class="pa-4">
                      <v-col cols="7"
                             v-if="doc.place_histories[0].place_licencing.licencing_type_id
                          == $store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.APPROVED ">
                        <b>{{ 'Έγγραφο έγκρισης για χώρο του παραρτήματος στην διεύθυνση: ' }}
                          {{ doc.address + ', ' + doc.city + ', ' + doc.postal_code }}</b>
                      </v-col>
                      <v-col cols="7"
                             v-if="doc.place_histories[0].place_licencing.licencing_type_id
                          == $store.getters.loggedinUser.CONSTANTS.PLACE.LICENCING.TYPE.REJECTED">
                        <b>{{ 'Έγγραφο απόρριψης για χώρο του παραρτήματος στην διεύθυνση: ' }}
                          {{ doc.address + ', ' + doc.city + ', ' + doc.postal_code }}</b>
                      </v-col>
                      <v-col cols="4">
                        {{ '(' }}{{ doc.place_histories[0].place_licencing.filename }}{{ ')' }}
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="1">
                        <v-btn class="mt-4r" color="primary" icon
                               @click="getLicencingDocument(doc.place_histories[0].place_licencing)">
                          <v-icon>mdi-download</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-card-text>
                <v-card-text
                    v-if="documentsForOfficials.length == 0 && Object.keys(selectedBranchForGettingFiles).length != 0">
                  <v-row justify="center" align="center" class="mt-3 mb-3 govgr-!-font-size-19">
                    <b>{{ 'Δεν βρέθηκαν σχετικά αρχεία χώρων για το συγκεκριμένο παράρτημα.' }}</b>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <v-dialog persistent v-model="editClubNameDialog" max-width="500">
      <v-card>
        <v-card-title style="background-color: #003375; word-break: normal" class="white--text">
          <v-row align="center" justify="center" class="ma-2 text-h4">
            Αλλαγή επωνυμίας λέσχης
          </v-row>
        </v-card-title>
        <v-divider/>
        <v-card-text class="mt-6">
          <v-text-field outlined label="Επωνυμία" v-model="newClubName"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeEditClubNameDialog()">
            Κλείσιμο
          </v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="OpenCheckForChangeNameDialog()">
            Αποθήκευση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="checkForChangeNameDialog" max-width="450">
      <v-card>
        <v-card-title class="justify-center primary">
          <v-icon color="white">mdi-comment-alert-outline</v-icon>
        </v-card-title>
        <v-card-text class="mt-2 govgr-!-font-size-19">
          <v-row align="center" justify="center" class="ma-1">
            Είστε σίγουρος/η για την αλλαγή επωνυμίας;
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="checkForChangeNameDialog = false">
            Όχι
          </v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="saveNewClubName()">
            Ναι, αποθήκευση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="ekprosopoiDialog" max-width="900px" persistent>
      <v-card tile>
        <v-card-title class="primary">
          <span class="headline white--text">{{ 'Στοιχεία υπευθύνων λειτουργίας' }}</span>
        </v-card-title>
        <v-card-text>
          <v-container v-for="user_branch in selectedItem.user_branches" :key="user_branch.id">
            <v-row class="my-1">
              <v-col cols="3">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ user_branch.user.vat }}</v-list-item-title>
                    <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="4">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ user_branch.first_name }} {{ user_branch.last_name }}</v-list-item-title>
                    <v-list-item-subtitle>Ονοματεπώνυμο</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="2">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ user_branch.father_name }}</v-list-item-title>
                    <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="3">
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title>{{ user_branch.identification_document }}</v-list-item-title>
                    <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn @click="ekprosopoiDialog = false; selectedItem = {};" color="primary" text>
            {{ 'Κλείσιμο' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="placeUserDialog" persistent max-width="900px">
      <v-card>
        <v-card-title style="background-color: #003375" class="white--text">
          Υπεύθυνος Χώρου
        </v-card-title>
        <v-card-text>
          <v-row class="my-1">
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ placeWithChangedUser.user.vat }}</v-list-item-title>
                  <v-list-item-subtitle>ΑΦΜ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ placeWithChangedUser.user.user_branches[0].first_name }}
                    {{ placeWithChangedUser.user.user_branches[0].last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Ονοματεπώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="2">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ placeWithChangedUser.user.user_branches[0].father_name }}</v-list-item-title>
                  <v-list-item-subtitle>Πατρώνυμο</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="3">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>{{ placeWithChangedUser.user.user_branches[0].identification_document }}
                  </v-list-item-title>
                  <v-list-item-subtitle>ΑΔΤ</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer/>
        <v-card-actions>
          <v-btn text @click="closePlaceUserDialog">
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import requests from "@/requests";
import prop from "@/properties";

export default {
  name: "ClubAndHistoryInfoForNeas",
  props: {
    editable: Boolean
  },
  data: () => ({
    club: {},
    company: {},
    branch: {},
    organization: {},
    editClubNameDialog: false,
    checkForChangeNameDialog: false,
    newClubName: '',
    ClubUserNEl: {},
    tab: null,
    headersBranches: [
      {
        text: 'Επωνυμία',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Εκπρόσωποι',
        align: 'center',
        sortable: false,
        value: 'representative',
      },
    ],
    branches: [],
    selectedItem: {},
    ekprosopoiDialog: false,
    search: '',
    searchObj: {},
    searchRules: [
      v => (v.trim().length >= 3 || v.trim().length == 0) || 'Απαιτούνται τουλάχιστον 3 χαρακτήρες.'
    ],
    headersMembersDS: [
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'relRelatedAfm',
        width: '25%',
      },
      {
        text: 'Όνομα',
        align: 'center',
        sortable: false,
        value: 'relRelatedOnoma',
        width: '25%',
      },
      {
        text: 'Επώνυμο',
        align: 'center',
        sortable: false,
        value: 'relRelatedEpwnumo',
        width: '25%',
      },
      {
        text: 'Πατρώνυμο',
        align: 'center',
        sortable: false,
        value: 'relRelatedPatrwnumo',
        width: '25%',
      },
    ],
    councilMembers: [
      {
        user: {
          user_branches: [
            {
              first_name: '',
              last_name: '',
              father_name: ''
            }
          ]
        }
      }
    ],
    placesHeaders: [
      {
        text: 'Τύπος',
        align: 'center',
        sortable: false,
        value: 'place_type.type',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Αρχείο',
        align: 'center',
        sortable: false,
        value: 'file',
      },
      {
        text: 'Υπεύθυνος',
        align: 'center',
        sortable: false,
        value: 'user'
      }
    ],
    branchesWithPlaces: [],
    places: [
      {
        user: {
          vat: '',
          user_branches: [
            {
              first_name: '',
              last_name: '',
            }
          ]
        }
      }
    ],
    selectedBranch: {},
    placeUserDialog: false,
    placeWithChangedUser: {
      user: {
        vat: '',
        first_name: '',
        last_name: '',
        father_name: '',
        identification_document: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
          }
        ]
      }
    },
    memberHeaders: [
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'vat',
      },
      {
        text: 'Όνομα',
        align: 'center',
        sortable: false,
        value: 'first_name',
      },
      {
        text: 'Επώνυμο',
        align: 'center',
        sortable: false,
        value: 'last_name',
      },
      {
        text: 'Πατρώνυμο',
        align: 'center',
        sortable: false,
        value: 'father_name',
      },
      {
        text: 'Ημερομηνία γέννησης',
        align: 'center',
        sortable: false,
        value: 'date_of_birth',
      },
      {
        text: 'ΑΜΚΑ',
        align: 'center',
        sortable: false,
        value: 'amka',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Αριθμός ταυτότητας',
        align: 'center',
        sortable: false,
        value: 'identification_document',
      },

    ],
    members: [],
    selectedBranchToShowMembers: {},
    itemsPerPage: 5,
    pageNumber: 1,
    tableLength: 0,
    documents: {},
    documentsForOfficials: [],
    licenceForClub: {},
    selectedBranchForGettingFiles: {},
    headersNEAS: [
      {
        text: 'Ονοματεπώνυμο',
        align: 'center',
        sortable: false,
        value: 'relRelatedOnomasia'
      },
      {
        text: 'ΑΦΜ',
        align: 'center',
        sortable: false,
        value: 'relRelatedAfm'
      },
    ],
    councilOfOrganization: [],
    selectedCouncilMember: {},
    councilMemberDialog: false,
    lastApplication: {},
    pageWidth: window.innerWidth,
    endDate: '',
  }),
  methods: {
    getClub() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_vat: this.$store.getters.initializationNEAS.club_vat
      }
      requests.postSecureRequest('club/get-club-by-vat', requestObj, this.getClubCallback)
    },

    getClubCallback(response) {
      this.club = response;
      this.newClubName = this.club.name;
      this.branch = response.branches[0];
      this.organization = this.club.organization;
      this.$store.commit('setSelectedClub', this.club);
      this.getNEL();
    },


    getNEL() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        club_vat: this.$store.getters.initializationNEAS.club_vat
      }
      requests.postSecureRequest('user/get-nel-by-club-vat', requestObj, this.getNelByClubVatCallback)
    },
    getNelByClubVatCallback(response) {

      this.ClubUserNEl = response
      this.$store.commit('setLoadingModal', false);
      this.getCouncilOfOrganization()
    },

    openEditClubNameDialog() {
      this.editClubNameDialog = true;
    },
    closeEditClubNameDialog() {
      this.editClubNameDialog = false;
    },
    OpenCheckForChangeNameDialog() {
      if (this.club.name == this.newClubName || this.newClubName.trim() == '') {
        this.$store.commit('setErrorDialogObj', {text: 'Ορίστε μια τιμή για τη νέα επωνυμία, διαφορετική από την υπάρχουσα.'});
        this.$store.commit('setErrorDialog', true)
      } else {
        this.checkForChangeNameDialog = true
      }
    },
    saveNewClubName() {
      this.$store.commit('setLoadingModal', true);
      requests.postSecureRequest('club/change-club-name', {
        club_id: this.club.id,
        new_club_name: this.newClubName
      }, this.saveNewClubNameCallBack)
    },
    saveNewClubNameCallBack(response) {

      this.getClub();
      this.checkForChangeNameDialog = false;
      this.editClubNameDialog = false;
      let neas = this.$store.getters.initializationNEAS
      neas.club_name = this.newClubName
      this.$store.commit('setInitializationNEAS', neas)
      this.$store.commit('setLoadingModal', false);
    },

    getCouncilMembers() {
      this.$store.commit('setLoadingModal', true)

      if (this.$store.getters.selectedSeasonKey == 0) {
        let requestObj = {
          club_id: this.$store.getters.selectedClub.id
        }
        requests.postSecureRequest('council/get-council-members', requestObj, this.getCouncilMembersCallBack)
      } else {
        let requestObj = this.$store.getters.selectedSeason
        requests.postSecureRequest('history/get-council', requestObj, this.getCouncilMembersCallBack)
      }

    },

    getCouncilMembersCallBack(response) {
      this.councilMembers = []
      this.councilMembers = response
      this.$store.commit('setLoadingModal', false)
      if (this.$store.getters.selectedSeasonKey != 0) {
        this.getDocuments()
      }
    },

    getDocuments() {
      this.$store.commit('setLoadingModal', true)
      let requestObj = {
        application: {
          id: this.$store.getters.selectedSeason.application_id
        }
      }
      requests.postSecureRequest('application/get-application-documents', requestObj, this.getDocumentsCallback)
    },

    getDocumentsCallback(response) {
      this.documents = response
      this.$store.commit('setLoadingModal', false)
    },

    download(item) {
      requests.downloadFileRequest('application/download/' + this.$store.getters.selectedSeason.application_id + '/' + item.document_id)
    },

    getBranches() {
      this.$store.commit('setLoadingModal', true);

      if (this.$store.getters.selectedSeasonKey == 0) {
        this.searchObj.club = this.$store.getters.selectedClub
        this.searchObj.parameter = '' + this.search + ''

        requests.postSecureRequest('branch/branchesOnClubWithoutDefaultIncludeUsers', {searchObj: this.searchObj}, this.getBranchesCallback)
      } else {
        let requestObj = this.$store.getters.selectedSeason
        requestObj.parameter = '' + this.search + ''
        requests.postSecureRequest('history/get-branches', requestObj, this.getBranchesCallback)
      }

    },
    getBranchesCallback(response) {
      this.branches = []
      if (this.$store.getters.selectedSeasonKey == 0) {
        this.branches = response.branches
      } else {
        for (let item of response) {
          // let user_branches = [{
          //   user: item.user
          // }]
          // item.user_branches = user_branches
          // this.branches.push(item)

          let branch = item.branch
          branch.name = item.name
          branch.address = item.address
          branch.city = item.city
          branch.postal_code = item.postal_code
          if (item.user.user_branches.length > 0) {
            let temp = Object.assign({}, item.user)
            delete temp.user_branches
            Object.assign(item.user.user_branches[0], {user: temp})
          }
          branch.user_branches = item.user.user_branches
          this.branches.push(branch)
        }

      }
      this.$store.commit('setLoadingModal', false);
    },

    tableRowClass(item) {
      if (item.branchstatus_id == 2) {
        return 'lightRed text-high-emphasis';
      } else {
        return '';
      }
    },


    //THA EXEI ANAZHTHSH OTAN EIMASTE SE FASH ISTORIKOU
    searchWithParameter() {
      if (this.search.trim().length < 3 && this.search.trim().length > 0) {
        prop.getErrorMessage({data: {message: 'Απαιτούνται τουλάχιστον 3 χαρακτήρες.'}})
        return
      }
      console.log('Searching. . .')
      this.getBranches();
    },

    getBranchesWithPlaces() {
      this.$store.commit('setLoadingModal', true);
      this.selectedBranch = ''
      if (this.$store.getters.selectedSeasonKey == 0) {
        requests.postSecureRequest('branch/get-branches-include-places', {club: this.$store.getters.selectedClub}, this.getBranchesWithPlacesCallback)
      } else {
        let requestObj = this.$store.getters.selectedSeason
        requests.postSecureRequest('history/get-places', requestObj, this.getBranchesWithPlacesCallback)
      }

    },
    getBranchesWithPlacesCallback(response) {
      this.branchesWithPlaces = []
      this.places = [
        {
          user: {
            vat: '',
            user_branches: [
              {
                first_name: '',
                last_name: '',
              }
            ]
          }
        }
      ]
      this.branchesWithPlaces = response
      if (this.branchesWithPlaces.length > 1) {
        this.branchesWithPlaces.sort(function (x, y) {
          return x.is_default == true ? -1 : y.is_default == false ? 1 : 0;
        })
      }
      for (let branch of this.branchesWithPlaces) {
        branch.places_count = branch.places.length
      }

      if (this.branchesWithPlaces.length > 0) {
        if (Object.keys(this.selectedBranch).length == 0) {
          this.selectedBranch = this.branchesWithPlaces[0]
          this.places = this.selectedBranch.places
          console.log(this.places);
        } else {
          for (let branch of this.branchesWithPlaces) {
            if (branch.id == this.selectedBranch.id) {
              this.selectedBranch = branch
              this.places = this.selectedBranch.places
            }
          }
        }
      }
      console.log(this.places);
      this.placeWithChangedUser = this.places[0]
      this.$store.commit('setLoadingModal', false)
    },

    selectBranchToViewPlaces(item) {
      this.selectedBranch = item
      console.log(JSON.parse(JSON.stringify(this.selectedBranch)));
      this.places = this.selectedBranch.places
    },

    openPlaceUserDialog(item) {
      this.placeUserDialog = !this.placeUserDialog
      this.placeWithChangedUser = item
    },

    closePlaceUserDialog() {
      this.placeUserDialog = !this.placeUserDialog

      this.replacedPlaceUser = {
        vat: '',
        first_name: '',
        last_name: '',
        father_name: '',
        identification_document: '',
      }
    },

    getMembers() {
      this.$store.commit('setLoadingModal', true);
      this.tableLength = 0
      this.pageNumber = 1

      if (this.$store.getters.selectedSeasonKey == 0) {
        this.searchObj.branch = this.selectedBranchToShowMembers
        this.searchObj.parameter = '' + this.search + ''

        requests.postSecureRequest('user/branchMembers',
            {
              searchObj: this.searchObj,
              itemsPerPage: this.itemsPerPage,
              pageNumber: 0
            },
            this.getMembersCallback)
      } else {
        let requestObj = {
          application_id: this.$store.getters.selectedSeason.application_id,
          effective_start_date: this.$store.getters.selectedSeason.effective_start_date,
          effective_end_date: this.$store.getters.selectedSeason.effective_end_date,
          season: this.$store.getters.selectedSeason.season,
          branch_id: this.selectedBranchToShowMembers.branch_id,
          limit: this.itemsPerPage,
          offset: 0
        }
        requests.postSecureRequest('history/get-branch-members', requestObj, this.getMembersCallback)
      }

    },

    getMembersCallback(response) {
      this.members = []
      if (this.$store.getters.selectedSeasonKey == 0) {
        let res = JSON.parse(JSON.stringify(response))
        for (let i = 0; i < res.members.length; i++) {
          this.members.push(res.members[i])
        }
        console.log(JSON.parse(JSON.stringify(this.members)));
        this.tableLength = res.membersCount
      } else {
        let res = JSON.parse(JSON.stringify(response))
        for (let i = 0; i < res.members.length; i++) {
          this.members.push(res.members[i].user)
        }
        this.tableLength = res.all
        console.log(this.members);
      }
      this.$store.commit('setLoadingModal', false);
    },

    getBuildingPerPage(item) {
      if (Object.keys(this.selectedBranchToShowMembers).length === 0) {
        return
      }
      this.$store.commit('setLoadingModal', true);
      this.pageNumber = item.page
      this.itemsPerPage = item.itemsPerPage
      this.printDataTableOptions()

      if (this.$store.getters.selectedSeason == 0) {
        requests.postSecureRequest('user/branchMembers',
            {
              searchObj: this.searchObj,
              itemsPerPage: this.itemsPerPage,
              pageNumber: this.pageNumber - 1
            },
            this.getMembersCallback)
      } else {
        let requestObj = {
          application_id: this.$store.getters.selectedSeason.application_id,
          effective_start_date: this.$store.getters.selectedSeason.effective_start_date,
          effective_end_date: this.$store.getters.selectedSeason.effective_end_date,
          season: this.$store.getters.selectedSeason.season,
          branch_id: this.selectedBranchToShowMembers.branch_id,
          limit: this.itemsPerPage,
          offset: this.pageNumber - 1
        }
        requests.postSecureRequest('history/get-branch-members', requestObj, this.getMembersCallback)
      }

    },

    printDataTableOptions() {
      console.log('Page: ' + this.pageNumber + '\nItems per page: ' + this.itemsPerPage)
    },

    downloadFile(place) {
      if (this.$store.getters.selectedSeasonKey == 0) {
        requests.downloadFileRequest('place/download-place-file/' + place.id + '/' + place.place_file.id)
      } else {
        if (place.place_history != null) {
          requests.downloadFileRequest('place/download-place-file/' + place.place_history.place_id + '/' + place.place_file.id)
        } else {
          requests.downloadFileRequest('place/download-place-file/' + place.id + '/' + place.place_file.id)
        }
      }
    },

    getLicenceOfClub() {
      this.$store.commit('setLoadingModal', true)
      if (this.$store.getters.selectedSeasonKey == 0) {
        requests.postSecureRequest('club/get-gga-files', {club_id: this.$store.getters.selectedClub.id}, this.getLicenceOfClubCallback)
      } else {
        requests.postSecureRequest('history/get-gga-files', {application_id: this.$store.getters.selectedSeason.application_id}, this.getLicenceOfClubCallback)
      }

    },

    getLicenceOfClubCallback(response) {
      this.licenceForClub = response
      this.getBranchesWithPlaces()
    },

    getApprovalDocument() {
      requests.downloadFileRequestWithObj('application/get-approval-file', {application_id: this.licenceForClub.application_id})
    },

    getDocumentsForClubAndHisoryForNeas() {
      this.$store.commit('setLoadingModal', true)
      console.log(this.selectedBranchForGettingFiles);
      if (this.$store.getters.selectedSeasonKey == 0) {
        requests.postSecureRequest('branch/get-at-files', {branch_id: this.selectedBranchForGettingFiles.id}, this.getDocumentsForClubAndHisoryForNeasCallback)
      } else {
        let requestObj = this.$store.getters.selectedSeason
        requestObj.branch_id = this.selectedBranchForGettingFiles.branch_id
        requests.postSecureRequest('history/get-at-files', requestObj, this.getDocumentsForClubAndHisoryForNeasCallback)
      }

    },

    getDocumentsForClubAndHisoryForNeasCallback(response) {
      this.documentsForOfficials = []
      this.documentsForOfficials = response
      this.$store.commit('setLoadingModal', false)
    },

    getLicencingDocument(item) {
      requests.downloadFileRequest('place/download-licencing/' + item.id)
    },

    getCouncilOfOrganization() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('club/get-relations-array', {club_id: this.$store.getters.selectedClub.id}, this.getCouncilOfOrganizationCallback)
    },

    getCouncilOfOrganizationCallback(response) {
      this.councilOfOrganization = []
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.length; i++) {
        let name = res[i].relRelatedOnomasia.split(',')
        let obj = {
          relRelatedAfm: res[i].relRelatedAfm,
          relFirstname: name[2],
          relFirst_Lastname: name[0],
          relSecond_Lastname: name[1],
          relFathername: name[3],
          relCateg: res[i].relCateg,
          relCategDescr: res[i].relCategDescr,
          relKind: res[i].relKind,
          relKindDescr: res[i].relKindDescr,
          relStartDate: res[i].relStartDate,
        }
        this.councilOfOrganization.push(obj)
      }
      console.log(this.councilOfOrganization);
      this.$store.commit('setLoadingModal', false)
    },

    openCouncilMemberDialog(item) {
      this.selectedCouncilMember = item
      this.councilMemberDialog = true
    },

    handleResize() {
      this.pageWidth = window.innerWidth;
    },

    // getLastApplication() {
    //   this.$store.commit('setLoadingModal',true)
    //   requests.postSecureRequest('club/active-until',
    //       {club_id: this.$store.getters.selectedClub.id},
    //       this.getlastApplicationCallback)
    // },
    //
    // getlastApplicationCallback(response) {
    //   this.lastApplication = response
    //   this.$store.commit('setLoadingModal',false)
    // }

    getEndDateOfStatus() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('club/get-status-until-date',
          {club_id: this.$store.getters.selectedClub.id}, this.getEndDateOfStatusCallback)
    },
    getEndDateOfStatusCallback(response) {
      this.endDate = response.date
      this.$store.commit('setLoadingModal', false)
    }

  },
  created() {
    if (this.$store.getters.selectedSeasonKey == 0) {
      if (Object.hasOwn(this.$store.getters.initializationNEAS, "exists")) {
        this.getClub();
        this.getEndDateOfStatus();
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
      }
    } else {
      this.getCouncilMembers();
    }
    this.$store.commit('setNotificationIcon', true)

  },
  beforeDestroy() {
    if (this.$store.getters.selectedSeasonKey == 0) {
      console.log('closed');
      window.removeEventListener('resize', this.handleResize);
    }
  },
}

</script>

<style scoped>

</style>