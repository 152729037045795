<template>
<div>
  <v-menu
      transition="slide-x-transition"
      offset-y
      bottom
      right
      max-width="450px"
      overflow: y-auto
      max-height="90vh"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge v-if="$store.getters.notificationIcon"
               :content="badgeValue"
               :value="badgeValue"
               :style=" $store.getters.notificationNumber>99 ? 'margin-right: 5px' : '-' "
               overlap
               color="red">
          <v-icon v-bind="attrs"
                  v-on="on"
                  :class="$store.getters.notificationNumber > 0  ? 'mr-2 mailIconRotate' : 'mr-2 mailIcon'"
          >
            mdi-bell
          </v-icon>
      </v-badge>
    </template>
    <v-list min-width="450px" class="mr-4 notificationList" >
      <v-list-item-title class="white--text govgr-!-font-size-19" style="background-color: #128695;">
        <section class="ml-2 pa-1">
          Ειδοποιήσεις
        </section>
      </v-list-item-title>
      <v-list-item
          v-for="(item, i) in $store.getters.notifications"
          :key="i"
          class="notificationList mb-0"
        >
        <v-hover
            v-slot="{ hover }"
            open-delay="10">
          <v-card
                  @click="openNotificationDialog(item)"
                  :elevation="hover ? 10 : 2"
                  outlined
                  min-width="450px"
                  :style="(!item['' + relationName+'s'][0]['notification_'+relationName].read) ? 'background-color: lightblue' : 'background-color: '">
            <v-card-title class="govgr-!-font-size-14 pa-1">
              {{item.actiontype.name}}
            </v-card-title>
            <v-card-text class="govgr-!-font-size-14 pr-4">
              <v-row>
                <v-col cols="10">
                  {{ item.text.substring(0,40) + ".." }}
                </v-col>
                <v-col cols="2" align="center">
                  <v-icon v-if="!item['' + relationName+'s'][0]['notification_'+relationName].read"
                          x-small
                          outline
                          style="color: #2C77FF"
                  >
                    mdi-checkbox-blank-circle
                  </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-subtitle style="margin-top: -7%; margin-left: -3%; padding-bottom: 3px">
              <v-chip outlined small>
                <v-icon small class="mr-1">mdi-alarm</v-icon>
                {{item.timestamp}}
              </v-chip>
            </v-card-subtitle>
          </v-card>
        </v-hover>
      </v-list-item>
      <v-btn width="100%" text v-if="$store.getters.notificationNumber>0" @click="moreNotificationsButton">Δείτε περισσότερες ειδοποιήσεις…</v-btn>
      <v-card v-else>
        <v-card-text>
          <v-row justify="center">
            Δεν έχετε αδιάβαστες ειδοποιήσεις
          </v-row>
        </v-card-text>
      </v-card>
    </v-list>
  </v-menu>

  <v-dialog persistent v-model="notificationDialog" width="700px">
    <v-card>
      <v-card-title style="background-color: #003375; word-break: normal" class="white--text">
        <v-row align="center" justify="center" class="ma-2 govgr-!-font-size-24">
          Λεπτομέρειες ειδοποίησης
        </v-row>
      </v-card-title>
      <v-card-text class="text-h6">
        <v-row align="center" style="font-weight: bold" class="ml-1 mb-1 mt-2">
          <span>{{notificationType}}</span>
        </v-row>
        <v-divider />
        <p style="text-align: justify; text-justify: inter-word;" class="mt-1">{{notificationInfo}}</p>
      </v-card-text>
      <v-card-actions>
        <v-chip>
          <v-icon>mdi-alarm</v-icon>
          <span class="ml-1">{{notificationTimestamp}}</span>
        </v-chip>
        <v-spacer/>
        <v-btn @click="closeNotificationDialog">
          Κλείσιμο
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
import requests from "@/requests";
import utilities from "@/utilities";
import services from "../../services";

export default {
  name: "NotificationsBell",
  data: () => ({
    notificationDialog: false,
    notificationInfo:'',
    notificationType:'',
    notificationTimestamp:'',
    notifications:[],
    unreadNotifications: 0,
    relationName: ''
  }),
  methods: {
    openNotificationDialog(item) {
      this.notificationInfo = item.text
      this.notificationType = item.actiontype.name
      this.notificationTimestamp = item.timestamp
      this.selectedNotificationIdToRead = item.id;
      this.notificationDialog = true
    },
    getNotifications() {
      this.$store.commit('setLoadingModal', true)
      let searchObj = {};
      if(this.$store.getters.loggedinUser.role == 'NEAS'){
        searchObj.club_id =  this.$store.getters.selectedClub.id
      } else if(this.$store.getters.loggedinUser.role == 'NEL'){
        searchObj.user_club_id = this.$store.getters.selectedClub.id
      } else if(this.$store.getters.loggedinUser.role == 'YLPL'){
        searchObj.user_club_id = this.$store.getters.selectedBranchYPL.club_id
      }
      searchObj.read = false
      let requestObj = {
        "pageNumber" : 0,
        "itemsPerPage" : 15,
        "searchObj" : searchObj
      }
      requests.postSecureRequest('notification/findByCriteriaPagination', requestObj, this.getNotificationsCallback)
    },

    getNotificationsCallback(response) {
      this.notifications = []
      let res = JSON.parse(JSON.stringify(response))
      for (let i = 0; i < res.notifications.length; i++) {
        res.notifications[i].timestamp = utilities.timestampToDatetime(res.notifications[i].timestamp);
        this.notifications.push(res.notifications[i])
      }

      this.$store.commit('setNotifications', this.notifications)
      this.$store.commit('setNotificationNumber', res.unread)
      this.unreadNotifications = response.unread
      this.tableLength = res.total;
      this.relationName = res.relationName;

      let searchObj = {};
      if(this.$store.getters.loggedinUser.role == 'NEAS'){
        searchObj.club_id =  this.$store.getters.selectedClub.id
      } else if(this.$store.getters.loggedinUser.role == 'NEL'){
        searchObj.user_club_id = this.$store.getters.selectedClub.id
      } else if(this.$store.getters.loggedinUser.role == 'YLPL'){
        searchObj.user_club_id = this.$store.getters.selectedBranchYPL.club_id
      }
      searchObj.read = false
      let requestObj = {
        "pageNumber" : 0,
        "itemsPerPage" : 15,
        "searchObj" : searchObj
      }

      services.getNotificationsInterval(requestObj)

      this.$store.commit('setLoadingModal', false)
    },

    closeNotificationDialog(){
      this.setNotificationAsRead()
    },



    setNotificationAsRead() {
      this.$store.commit('setLoadingModal', true)

      let identifyObj = {};
      if(this.$store.getters.loggedinUser.role == 'NEAS'){
        identifyObj.club_id =  this.$store.getters.selectedClub.id
      }

      let requestObj = {
        notification_id : this.selectedNotificationIdToRead,
        identify_obj: identifyObj
      }
      requests.postSecureRequest('notification/read-notification', requestObj, this.setNotificationAsReadCallback)
    },
    setNotificationAsReadCallback(response) {
      for(let notification of this.$store.getters.notifications){
        if(notification.id == this.selectedNotificationIdToRead) {

          if (!notification['' + this.relationName+'s'][0]['notification_'+ this.relationName].read ){


            let counter = this.$store.getters.notificationNumber;

            this.$store.commit('setNotificationNumber', --counter)
          }

          notification['' + this.relationName+'s'][0]['notification_'+ this.relationName].read = true


        }
      }
      this.$store.commit('setLoadingModal', false)
      this.notificationDialog = false
    },

    moreNotificationsButton(){
      this.$router.push({path: '/notifications-page'})
    },

    removeObjectWithId(arr, id) {
      const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

      if (objWithIdIndex > -1) {
        arr.splice(objWithIdIndex, 1);
      }

      return arr;
    }

  },
  created(){
   this.getNotifications()
  },
  computed: {
    badgeValue() {
      return this.$store.getters.notificationNumber > 99 ? '99+' : this.$store.getters.notificationNumber;
    },
  },
  watch:{
    '$store.getters.readNotifications': function (value) {

      if(value){


        let currentNOtifications = JSON.parse(JSON.stringify(this.$store.getters.notifications))


          this.$store.commit("setNotifications", this.removeObjectWithId(currentNOtifications, value.id))
          let counter = this.$store.getters.notificationNumber;
          this.$store.commit('setNotificationNumber', --counter)

      }

    }
  }
}
</script>

<style scoped>

.notificationList {
  padding: 2px 1px 2px 1px;
}

.mailIcon {
  transform: rotate(0deg);
}

.mailIconRotate {
  animation-name: rotate;
  animation-duration: 20s;
  animation: rotate 1s;
  animation-iteration-count: 15;
}

.v-menu__content::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.v-menu__content::-webkit-scrollbar-track {
  background: #b5c7c9; /* Background color of the scrollbar track */
}

.v-menu__content::-webkit-scrollbar-thumb {
  background: #696969; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners for the scrollbar thumb */
}

@keyframes rotate {
  0% {
    transform: rotate(30deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(30deg);
  }
}

</style>
