<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-row class="mb-1">
          <v-col class="mx-auto text-center font-weight-light headline">
            <div>
              {{ 'Χώροι Έδρας και Παραρτημάτων Λέσχης' }}
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="center" class="mb-2">
          <v-col cols="12">
            <v-data-table :headers="placesHeaders"
                          :items="places"
                          :items-per-page="10"
                          :footer-props="{page:1,
                                          pageCount:1,
                                          showCurrentPage:true,
                                          itemsPerPageOptions:[5,10,15,30],
                                          showFirstLastPage:true,
                                          itemsPerPageText:'Χώροι ανά σελίδα:',
                                          itemsPerPageAllText:'Όλα'}"
                          no-data-text="Δέν υπάρχουν αποτελέσματα."
                          class="elevation-3 mx-5 px-2">
              <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} εώς {{ items.pageStop }} από {{ items.itemsLength }}
              </template>
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>
                    <v-row>
                      <v-col>
                        <v-select label="Παραρτήματα"
                                  :items="branches"
                                  return-object
                                  :item-text="item =>item.name + ' (Χώροι:' + item.places_count + ')'"
                                  dense
                                  no-data-text="Δεν υπάρχουν καταχωρήσεις"
                                  v-model="selectedBranch"
                                  @change="selectBranchToViewPlaces(selectedBranch)">
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-toolbar-title>
                  <v-spacer/>
                  <v-btn v-if="selectedBranch.is_default"
                         color="primary" fab small
                         @click="openDialogToAddPlace"
                  > <!-- :disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.upload_file="{ item }">
                <td class="text-center d-sm-table-cell d-flex click-cursor">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon color="primary" small
                             @click="openUploadDialod(item)"
                      > <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                        <v-icon color="primary"
                                v-on="on"
                        >
                          mdi-upload
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ 'Ανέβασμα αρχείου' }}</span>
                  </v-tooltip>
                </td>
              </template>
              <template v-slot:item.lease_paper="{ item }">
                <td class="text-center d-sm-table-cell d-flex click-cursor"
                    v-if="(item.place_file != null)">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon color="primary" small
                             @click="downloadFile(item)">
                        <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                        <v-icon color="primary"
                                v-on="on"
                        >
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ 'Κατέβασμα αρχείου' }}</span>
                  </v-tooltip>
                </td>
                <td class="text-center d-sm-table-cell d-flex click-cursor" v-else>
                  {{ '-' }}
                </td>
              </template>
              <template v-slot:item.place_status="{ item }">
                <td class="text-center d-sm-table-cell d-flex click-cursor">
                  <v-chip v-if=" item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.APPROVED"
                          class="white--text" color="green">{{ item.place_status.status }}
                  </v-chip>
                  <v-chip v-else-if="item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.DELETED
                                      || item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.REJECTED
                                      || item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.UNLICENSED
                                      || item.place_status.id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.SUSPENDED"
                          color="red" class="white--text">{{ item.place_status.status }}
                  </v-chip>
                  <v-chip v-else>{{ item.place_status.status }}</v-chip>
                </td>
              </template>

              <template v-slot:item.file="{ item }">
                <td class="text-center d-sm-table-cell d-flex click-cursor" v-if="(item.place_licencings.length > 0) &&
                (item.place_status_id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.APPROVED ||
                item.place_status_id == $store.getters.loggedinUser.CONSTANTS.PLACE.STATUS.REJECTED)">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-btn icon color="primary" small
                             @click="getLicencingDocument(item)">
                        <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                        <v-icon color="primary"
                                v-on="on"
                        >
                          mdi-download
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ 'Κατέβασμα αρχείου' }}</span>
                  </v-tooltip>
                </td>
                <td class="text-center d-sm-table-cell d-flex click-cursor" v-else>
                  {{ '-' }}
                </td>
              </template>

              <template v-slot:item.user="{ item }">
                <td class="text-center d-sm-table-cell d-flex click-cursor" @click="openPlaceUserDialog(item)">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" v-on="on">mdi-account</v-icon>
                    </template>
                    <span>{{
                        item.user.user_branches[0].first_name
                      }} {{ item.user.user_branches[0].last_name }} {{ item.user.vat }}</span>
                  </v-tooltip>
                </td>
              </template>
              <template v-slot:item.delete="{ item }">
                <td class="text-center d-sm-table-cell d-flex click-cursor">
                  <v-btn icon color="red"
                         :disabled=!selectedBranch.is_default
                         @click="openDeletePlaceDialog(item)"
                  > <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                    <v-icon
                    > <!--:disabled="($store.getters.clubStatusToCheckForDissolution == 2)"-->
                      mdi-close
                    </v-icon>
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-col>

        </v-row>
      </v-col>
    </v-row>


    <v-dialog v-model="dialogToAddPlace" persistent max-width="850px">
      <v-card>
        <v-card-title v-if="selectedBranch.is_default == true || selectedBranch.is_default == 'true'"
                      class="primary white--text">
          Προσθήκη χώρου λέσχης
        </v-card-title>
        <v-card-title v-else class="primary white--text">
          Προσθήκη χώρου παραρτήματος
        </v-card-title>
        <v-card-text v-if="!((selectedBranch.is_default == true) || (selectedBranch.is_default == 'true'))"
                     class="mt-8">
          <v-row class="pl-5 pr-2">
            Στοιχεία χώρου:
          </v-row>
          <v-row class="pl-2 pr-2">
            <v-col>
              <v-text-field v-model="newPlaceAddress"
                            outlined
                            :rules="nameRules"
                            placeholder="Οδός και αριθμός"
                            label="Διεύθυνση"/>
            </v-col>
            <v-col>
              <v-text-field v-model="newPlaceCity"
                            outlined
                            :rules="nameRules"
                            label="Δήμος"/>
            </v-col>
            <v-col>
              <v-text-field v-model="newPlacePostalCode"
                            outlined
                            :rules="nameRules"
                            label="Ταχυδρομικός κώδικας"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select :items="placeType"
                        v-model="selectedPlaceType"
                        item-text="type"
                        label="Κατηγορία"
                        no-data-text="Δεν υπάρχουν καταχωρήσεις"
                        return-object/>
            </v-col>
            <v-col>
              <v-file-input small-chips v-model="file" :accept="['.pdf', '.png', '.jpg', '.jpeg']"
                            label="Μισθωτήριο χώρου" append-icon="mdi-file"></v-file-input>
            </v-col>
          </v-row>
          <v-row class="mt-2 mb-2 pl-2 pr-2">
            <v-divider/>
          </v-row>
          <v-row class="pl-5 pr-2">
            Υπεύθυνος χώρου:
          </v-row>
          <v-row class="pl-2 pr-2">
            <v-col>
              <v-text-field v-model="newPlaceUser.vat"
                            outlined
                            dense
                            :rules="vatRule" counter="9"
                            label="Α.Φ.Μ."
                            type="number" hide-spin-buttons/>
            </v-col>
            <v-col>
              <v-text-field v-model="newPlaceUser.identification_document"
                            outlined
                            dense
                            :rules="nameRules"
                            label="Αριθμός ταυτότητας"/>
            </v-col>
          </v-row>
          <v-row class="pl-2 pr-2 mt-0">
            <v-col>
              <v-text-field v-model="newPlaceUser.first_name"
                            outlined
                            dense
                            :rules="nameRules"
                            label="Όνομα"/>
            </v-col>
            <v-col>
              <v-text-field v-model="newPlaceUser.last_name"
                            outlined
                            dense
                            :rules="nameRules"
                            label="Επώνυμο"/>
            </v-col>
            <v-col>
              <v-text-field v-model="newPlaceUser.father_name"
                            outlined
                            dense
                            :rules="nameRules"
                            label="Πατρώνυμο"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else class="mt-8">
          <v-row class="pl-5 pr-2">
            Στοιχεία χώρου:
          </v-row>
          <v-row class="pl-2 pr-2">
            <v-col>
              <v-text-field v-model="newPlaceAddress"
                            outlined
                            :rules="nameRules"
                            placeholder="Οδός και αριθμός"
                            label="Διεύθυνση"/>
            </v-col>
            <v-col>
              <v-text-field v-model="newPlaceCity"
                            outlined
                            :rules="nameRules"
                            label="Δήμος"/>
            </v-col>
            <v-col>
              <v-text-field v-model="newPlacePostalCode"
                            outlined
                            :rules="nameRules"
                            label="Ταχυδρομικός κώδικας"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select :items="placeType"
                        v-model="selectedPlaceType"
                        item-text="type"
                        label="Κατηγορία"
                        no-data-text="Δεν υπάρχουν καταχωρήσεις"
                        return-object/>
            </v-col>
            <v-col>
              <v-file-input small-chips v-model="file" :accept="['.pdf', '.png', '.jpg', '.jpeg']"
                            label="Μισθωτήριο χώρου" append-icon="mdi-file"></v-file-input>
            </v-col>
          </v-row>
          <div v-if="newPlaceUser.identification_document != ''">
            <v-divider/>
            <v-row class="pl-5 pr-2 mt-4">
              Υπεύθυνος χώρου:
            </v-row>
            <v-row class="pl-2 pr-2">
              <v-col>
                <v-text-field v-model="newPlaceUser.vat"
                              outlined
                              dense
                              disabled
                              label="Α.Φ.Μ."
                              type="number" hide-spin-buttons/>
              </v-col>
              <v-col>
                <v-text-field v-model="newPlaceUser.user_branches[0].identification_document"
                              outlined
                              dense
                              disabled
                              label="Αριθμός ταυτότητας"/>
              </v-col>
            </v-row>
            <v-row class="pl-2 pr-2 mt-0">
              <v-col>
                <v-text-field v-model="newPlaceUser.user_branches[0].first_name"
                              outlined
                              dense
                              disabled
                              label="Όνομα"/>
              </v-col>
              <v-col>
                <v-text-field v-model="newPlaceUser.user_branches[0].last_name"
                              outlined
                              dense
                              disabled
                              label="Επώνυμο"/>
              </v-col>
              <v-col>
                <v-text-field v-model="newPlaceUser.user_branches[0].father_name"
                              outlined
                              dense
                              disabled
                              label="Πατρώνυμο"/>
              </v-col>
            </v-row>
          </div>
          <v-row>
            <v-col align="center">
              <v-btn class="primary white--text"
                     @click="addRepresentativeDialog = true">Αναζήτηση υπευθύνου
              </v-btn> <!--:disabled="($store.getters.selectedClub.clubstatus_id == 2)"-->
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text
                 @click="closeDialogToAddPlace">
            Άκυρο
          </v-btn>
          <v-spacer/>
          <v-btn color="primary"
                 class="mr-5 ml-2"
                 :disabled="!fieldsAreFilled"
                 @click="savePlaseAndCloseDialog">
            Αποθήκευση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="500px" persistent v-model="addRepresentativeDialog">
      <v-card>
        <v-card-title class="primary white--text">
          Αναζήτηση υπευθύνου χώρου
        </v-card-title>
        <v-card-text class="mt-5">
          <v-row>
            <v-col>
              <v-text-field v-model="findPlaceUser.vat"
                            outlined
                            dense
                            :rules="nameRules"
                            label="Α.Φ.Μ."
                            type="number" hide-spin-buttons/>
            </v-col>
          </v-row>
          <div v-if="findPlaceUser.identification_document != ''">
            <v-row class="pl-5 pr-2">
              Υπεύθυνος χώρου:
            </v-row>
            <v-row class="pl-2 pr-2">
              <v-col>
                <v-text-field v-model="findPlaceUser.vat"
                              outlined
                              dense
                              disabled
                              :rules="nameRules"
                              label="Α.Φ.Μ."
                              type="number" hide-spin-buttons/>
              </v-col>
              <v-col>
                <v-text-field v-model="findPlaceUser.user_branches[0].identification_document"
                              outlined
                              dense
                              disabled
                              :rules="nameRules"
                              label="Αριθμός ταυτότητας"/>
              </v-col>
            </v-row>
            <v-row class="pl-2 pr-2 mt-0">
              <v-col>
                <v-text-field v-model="findPlaceUser.user_branches[0].first_name"
                              outlined
                              dense
                              disabled
                              :rules="nameRules"
                              label="Όνομα"/>
              </v-col>
              <v-col>
                <v-text-field v-model="findPlaceUser.user_branches[0].last_name"
                              outlined
                              dense
                              disabled
                              :rules="nameRules"
                              label="Επώνυμο"/>
              </v-col>
              <v-col>
                <v-text-field v-model="findPlaceUser.user_branches[0].father_name"
                              outlined
                              dense
                              disabled
                              :rules="nameRules"
                              label="Πατρώνυμο"/>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeAddRepresentativeDialog()">
            Κλείσιμο
          </v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="checkUserIsBranchMember()" v-if="findPlaceUser.identification_document == ''">
            Αναζήτηση
          </v-btn>
          <v-btn color="primary" @click="addUser()" v-else>
            Προσθήκη υπευθύνου
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="placeUserDialog" persistent max-width="800px">
      <v-card>
        <v-card-title style="background-color: #003375" class="white--text">
          Υπεύθυνος Χώρου
        </v-card-title>
        <v-card-text>
          <v-row class="mt-2 ml-1">
            <v-col cols="2">
              <v-row>
                ΑΦΜ:
              </v-row>
              <v-row>
                {{ placeWithChangedUser.user.vat }}
              </v-row>
            </v-col>
            <v-col cols="5">
              <v-row>
                Ονοματεπώνυμο:
              </v-row>
              <v-row>
                {{ placeWithChangedUser.user.user_branches[0].first_name }}
                {{ placeWithChangedUser.user.user_branches[0].last_name }}
              </v-row>
            </v-col>
            <v-col cols="3">
              <v-row>
                Πατρώνυμο:
              </v-row>
              <v-row>
                {{ placeWithChangedUser.user.user_branches[0].father_name }}
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-row>
                ΑΔΤ:
              </v-row>
              <v-row>
                {{ placeWithChangedUser.user.user_branches[0].identification_document }}
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-subtitle class="govgr-!-font-weight-bold mt-2" v-if=" selectedBranch.is_default">
          <!--($store.getters.clubStatusToCheckForDissolution != 2) &&-->
          Αντικατάσταση υπευθύνου χώρου
        </v-card-subtitle>
        <v-card-text v-if="selectedBranch.is_default"> <!--($store.getters.clubStatusToCheckForDissolution != 2) &&-->
          <v-row>
            <v-col cols="2">
              <v-text-field dense disabled label="ΑΦΜ" v-model="replacedPlaceUser.vat"/>
            </v-col>
            <v-col cols="3">
              <v-text-field dense disabled label="Όνομα" v-model="replacedPlaceUser.user_branches[0].first_name"/>
            </v-col>
            <v-col cols="3">
              <v-text-field dense disabled label="Επώνυμο" v-model="replacedPlaceUser.user_branches[0].last_name"/>
            </v-col>
            <v-col cols="2">
              <v-text-field dense disabled label="Πατρώνυμο" v-model="replacedPlaceUser.user_branches[0].father_name"/>
            </v-col>
            <v-col cols="2">
              <v-text-field dense disabled label="ΑΔΤ"
                            v-model="replacedPlaceUser.user_branches[0].identification_document"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="closePlaceUserDialog">
            Κλείσιμο
          </v-btn>
          <v-spacer/>
          <v-btn class="primary" v-if="replacedPlaceUser.identification_document == ''"
                 :disabled="!selectedBranch.is_default" @click="addRepresentativeDialog = true">
            <!--($store.getters.clubStatusToCheckForDissolution == 2) ||-->
            Αναζήτηση υπευθύνου
          </v-btn>
          <v-btn class="primary" v-else @click="replaceThePlaceUser" :disabled="placeUserFieldsAreEmpty">
            Αντικατάσταση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletePlaceDialog" persistent max-width="400px">
      <v-card>
        <v-card-title class="primary white--text">
          Αλλαγή κατάστασης χώρου
        </v-card-title>
        <v-card-text class="mt-4 govgr-!-font-size-19">
          Είστε σίγουρος/η για τη διαγραφή του χώρου;
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="deletePlaceDialog = false">
            Όχι
          </v-btn>
          <v-spacer/>
          <v-btn class="red white--text" @click="closePlace(selectedItemForDelete)">
            Ναι, διαγραφή
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="uploadDialog" width="500px">
      <v-card>
        <v-card-title class="primary white--text mb-2">
          Ανέβασμα αρχείου μισθωτηρίου
        </v-card-title>
        <v-card-text>
          <v-file-input small-chips v-model="file" :accept="['.pdf', '.png', '.jpg', '.jpeg']" label="Μισθωτήριο χώρου"
                        append-icon="mdi-file"></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="uploadDialog=false">
            Κλείσιμο
          </v-btn>
          <v-spacer/>
          <v-btn color="primary" @click="uploadFile()" :disabled="!fieldsAreFilledForFileDialog">
            Μεταφόρτωση
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import requests from "@/requests";

export default {
  name: "Places",
  data: () => ({
    file: null,
    newPlaceId: null,
    addedNewPlace: false,
    uploadDialog: false,
    branches: [],
    branchHeaders: [
      {
        text: 'Επωνυμία',
        align: 'center',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Χώροι',
        align: 'center',
        sortable: false,
        value: 'places_count'
      },
    ],
    places: [],
    placesHeaders: [
      {
        text: 'Τύπος',
        align: 'center',
        sortable: false,
        value: 'place_type.type',
      },
      {
        text: 'Διεύθυνση',
        align: 'center',
        sortable: false,
        value: 'address',
      },
      {
        text: 'Δήμος',
        align: 'center',
        sortable: false,
        value: 'city',
      },
      {
        text: 'Ταχυδρομικός κώδικας',
        align: 'center',
        sortable: false,
        value: 'postal_code',
      },
      {
        text: 'Κατάσταση',
        align: 'center',
        sortable: false,
        value: 'place_status',
      },
      {
        text: 'Αλλαγή μισθωτηρίου',
        align: 'center',
        sortable: false,
        value: 'upload_file',
      },
      {
        text: 'Μισθωτήριο',
        align: 'center',
        sortable: false,
        value: 'lease_paper',
      },
      {
        text: 'Έγγραφο',
        align: 'center',
        sortable: false,
        value: 'file',
      },
      {
        text: 'Υπεύθυνος',
        align: 'center',
        sortable: false,
        value: 'user'
      },
      {
        text: 'Λειτουργίες',
        align: 'center',
        sortable: false,
        value: 'delete'
      }
    ],
    selectedBranch: {},
    dialogToAddPlace: false,
    deletePlaceDialog: false,
    selectedItemForDelete: {},
    placeType: [
      {
        id: 1,
        type: 'Γραφείο',
      },
      {
        id: 2,
        type: 'Εντευκτήριο',
      }
    ],
    selectedPlaceType: {},
    newPlaceAddress: '',
    newPlaceCity: '',
    newPlacePostalCode: '',
    newPlaceUser: {
      vat: '',
      identification_document: '',
      first_name: '',
      last_name: '',
      father_name: '',
      user_branches: [
        {
          first_name: '',
          last_name: '',
          father_name: '',
          identification_document: '',
        }
      ]
    },
    nameRules: [
      v => !!v || 'Το πεδίο είναι απαραίτητο.',
      v => !!v && v.trim() != '' && v.trim().length >= 3 || 'Το πεδίο είναι απαραίτητο.',
    ],
    vatRule: [
      v => !!v || 'Το πεδίο είναι απαραίτητο',
      v => /^[0-9]{9}$/.test(v) || 'Ο ΑΦΜ δεν είναι έγκυρος'
    ],
    placeUserDialog: false,
    placeWithChangedUser: {
      user: {
        vat: '',
        first_name: '',
        last_name: '',
        father_name: '',
        identification_document: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
          }
        ]
      }
    },
    replacedPlaceUser: {
      vat: '',
      first_name: '',
      last_name: '',
      father_name: '',
      identification_document: '',
      user_branches: [
        {
          first_name: '',
          last_name: '',
          father_name: '',
          identification_document: '',
        }
      ]
    },
    addRepresentativeDialog: false,
    findPlaceUser: {
      vat: '',
      identification_document: '',
      first_name: '',
      last_name: '',
      father_name: '',
      user_branches: [
        {
          first_name: '',
          last_name: '',
          father_name: '',
          identification_document: '',
        }
      ]
    },
    changeUser: false,
  }),
  created() {
    this.getBranchesWithPlaces()
  },
  methods: {
    closePlace(item) {
      this.$store.commit('setLoadingModal', true)

      requests.postSecureRequest('place/close-place', {place_id: item.id}, this.closePlaceCallback)
    },
    closePlaceCallback(response) {
      this.$store.commit('setThereWasAChangeInApplication', true)
      this.$store.commit('setLoadingModal', false)
      this.deletePlaceDialog = false
      if (response == true) {
        this.$store.commit('setInfoDialog', true)
        this.$store.commit('setInfoDialogObj', {text: 'Η διαγραφή σας ήταν επιτυχής.'})
      }
      this.getBranchesWithPlaces()
    },

    getBranchesWithPlaces() {
      requests.postSecureRequest('branch/get-branches-include-places', {club: this.$store.getters.selectedClub}, this.getBranchesWithPlacesCallback)
    },
    getBranchesWithPlacesCallback(response) {
      this.branches = []
      console.log(response)
      this.branches = response
      if (this.branches.length > 1) {
        this.branches.sort(function (x, y) {
          return x.is_default == true ? -1 : y.is_default == false ? 1 : 0;
        })
      }
      for (let branch of this.branches) {
        branch.places_count = branch.places.length
      }
      this.places = []
      if (this.branches.length > 0) {
        if (Object.keys(this.selectedBranch).length == 0) {
          this.selectedBranch = this.branches[0]
          this.places = this.selectedBranch.places
        } else {
          for (let branch of this.branches) {
            if (branch.id == this.selectedBranch.id) {
              this.selectedBranch = branch
              this.places = this.selectedBranch.places
            }
          }
        }
      }

      this.placeWithChangedUser = this.places[0]
    },

    selectBranchToViewPlaces(item) {
      this.selectedBranch = item
      console.log(JSON.parse(JSON.stringify(this.selectedBranch)));
      this.places = this.selectedBranch.places
    },

    openDialogToAddPlace() {
      this.dialogToAddPlace = true
    },
    closeDialogToAddPlace() {
      this.dialogToAddPlace = false

      this.newPlaceAddress = ''
      this.newPlaceCity = ''
      this.newPlacePostalCode = ''
      this.newPlaceUser = {
        vat: '',
        identification_document: '',
        first_name: '',
        last_name: '',
        father_name: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
          }
        ]
      }
    },
    savePlaseAndCloseDialog() {
      this.$store.commit('setLoadingModal', true)

      let requestObj = {
        branch_id: this.selectedBranch.id,
        address: this.newPlaceAddress,
        city: this.newPlaceCity,
        postal_code: this.newPlacePostalCode,
        place_type_id: this.selectedPlaceType.id,
        place_user: {
          vat: this.newPlaceUser.vat,
          identification_document: this.newPlaceUser.identification_document,
          first_name: this.newPlaceUser.first_name,
          last_name: this.newPlaceUser.last_name,
          father_name: this.newPlaceUser.father_name,
        },
      }

      requests.postSecureRequest('place/new-place', requestObj, this.newPlaceCallback)
    },
    newPlaceCallback(response) {

      this.newPlaceId = response.id
      this.addedNewPlace = true
      if (this.file != null) {
        this.uploadFile()
      }

      this.getBranchesWithPlaces()

      this.closeDialogToAddPlace()
      this.$store.commit('setThereWasAChangeInApplication', true)
      this.$store.commit('setLoadingModal', false)
    },

    openPlaceUserDialog(item) {
      this.placeUserDialog = !this.placeUserDialog
      this.placeWithChangedUser = item
      this.changeUser = true
    },
    closePlaceUserDialog() {
      this.placeUserDialog = !this.placeUserDialog
      this.changeUser = false
      this.replacedPlaceUser = {
        vat: '',
        first_name: '',
        last_name: '',
        father_name: '',
        identification_document: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
          }
        ]
      }
    },
    replaceThePlaceUser() {
      let requestObj = {
        user: this.replacedPlaceUser,
        place_id: this.placeWithChangedUser.id
      }


      requests.postSecureRequest('place/replace-user', requestObj, this.replaceThePlaceUserCallback)
    },
    async replaceThePlaceUserCallback(response) {
      this.replacedPlaceUser = {
        vat: '',
        first_name: '',
        last_name: '',
        father_name: '',
        identification_document: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
          }
        ]
      }
      this.$store.commit('setThereWasAChangeInApplication', true)
      this.closePlaceUserDialog()
      await this.getBranchesWithPlaces()
      this.selectBranchToViewPlaces(this.selectedBranch)
    },

    openDeletePlaceDialog(item) {
      this.selectedItemForDelete = item
      this.deletePlaceDialog = true
    },

    uploadFile() {
      let formData = new FormData();
      formData.append('file', this.file, encodeURI(this.file.name));
      formData.append('place_id', this.newPlaceId);
      this.uploadDialog = false
      requests.uploadFilePostAsync('place/upload-place-file', formData, this.uploadFileCallback)
    },

    uploadFileCallback(response) {
      if (this.addedNewPlace) {
        this.$store.commit('setInfoDialog', true)
        this.$store.commit('setInfoDialogObj', {text: 'Η προσθήκη χώρου και η μεταφόρτωση του αρχείου σας ήταν επιτυχής.'})
        this.addedNewPlace = false
      } else {
        this.$store.commit('setInfoDialog', true)
        this.$store.commit('setInfoDialogObj', {text: 'Η μεταφόρτωση του αρχείου σας ήταν επιτυχής.'})
      }

      this.$store.commit('setThereWasAChangeInApplication', true)
      this.file = null
      this.getBranchesWithPlaces()
    },

    openUploadDialod(item) {
      this.newPlaceId = item.id
      this.uploadDialog = true
    },

    downloadFile(item) {
      requests.downloadFileRequest('place/download-place-file/' + item.id + '/' + item.place_file.id)
    },

    closeAddRepresentativeDialog() {
      this.findPlaceUser = {
        vat: '',
        identification_document: '',
        first_name: '',
        last_name: '',
        father_name: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
          }
        ]
      }
      this.addRepresentativeDialog = false
    },

    checkUserIsBranchMember() {
      this.$store.commit('setLoadingModal', true)
      requests.postSecureRequest('branch/check-user-is-branch-member',
          {
            branch_id: this.$store.getters.selectedClub.myBranchesOnClub[0].id,
            vat: this.findPlaceUser.vat
          }, this.checkUserIsBranchMemberCallback)
    },
    checkUserIsBranchMemberCallback(response) {
      this.findPlaceUser.vat = response.vat
      this.findPlaceUser.identification_document = response.identification_document
      this.findPlaceUser.first_name = response.first_name
      this.findPlaceUser.last_name = response.last_name
      this.findPlaceUser.father_name = response.father_name
      this.findPlaceUser.user_branches[0].first_name = response.user_branches[0].first_name
      this.findPlaceUser.user_branches[0].last_name = response.user_branches[0].last_name
      this.findPlaceUser.user_branches[0].father_name = response.user_branches[0].father_name
      this.findPlaceUser.user_branches[0].identification_document = response.user_branches[0].identification_document
      this.$store.commit('setLoadingModal', false)
    },

    addUser() {
      if (this.changeUser == true) {
        this.replacedPlaceUser.vat = this.findPlaceUser.vat
        this.replacedPlaceUser.identification_document = this.findPlaceUser.identification_document
        this.replacedPlaceUser.first_name = this.findPlaceUser.first_name
        this.replacedPlaceUser.last_name = this.findPlaceUser.last_name
        this.replacedPlaceUser.father_name = this.findPlaceUser.father_name
        this.replacedPlaceUser.user_branches[0].first_name = this.findPlaceUser.user_branches[0].first_name
        this.replacedPlaceUser.user_branches[0].last_name = this.findPlaceUser.user_branches[0].last_name
        this.replacedPlaceUser.user_branches[0].father_name = this.findPlaceUser.user_branches[0].father_name
        this.replacedPlaceUser.user_branches[0].identification_document = this.findPlaceUser.user_branches[0].identification_document
        this.changeUser = false
      } else {
        this.newPlaceUser.vat = this.findPlaceUser.vat
        this.newPlaceUser.identification_document = this.findPlaceUser.identification_document
        this.newPlaceUser.first_name = this.findPlaceUser.first_name
        this.newPlaceUser.last_name = this.findPlaceUser.last_name
        this.newPlaceUser.father_name = this.findPlaceUser.father_name
        this.newPlaceUser.user_branches[0].first_name = this.findPlaceUser.user_branches[0].first_name
        this.newPlaceUser.user_branches[0].last_name = this.findPlaceUser.user_branches[0].last_name
        this.newPlaceUser.user_branches[0].father_name = this.findPlaceUser.user_branches[0].father_name
        this.newPlaceUser.user_branches[0].identification_document = this.findPlaceUser.user_branches[0].identification_document
      }
      this.findPlaceUser = {
        vat: '',
        identification_document: '',
        first_name: '',
        last_name: '',
        father_name: '',
        user_branches: [
          {
            first_name: '',
            last_name: '',
            father_name: '',
            identification_document: '',
          }
        ]
      }
      this.addRepresentativeDialog = false
    },

    getLicencingDocument(item) {
      requests.downloadFileRequest('place/download-licencing/' + item.place_licencings[0].id)
    },

  },
  computed: {
    fieldsAreFilled() {
      return this.newPlaceAddress.length >= 3 &&
          this.newPlaceCity.length >= 3 &&
          this.newPlacePostalCode.length >= 3 &&
          Object.keys(this.selectedPlaceType).length > 0 &&
          this.newPlaceUser.vat.length >= 3 &&
          this.newPlaceUser.first_name.length >= 3 &&
          this.newPlaceUser.last_name.length >= 3 &&
          this.newPlaceUser.father_name.length >= 3 &&
          this.newPlaceUser.identification_document.length >= 3
      //this.file != null;
    },

    fieldsAreFilledForFileDialog() {
      return this.file != null;
    },

    placeUserFieldsAreEmpty() {
      if (this.replacedPlaceUser.vat == '') {
        return true
      }
      if (this.replacedPlaceUser.first_name == '') {
        return true
      }
      if (this.replacedPlaceUser.last_name == '') {
        return true
      }
      if (this.replacedPlaceUser.father_name == '') {
        return true
      }
      if (this.replacedPlaceUser.identification_document == '') {
        return true
      }
      return false
    },

  }
}
</script>

<style scoped>

</style>